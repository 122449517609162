import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import ReactHtmlParser from "html-react-parser";

import Button from "../components/Button";
import Map from "../components/KakaoMap";
import Review from "../components/Review";
import classApi from "../api/class";
import { selectedClassReviewState } from "../store/reviewState";

import student1Image from "../assets/img/graphics/icons/student1.png";
import student2Image from "../assets/img/graphics/icons/student2.png";
import student3Image from "../assets/img/graphics/icons/student3.png";

const TabContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #fec000;
  }
`;

export default function ClassDetail() {
  const [classInfo, setClassInfo] = useState(undefined);

  const introduceRef = useRef(null);
  const lecturerRef = useRef(null);
  const programRef = useRef(null);
  const dateRef = useRef(null);
  const partnerRef = useRef(null);
  const reviewRef = useRef(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getClass = async () => {
      try {
        const response = await classApi.getClassInfo(id);

        setClassInfo(response.data);
      } catch (err) {
        alert(
          "내용을 불러오는데 오류가 생겼습니다. 잠시 후 다시 시도해주세요."
        );
      }
    };

    getClass();
  }, []);

  const reviews = useRecoilValue(selectedClassReviewState(classInfo?.id));

  const onClickTap = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main className="position-relative">
      <section
        className="py-lg-12 py-5 px-lg-15 px-4"
        style={{ background: "#f4f4f5" }}
      >
        {classInfo?.title.split("/").map((text, index) => (
          <h3 className="fw-bold" key={index}>
            {text}
            <br />
          </h3>
        ))}
        <p className="mb-0">{classInfo?.lecturer}</p>
      </section>
      <section
        className="text-center"
        style={{ borderBottom: "1px solid #c8c8c8" }}
      >
        <div className="container py-lg-4 py-3 px-lg-11">
          <div className="row">
            <TabContainer
              className="col-lg-2 col-2 fw-bold"
              onClick={() => onClickTap(introduceRef)}
            >
              <div className="button">소개</div>
            </TabContainer>
            <TabContainer
              className="col-lg-2 col-2 fw-bold"
              onClick={() => onClickTap(lecturerRef)}
            >
              <div className="button">강사</div>
            </TabContainer>
            <TabContainer
              className="col-lg-2 col-2 fw-bold"
              onClick={() => onClickTap(programRef)}
            >
              <div className="button">프로그램</div>
            </TabContainer>
            <TabContainer
              className="col-lg-2 col-2 fw-bold"
              onClick={() => onClickTap(dateRef)}
            >
              <div className="button">일정</div>
            </TabContainer>
            <TabContainer
              className="col-lg-2 col-2 fw-bold"
              onClick={() => onClickTap(partnerRef)}
            >
              <div className="button">파트너</div>
            </TabContainer>
            <TabContainer
              className="col-lg-2 col-2 fw-bold"
              onClick={() => onClickTap(reviewRef)}
            >
              <div className="button">리뷰</div>
            </TabContainer>
          </div>
        </div>
      </section>
      <div className="container py-lg-10 py-5 px-lg-11" ref={introduceRef}>
        <section>
          <h4
            className="text-center pb-lg-3 pb-5 fw-bolder"
            style={{ borderBottom: "1px solid black" }}
          >
            {classInfo?.sub_title}
          </h4>
          <div className="row align-items-center">
            <div className="col-lg-6 flex-center py-lg-4 py-4">
              <img
                src={classInfo?.class_image}
                className="img-fluid w-lg-90"
                style={{ borderRadius: "5px" }}
              />
            </div>
            <div className="col-lg-6 px-lg-7 px-3 py-lg-4">
              <h5 className="fw-bold mt-lg-2">어떤 강의인가요?</h5>
              <div className="my-lg-4">
                {classInfo && ReactHtmlParser(classInfo?.class_info)}
              </div>
              <div className="d-flex align-items-center flex-column">
                {classInfo?.is_close === "N" ? (
                  <>
                    {classInfo?.is_paid === "Y" && (
                      <>
                        <div
                          className="w-lg-90 w-100 d-flex justify-content-between mb-lg-4 mb-3 py-lg-3 px-lg-4 py-3 px-3"
                          style={{
                            border: "1px solid #c8c8c8",
                            borderRadius: "5px",
                          }}
                        >
                          <span className="fw-bolder">수강료</span>
                          <span className="">{classInfo?.class_cost}원</span>
                        </div>
                        <p
                          style={{ color: "#ff3d0d" }}
                          className="mb-lg-3 mb-3"
                        >
                          ✔️ 결제까지 하셔야 수강신청이 완료 됩니다.
                        </p>
                      </>
                    )}
                    <Button
                      width="20"
                      text="신청하기"
                      background="#000000"
                      color="#fec230"
                      border="none"
                      onClick={() =>
                        navigate(`/enroll_in/class/${classInfo?.id}`, {
                          state: {
                            classIdx: classInfo?.idx,
                            title: classInfo?.title,
                            lecturer: classInfo?.lecturer,
                            lectureDate: classInfo?.lecture_date,
                            date: classInfo?.class_date,
                            place: classInfo?.place,
                            qna: classInfo?.qna,
                            isPaid: classInfo?.is_paid,
                            cost: classInfo?.class_cost,
                          },
                        })
                      }
                    />
                  </>
                ) : (
                  <p
                    className="px-lg-3 py-lg-3"
                    style={{ borderRadius: "5px", background: "#fef4e5" }}
                  >
                    <span style={{ color: "#ff3d0d" }}>
                      해당 강의 신청은 선착순 마감으로 종료되었습니다.
                    </span>
                    <br />곧 다음회차 강의가 개설될 예정입니다.
                    <br />
                    많은 관심과 참여 부탁드립니다 🙂
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="" style={{ background: "#f4f4f5" }}>
        <div className="container py-lg-10 py-5 px-lg-11">
          <h4 className="text-center pb-lg-3 fw-bolder">강의 대상</h4>
          <div className="row justify-content-center mt-lg-5">
            <div className="col-lg-4 col-4 flex-center flex-column">
              <img src={student1Image} className="w-xl-30 w-lg-30 w-50" />
              <div className="mt-lg-3 text-center">
                {classInfo?.target_1.split("/").map((target, index) => (
                  <p key={index} className="mb-0">
                    {target}
                  </p>
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-4 flex-center flex-column">
              <img src={student2Image} className="w-xl-30 w-lg-30 w-50" />
              <div className="mt-lg-3 text-center">
                {classInfo?.target_2.split("/").map((target, index) => (
                  <p key={index} className="mb-0">
                    {target}
                  </p>
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-4 flex-center flex-column">
              <img src={student3Image} className="w-xl-30 w-lg-30 w-50" />
              <div className="mt-lg-3 text-center">
                {classInfo?.target_3.split("/").map((target, index) => (
                  <p key={index} className="mb-0">
                    {target}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div ref={lecturerRef}></div>
        </div>
      </section>
      <section className="container py-lg-10 py-5 px-lg-11">
        <h4 className="text-center pb-lg-3 fw-bolder">강사 소개</h4>
        <div className="row align-items-center">
          <div className="col-lg-6 flex-center py-lg-4 py-4">
            <img
              src={classInfo?.lecture_image}
              className="img-fluid w-xl-60 w-lg-70 w-50"
            />
          </div>
          <div className="col-lg-6 px-lg-7 px-3 py-lg-4">
            <div>{classInfo && ReactHtmlParser(classInfo?.lecture_info)}</div>
          </div>
        </div>
        <div ref={programRef}></div>
      </section>
      <section
        className="py-lg-10 py-5 px-lg-11 px-3"
        style={{ background: "#f4f4f5" }}
      >
        <div className="container">
          <h4 className="text-center pb-lg-5 fw-bolder">강의 프로그램</h4>
          <ol className="list-timeline-v list-unstyled mb-0">
            {classInfo?.program_1 && (
              <li data-aos="fade-left">
                <div
                  className="timeline-icon shadow text-white rounded-4"
                  style={{ background: "#A9D388" }}
                >
                  <i className="bx bx-donate-blood"></i>
                </div>
                <div className="d-block flex-column mb-5">
                  <small>PART 1</small>
                  <h6 className="mt-1 fw-bold">{classInfo?.program_1}</h6>
                </div>
              </li>
            )}
            {classInfo?.program_2 && (
              <li data-aos="fade-left">
                <div
                  className="timeline-icon shadow text-white rounded-4"
                  style={{ background: "#8CBE64" }}
                >
                  <i className="bx bx-leaf"></i>
                </div>
                <div className="d-block flex-column mb-5">
                  <small>PART 2</small>
                  <h6 className="mt-1 fw-bold">{classInfo?.program_2}</h6>
                </div>
              </li>
            )}
            {classInfo?.program_3 && (
              <li data-aos="fade-left">
                <div
                  className="timeline-icon shadow text-white rounded-4"
                  style={{ background: "#6EA443" }}
                >
                  <i className="bx bxs-tree"></i>
                </div>
                <div className="d-block flex-column mb-5">
                  <small>PART 3</small>
                  <h6 className="mt-1 fw-bold">{classInfo?.program_3}</h6>
                </div>
              </li>
            )}
            {classInfo?.program_4 && (
              <li data-aos="fade-left">
                <div
                  className="timeline-icon shadow text-white rounded-4"
                  style={{ background: "#4D8323" }}
                >
                  <i className="bx bx-landscape"></i>
                </div>
                <div className="d-block flex-column mb-5">
                  <small>PART 4</small>
                  <h6 className="mt-1 fw-bold">{classInfo?.program_4}</h6>
                </div>
              </li>
            )}
            {classInfo?.program_5 && (
              <li data-aos="fade-left">
                <div
                  className="timeline-icon shadow text-white rounded-4"
                  style={{ background: "#396F0E" }}
                >
                  <i className="bx bx-rocket"></i>
                </div>
                <div className="d-block flex-column mb-5">
                  <small>PART 5</small>
                  <h6 className="mt-1 fw-bold">{classInfo?.program_5}</h6>
                </div>
              </li>
            )}
          </ol>
        </div>
        <div ref={dateRef}></div>
      </section>
      <section
        className="py-lg-10 py-5 px-lg-11"
        style={{ background: "#000000" }}
      >
        <div className="container">
          <h4
            className="text-center pb-lg-5 pb-4 fw-bolder"
            style={{ color: "#fec230" }}
          >
            일정 및 장소
          </h4>
          <div className="d-flex justify-content-center">
            <div>
              <p className="mb-0">
                <span style={{ color: "#ff3d0d" }}>✓ 강의 일시: </span>
                <span style={{ color: "#ffffff" }}>
                  {classInfo?.lecture_date}
                </span>
              </p>
              <div className="mb-0 d-flex">
                <span style={{ color: "#ff3d0d" }}>✓ 모집 인원: </span>
                <span style={{ color: "#ffffff" }} className="ms-1">
                  {classInfo?.people.split("/").map((data, index) => (
                    <p key={index}> {data}</p>
                  ))}
                </span>
              </div>
              <div className="mb-0 d-flex">
                <span style={{ color: "#ff3d0d" }}>✓ 장소: </span>
                <span style={{ color: "#ffffff" }} className="ms-1">
                  {classInfo?.place.split("/").map((data, index) => (
                    <p key={index}> {data}</p>
                  ))}
                </span>
              </div>
              {classInfo?.is_paid === "Y" && (
                <div className="mb-0">
                  <span style={{ color: "#ff3d0d" }}>✓ 강의료: </span>
                  <span style={{ color: "#ffffff" }}>
                    {" "}
                    {classInfo?.class_cost} 원
                  </span>
                </div>
              )}
            </div>
          </div>
          {classInfo?.is_close === "N" && (
            <div className="d-flex justify-content-center mt-lg-5 mt-5">
              <Button
                width="20"
                text="신청하기"
                background="#fec230"
                color="#000000"
                border="none"
                onClick={() =>
                  navigate(`/enroll_in/class/${classInfo?.id}`, {
                    state: {
                      title: classInfo?.title,
                      lecturer: classInfo?.lecturer,
                      lectureDate: classInfo?.lecture_date,
                      date: classInfo?.class_date,
                      place: classInfo?.place,
                      qna: classInfo?.qna,
                    },
                  })
                }
              />
            </div>
          )}
          {classInfo?.latitude && (
            <div className="w-lg-100 mt-lg-5 mt-5 px-lg-15 px-0">
              <Map
                latitude={Number(classInfo?.latitude)}
                longitude={Number(classInfo?.longitude)}
              />
            </div>
          )}
        </div>
        <div ref={partnerRef}></div>
      </section>
      <section className="py-lg-10 py-5 px-lg-11">
        <div className="container">
          <h4 className="text-center pb-lg-5 fw-bolder">MEET OUR PARTNER</h4>
          {classInfo?.partner_image.length > 1 && (
            <div className="d-flex justify-content-center">
              <img
                src={classInfo?.partner_image}
                className="img-fluid w-xl-20 w-lg-10 w-40"
              />
            </div>
          )}
        </div>
        <div ref={reviewRef}></div>
      </section>
      <section className="py-lg-10 py-5 px-lg-11">
        <div className="container">
          <div className="my-lg-3 my-3">
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              강의 리뷰
            </h5>
            <div className="px-lg-2 px-2 py-lg-3 py-3">
              {reviews.map((review, index) => (
                <Review
                  key={index}
                  rating={Number(review.rating)}
                  content={review.content}
                  email={review.email}
                  createAt={review.create_at}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
