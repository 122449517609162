import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import TextInput from "../components/TextInput";
import Button from "../components/Button";
import LoginModal from "../components/LoginModal";
import ModalContainer from "../components/Modal";
import contactApi from "../api/contact";
import { userInfoState } from "../store/userState";
import { EMAIL_REGEXP, NAME_REGEXP, PHONE_REGEXP } from "../constants/RegExp";

import step1Image from "../assets/img/graphics/icons/write1.png";
import step2Image from "../assets/img/graphics/icons/partnership1.png";
import step3Image from "../assets/img/graphics/icons/interaction1.png";

export default function ContactService() {
  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  const [isDisabled, setIsDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isMarketingModalShow, setIsMarketingModalShow] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMarketingAgreement, setIsMarketingAgreement] = useState(false);
  const [userData, setUserData] = useState({
    name: userInfo?.name || "",
    officeName: "",
    phone: "",
    email: "",
    inquiry: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const steps = [
    {
      image: step1Image,
      content: "상담 신청 폼을 작성해서 제출해주세요.",
    },
    {
      image: step2Image,
      content: "접수 안내 문자가 발송됩니다.",
    },
    {
      image: step3Image,
      content: "원하시는 시간을 선택하시면 1:1 유선 상담이 진행됩니다.",
    },
  ];

  const isValidName = NAME_REGEXP.test(userData.name);
  const isValidEmail = EMAIL_REGEXP.test(userData.email);
  const isValidPhone = PHONE_REGEXP.test(userData.phone);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleAgreementCheck = () => {
    setIsMarketingAgreement(!isMarketingAgreement);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setIsDisabled(true);

    if (
      !userData.name ||
      !userData.email ||
      userData.phone.replaceAll(" ", "").length === 0 ||
      userData.phone.replaceAll("-", "").length !== 11
    ) {
      setIsDisabled(false);
      setIsEmpty(true);
      setErrorMessage("✓ 필수 입력란을 확인해주세요.");

      return;
    }

    if (isValidName && isValidEmail && isValidPhone) {
      try {
        const response = await contactApi.contactService({
          service: location.state.service,
          name: userData.name,
          officeName: userData.officeName,
          phone: userData.phone.replaceAll("-", ""),
          email: userData.email,
          inquiry: userData.inquiry,
          marketing: isMarketingAgreement,
        });

        if (response.result === "success") {
          setModalShow(true);
        }
      } catch (err) {
        if (
          err.response.data.error.message === "Expired access token" ||
          err.response.data.error.message === "jwt malformed" ||
          err.response.data.error.message === "Expired token"
        ) {
          alert(
            "로그인 정보가 만료되었습니다. 다시 로그인 후 서비스를 이용해주세요."
          );
          localStorage.removeItem("accessToken");
          window.location.replace("/login");
        }

        if (
          err.response.data.error?.message === "Not Saved" ||
          err.response.data.error?.message === "Database Error"
        ) {
          alert(
            "상담을 등록하는데 문제가 생겼습니다. 잠시 후 다시 이용해주세요."
          );

          return;
        }
      }
    }
  };

  useEffect(() => {
    if (userInfo?.name) {
      setUserData((prevSate) => ({
        ...prevSate,
        name: userInfo.name,
        email: userInfo.email,
      }));
    }

    if (userData.phone?.length === 11) {
      setUserData((prevSate) => ({
        ...prevSate,
        phone: userData.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }

    if (userData.phone?.length === 13) {
      setUserData((prevState) => ({
        ...prevState,
        phone: userData.phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }
  }, [userData.phone, userInfo]);

  return (
    <main>
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container px-lg-11 py-8 py-lg-6">
          <div className="row align-items-center">
            <div className="col-lg-6 me-auto mb-5 justify-content-center">
              <h2 className="mb-lg-3 mx-lg-3 mb-5">
                혼자 고민하지 마세요!
                <br />
                이제 BOOSTAR가 함께합니다
              </h2>
              <p className="fs-5 mb-lg-10 mx-lg-3">
                최적의 서비스 제공을 위해
                <br />
                BOOSTAR가 친절히 상담해드립니다.
              </p>
              {steps.map((step, index) => (
                <div
                  className="d-inline-flex w-lg-80 w-100"
                  style={{ borderBottom: "1px solid #d3d3d3" }}
                  key={index}
                >
                  <img
                    src={step.image}
                    className="img-fluid px-lg-3 py-lg-3 px-4 py-4"
                    alt="write"
                  />
                  <div className="me-2">
                    <p
                      className="mb-0"
                      style={{ color: "#FEC72F", fontWeight: "700" }}
                    >
                      {`Step ${index + 1}`}
                    </p>
                    <p className="mb-0">{step.content}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-6">
              <div
                className="py-10 px-7 shadow w-lg-90 mx-lg-11"
                style={{
                  background: "#ffffff",
                  borderRadius: "55px",
                }}
              >
                <form onSubmit={handleFormSubmit}>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>신청 서비스</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="serviceName"
                      type="text"
                      icon="bx-donate-heart"
                      value={location.state?.service}
                      readOnly
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>이름</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="name"
                      type="text"
                      placeholder="이름(실명)"
                      icon="bx-user"
                      value={userData.name || ""}
                      pattern={NAME_REGEXP}
                      errorMessage="✓ 이름을 확인해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이름을 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>회사명</span>
                    </div>
                    <TextInput
                      name="officeName"
                      type="text"
                      placeholder="Boostar"
                      icon="bx-buildings"
                      value={userData.officeName || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>연락처</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="phone"
                      type="text"
                      placeholder="01012345678"
                      icon="bx-phone"
                      value={userData.phone || ""}
                      maxLength={13}
                      pattern={PHONE_REGEXP}
                      errorMessage="✓ 핸드폰번호를 확인해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 핸드폰번호를 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>이메일</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="email"
                      type="text"
                      placeholder="user@boostar.co.kr"
                      icon="bx-envelope"
                      value={userData.email || ""}
                      pattern={EMAIL_REGEXP}
                      errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이메일을 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>
                        문의 내용을 간략하게 입력해주세요!
                      </span>
                    </div>
                    <div className="form-floating">
                      <textarea
                        name="inquiry"
                        className="form-control"
                        style={{ height: "100px" }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-check">
                      <label className="form-check-label small">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          required
                        />
                        <Link
                          to="/privacy"
                          className="fw-semibold link-decoration"
                          target="_blank"
                        >
                          개인정보취급방침
                        </Link>
                        에 동의합니다.
                      </label>
                      <p style={{ fontSize: "0.75rem" }} className="text-muted">
                        (상담신청을 위하여 남겨주신 개인정보는
                        개인정보취급방침에 의거하여 100% 안전하게 보호됩니다.)
                      </p>
                    </div>
                  </div>
                  <div className="mb-3 d-flex justify-content-between">
                    <div className="form-check">
                      <label
                        className="form-check-label small"
                        htmlFor="flexCheckDefault"
                      >
                        <input
                          className="form-check-input"
                          name="newsLetterAssignment"
                          onChange={handleAgreementCheck}
                          checked={isMarketingAgreement}
                          type="checkbox"
                          id="flexCheckDefault"
                        />
                        마케팅 수신 정보 이용 동의 (선택){" "}
                      </label>
                      <span
                        className="small ps-lg-1 ps-1"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsMarketingModalShow(true)}
                      >
                        자세히
                      </span>
                    </div>
                  </div>
                  <div className="mb-5">
                    <small className="text-danger">{errorMessage}</small>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      width="15"
                      border="none"
                      background="#fef4e5"
                      color="#fbb60d"
                      text="제출"
                      isDisabled={isDisabled}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ModalContainer
          show={modalShow}
          content="상담신청이 완료되었습니다."
          onHide={() => setModalShow(false)}
        >
          <p>곧 카카오톡으로 접수 안내가 발송될 예정입니다.</p>
          <div className="d-flex justify-content-center mt-lg-3">
            <Button
              width="10"
              text="확인"
              border="none"
              background="#f4f4f5"
              color="#797979"
              onClick={() => navigate("/")}
            />
          </div>
        </ModalContainer>
        <LoginModal userInfo={userInfo} />
        <ModalContainer
          show={isMarketingModalShow}
          content="마케팅 수신 정보 이용 동의"
          onHide={() => setIsMarketingModalShow(false)}
        >
          <p className="small">
            회원님들께 더 나은 서비스를 제공하고자 광고 ∙ 마케팅 목적(이벤트
            안내, 공지사항, 할인행사)의 개인정보 수집 및 이용에 대한 동의를
            받고자 합니다. 내용을 자세히 읽으신 후, 동의를 원하시면 원래의
            화면으로 돌아가 “동의”, “계속”, “동의하고 진행” 등 해당 화면의
            안내에 따라 버튼을 눌러 주시기 바랍니다. 보다 자세한 내용은 회사의
            이용 약관을 참조하여 주시기 바랍니다.
          </p>
          <p className="small mt-lg-3 ps-lg-2 mt-3 px-2">
            • 목적 : BOOSTAR에서 운영하는 서비스에 대한 광고 ∙ 홍보 ∙ 프로모션
            제공 <br />
            • 정보 수신 채널 : 이메일, SMS, 카카오톡 <br />• 개인정보 보유 및
            이용기간 : 광고 ∙ 마케팅 목적의 개인정보 수집 ∙ 이용에 대한 동의
            시부터 동의 철회 시 또는 회원 탈퇴 시까지
            <br />• 광고 ∙ 마케팅 항목은 선택사항이므로 동의를 거부하는 경우에도
            BOOSTAR 서비스의 이용에는 영향이 없습니다. 또한, 광고 ∙ 마케팅
            동의는 고객센터를 통해 언제든지 철회할 수 있습니다.
          </p>
          <div className="d-flex flex-center mt-lg-3 mt-3">
            <Button
              width="13"
              text="닫기"
              border="none"
              background="#fec230"
              color="#000000"
              onClick={() => setIsMarketingModalShow(false)}
            />
          </div>
        </ModalContainer>
      </section>
    </main>
  );
}
