import React from "react";

import blackLogo from "../assets/img/logo/boostar_black_logo.png";

export default function About() {
  return (
    <main className="position-relative">
      <div
        className="w-lg-100 d-flex justify-content-center align-items-center"
        style={{ height: "20rem", background: "#000000" }}
      >
        <div className="text-center">
          <img src={blackLogo} />
          <h1 className="display-5" style={{ color: "#ffffff" }}>
            BOOSTAR
          </h1>
        </div>
      </div>
      <div className="container py-lg-6 py-5 px-lg-11 px-4">
        <div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR PHILOSOPHY
            </h5>
            <div>
              BOOSTAR의 혁신적인 서비스가 스타트업을 위한 최상의 선택이 되기
              위해 최선을 다하고 있습니다. 우리는 타 서비스와 다른 특별한
              목적지를 향해가고 있습니다. 시대, 기술, 트렌드에 맞는 서비스를
              개발하며 기업, 사람, 사회에 대한 책임감을 BOOSTAR의 문화로
              자리잡도록 하는 것이 우리의 사명입니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR VISION
            </h5>
            <div>
              BOOSTAR는 비전은 간단하고 명료합니다.
              <br />
              스타트업 성장을 위한 최고의 선택이 되는 것입니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR MISSION
            </h5>
            <div>
              청렴, 윤리, 임직원에 대한 책임을 BOOSTART의 핵심가치로 삼고 고객을
              포함한 이해관계자를 위한 수익 창출과 우수한 서비스 제공에 전념하여
              다이내믹한 브랜드로 성공하는 것이 BOOSTAR의 미션입니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR EMPLOYEE
            </h5>
            <div>
              BOOSTAR의 임직원들은 브랜드의 가장 중요한 자원입니다. 임직원의
              열정과 노력은 BOOSTAR가 제공하는 상품과 서비스를 넘어 BOOSTAR
              브랜드의 성공에 직접적인 영향을 미치고 미래를 향해 나아갈 수
              있도록 합니다. BOOSTAR의 임직원들은 상호 신뢰와 존중을 바탕으로
              끈끈한 관계를 맺고 있습니다. 이러한 관계를 유지하기 위해 BOOSTAR의
              모든 임직원은 원칙을 지키기 위해 최선을 다하고 있습니다.
              <br /> BOOSTAR는 임직원 개개인의 가치를 알고 있기에 모든 직원을
              공정하게 대우하며, 직원들 또한 수행된 업무에 대해서 공정한 보상을
              받을 것입니다. 회사와 직원은 파트너 관계로서 서로의 니즈와 우려에
              예민해야 합니다. 개인으로서, 직원들은 직장에서 높은 수준으로
              책임감을 가지고 업무를 수행해야 합니다. 또한, 동료 직원을 신뢰와
              존중, 존엄으로 대우해야 합니다. 회사와 모든 임직원들은 우리의
              업무환경을 깨끗하고, 생산적이며 편안하게 그리고 보람 있고
              자극적이며 재미있는 곳으로 만들어야 합니다. 또한, BOOSTAR의
              근무환경은 긍정적인 관계와 변화를 촉진시키기 위해 개방적으로
              만듭니다. 우리는 직원들에게 기대를 전달하고 성과에 대해 적절한
              피드백을 제공할 것입니다.
              <br /> 우리 임직원들은 훌륭한 비즈니스 구조, 기능 및 문화를 갖춘
              팀으로서 일합니다. 또한, 우리는 BOOSTAR에서 창출된 정보, 모범 사례
              및 전략을 사회와 공유할 것입니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR CAREER
            </h5>
            <div>
              BOOSTAR는 임직원의 경력개발, 훈련기회, 성과에 대한 만족감 그리고
              직업 안정성을 제공함으로써 회사의 성공을 공유하고 직원들이 목표를
              달성하도록 권한을 부여합니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR SERVICE
            </h5>
            <div>
              BOOSTAR는 세상과 시대의 흐름을 이해하고 받아들여 더 혁신적이고,
              뛰어난 서비스를 시장에 제공합니다. 우리는 스타트업 생태계는 물론
              산업 전반을 발전시키는 서비스를 생각합니다. 또한, 공정한
              사회정의를 존중하고 서비스의 개발부터 판매에 이르기까지 모든
              관련자에 대한 공정한 보상을 보장합니다. 저희는 현재 제공되는
              BOOSTAR의 서비스뿐만 아니라 미래의 BOOSTAR에도 동일한 철학을
              적용할 것입니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR CUSTOMER
            </h5>
            <div>
              우리는 고객이 없으면 BOOSTAR도 존재하지 않는다는 것을 알고
              있습니다. 따라서, 우리는 상호신뢰 및 존중을 바탕으로 탁월한 고객
              관계를 발전하고 유지하며 고객의 기대를 지속적으로 일관성 있게
              충족시키기 위해 노력할 것입니다. BOOSTAR는 변화하는 시대와 요구에
              부응하며, 고객의 기대를 정확하게 파악할 것입니다. 우리는
              BOOSTAR만의 방식으로 우수한 품질을 유지하고 위해 전념할 것입니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR INNOVATION
            </h5>
            <div>
              혁신과 창의력은 비즈니스의 장기적인 성공을 위한 본질적인
              요소입니다. 따라서 BOOSTAR는 지속적으로 고객의 요구사항을
              충족시키기 위해 관행, 절차 및 방법을 개선하고 새로운 서비스를 찾고
              개발할 것입니다.
              <br /> 우리는 스타트업 산업에 대해 지속적으로 주시합니다. 시대의
              변화와 산업의 발전에 뒤처지지 않으며, BOOSTAR의 성과와 생산을
              향상시킬 수 있는 비즈니스 환경을 만들기 위해 최선을 다 할
              것입니다. 또한 혁신, 창의력, 팀스피릿 그리고 기업가정신을 발휘하는
              임직원들을 격려하고 보상합니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR PERFECTNESS
            </h5>
            <div>
              BOOSTAR는 개인의 존엄성과 지역사회를 존중하는 윤리적인 회사라는
              것을 자랑스럽게 생각합니다. 법을 준수하고, 안전한 제품을 생산하며,
              환경을 보호하고, 평등한 고용과 공정한 노동을 실천합니다. 우리는
              환경, 동물 및 지역사회를 보호, 보존해야 하는 사회적 책임이 있으며
              모든 거래 및 관계에서 정직성, 진실성, 성실성, 신뢰성 및 전문성에
              기초한 높은 윤리적 기준을 유지합니다.
              <br /> 우리는 우리의 모든 태도와 말 그리고 행동에 품위와 예의를
              더할 것이며, 우리가 타인에게 원하는 수준의 존경과 존엄성을 다른
              사람에게 보여줄 것입니다. 우리는 항상 BOOSTAR와 관련된 사람들에게
              진실한 감사를 표할 것입니다. 우리는 고객, 임직원 및 주주에 대한
              모든 약속을 소중히 합니다.
              <br /> 우리는 공개적이며, 정확하고 그리고 명확하게 의사
              소통합니다. 우리는 보다 나은 비지니스와 거래를 위해 도전적이고,
              건전하며, 보람 있는 환경을 조성합니다. 이를 위해, BOOSTAR는 업무
              및 프로세스를 끊임없이 변화시킬 것입니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR RESPONSIBILITY
            </h5>
            <div>
              BOOSTAR와 임직원들은 책임감 있는 시민들과 기업의 책임을
              지지합니다. 우리는 공동체에 실질적으로 기여하고 지역 사회 개발을
              돕기 위해 공동으로 노력할 책임이 있습니다. 우리는 임직원들에게
              자원봉사, 자선 및 지역사회활동 참여를 권장합니다. BOOSTAR는 기업
              자원을 활용하여 사람들의 삶을 도울 것이며 재능과 재산을 제공하여
              우리가 공유하는 세상을 개선하길 원합니다. 이런 활동으로, 우리는
              사회에 모범이 되는 비즈니스 리더로서의 롤모델이 되기를 바랍니다.
              <br />
              BOOSTAR는 현재와 미래의 세대를 위한 우리의 환경을 안전하게
              보호하고 보존하기 위해 최선을 다하고 있습니다. 저희는 건전한
              환경경영에 부합하는 비즈니스를 개발하고 실행하며, 다른
              사람들에게도 그러한 환경 경영을 권장합니다. 또한, 저희는 환경에
              중대한 부정적인 영향을 미치는 활동에 참여하거나 서비스를 제공하지
              않을 것입니다.
            </div>
          </div>
          <div className="mb-lg-3 mb-3">
            <h5 className="fw-bold" style={{ color: "#ff3d0d" }}>
              BOOSTAR PERFORMANCE
            </h5>
            <div>
              BOOSTAR의 모든 방면에서 뛰어난 성과를 내기 위해 최선을 다합니다.
              우리는 야심찬 목표를 세우며, 수익성 있는 방식으로 이익과 자산을
              늘리며, 주주들에게 지속적이며 긍정적인 수익에 대한 기대를
              충족시킵니다. 우리는 우리 자신을 능가하기 위해 항상 경쟁할
              것입니다.
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
