import React from "react";

export default function Introduction() {
  return (
    <section>
      <div className="container">
        <div className="mx-lg-5 mt-6">
          <div
            className="fw-bolder w-lg-20 text-center py-lg-1 py-1"
            style={{
              background: "#f4f4f5",
              color: "#2196F3",
              borderRadius: "5px",
            }}
          >
            INTRODUCTION
          </div>
          <div className="my-lg-5 my-5">
            <div className="my-lg-2 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder">
                {" "}
                한국 스타트업이 반드시 베트남 호치민에 방문해야 하는 이유!
              </span>
            </div>
            <div>
              <div
                style={{
                  background: "#fef4e5",
                  borderRadius: "5px",
                }}
                className="px-lg-2 px-2 py-lg-2 py-2 mb-lg-6 mb-5"
              >
                <p>
                  호치민은 베트남의 금융 및 문화의 중심지이고 정보 기술, 첨단
                  기술, 식품, 농업, 서비스 등 여러 산업군이 빠르고, 고르게
                  발달하고 있기 때문에 동남아 비즈니스 모델의 검증을 하기 최적의
                  도시입니다.
                </p>
              </div>
              <div className="my-lg-3 my-2 ms-lg-2">
                <span
                  className="py-1 px-2 me-lg-2 me-2 small"
                  style={{
                    borderRadius: "100%",
                    background: "#243354",
                    color: "#ffffff",
                  }}
                >
                  1
                </span>
                <span>
                  스타트업을 위한 정부의 적극적인 지원 및 민간으로 부터의 투자,
                  인큐베이터, 액셀러레이터 등 스타트업 육성 프로그램 활성화
                </span>
              </div>
              <div className="my-lg-3 my-2 ms-lg-2">
                <span
                  className="py-1 px-2 me-lg-2 me-2 small"
                  style={{
                    borderRadius: "100%",
                    background: "#243354",
                    color: "#ffffff",
                  }}
                >
                  2
                </span>
                <span>다양한 세미나와 행사 등을 통해 글로벌 네트워킹 가능</span>
              </div>
              <div className="my-lg-3 my-2 ms-lg-2">
                <span
                  className="py-1 px-2 me-lg-2 me-2 small"
                  style={{
                    borderRadius: "100%",
                    background: "#243354",
                    color: "#ffffff",
                  }}
                >
                  3
                </span>
                <span>
                  호치민시는 인구 약 900만 명, 베트남 스타트업의 40%, 스타트업
                  투자거래 70%가 이루어지는 동남아 최대의 성장 도시
                </span>
              </div>
            </div>
          </div>
          <div className="my-lg-5 my-5">
            <div className="my-lg-2 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder">
                {" "}
                베트남의 경제 중심, 호치민(Ho Chi Minh City)
              </span>
            </div>
            <div>
              <div className="my-2">
                <div className="mt-lg-3 ms-lg-2">
                  <span
                    className="py-1 px-2 me-lg-2 me-2 small"
                    style={{
                      borderRadius: "100%",
                      background: "#243354",
                      color: "#ffffff",
                    }}
                  >
                    1
                  </span>
                  <span>빠르게 성장하는 시장(HCMC)</span>
                </div>
                <p className="ms-lg-4 ms-3 small">
                  • 호치민시는 베트남의 경제 중심지이며, 경제 성장률은 연평균 약
                  7%로 동남아시아 및 전세계에서 가장 빠르게 성장하는 경제 지역
                </p>
              </div>
              <div className="my-2">
                <div className="mt-lg-3 ms-lg-2">
                  <span
                    className="py-1 px-2 me-lg-2 me-2 small"
                    style={{
                      borderRadius: "100%",
                      background: "#243354",
                      color: "#ffffff",
                    }}
                  >
                    2
                  </span>
                  <span>대규모 소비자 시장</span>
                </div>
                <p className="ms-lg-4 ms-3 small">
                  • 베트남은 약 1억 명에 달하는 인구를 보유하고 있으며, 이는
                  대규모의 소비자 시장을 의미
                </p>
                <p className="ms-lg-4 ms-3 small">
                  • 호치민시는 베트남에서 가장 인구 밀집도가 높은
                  도시(1천만명)로, 다양한 산업 분야에서 수요가 높은 시장
                </p>
              </div>
              <div className="my-2">
                <div className="mt-lg-3 ms-lg-2">
                  <span
                    className="py-1 px-2 me-lg-2 me-2 small"
                    style={{
                      borderRadius: "100%",
                      background: "#243354",
                      color: "#ffffff",
                    }}
                  >
                    3
                  </span>
                  <span>스타트업 투자 & 이벤트 중심지</span>
                </div>
                <p className="ms-lg-4 ms-3 small">
                  • 호치민시는 베트남에서 가장 많은 벤처 투자를 받는 도시이며,
                  2020년 약 1억 2,000만 달러의 벤처 투자 유치
                </p>
                <p className="ms-lg-4 ms-3 small">
                  • 호치민시는 다양한 규모의 스타트업 이벤트와 컨퍼런스를
                  개최하고 있으며, 많은 현지 및 해외 기업/기관들이 행사에 참여
                </p>
              </div>
              <div className="my-2">
                <div className="mt-lg-3 ms-lg-2">
                  <span
                    className="py-1 px-2 me-lg-2 me-2 small"
                    style={{
                      borderRadius: "100%",
                      background: "#243354",
                      color: "#ffffff",
                    }}
                  >
                    4
                  </span>
                  <span>지리적 중요성</span>
                </div>
                <p className="ms-lg-4 ms-3 small">
                  • 호치민시는 동남아시아 지역에서 중요한 비즈니스 허브로서의
                  역할을 하며, 호치민시를 중심으로 주변 국가들과의 경제 및
                  비즈니스 협력이 강화되고 있음
                </p>
                <p className="ms-lg-4 ms-3 small">
                  • 또한, 호치민시는 외국인들이 거주하기에 매우 인기 있는
                  도시이며, 외국인 거주 비율은 10% 정도로 높은 편
                </p>
              </div>
            </div>
          </div>
          <div className="my-lg-5 my-5">
            <div className="mt-lg-2 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 베트남 대표 스타트업 in HCMC</span>
              <p className="small ps-lg-4 ps-3">호치민 스타일의 스타트업</p>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  src="https://cdn-s3.boostar.co.kr/boostar/image/vietnam_startup.png"
                  className="img-fluid w-lg-100 w-100"
                />
              </div>
              <div className="col-lg-6 small">
                <p className="my-lg-3 my-1">
                  • Tiki.vn: 베트남의 대표적인 온라인 쇼핑, 배송 플랫폼
                </p>
                <p>• Lozi: 음식과 식당 정보를 공유하는 소셜 미디어 플랫폼</p>
                <p className="my-lg-3 my-1">
                  • Abivin: 인공지능과 빅데이터를 활용한 물류 및 창고 관리
                  솔루션
                </p>
                <p className="my-lg-3 my-1">
                  • eDoctor: 온라인 의료 플랫폼으로 원격진료, 의약품 배송 등
                  제공
                </p>
                <p className="my-lg-3 my-1">
                  • Base.vn: 기업 내 협업 및 프로젝트 관리를 위한 플랫폼
                </p>
                <p className="my-lg-3 my-1">
                  • Kyna.vn: 온라인 강좌, 교육 콘텐츠를 제공하는 온라인 교육
                  플랫폼
                </p>
                <p className="my-lg-3 my-1">
                  • Foody Corporation: 음식 및 레스토랑 검색, 공유, 리뷰 플랫폼
                </p>
                <p className="my-lg-3 my-1">
                  • Luxstay: 개인과 상업용 숙박 공간을 예약할 수 있는 숙박
                  플랫폼
                </p>
                <p className="my-lg-3 my-1">
                  • VNG Corporation: 베트남 최대 게임, 인터넷 서비스, 소프트웨어
                  개발 기업
                </p>
                <p className="my-lg-3 my-1">
                  • TikiLIVE: 실시간 비디오 스트리밍 및 OTT 플랫폼
                </p>
              </div>
            </div>
          </div>
          <div className="my-lg-5 my-5">
            <div className="mt-lg-2 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 베트남 대표 대기업 in HCMC</span>
              <p className="small ps-lg-4 ps-3">호치민 기반의 대기업</p>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  src="https://cdn-s3.boostar.co.kr/boostar/image/vietnam_large_corporation.png"
                  className="img-fluid w-lg-100 w-100"
                />
              </div>
              <div className="col-lg-6 small">
                <p className="my-lg-2 my-1">
                  • Vingroup: 베트남 최대의 기업으로 부동산, 소매, 교육, 의료,
                  관광 등 사업 진행
                </p>
                <p className="my-lg-2 my-1">
                  • Viettel Group: 이동 통신기업으로 통신 및 IT 기업으로 모바일,
                  인터넷, 디지털 솔루션 제공
                </p>
                <p className="my-lg-2 my-1">
                  • FPT Corporation: IT 기업으로 소프트웨어 개발, IT 컨설팅,
                  디지털 서비스 등 제공
                </p>
                <p className="my-lg-2 my-1">
                  • Masan Group: 소비재 및 기술 기업으로 음식, 음료, 소매 등
                  사업 진행
                </p>
                <p className="my-lg-2 my-1">
                  • Saigon Co.op: 주요 소매 및 유통 기업으로 대형 슈퍼마켓과
                  편의점 체인 운영
                </p>
                <p className="my-lg-2 my-1">
                  • THACO: 주요 자동차 제조 기업으로 상용차 및 승용차. 생산
                </p>
                <p className="my-lg-2 my-1">
                  • PetroVietnam (PVN): 국영 석유 및 가스 기업으로 석유 및 가스
                  탐사, 생산, 수송, 유통 등 다양한 사업 수행
                </p>
                <p className="my-lg-2 my-1">
                  • Sabeco: 맥주 제조 기업으로 사이공 맥주 등 다양한 맥주 브랜드
                  보유
                </p>
                <p className="my-lg-2 my-1">
                  • Vietnam Airlines: 베트남의 국가 항공사로 국내선 및 국제선을
                  운영
                </p>
                <p className="my-lg-2 my-1">
                  • Vietcombank: 상업 은행으로 대출, 예금, 외환 거래 등 다양한
                  금융 서비스 제공
                </p>
                <p className="my-lg-2 my-1">
                  • Hoa Sen Group: 철강 및 건설 기업으로 철강 시트 제조와
                  건설자재 생산 담당
                </p>
                <p className="my-lg-2 my-1">
                  • Saigon Securities Incorporation (SSI): 증권사로 증권 거래,
                  자산 관리, 투자 컨설팅 등 제공
                </p>
                <p className="my-lg-2 my-1">
                  • Vinamilk: 베트남 최대의 유제품 제조업체로 우유 및 유제품을
                  다양하게 생산
                </p>
                <p className="my-lg-2 my-1">
                  • Vietnam Mobile Telecom Services Company (MobiFone): 베트남의
                  대표적인 이동 통신 서비스 제공업체로, 휴대전화 통신 및 데이터
                  서비스 제공
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
