import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import Button from "../components/Button";
import SnsLogin from "../components/GoogleLogin";
import KakaoLogin from "../components/KakaoLogin";
import ModalContainer from "../components/Modal";
import NaverLogin from "../components/NaverLogin";
import TextInput from "../components/TextInput";
import { userInfoState } from "../store/userState";

const ClassBoxContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #fec230;
  }
`;

export default function DownloadFile() {
  const [modalContent, setModalContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  const bannerImage =
    "https://cdn-s3.boostar.co.kr/boostar/main_slide_banner/social_venture_banner_ver2.jpg";
  const incorporationThumbnail =
    "https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_list_thumbnail.jpg";

  const handleChange = useCallback((event) => {
    setErrorMessage("");
    setPassword(event.target.value);
  });

  const handlePasswordSubmit = (event) => {
    event.preventDefault();

    if (password !== process.env.REACT_APP_DOWNLOAD_PASSWORD) {
      setErrorMessage("✓ 비밀번호가 올바르지 않습니다.");
    }

    if (password === process.env.REACT_APP_DOWNLOAD_PASSWORD) {
      setIsCheck(true);
    }
  };

  useEffect(() => {
    if (!userInfo) {
      setModalContent("로그인 후 신청해주세요 🙂");
      setModalShow(true);

      return;
    }
  }, [userInfo]);

  return (
    <>
      <main className="position-relative">
        {isCheck ? (
          <>
            <div className="container py-lg-6 py-10 px-lg-11">
              <div
                className="mb-lg-5 mx-lg-3"
                style={{ borderBottom: "1px solid #c8c8c8" }}
              >
                <h4>2023년 예비창업패키지 소셜벤처분야 사업설명회</h4>
              </div>
              <div className="row px-lg-2">
                <div className="col-lg-8">
                  <img src={bannerImage} className="img-fluid" />
                </div>
                <div
                  className="col-lg-4 mt-3 px-3 px-lg-0 py-3 py-lg-0"
                  style={{ border: "1px solid #c8c8c8", borderRadius: "5px" }}
                >
                  <h5 className="mt-lg-3">
                    [특강] 예비창업패키지 소셜벤처분야 사업계획서 작성 비법
                  </h5>
                  <p className="text-muted small ms-lg-1 mb-2">
                    사업계획서 작성 | 더피치 이호재 대표
                  </p>
                  <p className="mb-2 ms-lg-1">
                    정부지원사업을 준비하는 예비 창업자들을 위한 사업계획서
                    작성법을 알려드립니다. 정부지원 사업계획서 작성 방향에 대해
                    이해하고 디테일한 작성 스킬까지 알아갈 수 있습니다.
                  </p>
                  <div className="d-flex flex-center flex-column mb-lg-3">
                    <p>
                      <span>강의 자료 다운받기</span>{" "}
                      <span style={{ color: "#fbb60d" }}>⬇️⬇️</span>
                    </p>
                    <a
                      href="https://cdn-s3.boostar.co.kr/boostar/%E1%84%8B%E1%85%A8%E1%84%87%E1%85%B5%E1%84%8E%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%91%E1%85%A2%E1%84%8F%E1%85%B5%E1%84%8C%E1%85%B5(%E1%84%89%E1%85%A9%E1%84%89%E1%85%A7%E1%86%AF%E1%84%87%E1%85%A6%E1%86%AB%E1%84%8E%E1%85%A5%E1%84%87%E1%85%AE%E1%86%AB%E1%84%8B%E1%85%A3)_%E1%84%80%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%B4%E1%84%8C%E1%85%A1%E1%84%85%E1%85%AD.pdf"
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      <Button
                        width="10"
                        text="다운로드"
                        border="none"
                        background="#fef4e5"
                        color="#fbb60d"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ background: "#f4f4f5" }}>
              <div className="container py-lg-6 py-10 px-lg-11">
                <div>
                  <div
                    className="mx-lg-3"
                    style={{ borderBottom: "1px solid #c8c8c8" }}
                  >
                    <h5 className="mb-0">관련 상품</h5>
                    <p className="small text-muted mb-0">
                      함께 이용하시면 더 좋을것 같아요!
                    </p>
                  </div>
                  <div className="row mx-lg-4 my-lg-5">
                    <ClassBoxContainer className="col-lg-4 col-6 px-lg-6">
                      <div
                        className="button"
                        onClick={() => navigate("/business_plan")}
                      >
                        <img
                          src="https://cdn-s3.boostar.co.kr/boostar/business_plan/business_plan_list_thumbnail.jpg"
                          className="img-fluid"
                        />
                        <p
                          className="small mt-lg-2 mt-2 mb-0"
                          style={{ color: "#797979" }}
                        >
                          비즈니스 전략
                        </p>
                        <p className="lh-sm mt-lg-1 mb-0 mt-2">
                          사업계획서 컨설팅
                        </p>
                        <p className="small text-end mt-lg-2">
                          <i
                            className="bx bxs-star"
                            style={{ color: "#fec230" }}
                          ></i>
                          <span style={{ color: "#797979" }}>
                            {" "}
                            5.0 | 2개의 평가
                          </span>
                        </p>
                      </div>
                    </ClassBoxContainer>
                    <ClassBoxContainer className="col-lg-4 col-6 px-lg-6">
                      <div
                        className="button"
                        onClick={() => navigate("/free_incorporation")}
                      >
                        <img
                          src={incorporationThumbnail}
                          className="img-fluid"
                        />
                        <p
                          className="small mt-lg-2 mt-2 mb-0"
                          style={{ color: "#797979" }}
                        >
                          무료법인설립센터
                        </p>
                        <p className="lh-sm mt-lg-1 mb-0 mt-2">무료법인설립</p>
                        <p className="small text-end mt-lg-2">
                          <i
                            className="bx bxs-star"
                            style={{ color: "#fec230" }}
                          ></i>
                          <span style={{ color: "#797979" }}>
                            {" "}
                            5.0 | 1개의 평가
                          </span>
                        </p>
                      </div>
                    </ClassBoxContainer>
                    <ClassBoxContainer className="col-lg-4 col-6 px-lg-6">
                      <div
                        className="button"
                        onClick={() => navigate("/ir_pack")}
                      >
                        <img
                          src="https://cdn-s3.boostar.co.kr/boostar/ir/ir_list_thumbnail.jpg"
                          className="img-fluid"
                        />
                        <p
                          className="small mt-lg-2 mt-2 mb-0"
                          style={{ color: "#797979" }}
                        >
                          IR 디자인
                        </p>
                        <p className="lh-sm mt-lg-1 mb-0 mt-2">
                          3,000억 투자유치의 비밀: 에센셜팩
                        </p>
                        <p className="small text-end mt-lg-2">
                          <i
                            className="bx bxs-star"
                            style={{ color: "#fec230" }}
                          ></i>
                          <span style={{ color: "#797979" }}>
                            {" "}
                            0.0 | 0개의 평가
                          </span>
                        </p>
                      </div>
                    </ClassBoxContainer>
                  </div>
                </div>
                <div>
                  <div
                    className="mx-lg-3"
                    style={{ borderBottom: "1px solid #c8c8c8" }}
                  >
                    <h5 className="mb-0">연관 강의</h5>
                    <p className="small text-muted mb-0">
                      BOOSTAR에서 다른 무료 강의들도 신청해보세요!
                    </p>
                  </div>
                  <div className="row mx-lg-4 my-lg-5">
                    <ClassBoxContainer className="col-lg-4 col-6 px-lg-6">
                      <div
                        className="button"
                        onClick={() => navigate("/class/230411")}
                      >
                        <img
                          src="https://cdn-s3.boostar.co.kr/boostar/class/class_230411_list_thumbnail.jpg"
                          className="img-fluid"
                        />
                        <p className="lh-sm mt-lg-2 mb-0 mt-2">
                          [무료강의] 돈이 되는 중국소싱 노하우
                        </p>
                        <p
                          className="small mt-lg-2 mt-2 mb-0"
                          style={{ color: "#797979" }}
                        >
                          중국 소싱 | 동심무역 김강길 과장
                        </p>
                      </div>
                    </ClassBoxContainer>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="container py-lg-6 py-10 px-lg-11">
            <div className="d-flex flex-center">
              <div className="w-lg-100">
                <form
                  className="d-flex flex-center flex-column"
                  onSubmit={handlePasswordSubmit}
                >
                  <p className="ms-lg-2">접속 비밀번호를 입력해주세요</p>
                  <TextInput
                    name="password"
                    type="password"
                    icon="bx-lock-open"
                    value={password || ""}
                    onChange={handleChange}
                  />
                  <div className="mb-3">
                    <small className="text-danger">{errorMessage}</small>
                  </div>
                  <Button
                    width="10"
                    text="확인"
                    border="none"
                    background="#fef4e5"
                    color="#fbb60d"
                  />
                </form>
              </div>
            </div>
          </div>
        )}
        <ModalContainer
          show={modalShow}
          content={modalContent}
          onHide={() => setModalShow(false)}
        >
          <>
            <div className="d-flex flex-center mt-lg-4">
              <Button
                width="8"
                background="#f4f4f5"
                color="#797979"
                text="가입하기"
                border="none"
                onClick={() => navigate("/signup")}
              />
              <Button
                width="8"
                background="#fef4e5"
                color="#fbb60d"
                text="로그인하기"
                border="none"
                onClick={() => navigate("/login?retUrl=" + location.pathname)}
              />
            </div>
            <div className="d-flex align-items-center py-3">
              <span className="flex-grow-1 border-bottom pt-1"></span>
              <span className="d-inline-flex flex-center lh-1 width-2x height-2x rounded-circle bg-white text-mono">
                or
              </span>
              <span className="flex-grow-1 border-bottom pt-1"></span>
            </div>
            <p className="text-center">3초안에 빠르게 회원가입/로그인 하기</p>
            <div className="d-flex justify-content-center px-lg-10 my-lg-2">
              <KakaoLogin url="/download/class_file" />
              <NaverLogin url="/download/class_file" />
              <SnsLogin url="/download/class_file" />
            </div>
          </>
        </ModalContainer>
      </main>
    </>
  );
}
