import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import Introduction from "../components/Hcmc/Introduction";
import ProgramIntro from "../components/Hcmc/ProgramIntro";
import Schedule from "../components/Hcmc/Schedule";
import VisitPlace from "../components/Hcmc/VisitPlace";
import ApplyForm from "../components/Hcmc/ApplyForm";
import ModalContainer from "../components/Modal";
import Button from "../components/Button";

import { userInfoState } from "../store/userState";
import InnoEx from "../components/Hcmc/InnoEx";

const MenuContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #2196f3;
  }
`;

export default function Hcmc() {
  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  const [isModalShow, setIsModalShow] = useState(false);

  const navigate = useNavigate();
  const { menu } = useParams();

  const handleApplyButtonClick = () => {
    if (!userInfo) {
      setIsModalShow(true);
    }

    if (userInfo?.user_idx) {
      navigate("/hcmc_2023/apply");
    }
  };

  return (
    <main className="position-relative">
      <div>
        <img
          src="https://cdn-s3.boostar.co.kr/boostar/image/hcmc_topbanner_2.jpg"
          className="img-fluid w-lg-100 w-100"
          alt="hcmc banner"
        />
      </div>
      <div className="position-relative container py-lg-6 py-3">
        <div className="row">
          <div className="col-lg-3 mt-lg-6">
            <div
              className="px-lg-3 shadow"
              style={{ border: "1px solid #c8c8c8", borderRadius: "5px" }}
            >
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/hcmc_2023")}
                  style={{ cursor: "pointer" }}
                >
                  프로그램 소개
                </div>
              </MenuContainer>
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/hcmc_2023/intro")}
                  style={{ cursor: "pointer" }}
                >
                  호치민 소개
                </div>
              </MenuContainer>
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/hcmc_2023/innoex")}
                  style={{ cursor: "pointer" }}
                >
                  INNOEX 소개
                </div>
              </MenuContainer>
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/hcmc_2023/schedule")}
                  style={{ cursor: "pointer" }}
                >
                  프로그램 일정
                </div>
              </MenuContainer>
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/hcmc_2023/visit")}
                  style={{ cursor: "pointer" }}
                >
                  방문기관 및 기업
                </div>
              </MenuContainer>
              {/* <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/hcmc_2023/expert")}
                  style={{ cursor: "pointer" }}
                >
                  참여 전문가
                </div>
              </MenuContainer> */}
              {/* <MenuContainer>
                <div
                  className="text-center py-lg-3 py-2 fw-bolder button"
                  onClick={handleApplyButtonClick}
                  style={{ cursor: "pointer" }}
                >
                  참가 신청
                </div>
              </MenuContainer> */}
            </div>
          </div>
          <div className="col-lg-9">
            {!menu && <ProgramIntro />}
            {menu === "intro" && <Introduction />}
            {menu === "innoex" && <InnoEx />}
            {menu === "schedule" && <Schedule />}
            {menu === "visit" && <VisitPlace />}
            {/* {menu === "apply" && <ApplyForm />} */}
          </div>
        </div>
      </div>
      <ModalContainer
        show={isModalShow}
        content="서비스를 시작하시려면 로그인을 해주세요 🙂"
        onHide={() => setIsModalShow(false)}
      >
        <>
          <div className="d-flex flex-center mt-lg-4">
            <Button
              width="8"
              background="#f4f4f5"
              color="#797979"
              text="가입하기"
              border="none"
              onClick={() => navigate("/signup")}
            />
            <Button
              width="8"
              background="#fef4e5"
              color="#fbb60d"
              text="로그인하기"
              border="none"
              onClick={() => navigate("/login?retUrl=" + location.pathname)}
            />
          </div>
        </>
      </ModalContainer>
    </main>
  );
}
