import React from "react";

export default function PartnerCard({ image, officeName, path, type }) {
  const Tag = ({ tagName }) => {
    return (
      <div
        className="badge badge-pill mx-lg-2 mx-1"
        style={{
          background: "#f4f4f5",
          color: "#797979",
        }}
      >
        # {tagName}
      </div>
    );
  };

  return (
    <>
      <div className="col-md-4 col-sm-6 mb-4 mb-md-4 my-4">
        <div className="card card-hover hover-lift hover-shadow-lg overflow-hidden rounded-5 shadow">
          <img
            className="card-img-top img-fluid img-zoom"
            src={require(`../assets/img/logo/${image}`)}
            alt="thumbnail"
          />
          <div className="card-body d-flex flex-column start-0 top-0 w-100 h-100 pb-lg-3 px-3">
            <p className="mb-lg-0 mb-0 ms-lg-1s ms-1">{officeName}</p>
            <div
              className="d-flex align-items-center pt-lg-3 pt-3 fw-bold"
              style={{ borderTop: "1px solid #cdcdcd" }}
            >
              {type.map((item, index) => (
                <Tag key={index} tagName={item} />
              ))}
            </div>
          </div>
          <a href={path} className="stretched-link" />
        </div>
      </div>
    </>
  );
}
