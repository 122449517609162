import React from "react";
import { useNavigate } from "react-router";

export default function EventCard({
  image,
  subTitle,
  title,
  deadline,
  date,
  isClosed,
  onClick,
}) {
  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        <img
          src={image}
          className="img-fluid w-lg-100 w-100 card-hover hover-lift"
          style={{ borderRadius: "5px" }}
        />
        <p className="small my-lg-2 my-2">{date} </p>
        <div className="lh-sm">
          <p>{title} </p>
          <p className="small">{subTitle} </p>
        </div>
        <p className="small text-muted mt-lg-2 mt-2">
          참가 신청 마감: {deadline}
        </p>
        <div className="d-flex justify-content-end mt-lg-2">
          {isClosed ? (
            <p
              className="w-lg-30 w-20 text-center small"
              style={{
                borderRadius: "5px",
                color: "#ffffff",
                background: "#ff3d0d",
              }}
            >
              신청 마감
            </p>
          ) : (
            <p
              className="w-lg-30 w-20 text-center small"
              style={{
                borderRadius: "5px",
                color: "#ffffff",
                background: "#fec230",
              }}
            >
              신청중
            </p>
          )}
        </div>
      </div>
    </>
  );
}
