import axios from "../configs/axiosInstance";

const reviewApi = {};

reviewApi.submitReview = async ({
  id,
  email,
  orderNumber,
  serviceName,
  serviceType,
  productCode,
  rating,
  content,
  regDate,
}) => {
  const response = await axios.post(`/review/${id}/order/${orderNumber}`, {
    email,
    serviceName,
    serviceType,
    productCode,
    rating,
    content,
    regDate,
  });

  return response.data;
};

reviewApi.getMyReviewList = async ({ userId }) => {
  const response = await axios.get(`/review/${userId}`);

  return response.data;
};

reviewApi.updateReview = async ({
  userId,
  id,
  orderNumber,
  rating,
  content,
}) => {
  const response = await axios.put(`/review/${userId}/update/${id}`, {
    orderNumber,
    rating,
    content,
  });

  return response.data;
};

reviewApi.getClassReview = async () => {
  const response = await axios.get("/review/class");

  return response.data;
};

reviewApi.getServiceReview = async () => {
  const response = await axios.get("/review/service");

  return response.data;
};

reviewApi.submitClassReview = async ({
  userId,
  regId,
  email,
  serviceType,
  serviceName,
  classDate,
  rating,
  content,
  regDate,
  classIdx,
}) => {
  const response = await axios.post(`/review/class/${regId}`, {
    userId,
    email,
    serviceType,
    serviceName,
    classDate,
    rating,
    content,
    regDate,
    classIdx,
  });

  return response.data;
};

export default reviewApi;
