import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../components/ServiceBox";

export default function LifeStyleList() {
  const navigate = useNavigate();

  const lifeStyleBanner =
    "https://cdn-s3.boostar.co.kr/boostar/life_style_list/life_style_banner.jpg";

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      <div className="container">
        <img
          src={lifeStyleBanner}
          className="img-fluid w-lg-100 mb-lg-4 px-lg-10"
        />
      </div>
      <div className="container py-lg-6 py-4 px-lg-11">
        <div
          className="mb-lg-4 mb-3"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <h3 className="fw-bolder ps-2">
            <span style={{ color: "#fbb60d" }}>라이프</span> <br />
            스타일
          </h3>
        </div>
        <div className="row">
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/life_style_list/meditation_list_thumbnail_230406.jpg"
            title="찾아가는 명상워크숍"
            info="라이프 스타일"
            rating="0.0"
            review="0"
            onClick={() => navigate("/meditation")}
          />
        </div>
      </div>
    </main>
  );
}
