import { selector, selectorFamily } from "recoil";

import resourceApi from "../api/resource";

export const bestProgramState = selector({
  key: "bestProgramState",
  get: async () => {
    try {
      const response = await resourceApi.getBestSupportProgramList();

      if (response.result === "success") {
        return response.data.results;
      }
    } catch (err) {
      if (err) {
        alert(
          "데이터를 불러오는데 문제가 생겼습니다. 잠시 후 다시 이용 바랍니다."
        );

        return [];
      }
    }
  },
});

export const totalProgramState = selectorFamily({
  key: "totalProgramState",
  get:
    ({ currentPage, order, search, filterType }) =>
    async () => {
      if (filterType === "total") {
        try {
          if (search) {
            const getSearchTotalList = await resourceApi.getNewProgramList({
              pageNumber: 1,
              order,
              search,
            });

            if (getSearchTotalList.result === "success") {
              return {
                data: getSearchTotalList.data.results,
                pageNumber: Math.ceil(
                  getSearchTotalList.data.total_count.CNT / 10
                ),
              };
            }

            if (
              getSearchTotalList.result === "fail" &&
              getSearchTotalList.message === "no data"
            ) {
              return {
                data: [],
                pageNumber: 1,
              };
            }
          }

          const getTotalList = await resourceApi.getNewProgramList({
            pageNumber: currentPage,
            order,
            search,
          });

          if (getTotalList.result === "success") {
            return {
              data: getTotalList.data.results,
              pageNumber: Math.ceil(getTotalList.data.total_count.CNT / 10),
            };
          }
        } catch (err) {
          if (err) {
            alert(
              "데이터를 불러오는데 문제가 생겼습니다. 잠시 후 다시 이용 바랍니다."
            );

            return {
              data: [],
              pageNumber: 1,
            };
          }
        }
      }
    },
});

export const typedProgramState = selectorFamily({
  key: "typedProgramState",
  get:
    ({ typeCode, currentPage, order, search, filterType }) =>
    async () => {
      if (filterType === "category") {
        try {
          if (search) {
            const getTypedSearchList = await resourceApi.getTypedProgramList({
              pageNumber: 1,
              type: typeCode,
              order: order,
              search: search,
            });

            if (getTypedSearchList.result === "success") {
              return {
                data: getTypedSearchList.data.results,
                pageNumber: Math.ceil(
                  getTypedSearchList.data.total_count.CNT / 10
                ),
              };
            }

            if (
              getTypedSearchList.result === "fail" &&
              getTypedSearchList.message === "no data"
            ) {
              return {
                data: [],
                pageNumber: 1,
              };
            }
          }

          const getTypedList = await resourceApi.getTypedProgramList({
            pageNumber: currentPage,
            type: typeCode,
            order: order,
            search: search,
          });

          if (getTypedList.result === "success") {
            return {
              data: getTypedList.data.results,
              pageNumber: Math.ceil(getTypedList.data.total_count.CNT / 10),
            };
          }

          if (
            getTypedList.result === "fail" &&
            getTypedList.message === "no data"
          ) {
            return {
              data: [],
              pageNumber: 1,
            };
          }
        } catch (err) {
          if (err) {
            alert(
              "데이터를 불러오는데 문제가 생겼습니다. 잠시 후 다시 이용 바랍니다."
            );

            return {
              data: [],
              pageNumber: 1,
            };
          }
        }
      }
    },
});

export const regionProgramState = selectorFamily({
  key: "regionProgramState",
  get:
    ({ region, currentPage, order, search, filterType }) =>
    async () => {
      if (filterType === "region") {
        try {
          if (search) {
            const getRegionProgramList =
              await resourceApi.getSelectedRegionProgramList({
                pageNumber: 1,
                region: region,
                order: order,
                search: search,
              });

            if (getRegionProgramList.result === "success") {
              return {
                data: getRegionProgramList.data.results,
                pageNumber: Math.ceil(
                  getRegionProgramList.data.total_count.CNT / 10
                ),
              };
            }

            if (
              getRegionProgramList.result === "fail" &&
              getRegionProgramList.message === "no data"
            ) {
              return {
                data: [],
                pageNumber: 1,
              };
            }
          }

          const getRegionSearchProgramList =
            await resourceApi.getSelectedRegionProgramList({
              pageNumber: currentPage,
              region: region,
              order: order,
              search: search,
            });

          if (getRegionSearchProgramList.result === "success") {
            return {
              data: getRegionSearchProgramList.data.results,
              pageNumber: Math.ceil(
                getRegionSearchProgramList.data.total_count.CNT / 10
              ),
            };
          }

          if (
            getRegionSearchProgramList.result === "fail" &&
            getRegionSearchProgramList.message === "no data"
          ) {
            return {
              data: [],
              pageNumber: 1,
            };
          }
        } catch (err) {
          if (err) {
            alert(
              "데이터를 불러오는데 문제가 생겼습니다. 잠시 후 다시 이용 바랍니다."
            );

            return {
              data: [],
              pageNumber: 1,
            };
          }
        }
      }
    },
});
