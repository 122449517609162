import React from "react";

import Intro2 from "../components/Service/Intro2";
import RightImage from "../components/RightImage";
import More from "../components/More";

import introImage from "../assets/img/image/startup_intro.png";
import companyBuildingImage from "../assets/img/image/company_building.png";
import expertNetworkImage from "../assets/img/image/expert_network.png";

export default function Startup() {
  return (
    <main>
      <Intro2
        image={introImage}
        title1="BOOSTAR for"
        title2="스타트업"
        subTitle="BOOSTAR는 스타트업의 생존과 성장을 위한 모든 것을 제공합니다."
        url="/contact"
      />
      <RightImage
        image={companyBuildingImage}
        title="컴퍼니빌딩 서비스"
        paragraph1="아무리 좋은 아이디어라도, 실제로 구현되어 매출이 발생하는 것은 쉽게 허락되지 않습니다. 회사를 설립하고 안정적인 운영을 하는 것 또한 엄청난 노력과 수고가 들어갑니다."
        paragraph2="BOOSTAR는 스타트업의 어려움을 덜고자 컴퍼니빌딩 서비스를 제공합니다."
      />
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 ph-lg-10">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3">
              <div className="w-lg-90">
                <h1 className="lh-base fs-3 mb-2 mb-lg-5">
                  전문가 네트워크 연결
                </h1>
                <h6 className="lh-base mb-2 mb-lg-4">
                  스타트업의 폭발적인 성장을 위해선 다양한 분야의 업무가
                  시너지를 발휘해야합니다.
                  <br />
                  그러나 창업자가 모든 업무에 신경 쓰기에는 현실적으로 전문성을
                  갖추기 어려워 비효율적입니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-4">
                  BOOSTAR는 이런 상황에 처한 스타트업 CEO에게 전문가 네트워크를
                  연결해 필요한 분야의 실력 있는 전문가를 연결해드립니다.
                </h6>
              </div>
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 d-flex justify-content-end">
              <img src={expertNetworkImage} className="img-fluid w-lg-90" />
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
