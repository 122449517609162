import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "../components/Button";
import TextInput from "../components/TextInput";
import userApi from "../api/user";
import { userInfoState } from "../store/userState";
import { PHONE_REGEXP } from "../constants/RegExp";
import ModalContainer from "../components/Modal";

export default function AddGoogleAdditionalInfo() {
  const accessToken = localStorage.getItem("accessToken");
  const user = useRecoilValue(userInfoState(accessToken));

  const [isMarketingModalShow, setIsMarketingModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfo, setUserInfo] = useState({
    phone: "",
    officeName: "",
    marketing: false,
  });

  const navigate = useNavigate();

  const isValidPhone = PHONE_REGEXP.test(userInfo.phone);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleMarketingClick = () => {
    setUserInfo((prevState) => ({
      ...prevState,
      marketing: !userInfo.marketing,
    }));
  };

  const handleUpdateButtonClick = async (event) => {
    event.preventDefault();

    if (
      !userInfo.officeName.replaceAll(" ", "").length ||
      !userInfo.phone.replaceAll(" ", "").length
    ) {
      setErrorMessage("✓ 필수입력란을 확인해주세요.");

      return;
    }

    try {
      if (
        isValidPhone &&
        userInfo.officeName.replaceAll(" ", "").length &&
        userInfo.phone.replaceAll(" ", "").length
      ) {
        const response = await userApi.addGoogleAdditionalInfo({
          idx: user?.user_idx,
          phone: userInfo.phone.replaceAll("-", ""),
          officeName: userInfo.officeName,
          marketing: userInfo.marketing,
        });

        if (response.result === "success") {
          navigate("/");
        }
      }
    } catch (err) {
      if (err) {
        alert("등록중 에러가 발생했습니다. 잠시후 다시 등록부탁드립니다.");
      }
    }
  };

  useEffect(() => {
    if (userInfo.phone?.length === 11) {
      setUserInfo((prevSate) => ({
        ...prevSate,
        phone: userInfo.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }

    if (userInfo.phone?.length === 13) {
      setUserInfo((prevState) => ({
        ...prevState,
        phone: userInfo.phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }
  }, [userInfo.phone]);

  return (
    <main>
      <section className="position-relative">
        <div className="container py-lg-15 px-lg-11 py-6">
          <div className="d-flex justify-content-center">
            <div
              className="w-lg-40 w-100 py-lg-5 py-5 px-3"
              style={{ border: "1px solid #c8c8c8", borderRadius: "13px" }}
            >
              <p className="fs-4 text-center mb-lg-5">하나만 더 확인할게요!</p>
              <form onSubmit={handleUpdateButtonClick}>
                <div className="px-lg-4">
                  <div className="px-lg-2 mb-2">
                    <span style={{ color: "#000000" }}>연락처</span>
                    <span style={{ color: "#ff3d0d" }}>*</span>
                  </div>
                  <TextInput
                    name="phone"
                    type="text"
                    placeholder="01012345678"
                    icon="bx-phone"
                    value={userInfo.phone || ""}
                    maxLength={13}
                    pattern={PHONE_REGEXP}
                    errorMessage="✓ 핸드폰번호를 확인해주세요."
                    onChange={handleChange}
                  />
                </div>
                <div className="px-lg-4">
                  <div className="px-lg-2 mb-2">
                    <span style={{ color: "#000000" }}>회사 이름</span>
                    <span style={{ color: "#ff3d0d" }}> *</span>
                  </div>
                  <TextInput
                    name="officeName"
                    type="text"
                    placeholder="BOOSTAR"
                    icon="bx-building"
                    value={userInfo.officeName || ""}
                    onChange={handleChange}
                  />
                  <div className="mx-lg-2 mb-2">
                    <small className="text-danger">{errorMessage}</small>
                  </div>
                </div>
                <div className="mb-3 px-lg-4 d-flex justify-content-between">
                  <div className="form-check">
                    <label className="form-check-label small text-muted">
                      <input
                        className="form-check-input"
                        name="newsLetterAssignment"
                        onChange={handleMarketingClick}
                        checked={userInfo.marketing}
                        type="checkbox"
                      />
                      마케팅 수신 정보 이용 동의 (선택){" "}
                    </label>
                    <span
                      className="small ps-lg-1 ps-1 link-decoration"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsMarketingModalShow(true)}
                    >
                      자세히
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    width="15"
                    text="가입 완료하기"
                    border="none"
                    background="#fef4e5"
                    color="#fbb60d"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <ModalContainer
          show={isMarketingModalShow}
          content="마케팅 수신 정보 이용 동의"
          onHide={() => setIsMarketingModalShow(false)}
        >
          <p className="small">
            회원님들께 더 나은 서비스를 제공하고자 광고 ∙ 마케팅 목적(이벤트
            안내, 공지사항, 할인행사)의 개인정보 수집 및 이용에 대한 동의를
            받고자 합니다. 내용을 자세히 읽으신 후, 동의를 원하시면 원래의
            화면으로 돌아가 “동의”, “계속”, “동의하고 진행” 등 해당 화면의
            안내에 따라 버튼을 눌러 주시기 바랍니다. 보다 자세한 내용은 회사의
            이용 약관을 참조하여 주시기 바랍니다.
          </p>
          <p className="small mt-lg-3 ps-lg-2 mt-3 px-2">
            • 목적 : BOOSTAR에서 운영하는 서비스에 대한 광고 ∙ 홍보 ∙ 프로모션
            제공 <br />
            • 정보 수신 채널 : 이메일, SMS, 카카오톡 <br />• 개인정보 보유 및
            이용기간 : 광고 ∙ 마케팅 목적의 개인정보 수집 ∙ 이용에 대한 동의
            시부터 동의 철회 시 또는 회원 탈퇴 시까지
            <br />• 광고 ∙ 마케팅 항목은 선택사항이므로 동의를 거부하는 경우에도
            BOOSTAR 서비스의 이용에는 영향이 없습니다. 또한, 광고 ∙ 마케팅
            동의는 고객센터를 통해 언제든지 철회할 수 있습니다.
          </p>
          <div className="d-flex flex-center mt-lg-3 mt-3">
            <Button
              width="13"
              text="닫기"
              border="none"
              background="#fec230"
              color="#000000"
              onClick={() => setIsMarketingModalShow(false)}
            />
          </div>
        </ModalContainer>
      </section>
    </main>
  );
}
