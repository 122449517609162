import React from "react";
import { useNavigate } from "react-router-dom";

export default function Card({
  id,
  thumbnail,
  date,
  title,
  explanation,
  price,
}) {
  const navigate = useNavigate();

  const handleEbookClick = () => {
    navigate(`/ebook/${id}`);
  };

  return (
    <>
      <div
        className="col-md-4 col-sm-6 mb-4 mb-md-4 my-4"
        onClick={handleEbookClick}
      >
        <div className="card card-hover hover-lift hover-shadow-lg border-1 border-dark overflow-hidden rounded-5">
          <img
            className="card-img-top img-fluid img-zoom"
            src={thumbnail}
            alt="content thumbnail"
          />
          <div
            className="card-body d-flex flex-column start-0 top-0 w-100 h-100 justify-content-end pb-lg-5 px-4"
            style={{ background: "#ffffff" }}
          >
            <p className="small ms-ls-2 ms-1">
              <span>{date}</span>
            </p>
            <h5
              className="h5 py-lg-1 ms-ls-2 ms-1"
              style={{ height: "2.5rem" }}
            >
              <span>{title}</span>
            </h5>
            <h6 className="py-lg-2 py-2 ms-ls-2 ms-1">{explanation}</h6>
            <div
              className="text-end mx-lg-1 mx-1 pt-lg-1 fw-bold"
              style={{ borderTop: "1px solid black" }}
            >
              15,000 원
            </div>
            <div className="ps-lg-2 ps-2 mb-lg-1 mb-2 fw-bold">{price}</div>
          </div>
        </div>
      </div>
    </>
  );
}
