import React from "react";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";

import Button from "../Button";

export default function Introduction() {
  const navigate = useNavigate();
  return (
    <section>
      <div className="container d-flex flex-center flex-column">
        <div className="mx-lg-5 mt-6">
          <div className="fw-bolder" style={{ color: "#2196F3" }}>
            INTRODUCTION
          </div>
          <div className="text-center my-lg-4 my-3">
            <h1 className="fs-1">Startup Wheel Competition</h1>
            <h4>동남아시아 최대 규모 스타트업 경연대회</h4>
          </div>
          <div className="ratio ratio-16x9 w-lg-100 mb-0">
            <iframe
              src="https://player.vimeo.com/video/814839271?h=fb2ca349f0?autoplay=1&loop=1&title=0&background=1"
              width="1920"
              height="1080"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="my-lg-5 my-5">
            <div>
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> About</span>
            </div>
            <div>
              <p>
                Startup Wheel (SW)는 2013년에 시작되었으며, 매년 3월부터 8월까지{" "}
                <span className="fw-bolder">
                  6개월 동안 진행되는 동남아시아 최대 규모 액셀러레이터
                </span>{" "}
                프로그램입니다.
                <br />
                전세계의 모든 스타트업들이 지원할 수 있으며, 다양한 산업군에서
                스테이지에 상관없이 참가할 수 있습니다.
                <br /> 더 밝은 미래를 가진 스타트업을 유치하기 위해, 대회는 두
                가지 트랙으로 이루어집니다.
                <br />
                <br />• <span className="fw-bolder">International Track</span> -
                베트남 시장으로 사업을 확장하고 동남아시아 스타트업 생태계와
                연결하려는 해외 스타트업을 위한 트랙
              </p>
            </div>
          </div>
          <div className="my-lg-5 my-5">
            <div>
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> TOGETHER</span>
            </div>
            <div className="row my-lg-3">
              <div className="col-lg-4 col-4 flex-center flex-column">
                <div className="fs-1 fw-bolder">
                  <CountUp end={8500} duration={3} />+
                </div>
                <div className="fs-6 fw-bolder text-muted">STARTUPS</div>
              </div>
              <div className="col-lg-4 col-4 flex-center flex-column">
                <div className="fs-1 fw-bolder">
                  <CountUp end={30} duration={3} />+
                </div>
                <div className="fs-6 fw-bolder text-muted">COUNTRIES</div>
              </div>
              <div className="col-lg-4 col-4 flex-center flex-column">
                <div className="fs-1 fw-bolder">
                  <CountUp end={20} duration={3} />+
                </div>
                <div className="fs-6 fw-bolder text-muted">GLOBAL PARTNERS</div>
              </div>
            </div>
          </div>
          <div className="my-lg-5 my-5">
            <div>
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> ABOUT THE ORGANIZER</span>
            </div>
            <div>
              <p>
                <span className="fw-bolder">
                  BSSC (Business Startup Support Center)
                </span>
                는 투자자, 기업 등 생태계 이해관계자들과 스타트업을 연결하며,
                베트남 스타트업 생태계를 발전시키고 강화하는 데 기여하고
                있습니다.
                <br /> 또한 베트남 스타트업이 해외로 진출할 수 있는 환경을
                제공하고, 국제 스타트업들도 베트남의 스타트업 생태계와 연결될 수
                있도록 교량 역할을 하여 상호성장할 수 있는 기회를 제공합니다.
              </p>
            </div>
          </div>
        </div>
        {/* <Button
          width="18"
          text="참가하기"
          border="none"
          background="#f44336"
          color="#ffffff"
          onClick={() => navigate("/startup_wheel_2023/apply")}
        /> */}
      </div>
    </section>
  );
}
