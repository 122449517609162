import React, { useState } from "react";

export default function Rating({ rating, onClickStar }) {
  return (
    <div className="row">
      {[1, 2, 3, 4, 5].map((num, index) => (
        <div
          key={index}
          onClick={() => onClickStar(num)}
          className="col-lg-2 col-2 mx-lg-1 mx-1"
        >
          {num <= rating ? (
            <i className="bx bxs-star fs-2" style={{ color: "#fec230" }} />
          ) : (
            <i className="bx bxs-star fs-2" style={{ color: "#f4f4f5" }} />
          )}
        </div>
      ))}
    </div>
  );
}
