import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SERVER_URL
      : process.env.REACT_APP_LOCAL_SERVER_URL,
  withCredentials: true,
  // headers: {
  //   "Content-Type": "application/json",
  //   authorization: localStorage.getItem("accessToken")
  //     ? `Bearer ${localStorage.getItem("accessToken")}`
  //     : undefined,
  // },
});

instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  const { headers } = config;

  if (!headers.Authorization?.length || !headers.Authorization) {
    config.headers.authorization = accessToken ? `Bearer ${accessToken}` : "";
  }

  return config;
});

export default instance;
