import React, { useState } from "react";

import Carousel from "react-bootstrap/Carousel";

export default function AddSlide() {
  const [index, setIndex] = useState(0);

  const adImage = [
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/dongsim_partner_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/forest_main_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/kova_partner_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/wise_main_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/ad/theon_main_ad_banner.png",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/the_on_main_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/sustainable_main_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/paperlogy_partner_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/formation_pro_partner_banner.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/hurdlers_partner_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/groundup_partner_ad.jpg",
    "https://cdn-s3.boostar.co.kr/boostar/partner_slide/byon_partner_ad.jpg",
  ];

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <section className="position-relative">
      <div className="container px-lg-11 py-lg-6 pt-5">
        <div onClick={() => window.open("https://www.lawtheon.com/", "_blank")}>
          <img
            src="https://cdn-s3.boostar.co.kr/boostar/ad/theon_main_ad_banner.png"
            className="img-fluid w-lg-100 px-lg-3"
            style={{ borderRadius: "5px", cursor: "pointer" }}
          />
          {/* <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            fade
            indicators={false}
            controls={false}
            interval={3000}
          >
            {adImage.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  src={image}
                  className="img-fluid w-lg-100 px-lg-3"
                  style={{ borderRadius: "5px" }}
                />
              </Carousel.Item>
            ))}
          </Carousel> */}
        </div>
      </div>
    </section>
  );
}
