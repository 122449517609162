import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import ModalContainer from "../components/Modal";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import userApi from "../api/user";
import { userInfoState } from "../store/userState";
import { EMAIL_REGEXP, NAME_REGEXP, PHONE_REGEXP } from "../constants/RegExp";
import LoginModal from "../components/LoginModal";

export default function MyPage() {
  const accessToken = localStorage.getItem("accessToken");
  const user = useRecoilValue(userInfoState(accessToken));

  const [modalShow, setModalShow] = useState(false);
  const [isMarketingModalShow, setIsMarketingModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [path, setPath] = useState("");
  const [modalDetail, setModalDetail] = useState({
    content: "",
    buttonTitle: "",
  });
  const [marketing, setMarketing] = useState(
    user?.marketing === null || user?.marketing === 0 ? false : true
  );
  const [updateUserInfo, setUpdateUserInfo] = useState({
    name: user?.name,
    phone: user?.phone,
    officeName: user?.officeName,
  });

  const navigate = useNavigate();

  const isValidName = NAME_REGEXP.test(updateUserInfo.name);
  const isValidPhone = PHONE_REGEXP.test(updateUserInfo.phone);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setUpdateUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleMarketingClick = () => {
    setMarketing(!marketing);
  };

  const handleUpdateButtonClick = async (event) => {
    event.preventDefault();

    if (!updateUserInfo.name) {
      return setIsEmpty(true);
    }

    if (updateUserInfo.phone !== null && updateUserInfo.phone.length < 13) {
      return setErrorMessage("✓ 핸드폰번호를 확인해주세요.");
    }

    if (
      isValidName &&
      (updateUserInfo.phone === null ||
        (updateUserInfo.phone.length === 0 && !isValidPhone) ||
        (updateUserInfo.phone.length === 13 && isValidPhone))
    ) {
      try {
        const response = await userApi.updateUserInfo({
          id: user?.id,
          name: updateUserInfo.name,
          phone:
            updateUserInfo.phone === null
              ? ""
              : updateUserInfo.phone.replaceAll("-", ""),
          officeName:
            updateUserInfo.officeName === null ? "" : updateUserInfo.officeName,
          marketing: marketing,
        });

        if (response.result === "success") {
          setPath("/");
          setModalDetail((prevSate) => ({
            ...prevSate,
            content: "회원정보가 수정되었습니다.",
            buttonTitle: "돌아가기",
          }));
          setModalShow(true);
        }
      } catch (err) {
        if (err) {
          if (err.response.data.error.message === "invalid") {
            alert("로그인 정보가 유효하지 않습니다. 다시 로그인해주세요.");

            localStorage.removeItem("accessToken");

            navigate("/");
          } else {
            alert("에러가 발생했습니다. 잠시 후 다시 이용바랍니다.");
          }
        }
      }
    }
  };

  const handleDeleteButtonClick = () => {
    navigate("/withdrawal/member");
  };

  useEffect(() => {
    if (user?.is_pass_init === "Y") {
      navigate("/my_page/change_password", {
        state: {
          resetPassword: false,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (updateUserInfo.phone?.length === 11) {
      setUpdateUserInfo((prevSate) => ({
        ...prevSate,
        phone: updateUserInfo.phone.replace(
          /(\d{3})(\d{4})(\d{4})/,
          "$1-$2-$3"
        ),
      }));
    }

    if (updateUserInfo.phone?.length === 13) {
      setUpdateUserInfo((prevState) => ({
        ...prevState,
        phone: updateUserInfo.phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }
  }, [updateUserInfo.phone]);

  return (
    <main>
      <section className="position-relative">
        <div className="container position-relative">
          <div className="position-relative pt-9 pb-9 pb-lg-11">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column w-lg-50">
                <nav className="nav nav-pills">
                  <a href="/my_page" className="nav-link active">
                    <i className="bx bx-user-circle me-2" />내 프로필
                  </a>
                  <a href="/order/list" className="nav-link">
                    <i className="bx bx-credit-card me-2" />
                    결제 내역
                  </a>
                  <a href="/my_class/list" className="nav-link">
                    <i className="bx bx-rocket me-2" />
                    강의 신청 내역
                  </a>
                  <a href="/review/list" className="nav-link">
                    <i className="bx bx-comment-detail me-2" />
                    내가 쓴 리뷰
                  </a>
                </nav>
                <div className="d-flex align-items-center pt-1 pb-3">
                  <span className="flex-grow-1 border-bottom pt-1" />
                </div>
                <div className="h-100">
                  <h5 className="mb-4">회원 정보 수정</h5>
                  <form onSubmit={handleUpdateButtonClick}>
                    <div>
                      <div className="mb-3">
                        <span style={{ color: "#000000" }}>이름</span>
                      </div>
                      <TextInput
                        name="name"
                        type="text"
                        placeholder="이름(실명)"
                        icon="bx-user"
                        value={updateUserInfo.name || ""}
                        pattern={NAME_REGEXP}
                        errorMessage="✓ 이름을 확인해주세요."
                        emptyValidation={isEmpty}
                        emptyMessage="✓ 이름을 확인해주세요."
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <div className="mb-3">
                        <span style={{ color: "#000000" }}>이메일</span>
                      </div>
                      <TextInput
                        name="email"
                        type="text"
                        placeholder="user@ground-up.kr"
                        icon="bx-envelope"
                        value={user?.email}
                        pattern={EMAIL_REGEXP}
                        errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                        emptyValidation={isEmpty}
                        emptyMessage="✓ 이메일을 확인해주세요."
                        onChange={handleChange}
                      />
                    </div>
                    {user?.is_password === "Y" && (
                      <div>
                        <div className="mb-3">
                          <span
                            className="me-lg-2 me-2"
                            style={{ color: "#000000" }}
                          >
                            비밀번호
                          </span>
                          <Button
                            width="6"
                            text="변경하기"
                            background="#f4f4f5"
                            color="#797979"
                            border="none"
                            onClick={() => navigate("/my_page/change_password")}
                          />
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="mb-3">
                        <span style={{ color: "#000000" }}>연락처</span>
                      </div>
                      <TextInput
                        name="phone"
                        type="text"
                        placeholder="01012345678"
                        icon="bx-phone"
                        value={updateUserInfo.phone || ""}
                        maxLength={13}
                        pattern={PHONE_REGEXP}
                        errorMessage="✓ 핸드폰번호를 확인해주세요."
                        emptyValidation={isEmpty}
                        emptyMessage="✓ 핸드폰번호를 확인해주세요."
                        onChange={handleChange}
                      />
                      <div className="mb-3">
                        <small className="text-danger">{errorMessage}</small>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <span style={{ color: "#000000" }}>회사</span>
                      </div>
                      <TextInput
                        name="officeName"
                        type="text"
                        placeholder="BOOSTAR"
                        icon="bx-buildings"
                        value={updateUserInfo.officeName || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 d-flex justify-content-between">
                      <div className="form-check">
                        <label
                          className="form-check-label small"
                          htmlFor="flexCheckDefault"
                        >
                          <input
                            className="form-check-input"
                            name="newsLetterAssignment"
                            onChange={handleMarketingClick}
                            checked={marketing}
                            type="checkbox"
                            id="flexCheckDefault"
                          />
                          마케팅 수신 정보 이용 동의 (선택){" "}
                        </label>
                        <span
                          className="small ps-lg-1 ps-1"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsMarketingModalShow(true)}
                        >
                          자세히
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mb-lg-2 mb-2">
                      <Button
                        width="20"
                        text="수정하기"
                        border="none"
                        background="#fef4e5"
                        color="#fbb60d"
                      />
                    </div>
                  </form>
                  <div className="d-flex justify-content-center">
                    <Button
                      width="20"
                      text="탈퇴하기"
                      border="none"
                      background="#f4f4f5"
                      color="#797979"
                      onClick={handleDeleteButtonClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalContainer
        show={modalShow}
        content={modalDetail.content}
        onHide={() => setModalShow(false)}
      >
        <div className="mt-lg-3 mb-lg-2">
          <Button
            width="10"
            text={modalDetail.buttonTitle}
            border="none"
            background="#fef4e5"
            color="#fbb60d"
            onClick={() => navigate("/")}
          />
        </div>
      </ModalContainer>
      <LoginModal userInfo={user} />
      <ModalContainer
        show={isMarketingModalShow}
        content="마케팅 수신 정보 이용 동의"
        onHide={() => setIsMarketingModalShow(false)}
      >
        <p className="small">
          회원님들께 더 나은 서비스를 제공하고자 광고 ∙ 마케팅 목적(이벤트 안내,
          공지사항, 할인행사)의 개인정보 수집 및 이용에 대한 동의를 받고자
          합니다. 내용을 자세히 읽으신 후, 동의를 원하시면 원래의 화면으로
          돌아가 “동의”, “계속”, “동의하고 진행” 등 해당 화면의 안내에 따라
          버튼을 눌러 주시기 바랍니다. 보다 자세한 내용은 회사의 이용 약관을
          참조하여 주시기 바랍니다.
        </p>
        <p className="small mt-lg-3 ps-lg-2 mt-3 px-2">
          • 목적 : BOOSTAR에서 운영하는 서비스에 대한 광고 ∙ 홍보 ∙ 프로모션
          제공 <br />
          • 정보 수신 채널 : 이메일, SMS, 카카오톡 <br />• 개인정보 보유 및
          이용기간 : 광고 ∙ 마케팅 목적의 개인정보 수집 ∙ 이용에 대한 동의
          시부터 동의 철회 시 또는 회원 탈퇴 시까지
          <br />• 광고 ∙ 마케팅 항목은 선택사항이므로 동의를 거부하는 경우에도
          BOOSTAR 서비스의 이용에는 영향이 없습니다. 또한, 광고 ∙ 마케팅 동의는
          고객센터를 통해 언제든지 철회할 수 있습니다.
        </p>
        <div className="d-flex flex-center mt-lg-3 mt-3">
          <Button
            width="13"
            text="닫기"
            border="none"
            background="#fec230"
            color="#000000"
            onClick={() => setIsMarketingModalShow(false)}
          />
        </div>
      </ModalContainer>
    </main>
  );
}
