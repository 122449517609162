import React, { useCallback, useEffect, useState } from "react";

import FounderTeam from "./FounderTeam";
import Funding from "./Funding";
import OfficeInformation1 from "./OfficeInformation1";
import OfficeInformation2 from "./OfficeInformation2";
import OfficeInformation3 from "./OfficeInformation3";
import PersonalInformation from "./PersonalInformation";
import Result from "./Result";

export default function ApplyForm() {
  const [isEmpty, setIsEmpty] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [stage, setStage] = useState({
    personalInformation: true,
    officeInformation1: false,
    officeInformation2: false,
    officeInformation3: false,
    funding: false,
    founderTeam: false,
    result: false,
  });
  const [applyData, setApplyData] = useState({
    name: "",
    gender: "",
    email: "",
    birth: "",
    phone: "",
    currentTitle: "",
    officeName: "",
    officeDescription: "",
    location: "",
    establishmentDate: "",
    businessModel: "",
    developmentStage: "",
    mainIndustry: "",
    mainTechnology: "",
    pitchDeckLink: "",
    pitchingVideoLink: "",
    certification: "",
    patent: "",
    totalFund: "",
    fundingRound: "",
    fundingAmount: "",
    numberOfFounders: "",
    foundersProfile: "",
    vietnamTeam: "",
    howToStartupWheel: "",
    howToBoostar: "",
  });

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setApplyData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  useEffect(() => {}, [stage]);

  return (
    <section>
      <div className="container d-flex flex-center flex-column">
        <div className="mx-lg-5 w-lg-90">
          <div className="fw-bolder mb-lg-4 mt-6" style={{ color: "#2196F3" }}>
            APPLY
          </div>
          <div>
            {stage.personalInformation === true && (
              <PersonalInformation
                applyData={applyData}
                isEmpty={isEmpty}
                setIsEmpty={setIsEmpty}
                handleChange={handleChange}
                handleChangeStage={setStage}
              />
            )}
            {stage.officeInformation1 === true && (
              <OfficeInformation1
                applyData={applyData}
                isEmpty={isEmpty}
                setIsEmpty={setIsEmpty}
                handleChange={handleChange}
                handleChangeStage={setStage}
              />
            )}
            {stage.officeInformation2 === true && (
              <OfficeInformation2
                applyData={applyData}
                isEmpty={isEmpty}
                setIsEmpty={setIsEmpty}
                handleChange={handleChange}
                handleChangeStage={setStage}
              />
            )}
            {stage.officeInformation3 === true && (
              <OfficeInformation3
                applyData={applyData}
                isEmpty={isEmpty}
                setIsEmpty={setIsEmpty}
                handleChange={handleChange}
                handleChangeStage={setStage}
              />
            )}
            {stage.funding === true && (
              <Funding
                applyData={applyData}
                isEmpty={isEmpty}
                setIsEmpty={setIsEmpty}
                handleChange={handleChange}
                handleChangeStage={setStage}
              />
            )}
            {stage.founderTeam === true && (
              <FounderTeam
                applyData={applyData}
                isEmpty={isEmpty}
                setIsEmpty={setIsEmpty}
                handleChange={handleChange}
                handleChangeStage={setStage}
              />
            )}
            {stage.result === true && <Result />}
          </div>
        </div>
      </div>
    </section>
  );
}
