import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import orderApi from "../api/order";

import Button from "../components/Button";
import checkBusinessNumber from "../util/checkBusinessNum";
import TextInput from "./TextInput";
import { PHONE_REGEXP } from "../constants/RegExp";
import { orderState } from "../store/orderState";

export default function CashReceiptModalCard({ onHide }) {
  const [receiptType, setReceiptType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfo, setUserInfo] = useState({
    phone: "",
    businessNumber: "",
  });

  const [orderInfo, setOrderInfo] = useRecoilState(orderState);

  const isValidPhone = PHONE_REGEXP.test(userInfo.phone);

  const handleRequestReceiptSubmit = async (event) => {
    event.preventDefault();

    const isCheckNumber = checkBusinessNumber(userInfo.businessNumber);

    if (!isCheckNumber) {
      setErrorMessage("✓ 올바르지 않은 사업자등록번호입니다.");
    }

    if ((isValidPhone && userInfo.phone.length > 0) || isCheckNumber) {
      try {
        const response = await orderApi.requestCashReceipt({
          orderId: orderInfo.order_id,
          orderName: orderInfo.service_name,
          amount: orderInfo.amount,
          type: receiptType,
          customerIdentityNumber: userInfo,
        });

        if (response.result === "success") {
          setOrderInfo((prevSate) => ({
            ...prevSate,
            cash_receipt: response.data.cashReceipt,
            cash_receipt_type: response.data.type,
          }));
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  const handleTypeChange = (event) => {
    setReceiptType(event.target.value);

    if (event.target.value === "소득공제") {
      setUserInfo((prevState) => ({
        ...prevState,
        businessNumber: "",
      }));
      setErrorMessage("");
    }

    if (event.target.value === "지출증빙") {
      setUserInfo((prevSate) => ({
        ...prevSate,
        phone: "",
      }));
    }
  };

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleCloseButtonClick = (event) => {
    event.preventDefault();

    setErrorMessage("");
    onHide(false);
  };

  useEffect(() => {
    if (userInfo.phone?.length === 11) {
      setUserInfo((prevSate) => ({
        ...prevSate,
        phone: userInfo.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }

    if (userInfo.phone?.length === 13) {
      setUserInfo((prevState) => ({
        ...prevState,
        phone: userInfo.phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }

    if (userInfo.businessNumber.length === 10) {
      setUserInfo((prevSate) => ({
        ...prevSate,
        businessNumber: userInfo.businessNumber.replace(
          /(\d{3})(\d{2})(\d{5})/,
          "$1-$2-$3"
        ),
      }));
    }
  }, [userInfo.phone, userInfo.businessNumber]);

  return (
    <>
      {orderInfo.cash_receipt_type === "null" ? (
        <div>
          <form onSubmit={handleRequestReceiptSubmit}>
            <div className="my-lg-4">
              <div
                className="mx-lg-2 my-lg-2 mx-2 my-2"
                style={{ borderBottom: "1px solid #e8e8e8" }}
              >
                발급목적
              </div>
              <div className="my-lg-3 my-3">
                <label className="mx-lg-2 mx-2">
                  <input
                    type="radio"
                    name="type"
                    value="소득공제"
                    checked={receiptType === "소득공제" ? true : false}
                    onChange={handleTypeChange}
                  />
                  개인소득공제
                </label>
                <label className="mx-lg-2 mx-2">
                  <input
                    type="radio"
                    name="type"
                    value="지출증빙"
                    checked={receiptType === "지출증빙" ? true : false}
                    onChange={handleTypeChange}
                  />
                  사업자지출증빙
                </label>
              </div>
            </div>
            <div className="my-lg-4">
              <div
                className="mx-lg-2 my-lg-2 mx-2 my-2"
                style={{ borderBottom: "1px solid #e8e8e8 " }}
              >
                신청정보
              </div>
              {receiptType === "소득공제" && (
                <div className="mx-lg-2 my-lg-3 mx-2 my-3">
                  <div className="my-lg-2 my-2">• 휴대폰번호</div>
                  <TextInput
                    name="phone"
                    type="text"
                    placeholder="01012345678"
                    icon="bx-phone"
                    value={userInfo.phone || ""}
                    maxLength={13}
                    pattern={PHONE_REGEXP}
                    errorMessage="✓ 핸드폰번호를 확인해주세요."
                    emptyMessage="✓ 핸드폰번호를 확인해주세요."
                    onChange={handleChange}
                    autofocus
                  />
                </div>
              )}
              {receiptType === "지출증빙" && (
                <div className="mx-lg-2 my-lg-3 mx-2 my-3">
                  <div className="my-lg-2 my-2">• 사업자번호</div>
                  <TextInput
                    name="businessNumber"
                    type="text"
                    placeholder="1234567890"
                    icon="bx-store"
                    maxLength={12}
                    value={userInfo.businessNumber || ""}
                    onChange={handleChange}
                  />
                  <div className="mb-4">
                    <small className="text-danger">{errorMessage}</small>
                  </div>
                </div>
              )}
            </div>
            <div className="row justify-content-center">
              <button
                className="btn w-lg-40 w-100 mx-lg-2 my-2"
                style={{ background: "#424557", color: "#ffffff" }}
              >
                신청
              </button>
              <button
                className="btn w-lg-40 w-100 mx-lg-2 my-2"
                style={{ border: "1px solid #424557" }}
                onClick={handleCloseButtonClick}
              >
                닫기
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="my-lg-4">
          <div>정상적으로 신청되었습니다.</div>
          <div className="d-flex justify-content-center mt-lg-4">
            <Button
              width="10"
              background="#424557"
              color="#ffffff"
              border="none"
              text="닫기"
              onClick={handleCloseButtonClick}
            />
          </div>
        </div>
      )}
    </>
  );
}
