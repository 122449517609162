import React from "react";

import ContentCard from "../ContentCard";

export default function ContentList({ data }) {
  return (
    <>
      <section className="position-relative">
        <div className="container px-lg-11 pb-1 pb-lg-7 position-relative">
          <div className="row justify-content-center">
            {/* <div className="col-lg-3 col-md-4 col-sm-6 mb-4 mb-md-4 my-md-4">
              <div className="card card-hover hover-lift hover-shadow-lg border-1 border-dark overflow-hidden rounded-4">
                <img
                  src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/ad/theon_ad2_sampel2.png"
                  className="img-fluid w-lg-100"
                />
              </div>
            </div> */}
            {data.map((item) => (
              <ContentCard key={item.idx} contentInfo={item} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
