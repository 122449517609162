import React from "react";

import Intro2 from "../components/Service/Intro2";
import More from "../components/More";

import introImage from "../assets/img/image/designer_intro.png";
import designerImage from "../assets/img/image/designer_image.png";

export default function Designer() {
  return (
    <main>
      <Intro2
        image={introImage}
        title1="BOOSTAR에서"
        title2="최고의 디자이너와 함께하세요"
        subTitle="스타트업이 필요로 하는 모든 디자인 작업, BOOSTAR에서 해결하세요."
        url="/contact"
      />
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                비즈니스의 첫 인상, 디자인이 결정합니다
              </h1>
              <h5 className="mb-3 mb-lg-6">
                아무리 좋은 사업이라도 사람들에게 전달되지 않으면 의미가
                없습니다.
              </h5>
              <h5 className="mb-3 mb-lg-6">
                가장 빠른 시간 안에 회사의 이미지를 각인시키는 것은
                디자인입니다.
                <br /> 회사의 철학과 스토리, 그리고 서비스를 뛰어난 디자인을
                통해 직관적으로 빠르게 전달합니다.
              </h5>
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                어떤 디자인이 필요하신가요?
              </h1>
              <h5 className="mb-3 mb-lg-4">
                IR 자료, 회사소개서, CI, BI, 웹디자인까지 스타트업 운영에 필요한
                디자인 요소는 무수히 많습니다.
                <br />
                스타트업 운영에 필요한 모든 디자인을 완성해줄 최고의 디자이너를
                만날 수 있습니다.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={designerImage} className="img-fluid w-lg-90" />
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <div
                className="px-lg-3 px-3 py-lg-8 py-2 w-lg-90"
                style={{ background: "#fec72f" }}
              >
                <h1 className="lh-base mb-lg-5 fs-4 mb-2">
                  스타트업 전문
                  <br />
                  디자이너를 만나보세요
                </h1>
                <h6 className="lh-base mb-2 mb-lg-3">
                  고객에 대한 이해를 기반으로 한 차별화된 디자인 서비스를
                  제공합니다.
                  <br />
                  같은 실력의 디자이너라도 고객 이해도에 따라 결과물은 크게
                  달라집니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  BOOSTAR에서 스타트업 전문 디자이너를 빠르게 연결해드립니다.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
