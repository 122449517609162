import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import ContentList from "../components/Blog/ContentList";
import Tag from "../components/Blog/Tag";
import Button from "../components/Button";
import resourceApi from "../api/resource";
import { contentsState } from "../store/contentState";

export default function Blog() {
  const [filteredContents, setFilteredContents] = useState(null);
  const [search, setSearch] = useState(undefined);

  const [contents, setContents] = useRecoilState(contentsState);

  const tags = ["투자유치", "경영", "재무", "법무", "노무", "세무"];

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getBlogContents = async () => {
      try {
        const response = await resourceApi.getBlogContents();

        if (response.result === "success") {
          setContents(response.data);
        }
      } catch (err) {
        alert(
          "콘텐츠를 불러오는데 에러가 발생했습니다. 잠시 후 다시 이용바랍니다."
        );
      }
    };

    getBlogContents();
  }, []);

  useEffect(() => {
    if (!params.type) {
      setFilteredContents(contents);

      return;
    }

    if (params.type === "qna" && params.topic === undefined) {
      const qnaContent = contents?.filter(
        (content) => content.blog_type === "Q&A"
      );

      setFilteredContents(qnaContent);

      return;
    } else if (params.type === "startup-news") {
      const newsContents = contents?.filter(
        (content) => content.blog_type === "스타트업 뉴스"
      );

      setFilteredContents(newsContents);

      return;
    } else if (params.type === "business-support") {
      const businessSupportContent = contents?.filter(
        (content) => content.blog_type === "지원사업"
      );

      setFilteredContents(businessSupportContent);

      return;
    }
  }, [contents, params]);

  const handleTypeButtonClick = (event) => {
    event.preventDefault();

    const filtering = contents?.filter(
      (content) => content.topic === event.target.id
    );

    navigate(`/blog/qna/${event.target.id}`);
    setFilteredContents(filtering);
  };

  const handleSearchInputChange = useCallback((event) => {
    event.preventDefault();

    setSearch(event.target.value);
  });

  const handleSearchButtonClick = useCallback((event) => {
    event.preventDefault();

    if (!search) {
      return;
    }

    const searchContent = contents.filter((content) => {
      const title = content.title.replaceAll(" ", "");
      const searchedTitle = search.replaceAll(" ", "");

      return title.includes(searchedTitle);
    });

    setSearch("");
    setFilteredContents(searchContent);

    navigate("/blog/search");
  });

  return (
    <main>
      <section className="positive-relative d-flex align-items-center text-white">
        <div className="w-100" style={{ background: "#000000" }}>
          <div className="container position-relative px-lg-11 py-8 py-lg-6">
            <div className="row col-lg-8 align-items-center my-lg-3">
              <div className="mb-3 mb-lg-0">
                <h2 className="display-5 mb-2" style={{ color: "#ffc230" }}>
                  스타트업 네비게이터
                </h2>
                <p className="lh-sm mb-lg-4">
                  스타트업을 위한 모든 여정을 BOOSTAR 지식 센터에서 확인하세요.
                  <br />
                  BOOSTAR의 전문가 팀이 E-BOOK, 블로그, 웨비나, CASE STUDY에서
                  <br />
                  사업 성장에 필요한 정보를 전해드립니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative pb-1 pb-lg-2">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container position-relative justify-content-end">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0 mx-2 mx-lg-5">
              <li className="nav-item active">
                <Link to="/blog" className="nav-link">
                  전체
                </Link>
              </li>
              <li className="nav-item active">
                <Link to="/blog/startup-news" className="nav-link">
                  스타트업 뉴스
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blog/qna" className="nav-link">
                  Q&A
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blog/business-support" className="nav-link">
                  지원사업
                </Link>
              </li>
            </ul>
            <div className="d-flex align-items-center navbar-no-collapse-items order-lg-last">
              <div className="d-flex">
                <input
                  className="form-control mx-2 mx-lg-0"
                  type="search"
                  value={search}
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchInputChange}
                />
                <Button
                  width="7"
                  text="검색"
                  border="none"
                  background="#fef4e5"
                  color="#fbb60d"
                  onClick={handleSearchButtonClick}
                />
              </div>
            </div>
          </div>
        </nav>
        <div className="d-flex align-items-center py-3">
          <span className="flex-grow-1 border-bottom pt-1" />
        </div>
      </section>
      {params.type === "qna" && (
        <div className="container d-flex justify-content-end pe-6 pb-2">
          {tags.map((type, index) => (
            <div key={index} type="button" onClick={handleTypeButtonClick}>
              <Tag title={type} />
            </div>
          ))}
        </div>
      )}
      {filteredContents?.length > 0 && <ContentList data={filteredContents} />}
    </main>
  );
}
