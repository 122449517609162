import React from "react";
import { Link } from "react-router-dom";

export default function Intro2({ image, title1, title2, subTitle, url }) {
  return (
    <>
      <section className="position-relative d-flex align-items-center text-white">
        <img src={image} alt="Service image" className="bg-image" />
        <div className="container position-relative py-8 py-lg-10">
          <div className="row align-items-center my-lg-3">
            <div className="mb-3 mb-lg-0">
              <h2 className="display-5 mb-5 mb-lg-7">
                {title1} <br />
                {title2}
              </h2>
              <p className="fs-5 lh-sm mb-lg-4">{subTitle}</p>
              <Link to={url}>
                <button
                  className="btn text-dark width-13x mt-3 mt-lg-2"
                  style={{ background: "#fec72f", borderRadius: "0.37" }}
                >
                  시작하기
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
