import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.button`
  margin: 0 0.5rem;
  width: ${(props) => props.width}rem;
  height: 3rem;
  border: ${(props) => props.border};
  border-radius: 5px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  cursor: pointer;
`;

const Button = ({
  width,
  onClick,
  text,
  background,
  color,
  border,
  isDisabled,
}) => {
  return (
    <ButtonContainer
      className="fw-bold"
      width={width}
      background={background}
      color={color}
      onClick={onClick}
      border={border}
      disabled={isDisabled}
    >
      {text}
    </ButtonContainer>
  );
};

export default Button;
