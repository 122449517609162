import React, { useRef } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import TossPayments from "../components/tossPayments";
import Review from "../components/Review";
import ScrollToTop from "../components/ScrollToTop";
import ServiceBox from "../components/ServiceBox";
import { selectedServiceState } from "../store/reviewState";

const TabContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #fec000;
  }
`;

export default function HrPack() {
  const infoRef = useRef(null);
  const reviewRef = useRef(null);
  const detailInfoRef = useRef(null);
  const refundInfoRef = useRef(null);

  const navigate = useNavigate();
  const reviews = useRecoilValue(selectedServiceState("PC03S01"));

  const image = "https://cdn-s3.boostar.co.kr/boostar/230131+hr_page.jpg";

  const onClickTap = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main className="position-relative pt-lg-0 pt-3">
      <div className="container py-lg-6 px-lg-11">
        <ScrollToTop />
        <div className="row align-items-start">
          <div className="col-lg-7 flex-center">
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/hr/hr_thumbnail_230609.jpg"
              alt="hr image"
              className="img-fluid w-lg-70 w-70 mb-lg-3"
              style={{ borderRadius: "5px" }}
            />
          </div>
          <div className="col-lg-5">
            <TossPayments
              primeCost="500000"
              price="330000"
              discount="170000"
              title="인사 노무 폭탄 제거"
              type="인사노무 컨설팅"
              productCode="PC03S01"
              pageCode="B-23C-IRHR-01"
              pageTitle="인사 노무 폭탄 제거"
              url="hr_pack"
            >
              <div
                className="py-lg-3 py-3"
                style={{ borderBottom: "1px solid #c8c8c8" }}
              >
                <div>
                  <p className="fw-bold">
                    🎁 회원가입 후 서비스를 구매하시면 아래와 같은 혜택들이
                    제공됩니다!
                  </p>
                  <p className="ps-lg-2 ps-2 small">
                    • 재무관리 솔루션 OKCEO 6개월 무료 사용권
                    <br />• 부스타 컨설팅 30% 할인 (세무, 인사노무, 법률,
                    마케팅)
                    <br />• 스타트업 무료 세무 상담 제공 (1회)
                    <br />• 부스타 오프라인 세미나 우선 참석권 (1회)
                  </p>
                </div>
              </div>
              <div className="py-lg-1 py-3">
                <div>
                  <span className="fw-bolder small text-muted">분야</span>
                  <span className="ms-lg-2 ms-2 small text-muted">
                    인사 노무
                  </span>
                </div>
                <div>
                  <span className="fw-bolder small text-muted">
                    진행 전문가
                  </span>
                  <span className="ms-lg-2 ms-2 small text-muted">노무사</span>
                </div>
                <div>
                  <span className="fw-bolder small text-muted">진행 과정</span>
                  <span className="ms-lg-2 ms-2 small text-muted">
                    일정 조율 및 사전 체크 &gt; 전문가 상담
                  </span>
                </div>
                <div>
                  <span className="fw-bolder small text-muted">기간</span>
                  <span className="ms-lg-2 ms-2 small text-muted">2주</span>
                </div>
                <div>
                  <span className="fw-bolder small text-muted">안내사항</span>
                  <span className="ms-lg-2 ms-2 small text-muted">
                    전문가의 업무스케줄에 따라 업무 착수일이 늦어질 수 있습니다.
                  </span>
                </div>
              </div>
            </TossPayments>
          </div>
        </div>
        <div>
          <div
            className="row my-lg-4 my-1 py-lg-0 py-2 mx-lg-0 mx-0"
            style={{ border: "1px solid #c8c8c8", borderRadius: "5px" }}
          >
            <TabContainer
              className="col-lg-3 col-3 px-0"
              onClick={() => onClickTap(infoRef)}
            >
              <div
                className="py-lg-1 text-center button"
                style={{ borderRight: "1px solid #c8c8c8" }}
              >
                서비스 설명
              </div>
            </TabContainer>
            <TabContainer
              className="col-lg-3 col-3 px-0"
              onClick={() => onClickTap(reviewRef)}
            >
              <div
                className="py-lg-1 px-0 text-center button"
                style={{ borderRight: "1px solid #c8c8c8" }}
              >
                리뷰
              </div>
            </TabContainer>
            <TabContainer
              className="col-lg-3 col-3 px-0"
              style={{ borderRight: "1px solid #c8c8c8" }}
              onClick={() => onClickTap(detailInfoRef)}
            >
              <div className="py-lg-1 text-center button">상세정보</div>
            </TabContainer>
            <TabContainer
              className="col-lg-3 col-3 px-0"
              onClick={() => onClickTap(refundInfoRef)}
            >
              <div className="py-lg-1 text-center button">취소환불</div>
            </TabContainer>
          </div>
          <div className="pb-lg-5 d-flex flex-center flex-column" ref={infoRef}>
            <div className="ratio ratio-16x9 w-lg-70 mb-0">
              <iframe
                src="https://player.vimeo.com/video/808540336?h=955826d370?autoplay=1&loop=1&title=0&background=1"
                width="640"
                height="360"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              />
            </div>
            <img src={image} className="w-lg-70 w-100" />
          </div>
          <div className="mt-3">
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2 mb-lg-3 mb-3"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              다른 고객이 함께 구매한 상품
            </h5>
            <div className="row">
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_list_thumbnail_230519.jpg"
                title="무료법인설립"
                info="정부지원사업 지원 컨설팅"
                rating="0.0"
                review="0"
                onClick={() => navigate("/free_incorporation")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/business_plan/business_plan_list_thumbnail_230406.jpg"
                title="사업계획서 컨설팅"
                info="성장 전략 컨설팅"
                salePrice="330,000 원"
                price="500,000 원"
                discount="34%"
                rating="5.0"
                review="1"
                onClick={() => navigate("/business_plan")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/pr_list/media_list_thumbnail(230321).jpg"
                title="초특가 언론 홍보 패키지"
                info="홍보 및 미디어"
                rating="0.0"
                review="0"
                onClick={() => navigate("/pr_media_pack")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/pr_list/pr_video_pack_list_thumbnail_230504.jpg"
                title="기업 맞춤 올인원 영상 팩"
                info="홍보 및 미디어"
                rating="0.0"
                review="0"
                onClick={() => navigate("/pr_video_pack")}
              />
            </div>
          </div>
          <div className="my-lg-3 my-3" ref={reviewRef}>
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              리뷰
            </h5>
            {reviews?.map((review, index) => (
              <Review
                key={index}
                rating={review.rating}
                content={review.content}
                email={review.email}
                createAt={review.create_at}
              />
            ))}
          </div>
          <div className="my-lg-3 my-3" ref={detailInfoRef}>
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              상세정보
            </h5>
            <div className="px-lg-2 px-2 py-lg-3 py-3">
              <div className="">
                <p className="mb-lg-2">
                  • 본 서비스는 인사노무 컨설팅으로 계약 완료 후 업무가 착수되면
                  대면 컨설팅과 관련 서식 및 템플릿 자료를 제공해드립니다.
                </p>
                <div className="mb-lg-2">
                  <p className="fw-bold">[컨설팅 분야]</p>
                  <p className="ms-lg-1">
                    인사노무 (급여 / 연차 관리 / 인사 관리)
                  </p>
                </div>
                <div className="mb-lg-2">
                  <p className="fw-bold">[컨설팅 과정]</p>
                  <p className="ms-lg-1">
                    • 결제를 완료하시면 결제완료 메일과 함께 영수증과 계약서가
                    발송됩니다. <br />
                    • 결제후 1영업일 이내에 부스타 담당자가 연락을 드려 문제
                    내용을 확인하고, 컨설팅 일정 조율을 해드립니다.
                    <br />• 상담이후 정해진 시간에 전문가와 상담을 받으실수
                    있습니다.
                  </p>
                </div>
                <div>
                  <p className="fw-bold">[컨설팅 기간]</p>
                  <p>• 2주</p>
                  <p className="mt-lg-2">
                    <span style={{ color: "#ff3d0d" }}>✓</span> 기업/전문가의
                    스케줄과 기업 유형에 따라 기간은 변경될 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-lg-3 my-3" ref={refundInfoRef}>
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              취소/환불
            </h5>
            <div className="px-lg-2 px-2 py-lg-3 py-3">
              <div>
                <p className="fw-bold mb-0">[기본 환불 규정]</p>
                <p className="px-lg-1 px-3">
                  • 구매일로부터 7일 이내에 컨설팅 또는 서비스가 제공되지
                  않았다면 전문가와 의뢰인의 상호 협의 하에 청약 철회 및 환불이
                  가능합니다. 단 컨설팅 또는 서비스가 개시되었다면, 이용한
                  일수를 제외하고 일할 계산으로 환불이 진행됩니다.
                  <br />• 컨설팅이 완료된 이후 또는 자료, 프로그램 등 서비스가
                  제공된 이후에는 환불이 불가합니다. (소비자보호법 17조 2항의
                  5조. 용역 또는 「문화산업진흥 기본법」 제2조제5호의
                  디지털콘텐츠의 제공이 개시된 경우에 해당)
                </p>
              </div>
              <div>
                <p className="fw-bold mb-0">[전문가 책임 사유]</p>
                <p className="px-lg-1 px-3">
                  • 전문가의 귀책사유로 당초 약정했던 서비스 미이행 혹은
                  보편적인 관점에서 심각하게 잘못 이행한 경우 결제 금액 전체
                  환불이 가능합니다.
                </p>
              </div>
              <div>
                <p className="fw-bold mb-0">[의뢰인 책임 사유]</p>
                <p className="px-lg-1 px-3">
                  • 서비스 진행 도중 의뢰인의 귀책사유로 인해 환불을 요청할
                  경우, 사용 금액을 아래와 같이 계산 후 총 금액의 10%를 공제하여
                  환불합니다.
                  <br /> • 총 컨설팅 시간의 1/3 경과 전 : 이미 납부한 요금의
                  2/3해당액
                  <br /> • 총 컨설팅 시간의 1/2 경과 전 : 이미 납부한 요금의
                  1/2해당액
                  <br /> • 총 컨설팅 시간의 1/2 경과 후 : 반환하지 않음 <br />
                  <p className="mt-lg-2">
                    <span style={{ color: "#ff3d0d" }}>✓</span> 상담 진행 중
                    의뢰인의 폭언, 욕설 등이 있을 경우 상담은 종료되며 잔여
                    이용요금은 환불되지 않습니다.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
