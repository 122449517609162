import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../../components/ServiceBox";

import bsBanner from "../../assets/img/image/business_strategies_list_top_banner.jpg";

export default function BusinessStrategiesList() {
  const navigate = useNavigate();

  const developService =
    "https://cdn-s3.boostar.co.kr/boostar/business_strategies/develop_service_list_thumbnail.jpg";

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      {/* <div className="container">
        <img src={bsBanner} className="img-fluid w-lg-100 mb-lg-4 px-lg-10" />
      </div> */}
      <div className="container py-lg-6 py-4 px-lg-11">
        <div
          className="mb-lg-4 mb-3"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <h3 className="fw-bolder ps-2">
            <span style={{ color: "#fbb60d" }}>사업 성장 및 지원</span> <br />
            서비스
          </h3>
        </div>

        <div className="row">
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/business_strategies/develop_list_thumbnail.jpg"
            title="서비스 개발 성공 비법 컨설팅"
            info="성장 전략 컨설팅"
            salePrice="500,000 원"
            price="5,000,000 원"
            discount="90%"
            rating="0.0"
            review="0"
            onClick={() => navigate("/develop_service")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/business_plan/business_plan_list_thumbnail_230406.jpg"
            title="사업계획서 컨설팅"
            info="성장 전략 컨설팅"
            salePrice="330,000 원"
            price="500,000 원"
            discount="34%"
            rating="5.0"
            review="1"
            onClick={() => navigate("/business_plan")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/global_business/global_pr_list_thumbnail_230609.jpg"
            title="1분만에 글로벌 진출하기"
            info="해외 진출 지원"
            rating="0.0"
            review="0"
            onClick={() => navigate("/global_pr_pack")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/global_business/usa_incorporation_list_thmbnail_230609.png"
            title="ONE-STOP 미국 법인 설립"
            info="해외 진출 지원"
            rating="0.0"
            review="0"
            onClick={() => navigate("/usa_incorporation")}
          />
        </div>
      </div>
    </main>
  );
}
