import { selector, selectorFamily } from "recoil";

import reviewApi from "../api/review";

export const classReviewState = selector({
  key: "classReviewState",
  get: async () => {
    const response = await reviewApi.getClassReview();

    return response.data;
  },
});

export const selectedClassReviewState = selectorFamily({
  key: "selectedClassReviewState",
  get:
    (classDate) =>
    ({ get }) => {
      const classReviewList = get(classReviewState);
      const selectedClassReviews = classReviewList.filter(
        (review) => review.class_date === classDate
      );

      return selectedClassReviews;
    },
});

export const serviceReviewState = selector({
  key: "serviceReviewState",
  get: async () => {
    const response = await reviewApi.getServiceReview();

    return response.data;
  },
});

export const selectedServiceState = selectorFamily({
  key: "selectedServiceState",
  get:
    (productCode) =>
    ({ get }) => {
      const serviceReviewList = get(serviceReviewState);
      const selectedServiceReview = serviceReviewList.filter(
        (review) => review.product_code === productCode
      );

      return selectedServiceReview;
    },
});
