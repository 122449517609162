import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../components/ServiceBox";

export default function IncorporationList() {
  const navigate = useNavigate();

  const incorporationBanner =
    "https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_top_banner.jpg";
  const incorporationThumbnail =
    "https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_list_thumbnail.jpg";

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      <div className="container">
        <img
          src={incorporationBanner}
          className="img-fluid w-lg-100 mb-lg-4 px-lg-10"
        />
      </div>
      <div className="container py-lg-6 py-4 px-lg-11">
        <div className="row">
          <div className="col-lg-3">
            <h3 className="fw-bolder py-lg-0 ps-2 mb-3">
              <span style={{ color: "#fbb60d" }}>무료법인</span> <br />
              설립센터
            </h3>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <ServiceBox
                image={incorporationThumbnail}
                title="무료법인설립"
                info="무료법인설립센터"
                onClick={() => navigate("/free_incorporation")}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
