import React from "react";
import { Route, Routes } from "react-router-dom";

import Agreement from "./features/Agreement";
import Privacy from "./features/Privacy";
import ChannelService from "./configs/ChannelService";

import Login from "./features/Login";
import Signup from "./features/Signup";
import Blog from "./features/Blog";
import Contact from "./features/Contact";
import ContactPartner from "./features/ContactPartner";
import IrPack from "./features/Service_IrPack";
import Lawyer from "./features/Expert_Lawyer";
import LaborAttorney from "./features/Expert_LaborAttorney";
import TaxAccountant from "./features/Expert_TaxAccountant";
import Developer from "./features/Expert_Developer";
import Designer from "./features/Expert_Designer";
import Marketer from "./features/Expert_Marketer";
import JudicialScrivener from "./features/Expert_JudicialScrivener";
import Accelerator from "./features/Expert_Accelerator";
import MarketPlace from "./features/About_MarketPlace";
import Partners from "./features/Partners";
import Press from "./features/About_Press";
import Government from "./features/For_Government";
import Nongovernment from "./features/For_Nongovernment";
import Startup from "./features/For_Startup";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Fail from "./components/Fail";
import ComingSoon from "./components/ComingSoon";
import EbookList from "./features/EbookList";
import Ebook from "./features/Ebook";
import Main from "./features/Main";
import PartnerDetail from "./features/PartnerDetail";
import GroundupDetail from "./features/GroundupDetail";
import PaperlogyDetail from "./features/Paperlogy";
import MyPage from "./features/MyPage";
import Withdrawal from "./features/Withdrawal";
import OrderSuccess from "./features/OrderSuccess";
import OrderDetail from "./features/OrderDetail";
import OrderList from "./features/OrderList";
import OrderCancel from "./features/OrderCancel";
import About from "./features/About";
import FreeIncorporation from "./features/FreeIncorporation";
import AddNaverAdditionalInfo from "./features/AddNaverAdditionalInfo";
import AddGoogleAdditionalInfo from "./features/AddGoogleAddtionalInfo.js";
import Incorporation from "./features/Service_Incorporation";
import ContactService from "./features/ContactService";
import ClassList from "./features/ClassList";
import FundrasingList from "./features/Service/FundrasingList";
import EnrollInClass from "./features/EnrollInClass";
import ClassDetail from "./features/ClassDetail";
import ReviewList from "./features/ReviewList";
import InfraList from "./features/Service/InfraList";
import HrPack from "./features/Service_Hr";
import PrivatePay from "./features/PrivatePay";
import PrivatePayTest from "./features/PrivateTest";
import BusinessPlan from "./features/Service_BusinessPlan";
import MyClassList from "./features/MyClassList";
import BusinessGrowthList from "./features/Service/BusinessGrowthList";
import LifeStyleList from "./features/LifeStyleList";
import Meditation from "./features/Service_meditation";
import UsaIncorporation from "./features/Service_UsaIncorporation";
import IncorporationList from "./features/IncorporationList";
import DevelopService from "./features/Service_DevelopService";
import ChangePassword from "./features/ChangePassword";
import FindPassword from "./features/FindPassword";
import DownloadFile from "./features/DownloadFile";
import MarketingList from "./features/Service/MarketingList";
import BigDataMarketing from "./features/Service_BigDataMarketing";
import PrList from "./features/PrList";
import PrVideoPack from "./features/Service_PrVideoPack";
import MediaPack from "./features/Service_media";
import GlobalPrPack from "./features/Service_GlobalMedia";
import BlogDetail from "./features/BlogDetail";
import EstablishmentCertificationList from "./features/EstablishmentCertificationList";
import EstablishmentRI from "./features/Service_EstablishmentRI";
import FinancingList from "./features/FinancingList";
import ServiceFiin from "./features/Service_Fiin";
import StartupWheel from "./features/StartupWheel";
import VentureCertification from "./features/Service_VentureCertification";
import IrPitching from "./features/Service_IrPitching";
import SupportProgram from "./features/SupportProgram";
import SupportProgramDetail from "./features/SupportProgramDetail";
import ClassPay from "./features/ClassPay";
import ContactIncorporation from "./features/ContactIncorporation";
import NavBar from "./components/NavBar";
import EventList from "./features/Event";
import Hcmc from "./features/Hcmc";
import PrFullPack from "./features/Service_PrFullPack";

function App() {
  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY,
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div style={{ flex: "1" }}>
        {/* <Nav /> */}
        <NavBar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/my_page" element={<MyPage />} />
          <Route path="/my_page/change_password" element={<ChangePassword />} />
          <Route path="/find_password" element={<FindPassword />} />
          <Route path="/withdrawal/member" element={<Withdrawal />} />
          <Route path="/about/boostar" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/contact/incorporation"
            element={<ContactIncorporation />}
          />
          <Route path="/contact/partner" element={<ContactPartner />} />
          <Route path="/contact/service" element={<ContactService />} />
          <Route
            path="/service/list/fund_rasing"
            element={<FundrasingList />}
          />
          <Route path="/service/list/infra" element={<InfraList />} />
          <Route path="/service/list/life_style" element={<LifeStyleList />} />
          <Route
            path="/service/list/business_growth"
            element={<BusinessGrowthList />}
          />
          <Route
            path="/service/list/free_incorporation"
            element={<IncorporationList />}
          />
          <Route path="/service/list/marketing" element={<MarketingList />} />
          <Route path="/service/list/pr" element={<PrList />} />
          <Route
            path="/service/list/establishment_and_certification"
            element={<EstablishmentCertificationList />}
          />
          <Route path="/service/list/financing" element={<FinancingList />} />
          <Route path="/ir_pack" element={<IrPack />} />
          <Route path="/hr_pack" element={<HrPack />} />
          <Route path="/pr_video_pack" element={<PrVideoPack />} />
          <Route path="/pr_media_pack" element={<MediaPack />} />
          <Route path="/global_pr_pack" element={<GlobalPrPack />} />
          <Route path="/business_plan" element={<BusinessPlan />} />
          <Route path="/usa_incorporation" element={<UsaIncorporation />} />
          <Route path="/develop_service" element={<DevelopService />} />
          <Route
            path="/pay/:price/:pageCode/:productCode"
            element={<PrivatePay />}
          />
          {/* <Route path="/private/test" element={<PrivatePayTest />} /> */}
          <Route path="/free_incorporation" element={<Incorporation />} />
          <Route path="/incorporation" element={<FreeIncorporation />} />
          <Route path="/marketing/big_data" element={<BigDataMarketing />} />
          <Route path="/meditation" element={<Meditation />} />
          <Route path="/pr_full_package" element={<PrFullPack />} />
          <Route path="/enroll_in/class/:id" element={<EnrollInClass />} />
          <Route
            path="/establishment_research_institute"
            element={<EstablishmentRI />}
          />
          <Route path="/fiin" element={<ServiceFiin />} />
          <Route
            path="/venture_certification"
            element={<VentureCertification />}
          />
          <Route path="/ir_pitching" element={<IrPitching />} />
          <Route path="/enroll_in/class/:date" element={<EnrollInClass />} />
          <Route path="/class" element={<ClassList />}></Route>
          <Route path="/my_class/list" element={<MyClassList />} />
          <Route path="/class/:id" element={<ClassDetail />} />
          <Route path="/event" element={<EventList />} />
          <Route path="/expert/developer" element={<Developer />} />
          <Route path="/expert/lawyer" element={<Lawyer />} />
          <Route path="/expert/labor_attorney" element={<LaborAttorney />} />
          <Route path="/expert/tax_accountant" element={<TaxAccountant />} />
          <Route path="/expert/designer" element={<Designer />} />
          <Route path="/expert/marketer" element={<Marketer />} />
          <Route path="/expert/accelerator" element={<Accelerator />} />
          <Route
            path="/expert/judicial_scrivener"
            element={<JudicialScrivener />}
          />
          <Route path="/about/market_place" element={<MarketPlace />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/partners/:officeName" element={<PartnerDetail />} />
          <Route path="/partners/paperlogy" element={<PaperlogyDetail />} />
          <Route path="/groundup_ventures" element={<GroundupDetail />} />
          <Route path="/about/press" element={<Press />} />
          <Route path="/about/recruitment" element={<ComingSoon />} />
          <Route path="/for/startup" element={<Startup />} />
          <Route path="/for/investor" element={<ComingSoon />} />
          <Route path="/for/government" element={<Government />} />
          <Route path="/for/nongovernment" element={<Nongovernment />} />
          <Route path="/case_studies" element={<ComingSoon />} />
          <Route path="/ebook" element={<EbookList />} />
          <Route path="/ebook/:id" element={<Ebook />} />
          <Route
            path="/order/success/:orderNumber"
            element={<OrderSuccess />}
          />
          <Route path="/order/detail/:orderNumber" element={<OrderDetail />} />
          <Route path="/order/list" element={<OrderList />} />
          <Route path="/order/cancel/:orderNumber" element={<OrderCancel />} />
          <Route path="/pay/class/:idx" element={<ClassPay />} />
          <Route path="/review/list" element={<ReviewList />} />
          <Route path="/template" element={<ComingSoon />} />
          <Route path="/youtube" element={<ComingSoon />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/add/naver/info" element={<AddNaverAdditionalInfo />} />
          <Route
            path="/add/google/info"
            element={<AddGoogleAdditionalInfo />}
          />
          <Route path="/download/class_file" element={<DownloadFile />} />
          <Route path="/blog" element={<Blog />}>
            <Route path="/blog/:type" element={<Blog />}>
              <Route path="/blog/:type/:topic" element={<Blog />} />
            </Route>
          </Route>
          <Route path="/startup_wheel_2023" element={<StartupWheel />}>
            <Route
              path="/startup_wheel_2023/:menu"
              element={<StartupWheel />}
            />
          </Route>
          <Route path="/hcmc_2023" element={<Hcmc />}>
            <Route path="/hcmc_2023/:menu" element={<Hcmc />} />
          </Route>
          <Route path="/support_program" element={<SupportProgram />} />
          <Route
            path="/support_program/:idx"
            element={<SupportProgramDetail />}
          />
          <Route path="blog/content/:idx" element={<BlogDetail />} />
          <Route path="/coming_soon" element={<ComingSoon />} />
          <Route
            path="/error"
            element={<Fail message="에러가 발생했습니다" />}
          />
          <Route
            path="*"
            element={<Fail message="Not Found" errorCode="404" />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
