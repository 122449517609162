import { AccordionCollapse } from "react-bootstrap";
import axios from "../configs/axiosInstance";

const resourceApi = {};

resourceApi.getContents = async () => {
  const response = await axios.get("/resource/getContents");

  return response.data;
};

resourceApi.getEbookList = async () => {
  const response = await axios.get("/resource/getEbookList");

  return response.data;
};

resourceApi.postDownloadEbook = async ({ name, email }) => {
  const response = await axios.post("/resource/postDownloadEbook", {
    name,
    email,
  });

  return response.data;
};

resourceApi.getBlogContents = async () => {
  const response = await axios.get("/resource/getNewContents");

  return response.data;
};

resourceApi.getBlogContentDetail = async (idx) => {
  const response = await axios.get(`/resource/getNewContentDetail/${idx}`);

  return response.data;
};

resourceApi.getBannerList = async () => {
  const response = await axios.get("/resource/getMainSlideBannerList");

  return response.data;
};

resourceApi.getBestSupportProgramList = async () => {
  const response = await axios.get("/resource/getBizInfoList?is_best=Y");

  return response.data;
};

resourceApi.getProgramDetail = async (idx) => {
  const response = await axios.get(`/resource/getBizInfoDetail/${idx}`);

  return response.data;
};

resourceApi.getNewProgramList = async ({ pageNumber, order, search }) => {
  const response = await axios.get(
    `/resource/getBizInfoList?page=${pageNumber}&end_date_sort=${order}&title=${search}`
  );

  return response.data;
};

resourceApi.getTypedProgramList = async ({
  pageNumber,
  type,
  order,
  search,
}) => {
  const response = await axios.get(
    `/resource/getBizInfoList?page=${pageNumber}&bizinfo_type=${type}&end_date_sort=${order}&title=${search}`
  );

  return response.data;
};

resourceApi.getSelectedRegionProgramList = async ({
  pageNumber,
  region,
  order,
  search,
}) => {
  const response = await axios.get(
    `/resource/getBizInfoList?page=${pageNumber}&city_gov=${region}&end_date_sort=${order}&title=${search}`
  );

  return response.data;
};

resourceApi.getProgramFiles = async (fileIdx) => {
  const response = await axios.get(
    `/resource/bizinfo/fileDownload/${fileIdx}`,
    {
      responseType: "blob",
    }
  );
};

export default resourceApi;
