import React from "react";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import styled from "styled-components";

import { authentication } from "../configs/firebase";
import userApi from "../api/user";

import googleLogo from "../assets/img/brands/google_logo.png";
import googleIcon from "../assets/img/brands/google_logo_icon.png";

const GoogleLoginButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.3rem;
  margin: 0.5rem 0;
  background-color: #ffffff;
  border: 0;
  border-radius: 12px;
`;

const GoogleIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background: url(${googleLogo}) no-repeat center;
  background-size: 25px;
`;

const GoogleLoginTitle = styled.span`
  width: 100%;
  color: ${({ theme }) => theme.White};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

const GoogleIconButton = styled.div`
  display: flex;
  justify-content: center;

  .button {
    cursor: pointer;
  }
`;

export default function SnsLogin({ url }) {
  const navigate = useNavigate();

  const handleGoogleButtonClick = async (event) => {
    const provider = new GoogleAuthProvider();

    try {
      const googleResponse = await signInWithPopup(authentication, provider);
      const googleAccessToken = googleResponse.user.accessToken;
      const response = await userApi.googleLogin(googleAccessToken);

      if (response.result === "success") {
        const accessToken = response.data.accessToken;

        localStorage.setItem("accessToken", accessToken);

        if (response.data.marketing === null) {
          navigate("/add/google/info");
        } else {
          const retUrl = localStorage.getItem("retUrl");

          if (retUrl !== null) {
            localStorage.removeItem("retUrl");
            navigate(retUrl);
          } else {
            navigate("/");
          }
        }
      }
    } catch (err) {
      if (err.response?.data.error.message === "User") {
        alert("이미 등록된 이메일입니다.");
      }
    }
  };
  return (
    <>
      {!url ? (
        <GoogleLoginButton className="shadow" onClick={handleGoogleButtonClick}>
          <GoogleIcon alt="google icon" />
          <GoogleLoginTitle>구글로 로그인하기</GoogleLoginTitle>
        </GoogleLoginButton>
      ) : (
        <GoogleIconButton>
          <img
            src={googleIcon}
            className="shadow img-fluid w-lg-50 w-50 button"
            onClick={handleGoogleButtonClick}
          />
        </GoogleIconButton>
      )}
    </>
  );
}
