import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Dropdown } from "react-bootstrap";
import { signOut } from "firebase/auth";

import Slider from "./Slider";
import { authentication } from "../configs/firebase";

import logo from "../assets/img/logo/boostar_logo.png";
import boostarLogo from "../assets/img/logo/boostar_logo_b.png";
import hotIcon from "../assets/img/graphics/icons/hot_icon.png";
import newIcon from "../assets/img/graphics/icons/new_icon.png";

export default function NavBar() {
  const [isShowFundRasing, setIsShowFundRasing] = useState(false);
  const [isShowBusinessGrowth, setIsShowBusinessGrowth] = useState(false);
  const [isShowInfra, setIsShowInfra] = useState(false);
  const [isShowMarketing, setIsShowMarketing] = useState(false);

  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");

  const text = [
    "BOOSTAR: 스타트업이 필요한 모든 정보와 서비스",
    "지금 법인설립하면? OKCEO 6개월 무료!",
    "추가로 기간한정 정부지원사업 컨설팅까지 무료 제공!",
    "지금 가입하고 정부지원사업 메일을 구독해보세요!",
    "혼자 사업 운영하기 버거우시죠?",
    "각 분야의 전문가들이 대표님을 기다립니다.",
    "투자유치의 꿈, BOOSTAR와 함께 이뤄보세요!",
    "지원사업에 대한 정보도 이제 부스타에서 확인하세요",
    "정부 지원사업 합격, 부스타가 응원합니다!",
    "스타트업의 모든 여정, BOOSTAR와 함께!",
  ];

  const { Kakao } = window;

  const handleLogoutButtonClick = async () => {
    localStorage.removeItem("accessToken");

    signOut(authentication);

    localStorage.removeItem("com.naver.nid.oauth.state_token");
    localStorage.removeItem("com.naver.nid.access_token");

    // await Kakao.Auth.logout();

    navigate("/");
  };

  const handleFundrasingClick = () => {
    if (window.innerWidth > 912) {
      window.location.replace("/service/list/fund_rasing");
    }
  };

  const handleBusinessGrowthClick = () => {
    if (window.innerWidth > 912) {
      window.location.replace("/service/list/business_growth");
    }
  };

  const handleInfraClick = () => {
    if (window.innerWidth > 912) {
      window.location.replace("/service/list/infra");
    }
  };

  const handlMarketingClick = () => {
    if (window.innerWidth > 912) {
      window.location.replace("/service/list/marketing");
    }
  };

  return (
    <>
      <div className="py-lg-2 py-1" style={{ background: "#fec230" }}>
        <div className="container mb-0">
          <Slider items={text} />
        </div>
      </div>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        fixed="top"
        sticky="top"
        className="px-lg-10 small"
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} className="img-fluid w-lg-100" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <i className="bx bx-menu" />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown
                title="서비스"
                id="collasible-nav-dropdown"
                name="service"
              >
                {window.innerWidth > 911 ? (
                  <NavDropdown
                    className="d-flex justify-content-start align-items-center ms-0 my-0"
                    style={{ height: "2.75rem" }}
                    title="자금 조달 및 지원"
                    drop="end"
                    onClick={() => handleFundrasingClick()}
                    show={isShowFundRasing}
                    onMouseEnter={() => setIsShowFundRasing(!isShowFundRasing)}
                    onMouseLeave={() => setIsShowFundRasing(false)}
                  >
                    <div>
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          투자 유치 컨설팅
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/ir_pack">
                        <p className="small">
                          3,000억 투자유치의 비밀: 에센셜팩
                        </p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/ir_pitching">
                        <p className="small">투자 유치 받는 IR 피칭</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          자금 조달 설계 컨설팅
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/fiin">
                        <p className="small">
                          Fiin 정부보증대출 사전심사서비스
                        </p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          정부지원사업 정보 및 지원 컨설팅
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/free_incorporation">
                        <p className="small">무료법인설립</p>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                ) : (
                  <NavDropdown title="자금 조달 및 지원">
                    <div>
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          투자 유치 컨설팅
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/ir_pack">
                        <p className="small">
                          3,000억 투자유치의 비밀: 에센셜팩
                        </p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/ir_pitching">
                        <p className="small">투자 유치 받는 IR 피칭</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          자금 조달 설계 컨설팅
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/fiin">
                        <p className="small">
                          Fiin 정부보증대출 사전심사서비스
                        </p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          정부지원사업 정보 및 지원 컨설팅
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/free_incorporation">
                        <p className="small">무료법인설립</p>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                )}
                {window.innerWidth > 911 ? (
                  <NavDropdown
                    className="d-flex justify-content-start align-items-center ms-0"
                    style={{ height: "2.75rem" }}
                    title="사업 성장 및 지원"
                    drop="end"
                    onClick={() => handleBusinessGrowthClick()}
                    show={isShowBusinessGrowth}
                    onMouseEnter={() =>
                      setIsShowBusinessGrowth(!isShowBusinessGrowth)
                    }
                    onMouseLeave={() => setIsShowBusinessGrowth(false)}
                  >
                    <div>
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          성장 전략 컨설팅
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/develop_service">
                        <p className="small">서비스 개발 성공 비법 컨설팅</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/business_plan">
                        <p className="small">사업계획서 컨설팅</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          해외 진출 지원
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/global_pr_pack">
                        <p className="small">1분만에 글로벌 진출하기</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/usa_incorporation">
                        <p className="small">ONE-STOP 미국법인설립</p>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                ) : (
                  <NavDropdown title="사업 성장 및 지원">
                    <div>
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          성장 전략 컨설팅
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/develop_service">
                        <p className="small">서비스 개발 성공 비법 컨설팅</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/business_plan">
                        <p className="small">사업계획서 컨설팅</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          해외 진출 지원
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/global_pr_pack">
                        <p className="small">1분만에 글로벌 진출하기</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/usa_incorporation">
                        <p className="small">ONE-STOP 미국법인설립</p>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                )}
                {window.innerWidth > 911 ? (
                  <NavDropdown
                    className="d-flex justify-content-start align-items-center ms-0"
                    style={{ height: "2.75rem" }}
                    title="인프라 및 리소스"
                    drop="end"
                    onClick={() => handleInfraClick()}
                    show={isShowInfra}
                    onMouseEnter={() => setIsShowInfra(!isShowInfra)}
                    onMouseLeave={() => setIsShowInfra(false)}
                  >
                    <div>
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          회사 설립 지원 서비스
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/free_incorporation">
                        <p className="small">무료법인설립</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/usa_incorporation">
                        <p className="small">ONE-STOP 미국법인설립</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          HR 지원 서비스
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/hr_pack">
                        <p className="small">인사 노무 폭탄 제거</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/meditation">
                        <p className="small">찾아가는 명상워크숍</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          기술, 도구 및 인프라 서비스
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/venture_certification">
                        <p className="small">쉽고 빠른 벤처기업인증</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/establishment_research_institute">
                        <p className="small">아무도 몰랐던 절세 혜택</p>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                ) : (
                  <NavDropdown title="인프라 및 리소스">
                    <div>
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          회사 설립 지원 서비스
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/free_incorporation">
                        <p className="small">무료법인설립</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/usa_incorporation">
                        <p className="small">ONE-STOP 미국법인설립</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          HR 지원 서비스
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/hr_pack">
                        <p className="small">인사 노무 폭탄 제거</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/meditation">
                        <p className="small">찾아가는 명상워크숍</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          기술, 도구 및 인프라 서비스
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/venture_certification">
                        <p className="small">쉽고 빠른 벤처기업인증</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/establishment_research_institute">
                        <p className="small">아무도 몰랐던 절세 혜택</p>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                )}
                {window.innerWidth > 911 ? (
                  <NavDropdown
                    className="d-flex justify-content-start align-items-center ms-0"
                    style={{ height: "2.75rem" }}
                    title="홍보 및 마케팅 지원"
                    drop="end"
                    onClick={() => handlMarketingClick()}
                    show={isShowMarketing}
                    onMouseEnter={() => setIsShowMarketing(!isShowMarketing)}
                    onMouseLeave={() => setIsShowMarketing(false)}
                  >
                    <div>
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          마케팅 및 광고
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/marketing/big_data">
                        <p className="small">빅데이터 마케팅 솔루션</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="small fw-bold"
                          style={{ color: "#000000" }}
                        >
                          홍보 및 미디어
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/pr_media_pack">
                        <p className="small">초특가 언론 홍보 패키지</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/pr_video_pack">
                        <p className="small">기업 맞춤 올인원 영상팩</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/pr_full_package">
                        <p className="small">제품-서비스 홍보 영상 풀패키지</p>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                ) : (
                  <NavDropdown title="홍보 및 마케팅 지원">
                    <div>
                      <Dropdown.Header>
                        <p
                          className="samll fw-bold"
                          style={{ color: "#000000" }}
                        >
                          마케팅 및 광고
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/marketing/big_data">
                        <p className="small">빅데이터 마케팅 솔루션</p>
                      </NavDropdown.Item>
                    </div>
                    <div className="mt-2">
                      <Dropdown.Header>
                        <p
                          className="small fw-bold"
                          style={{ color: "#000000" }}
                        >
                          홍보 및 미디어
                        </p>
                      </Dropdown.Header>
                      <NavDropdown.Item href="/pr_media_pack">
                        <p className="small">초특가 언론 홍보 패키지</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/pr_video_pack">
                        <p className="small">기업 맞춤 올인원 영상팩</p>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/pr_full_package">
                        <p className="small">제품-서비스 홍보 영상 풀패키지</p>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                )}
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    <img
                      src={boostarLogo}
                      alt="boostar logo"
                      style={{ width: "1rem" }}
                    />
                    카데미
                  </>
                }
                id="nav-dropdown"
              >
                <NavDropdown.Item href="/ebook" style={{ height: "2.75rem" }}>
                  가이드북
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/download/class_file"
                  style={{ height: "2.75rem" }}
                >
                  강의자료
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="/blog"
                className="d-flex align-items-center mx-lg-2"
              >
                스타트업 네비게이터
              </Nav.Link>
              <Nav.Link
                href="/class"
                className="d-flex align-items-center mx-lg-2"
              >
                BOOSTAR 특강
              </Nav.Link>
              <Nav.Link
                href="/event"
                className="d-flex align-items-center mx-lg-2"
              >
                이벤트
                <img src={newIcon} className="w-lg-30 mb-lg-5 mb-5" />
              </Nav.Link>
              <Nav.Link
                href="/free_incorporation"
                className="d-flex align-items-center mx-lg-2"
              >
                무료법인설립센터
                <img src={hotIcon} className="w-lg-20 mb-lg-5 mb-5" />
              </Nav.Link>
              <Nav.Link
                href="/support_program"
                className="d-flex align-items-center mx-lg-2"
              >
                정부지원사업
              </Nav.Link>
            </Nav>
            <Nav>
              {accessToken ? (
                <>
                  <Nav.Link onClick={handleLogoutButtonClick}>
                    로그아웃
                  </Nav.Link>
                  <Nav.Link href="/my_page">MY PAGE</Nav.Link>
                </>
              ) : (
                <Nav.Link href="/login">로그인</Nav.Link>
              )}
            </Nav>
            <Nav.Link
              href="/contact"
              className="btn btn-sm position-relative fw-bold py-2 px-2 ms-lg-3"
              style={{
                background: "#000000",
                borderRadius: "5px",
                color: "#fec230",
              }}
            >
              연락하기
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
