import React from "react";

import Card from "../components/Card";

export default function Press() {
  return (
    <main>
      <section className="positive-relative d-flex align-items-center text-white">
        <div
          className="w-100"
          style={{ height: "26.5rem", background: "#42445C" }}
        >
          <div className="container position-relative py-8 py-lg-10">
            <div className="row col-lg-8 align-items-center my-lg-3">
              <div className="mb-3 mb-lg-0">
                <h2 className="display-5 mb-5">PRESS</h2>
                <p className="fs-5 lh-sm mb-lg-4">
                  BOOSTAR의 최신 뉴스를 확인해보세요
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8 py-lg-10 px-lg-15">
          <div className="row col-lg-12">
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
          </div>
        </div>
      </section>
    </main>
  );
}
