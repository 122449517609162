import React from "react";
import { Link } from "react-router-dom";

export default function More({ content, subContent, buttonTitle, url }) {
  return (
    <section
      className="position-relative text-white"
      style={{ background: "#42445c" }}
    >
      <div className="container py-lg-6 py-5">
        <div className="">
          <div className="row text-center justify-content-center">
            <h4 className="fw-bold lh-base mt-lg-0 mb-4 mb-lg-3">{content}</h4>
            <p className="mb-lg-5 mb-4" style={{ borderBottom: 0 }}>
              {subContent}
            </p>
            <Link to={url}>
              <button
                className="btn text-dark width-13x"
                style={{ background: "#fec72f", borderRadius: "0.37" }}
              >
                {buttonTitle}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
