import React from "react";

import ExpertIntro from "../components/Service/Intro2";
import More from "../components/More";

import introImage from "../assets/img/image/labor_attorney_intro.png";
import laborAttorneyImage from "../assets/img/image/labor_attorney_image.png";

export default function LaborAttorney() {
  return (
    <main>
      <ExpertIntro
        image={introImage}
        title1="BOOSTAR에서"
        title2="최고의 스타트업 노무사와 함께하세요"
        subTitle="스타트업의 인사노무 자문을 통해 성장에 집중할 수 있도록 도와드립니다."
        url="/contact"
      />
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-5 fs-3">
                팀 빌딩을 위한 인사노무관리
              </h1>
              <h5 className="mb-3 mb-lg-3">
                스타트업의 성장에 있어서 팀 빌딩은 핵심. 팀 빌딩을 위해 뛰어난
                인력을 확보하고 관리하는 것은 필수!
              </h5>
              <h5 className="mb-3 mb-lg-3">
                초기 스타트업은 직원이 적거나 없어서 인사 담당 직원이 없을
                뿐더러 노무 관리에 무지한 경우가 많습니다. <br />
                그러나 직원을 한 명이라도 채용한다면 노동법 뿐만 아니라 신경
                써야할 인사관리 업무가 빠르게 생기기 시작합니다.
              </h5>
              <h5 className="mb-3 mb-lg-3">
                안정적인 인사노무 시스템을 구축해 팀 빌딩, 더 나아가 성장에 한
                발 다가갈 수 있습니다.
              </h5>
            </div>
            <div className="my-2 mt-lg-1 mb-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-5 fs-3">
                어떤 노무 자문이 필요하신가요?
              </h1>
              <h5 className="mb-3 mb-lg-3">
                근로계약서, 취업규칙과 같은 기초 노무 세팅부터 인사관리, 노동법
                관련 자문을 제공합니다. <br />
                스타트업이 반드시 확인해야 할 인사노무 이슈를 중점으로 서비스를
                제공, 스타트업에 최적화된 인사노무 관리를 도와드립니다.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={laborAttorneyImage} className="img-fluid w-lg-90" />
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <div
                className="px-lg-3 px-3 py-lg-7 py-2 w-lg-90"
                style={{ background: "#fec72f" }}
              >
                <h1 className="lh-base mb-lg-5 fs-4 mb-2">
                  스타트업 전문 <br />
                  노무사를 만나보세요
                </h1>
                <h6 className="lh-base mb-2 mb-lg-3">
                  같은 노무 자문이라도, 일반 기업과 스타트업을 대상으로 하는
                  노무 자문은 다를 수 있습니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  스타트업에 대한 이해를 기반으로 한 인사노무 전문가.
                  BOOSTAR에서 비즈니스를 한층 성장 시킬 노무사를 빠르게
                  연결해드립니다.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
