import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { signOut } from "firebase/auth";

import { authentication } from "../configs/firebase";
import userApi from "../api/user";
import { userInfoState } from "../store/userState";
import ModalContainer from "../components/Modal";
import Button from "../components/Button";

export default function Withdrawal() {
  const [modalShow, setModalShow] = useState(false);

  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate("/");
  };

  const handleWithdrawalButtonClick = async () => {
    try {
      const response = await userApi.deleteUserInfo(userInfo.id);

      if (response.result === "success") {
        localStorage.removeItem("accessToken");
        signOut(authentication);
        localStorage.removeItem("com.naver.nid.oauth.state_token");
        localStorage.removeItem("com.naver.nid.access_token");

        setModalShow(true);
      }
    } catch (err) {
      if (err) {
        alert("에러가 발생했습니다.");
      }
    }
  };

  return (
    <>
      <section className="position-relative overflow-hidden">
        <div className="container pt-14 pt-lg-15 pb-9 px-4">
          <div className="col-md-10 col-lg-12 mx-auto text-center position-relative">
            <div className="position-relative z-index-1">
              <div className="flex-center flex-column">
                <p className="mb-4 fs-5">✓ 안내드립니다</p>
                <div
                  className="text-start pt-lg-3 pt-3 px-lg-3 px-3"
                  style={{ border: "1px solid #d3d3d3", borderRadius: "10px" }}
                >
                  <p style={{ fontSize: "14px" }}>
                    ∙ 회원 탈퇴 시점을 기준으로 결제내역, 다운로드 내역이 모두
                    삭제되어 복구되지 않습니다.
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    ∙ 회원 탈퇴 후 회원 정보는 즉시 파기됩니다.
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    ∙ 단, 전자상거래 등에서의 소비자보호에 관한 법률,
                    통신비밀보호법 등 관련 법령의 규정에 의하여 개인정보가 일정
                    기간 보관됩니다.
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    ∙ E-MAIL 광고를 수신하셨다면 탈퇴 이후 약 3일 정도까지
                    광고가 전송될 수 있사오니, 이 점 양해 부탁드립니다.
                  </p>
                </div>
                <div className="my-5">
                  <button
                    className="btn btn-dark mx-3"
                    onClick={handleBackButtonClick}
                  >
                    돌아가기
                  </button>
                  <button
                    className="btn btn-dark mx-3"
                    onClick={handleWithdrawalButtonClick}
                  >
                    탈퇴하기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalContainer show={modalShow} onHide={() => setModalShow(false)}>
          <div className="my-lg-4 d-flex flex-column align-items-center">
            <div>BOOSTAR 탈퇴가 완료되었습니다.</div>
            <div className="mb-lg-3">감사합니다 🙂</div>
            <Button
              width="10"
              text="확인"
              border="none"
              background="#fef4e5"
              color="#fbb60d"
              onClick={() => navigate("/")}
            />
          </div>
        </ModalContainer>
      </section>
    </>
  );
}
