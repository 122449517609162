import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import groundup from "../../assets/img/logo/slide_groundup_logo.png";
import sustainable from "../../assets/img/logo/sustainable_logo.png";
import kova from "../../assets/img/logo/kova_logo.png";
import byon from "../../assets/img/logo/slide_byon_logo.png";
import paperlogy from "../../assets/img/logo/slide_paperlogy_logo.png";
import wise from "../../assets/img/logo/slide_wise_logo.png";
import dongsim from "../../assets/img/logo/slide_dongsim_logo.png";
import forest from "../../assets/img/logo/slide_forest_logo.png";
import theOn from "../../assets/img/logo/slide_theon_logo.png";
import hurdlersLogo from "../../assets/img/logo/hurdlers_logo.png";
import formationPro from "../../assets/img/logo/formation_pro_logo.png";
import gridge from "../../assets/img/logo/gridge_logo.png";

export default function Partner() {
  const slideSetting = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  return (
    <section className="container px-lg-1 pt-lg-4 py-2">
      <div className="px-lg-1 px-1 py-lg-2 py-2">
        <h6 className="mb-lg-3 text-center">CLIENTS & PARTNERS</h6>
        <div className="row">
          <div>
            <Slider {...slideSetting}>
              <div className="flex-center">
                <img
                  src={groundup}
                  alt="groundup logo"
                  className="img-fluid w-lg-40 w-80 pt-4"
                />
              </div>
              <div className="flex-center">
                <img
                  src={sustainable}
                  alt="sustainable logo"
                  className="img-fluid w-lg-50 w-80 pt-4"
                />
              </div>
              <div className="flex-center">
                <img
                  src={kova}
                  alt="kova logo"
                  className="img-fluid w-lg-60 w-80 pt-4"
                />
              </div>
              <div className="flex-center">
                <img
                  src={byon}
                  alt="byon logo"
                  className="img-fluid w-lg-50 w-80 pt-lg-1 pt-3"
                />
              </div>
              <div className="flex-center">
                <img
                  src={paperlogy}
                  alt="paperlogy logo"
                  className="img-fluid w-lg-40 w-60 pt-lg-3 pt-4"
                />
              </div>
              <div className="flex-center">
                <img
                  src={wise}
                  alt="wise logo"
                  className="img-fluid w-lg-40 w-70 pt-lg-2 pt-4"
                />
              </div>
              <div className="flex-center">
                <img
                  src={dongsim}
                  alt="dongsim logo"
                  className="img-fluid w-lg-40 w-60 pt-lg-2 pt-4"
                />
              </div>
              <div className="flex-center">
                <img
                  src={forest}
                  alt="forest logo"
                  className="img-fluid w-lg-30 w-70 pt-lg-2 pt-3"
                />
              </div>
              <div className="flex-center">
                <img
                  src={theOn}
                  alt="the on logo"
                  className="img-fluid w-lg-30 w-60 pt-lg-1 pt-3"
                />
              </div>
              <div className="flex-center">
                <img
                  src={hurdlersLogo}
                  alt="hurdlers logo"
                  className="img-fluid w-lg-40 w-80 pt-lg-4 pt-4"
                />
              </div>
              <div className="flex-center">
                <img
                  src={formationPro}
                  alt="hurdlers logo"
                  className="img-fluid w-lg-50 w-90 pt-lg-3 pt-4"
                />
              </div>
              <div className="flex-center">
                <img
                  src={gridge}
                  alt="hurdlers logo"
                  className="img-fluid w-lg-50 w-90 pt-lg-2 pt-3"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}
