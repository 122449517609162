import React from "react";
import styled from "styled-components";

const ClassBaxContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #fec000;
  }
`;

export default function ClassBox({ image, title, info, onClick }) {
  return (
    <ClassBaxContainer className="col-lg-4 col-6 mb-lg-3 mb-3">
      <div className="button" onClick={onClick}>
        <img src={image} className="img-fluid" />
        <p className="lh-sm mt-lg-3 mb-0 mt-3">{title}</p>
        <p className="small pt-lg-1" style={{ color: "#797979" }}>
          {info}
        </p>
      </div>
    </ClassBaxContainer>
  );
}
