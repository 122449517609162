import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import userApi from "../api/user";

import logoImage from "../assets/img/brands/naver_logo_icon.png";

const NaverIdLogin = styled.div`
  display: none;
`;

const NaverLoginButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.3rem;
  background-color: #03c75a;
  border: 0;
  border-radius: 12px;
`;

const NaverIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background: url(${logoImage}) no-repeat center;
  background-size: 30px;
`;

const NaverLoginTitle = styled.span`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

const NaverIconButton = styled.div`
  display: flex;
  justify-content: center;

  .button {
    cursor: pointer;
  }
`;

export default function NaverLogin({ url }) {
  const naverRef = useRef();

  const navigate = useNavigate();
  const { naver } = window;

  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
      callbackUrl:
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_NAVER_CALLBACK_URL
          : process.env.REACT_APP_NAVER_LOCAL_CALLBACK_URL,
      isPopup: false,
      loginButton: { color: "green", type: 3, height: 40 },
      callBackHandle: true,
    });

    naverLogin.init();
  };

  const userAccessToken = () => {
    window.location.href.includes("access_token") && getToken();
  };

  const getToken = async () => {
    const naverToken = window.location.href.split("=")[1].split("&")[0];

    try {
      const response = await userApi.naverLogin(naverToken);

      if (response.result === "success") {
        const accessToken = response.data.accessToken;

        localStorage.setItem("accessToken", accessToken);

        const retUrl = localStorage.getItem("retUrl");

        if (response.data.userInfo.marketing === null) {
          navigate("/add/naver/info");
        } else {
          if (retUrl !== null) {
            localStorage.removeItem("retUrl");
            navigate(retUrl);
          } else {
            navigate("/");
          }
        }
      }
    } catch (err) {
      if (err.response?.data.error.message === "User") {
        localStorage.removeItem("com.naver.nid.oauth.state_token");
        localStorage.removeItem("com.naver.nid.access_token");

        alert("이미 등록된 이메일입니다.");
        navigate("/login");
      }

      if (err.response?.data.error.message === "Authentication failed") {
        alert("로그인중 에러가 발생했습니다.");
      }
    }
  };

  const handleNaverLogin = () => {
    naverRef.current.children[0].click();
  };

  useEffect(() => {
    initializeNaverLogin();
    userAccessToken();
  }, []);

  return (
    <>
      <NaverIdLogin ref={naverRef} id="naverIdLogin" />
      {!url ? (
        <NaverLoginButton onClick={handleNaverLogin}>
          <NaverIcon alt="naver icon" />
          <NaverLoginTitle className="text-white">
            네이버로 로그인하기
          </NaverLoginTitle>
        </NaverLoginButton>
      ) : (
        <NaverIconButton>
          <img
            src={logoImage}
            className="img-fluid w-lg-50 w-50 button"
            onClick={handleNaverLogin}
          />
        </NaverIconButton>
      )}
    </>
  );
}
