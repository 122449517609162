import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import userApi from "../api/user";
import Button from "../components/Button";
import ModalContainer from "../components/Modal";
import TextInput from "../components/TextInput";
import { PASSWORD_REGEXP } from "../constants/RegExp";
import { userInfoState, userState } from "../store/userState";

export default function ChangePassword() {
  const [isModalShow, setIsModalShow] = useState(false);
  const [isChangePw, setIsChangePw] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [userData, setUserData] = useState({
    oldPassword: "",
    password: "",
    checkedPassword: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  const isValidPassword = PASSWORD_REGEXP.test(userData.password);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleUpdateButtonClick = async (event) => {
    event.preventDefault();

    if (userData.password !== userData.checkedPassword) {
      setErrorMessage("✓ 비밀번호가 일치하지 않습니다.");

      return;
    }

    if (
      !userData.oldPassword.replaceAll(" ", "").length ||
      !userData.password.replaceAll(" ", "").length ||
      !userData.checkedPassword.replaceAll(" ", "").length
    ) {
      setIsEmpty(true);

      return;
    }

    if (
      isValidPassword &&
      userData.oldPassword.replace(" ", "").length &&
      userData.password.replace(" ", "").length &&
      userData.password === userData.checkedPassword
    ) {
      try {
        const response = await userApi.changePassword({
          user_idx: userInfo.id,
          old_password: userData.oldPassword,
          password: userData.password,
        });

        if (response.result === "success") {
          setModalContent(["비밀번호가 변경되었습니다."]);
          setIsChangePw(true);
          setIsModalShow(true);
        }
      } catch (err) {
        if (err.response.data.error.message === "Wrong password") {
          setModalContent([
            "현재 비밀번호가 올바르지 않습니다.",
            "비밀번호를 다시 한 번 확인해주시길 바랍니다.",
          ]);
          setIsChangePw(false);
          setIsModalShow(true);

          return;
        }

        if (err) {
          setModalContent([
            "비밀번호를 변경하는 도중 오류가 발생했습니다.",
            "잠시 후 다시 이용부탁드립니다.",
          ]);
          setIsChangePw(false);
          setIsModalShow(true);

          return;
        }
      }
    }
  };

  return (
    <main className="position-relative">
      <div className="container py-lg-10 py-5 px-lg-11">
        <div className="d-flex flex-center">
          <form onSubmit={handleUpdateButtonClick} className="w-lg-40">
            {location.state?.resetPassword === false && (
              <div className="text-center mb-3">
                사용하신 비밀번호는 임시발급된 비밀번호입니다.
                <br />
                비밀번호를 변경해주세요 🙂
              </div>
            )}
            <div>
              <span>현재 비밀번호</span>
              <TextInput
                name="oldPassword"
                type="password"
                icon="bx-lock-open-alt"
                placeholder="현재 사용하고 있는 비밀번호를 입력해주세요."
                value={userData.oldPassword || ""}
                emptyValidation={isEmpty}
                emptyMessage="✓ 비밀번호를 확인해주세요."
                onChange={handleChange}
              />
            </div>
            <div>
              <span>새 비밀번호</span>
              <TextInput
                name="password"
                type="password"
                icon="bx-lock-open"
                placeholder="영문 + 숫자 + 특수문자, 8~16자리"
                value={userData.password || ""}
                pattern={PASSWORD_REGEXP}
                errorMessage="✓ 영문 + 숫자 + 특수문자, 8~16자리로 입력해주세요."
                emptyValidation={isEmpty}
                emptyMessage="✓ 새로운 비밀번호를 입력해주세요."
                onChange={handleChange}
              />
            </div>
            <div>
              <span>비밀번호 확인</span>
              <TextInput
                name="checkedPassword"
                type="password"
                icon="bx-lock-open"
                placeholder="비밀번호를 확인해주세요"
                emptyValidation={isEmpty}
                emptyMessage="✓ 새로운 비밀번호를 한 번 더 확인해주세요."
                value={userData.checkedPassword || ""}
                onChange={handleChange}
              />
            </div>
            <div className="mb-5">
              <small className="text-danger">{errorMessage}</small>
            </div>
            <div className="d-flex flex-center">
              <Button
                width="10"
                text="비밀번호 변경"
                background="#fef4e5"
                color="#fbb60d"
                border="none"
              />
            </div>
          </form>
        </div>
      </div>
      <ModalContainer show={isModalShow} onHide={() => setIsModalShow(false)}>
        <div className="mt-lg-3 flex-center flex-column">
          <div className="mb-2">
            {modalContent.map((content, index) => (
              <p key={index} className="mb-0 text-center">
                {content}
              </p>
            ))}
          </div>
          <Button
            width="10"
            text="확인"
            border="none"
            background="#fef4e5"
            color="#fbb60d"
            onClick={
              isChangePw ? () => navigate("/") : () => setIsModalShow(false)
            }
          />
        </div>
      </ModalContainer>
    </main>
  );
}
