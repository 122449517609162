import React from "react";

export default function MainCard({ thumbnail, title, type, url }) {
  return (
    <>
      <div className="col-md-3 col-6 mb-4 mb-md-4 my-4">
        <div
          className="card card-hover hover-lift hover-shadow-lg border-1 overflow-hidden rounded-3"
          style={{ border: "1px solid #c8c8c8" }}
        >
          <img
            className="card-img-top img-fluid img-zoom"
            src={thumbnail}
            alt="content thumbnail"
          />
          <div
            className="card-body d-flex flex-column start-0 top-0 w-100 h-100 justify-content-end pb-lg-3 px-lg-2"
            style={{ background: "#ffffff" }}
          >
            <p className="small ms-ls-2 ms-1">
              <span>{type}</span>
            </p>
            <div className="py-lg-1 ms-ls-2 ms-1" style={{ height: "4.6rem" }}>
              <p className="mb-0">{title}</p>
            </div>
            <div
              className="text-end mx-lg-1 mx-1 pe-lg-1 pt-lg-2 fw-bold"
              style={{ borderTop: "1px solid #c8c8c8" }}
            >
              Read
            </div>
            <a href={url} className="stretched-link" />
          </div>
        </div>
      </div>
    </>
  );
}
