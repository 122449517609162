import React from "react";

export default function ProgramIntro() {
  return (
    <section>
      <div className="container">
        <div className="mx-lg-5 mt-6">
          <div
            className="fw-bolder w-lg-20 text-center py-lg-1 py-1"
            style={{
              background: "#f4f4f5",
              color: "#2196F3",
              borderRadius: "5px",
            }}
          >
            PROGRAM
          </div>
          <div className="my-lg-5 my-5">
            <div className="my-lg-2 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 개요</span>
            </div>
            <div className="ps-lg-2">
              <div>
                <p className="my-lg-2 my-1">• 대상</p>
                <p className="ps-lg-2 ps-2">
                  베트남 진출을 준비하고 있는 글로벌 기반의 스타트업, 기관/기업
                  관계자 및 관련 업계 종사자
                </p>
              </div>
              <div>
                <p className="my-lg-2 my-1">• 일정</p>
                <p className="ps-lg-2 ps-2">
                  2023년 8월 21일(월) ~ 8월 25일 (금) / 4박 5일
                </p>
              </div>
              <div>
                <p className="my-lg-2 my-2">• 모집 인원</p>
                <p className="ps-lg-2 ps-2">
                  최대 25명 <span className="fw-bold">(선착순 모집)</span>
                </p>
              </div>
              <div>
                <p className="my-lg-2 my-2">• 모집 마감</p>
                <p className="ps-lg-2 ps-2">2023년 7월 25일(화) 15시 까지</p>
              </div>
              <div>
                <p className="my-lg-2 my-2">• 비용</p>
                <p className="fw-bold ps-lg-2 ps-2">1인당 231만원 (VAT 포함)</p>
                <p className="small ps-lg-2 ps-2">
                  <span className="fw-bold" style={{ color: "#ff3d0d" }}>
                    ✓
                  </span>{" "}
                  포함사항: 항공권, 숙박비(2인 1실), InnoEx 초대권, 교통비,
                  식사비, 여행자보험 등
                </p>
                <p className="small ps-lg-2 ps-2">
                  <span className="fw-bold" style={{ color: "#ff3d0d" }}>
                    ✓
                  </span>{" "}
                  불포함사항: 비자/여권 발급 비용 및 개인비용, 호텔 팁 등
                </p>
              </div>
              <div>
                <p className="my-lg-2 my-2">• 기대효과 및 혜택</p>
                <p className="ps-lg-2 ps-2">
                  ◦ 스타트업 지원 기관 등 관계자 - 현지 투자사/지원 기관과 업무
                  협의 기회 제공
                </p>
                <p className="ps-lg-2 ps-2">
                  ◦ 스타트업 관계자 - 현지 기업과 오픈이노베이션 및 진출 협력
                  기회 제공
                </p>
              </div>
              <div>
                <p className="my-lg-2 my-2">• 첨부파일</p>
                <a
                  href="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/files/(%E1%84%80%E1%85%A9%E1%86%BC%E1%84%80%E1%85%A9)+23%E1%84%82%E1%85%A7%E1%86%AB+%E1%84%87%E1%85%A6%E1%84%90%E1%85%B3%E1%84%82%E1%85%A1%E1%86%B7+%E1%84%92%E1%85%A9%E1%84%8E%E1%85%B5%E1%84%86%E1%85%B5%E1%86%AB+%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3+%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%80%E1%85%B3%E1%84%85%E1%85%A2%E1%86%B8+%E1%84%8E%E1%85%A1%E1%86%B7%E1%84%80%E1%85%A1%E1%84%8C%E1%85%A1+%E1%84%86%E1%85%A9%E1%84%8C%E1%85%B5%E1%86%B8.hwp"
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  📎 (공고) 2023 베트남 호치민 인사이트 프로그램 참가자 모집.hwp
                </a>
              </div>
              <div>
                <p className="my-lg-2 my-2">• 문의</p>
                <p className="ps-lg-2 ps-2">
                  강호천 010-5002-8451 / hckang@ground-up.kr
                  <br />
                  봉길원 010-2012-8325 / gwbong@ground-up.kr
                </p>
              </div>
            </div>
          </div>
          <div className="my-lg-5 my-5">
            <div className="my-lg-2 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 구성</span>
            </div>
            <div>
              <div
                className="py-lg-3 py-2 px-lg-3 px-2 mb-3"
                style={{ background: "#f4f4f5" }}
              >
                <p>투어 중심의 단순 탐방이 아닌</p>
                <p className="fw-bold">
                  현지 전문가를 통한 생생한 경험을 공유하고,
                </p>
                <p className="fw-bold">
                  현지 진출 시 직간접적 협력을 지원받을 수 있는 다양한 형태의
                  네트워크 구축할 수 있는 기회를 제공합니다.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-3 col-6 my-lg-5">
                  <div className="d-flex flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/image/circle_seminar.png"
                      className="img-fluid w-lg-80 w-100"
                    />
                  </div>
                  <div className="mt-lg-5 my-3 small">
                    <p>• 현지 진출을 위한 필수 교육 프로그램 구성</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6 my-lg-5">
                  <div className="d-flex flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/image/circle_startup_wheel.png"
                      className="img-fluid w-lg-80 w-100"
                    />
                  </div>
                  <div className="mt-lg-5 my-3 small">
                    <p className="mb-lg-1">
                      • 베트남 최대 스타트업 행사 및 컴피티션 2일간 참관
                    </p>
                    <p>• 베트남 현지 기업 및 글로벌 스타트업 참가</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6 my-lg-5">
                  <div className="d-flex flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/image/circle_corp.png"
                      className="img-fluid w-lg-80 w-100"
                    />
                  </div>
                  <div className="mt-lg-5 my-3 small">
                    <p className="mb-lg-1">
                      • 베트남 및 글로벌 투자자, 정부기관, 단체, 기업 교류 및
                      사업 협력 관계 계기 마련
                    </p>
                    <p>• 현지 진출 및 확장 방안 공유 </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6 my-lg-5">
                  <div className="d-flex flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/image/circle_networking.png"
                      className="img-fluid w-lg-80 w-100"
                    />
                  </div>
                  <div className="mt-lg-5 my-3 small">
                    <p>• 다양한 네트워킹을 통한 현지 진출 교두보 마련</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
