import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../components/ServiceBox";

export default function FinancingList() {
  const navigate = useNavigate();

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      <div className="container">
        <img
          src="https://cdn-s3.boostar.co.kr/boostar/financing/financing_top_banner.jpg"
          className="img-fluid w-lg-100 mb-lg-4 px-lg-10"
        />
      </div>
      <div className="container py-lg-6 py-4 px-lg-11">
        <div
          className="mb-lg-4 mb-3"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <h3 className="fw-bolder ps-2">
            <span style={{ color: "#fbb60d" }}>자금</span> <br />
            조달
          </h3>
        </div>
        <div className="row">
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/financing/ffin_list_thumbnail_230504.jpg"
            title="Fiin정부보증대출 사전심사서비스"
            info="자금조달"
            rating="0.0"
            review="0"
            onClick={() => navigate("/fiin")}
          />
        </div>
      </div>
    </main>
  );
}
