import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import DayTag from "./DayTag";

const StyledTable = styled.table`
  table-layout: fixed;

  tr {
    cursor: pointer;
    :hover {
      background-color: #fbfbfb;
    }
  }
`;

export default function SupportProgramList({ list }) {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="my-lg-3">
          <StyledTable
            className="w-lg-100 w-100 text-center"
            style={{ borderRadius: "5px" }}
          >
            <thead
              style={{
                borderBottom: "1px solid #c8c8c8",
                background: "#f4f4f5",
              }}
            >
              <th className="col-lg-1 py-lg-3 py-2">분야</th>
              <th className="col-lg-2 py-lg-3 py-2">소관부처</th>
              <th className="col-lg-6 py-lg-3 py-2">지원사업명</th>
              <th className="col-lg-2 py-lg-3 py-2">신청기간</th>
              <th className="col-lg-1 py-lg-3 py-2">마감상태</th>
            </thead>
            <tbody>
              {list?.map((data, index) => (
                <tr
                  key={index}
                  className="list"
                  onClick={() => navigate(`/support_program/${data.idx}`)}
                >
                  <td
                    className="py-lg-3"
                    style={{
                      borderBottom: "1px solid #c8c8c8",
                      height: "auto",
                      wordBreak: "break-all",
                    }}
                  >
                    {data.bizinfo_type}
                  </td>
                  <td
                    className="py-lg-3"
                    style={{
                      borderBottom: "1px solid #c8c8c8",
                      height: "auto",
                      wordBreak: "break-all",
                    }}
                  >
                    {data.city_gov}
                  </td>
                  <td
                    className="py-lg-3"
                    style={{
                      borderBottom: "1px solid #c8c8c8",
                      height: "auto",
                      wordBreak: "break-all",
                    }}
                  >
                    {data.title}
                  </td>
                  <td
                    className="py-lg-3"
                    style={{
                      borderBottom: "1px solid #c8c8c8",
                      height: "auto",
                      wordBreak: "break-all",
                    }}
                  >
                    {data.due_date}
                  </td>
                  <td
                    className="py-lg-3 px-lg-2 px-1"
                    style={{
                      borderBottom: "1px solid #c8c8c8",
                      height: "auto",
                      wordBreak: "break-all",
                    }}
                  >
                    {data.end_date && <DayTag date={data.end_date} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
      </div>
    </>
  );
}
