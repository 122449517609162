import React, { useCallback, useEffect, useState } from "react";

export default function TextInput({
  name,
  type,
  placeholder,
  onChange,
  pattern,
  value,
  icon,
  emptyValidation,
  emptyMessage,
  successMessage,
  errorMessage,
  ...props
}) {
  const isValidValue = pattern?.test(value);
  const [error, setError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const isError = useCallback(() => {
    if (!pattern && !emptyValidation) {
      return;
    }

    if (emptyValidation && !value.replaceAll(" ", "").length) {
      setError(true);
      setAlertMessage(emptyMessage);

      return;
    }

    if (value && !isValidValue) {
      setError(true);
      setAlertMessage(errorMessage);

      return;
    }

    if (isValidValue) {
      setError(false);
      setAlertMessage(successMessage);
    }
  }, [value, pattern, emptyValidation]);

  useEffect(() => {
    isError();
  }, [isError]);

  return (
    <>
      <div className="input-icon-group mb-1">
        {icon && (
          <span className="input-icon">
            <i className={`bx ${icon}`} />
          </span>
        )}
        <input
          {...props}
          name={name}
          type={type}
          value={value}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
      <div className="ms-2 mb-3">
        <small className={error ? `text-danger` : `text-success`}>
          {alertMessage}
        </small>
      </div>
    </>
  );
}
