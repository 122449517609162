import React from "react";

import Intro2 from "../components/Service/Intro2";
import More from "../components/More";

import introImage from "../assets/img/image/accelerator_intro.png";
import acceleratorImage from "../assets/img/image/accelerator_image.png";

export default function Accelerator() {
  return (
    <main>
      <Intro2
        image={introImage}
        title1="BOOSTAR에서"
        title2="최고의 액셀러레이터와 함께하세요"
        subTitle="비즈니스에 적합한 액셀러레이터를 만나 빠르게 성장할 수 있습니다."
        url="/contact"
      />
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                빠른 성장이 필요하시다면?
              </h1>
              <h5 className="mb-3 mb-lg-6">
                BOOSTAR 네트워크의 검증된 액셀러레이터는 교육과 멘토링
                프로그램을 제공하고 네트워킹 기회 및 자본 투자를 제공합니다.
              </h5>
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                투자 유치로 한 걸음 더
              </h1>
              <h5 className="mb-3 mb-lg-4">
                액셀러레이팅 프로그램은 단기간에 회사의 규모와 가치를 크게 성장
                시킬 수 있습니다.
                <br />
                스타트업 생태계를 처음 접하는 기업에게 벤처 캐피털의 투자를 받을
                수 있는 이상적인 관문으로 갈 수 있습니다.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={acceleratorImage} className="img-fluid w-lg-90" />
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <div
                className="px-lg-3 px-3 py-lg-10 py-2 w-lg-90"
                style={{ background: "#fec72f" }}
              >
                <h1 className="lh-base mb-lg-5 fs-4 mb-2">
                  최고의 액셀러레이터를 만나보세요
                </h1>
                <h6 className="lh-base mb-2 mb-lg-3">
                  빠른 성장을 위한 교육과 멘토링, 시드 투자까지
                  <br />
                  여러분의 비즈니스 성장에 최적화된 액셀러레이터를
                  연결해드립니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  BOOSTAR에서 지금 시작하세요.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
