import React from "react";

export default function Rules() {
  return (
    <section>
      <div className="container d-flex flex-center flex-column">
        <div className="mx-lg-5 w-lg-90">
          <div className="fw-bolder mb-lg-4 mt-6" style={{ color: "#2196F3" }}>
            RULES & ELIGIBILITY
          </div>
          <div className="">
            <div className="mb-lg-4 mt-5 mb-2">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 참가 자격 요건</span>
            </div>
            <div>
              <p className="ms-lg-2">
                • 연령 및 국적 제한 없이 모두 참가 가능하며, 특히 베트남에서
                사업을 하고 있거나 계획 중인 분들에게 참여하길 권장합니다.
                <br />
                • 확장 가능한 비즈니스 모델을 가진 스타트업
                <br />
                • 혁신적인 비즈니스 모델을 가진 기업
                <br />
                • 대학의 과학 연구를 상용화할 수 있는 연구원/과학자
                <br />• 베트남 시장으로 사업을 탐색하고 확장하려는 스타트업
              </p>
              <p>신청자는 다음의 조건을 충족해야 합니다.</p>
              <p className="ms-lg-2">
                • 창업 후 8년 이내 <br />
                • 자회사가 아닌 독립적인 회사
                <br />
                • 실제 사용되고 있는 베타 제품 또는 서비스가 있어야 합니다.
                (우대사항) <br />
              </p>
            </div>
            <div className="mb-lg-4 mt-5 mb-2">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 수상, 보안 규정</span>
            </div>
            <div>
              <p className="fw-bolder">• 수상</p>
              <p className="ms-lg-2">신청자는 다음의 조건을 충족해야 합니다.</p>
              <p className="ms-lg-2">
                Startup Wheel 시상식 이후 15일 이내에 스타트업 대표에게 상금이
                이체됩니다. 수상자는 현행 베트남 법률에 따라 개인소득세(PIT) 및
                상금과 관련된 기타 세금이나 수수료에 대한 책임을 져야 합니다.
                주최자는 이 PIT(Personal Income Tax)를 징수하고 국가 예산에
                지불합니다. 이 PIT(Personal Income Tax)는 수상과 동시에
                주최자에게 지불됩니다.
              </p>
              <p className="fw-bolder">• 보안 규정</p>
              <p className="ms-lg-2">
                대회에 참가하는 경우, 지원자는 주최자가 지원자의 방송 라이브,
                인터뷰, 사진, 레코드, 영화, 전시물을 뉴스, 내러티브, 홍보 목적,
                TV 프로그램, 광고 등에 사용하고 재생산할 것을 동의한 것으로
                간주됩니다. 주최자와 그 파트너 및 모든 관련 당사자는 이 대회에서
                사진 촬영, 녹음, 영상 촬영 또는 출판된 사진, 녹음, 비디오로 인해
                발생하는 책임에서 면제됩니다.
                <br />
                사업 계획서를 제출할 때, 사업 계획서의 간다한 소개는 대회와
                관련된 문서에서 사용될 수 있는 요약으로 간주됩니다. 주최자는
                대회에서의 사업 계획서, pitch deck 및 2분의 피칭 비디오의 사본을
                심사 위원회 및 관련 담당자에게 제공하며, 이들에게 추가적인
                사본을 만들지 않도록 요청할것입니다.
                <br />위 내에서 부정행위, 윤리적, 법적 위반 행위가 발생하는
                경우, 주최자는 지원자의 권리를 일방적으로 취소하고 법적으로
                처리할 권리가 있습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
