import React from "react";

import Intro2 from "../components/Service/Intro2";
import More from "../components/More";

import introImage from "../assets/img/image/judicial_scrivener_intro.png";
import JudicialScrivenerImage from "../assets/img/image/judicial_scrivener_image.png";

export default function JudicialScrivener() {
  return (
    <main>
      <Intro2
        image={introImage}
        title1="BOOSTAR에서"
        title2="최고의 스타트업 법무사와 함께하세요"
        subTitle="스타트업 법률 자문을 통해 성장에만 집중할 수 있도록 도와드립니다."
        url="/contact"
      />
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                스타트업, 법무사의 자문을 꼭 받아야 하나요?{" "}
              </h1>
              <h5 className="mb-3 mb-lg-6">
                스타트업은 일반적으로 개인사업자보다는 법인사업자로 시작하게
                되는 경우가 많습니다.
                <br />
                스타트업 전문 법무사를 통해 법인을 설립하면 서류 작성 시 놓칠 수
                있는 사항들을 꼼꼼하게 챙길 수 있습니다.
              </h5>
              <h5 className="mb-3 mb-lg-6">
                회사가 성장함에 따라 법인 정관의 변경도 지속적으로 이루어지는데
                이때도 마찬가지로 법무사의 자문을 받아야 합니다.
                <br />
                언제, 어떻게 변경 등기를 진행해야하는 지, 필요한 준비 서류는
                무엇인지 창업자가 일일이 챙기는 것은 매우 비효율적이므로
                법무사의 도움이 필요합니다.
              </h5>
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                어떤 법률 자문이 필요하신가요?
              </h1>
              <h5 className="mb-3 mb-lg-4">
                법인설립, 정관변경, 계약서 검토, 투자유치를 위한 법률 자문까지
                스타트업에 특화된 법률 자문을 받을 수 있습니다.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={JudicialScrivenerImage} className="img-fluid w-lg-90" />
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <div
                className="px-lg-3 px-3 py-lg-6 py-2 w-lg-90"
                style={{ background: "#fec72f" }}
              >
                <h1 className="lh-base mb-lg-5 fs-4 mb-2">
                  스타트업 전문
                  <br />
                  법무사를 만나보세요
                </h1>
                <h6 className="lh-base mb-2 mb-lg-3">
                  고객에 대한 이해를 기반으로 한 법률 서비스를 제공합니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  같은 분야의 법률 자문이라도, 일반 기업과 스타트업을 대상으로
                  하는 법률 자문은 다를 수 있습니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  BOOSTAR에서 스타트업 전문 법무사를 빠르게 연결해드립니다.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
