import React from "react";

import gvLogo from "../assets/img/logo/gv_logo.png";
import Button from "../components/Button";

export default function GroundupDetail() {
  return (
    <main>
      <section className="positive-relative d-flex align-items-center text-white">
        <div className="w-100" style={{ background: "#000000" }}>
          <div className="container position-relative py-8 py-lg-6">
            <div className="row col-lg-8 align-items-center my-lg-3">
              <div className="mb-3 mb-lg-0">
                <h2 className="display-5 mb-2" style={{ color: "#fec230" }}>
                  그라운드업벤처스
                </h2>
                <p className="lh-sm mb-lg-4 ps-lg-2">비즈니스 컨설팅</p>
                <Button
                  width="10"
                  text="Contact"
                  background="fef4e5"
                  color="#fbb60d"
                  border="none"
                  onClick={() => window.open("https://ground-up.kr/", "_blank")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative mt-lg-10 mb-lg-8">
        <div className="container">
          <div className="row flex-lg-row-reverse justify-content-center">
            <div className="col-lg-3 my-5">
              <img
                src={gvLogo}
                alt="ground-up ventures logo"
                className="w-lg-80 w-100 mb-lg-6"
              />
              <div className="mb-lg-5 my-3 ps-lg-3 px-3">
                <h4 className="fs-4 fw-bold" style={{ color: "#fec72f" }}>
                  비즈니스 타입
                </h4>
                <p>비즈니스 컨설팅</p>
              </div>
              <div className="mb-lg-5 my-3 ps-lg-3 px-3">
                <h4 className="fs-4 fw-bold" style={{ color: "#fec72f" }}>
                  웹사이트
                </h4>
                <a
                  href="https://ground-up.kr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://ground-up.kr
                </a>
              </div>
            </div>
            <div className="col-lg-9 px-lg-6 px-3">
              <div className="mb-lg-5">
                <h3 className="display-6 mb-lg-5 mb-3">소개</h3>
                <h4 className="fs-4 fw-bold mb-lg-5 mb-3">
                  초기 창업 기업의 한 단계 성장과 생존을 돕는다!
                </h4>
                <p>
                  스타트업은 세상을 혁신 시키며 비즈니스 생태계의 중심이
                  되어가고 있습니다. 우리는 다양한 어려움을 겪는 초기 기업을
                  위한 성장 솔루션을 제공하고, 과정을 함께하는 건강한 성장의
                  동반자가 되고자 합니다. 데이터기반 기술, 진정성 있는 전문가
                  그리고 투자가 어우러져 초기 기업의 성장에 핵심 요소인 사업과
                  사람의 성장에 관한 문제를 해결합니다.
                </p>
              </div>
              <div className="mb-lg-5">
                <h3 className="display-6 mb-lg-5 mb-3">업무 분야</h3>
                <div>
                  <h4 className="fs-4 fw-bold mb-lg-3 mb-3">
                    스타트업 액셀러레이팅
                  </h4>
                  <p>
                    그라운드업벤처스는 가능성 있는 사람과 조직, 혁신적인 모델과
                    기술을 보유한 초기 스타트업을 발굴, 성장 가속화를 지원하고,
                    현재 대기업, 투자사, 공공기관 대상의 오픈이노베이션,
                    사내벤처, 액셀러레이션 프로그램을 운영하고 있습니다.
                  </p>
                  <div>
                    <h5 className="fs-5 fw-bold mb-lg-3">• 창업 단계</h5>
                    <p>
                      사업 아이디어의 구체화, 비즈니스 모델 개발, 초기 자금 확보
                      등 창업 초기에 필요한 사항들을 설계·실행
                    </p>
                  </div>
                  <div>
                    <h5 className="fs-5 fw-bold mb-lg-3">• 사업화 단계</h5>
                    <p>
                      투자 유치, 영업 / 마케팅, 홍보, 재무관리, 세무 등 사업을
                      가속화에 필요한 사항들을 설계·실행
                    </p>
                  </div>
                  <div className="mb-lg-5">
                    <h5 className="fs-5 fw-bold mb-lg-3 mb-3">• 출구 단계</h5>
                    <p>
                      M&A, IPO 등 스타트업의 성공을 1차적으로 정리하고 한 단계
                      도약을 위해 필요한 사항들을 설계
                    </p>
                  </div>
                </div>
                <div className="mb-lg-5 mb-3">
                  <h4 className="fs-4 fw-bold mb-lg-3 mb-3">
                    직접 투자 및 제품 양산 투자
                  </h4>
                  <p>
                    그라운드업벤처스는 성장에 난관을 겪고 있는 초기기업을 위한
                    재무적 투자를 진행합니다. 성장 가능성이 큰 초기 기업 발굴 및
                    시드 투자, 우수 제품 보유 기업 발굴 및 시드 투자를
                    진행합니다.
                  </p>
                </div>
                <div className="mb-lg-5 mb-3">
                  <h4 className="fs-4 fw-bold mb-lg-3 mb-3">
                    디지털 콘텐츠 제작
                  </h4>
                  <p>
                    기관/ 기업의 디지털 콘텐츠의 제작을 대행하고, 자체 제작
                    콘텐츠를 유통합니다.
                  </p>
                </div>
                <div className="mb-lg-5 mb-3">
                  <h4 className="fs-4 fw-bold mb-lg-3 mb-3">
                    인큐베이팅 공간 운영
                  </h4>
                  <p>
                    기관/기업의 액셀러레이팅 프로그램을 위탁 운영하며, 자체
                    보육공간을 운영하고 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
