import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import ReviewUpdateModal from "../components/ReviewUpdateModal";
import reviewApi from "../api/review";
import { myReviewState, userInfoState } from "../store/userState";

export default function ReviewList() {
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [selectedReview, setSelectedReview] = useState(undefined);

  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));
  const [review, setReview] = useRecoilState(myReviewState);

  useEffect(() => {
    const getReviewList = async () => {
      try {
        const getReviews = await reviewApi.getMyReviewList({
          userId: userInfo?.user_idx,
        });

        if (getReviews.result === "success") {
          setReview(getReviews.data);
        }
      } catch (err) {
        alert("리뷰를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.");
      }
    };

    getReviewList();
  }, []);

  const handleUpdateButtonClick = (info) => {
    setSelectedReview(info);
    setReviewModalShow(true);
  };

  return (
    <main>
      <section className="position-relative">
        <div className="container position-relative">
          <div className="position-relative pt-9 pb-9 pb-lg-11">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column w-lg-50 w-100">
                <nav className="nav nav-pills">
                  <a href="/my_page" className="nav-link">
                    <i className="bx bx-user-circle me-2"></i>내 프로필
                  </a>
                  <a href="/order/list" className="nav-link">
                    <i className="bx bx-credit-card me-2"></i>결제 내역
                  </a>
                  <a href="/my_class/list" className="nav-link">
                    <i className="bx bx-rocket me-2" />
                    강의 신청 내역
                  </a>
                  <a href="/review/list" className="nav-link active">
                    <i className="bx bx-comment-detail me-2" />
                    내가 쓴 리뷰
                  </a>
                </nav>
                <div className="d-flex align-items-center pt-1 pb-3">
                  <span className="flex-grow-1 border-bottom pt-1" />
                </div>
                <div className="h-100">
                  {review?.map((info, index) => {
                    const savedDate = new Date(info?.create_at);
                    const regDate = new Date(info?.reg_date);

                    return (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between py-lg-3 py-3 px-lg-3 px-2 my-lg-3 my-3"
                          style={{
                            border: "1px solid #e8e8e8",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          key={info?.order_number}
                          onClick={() => handleUpdateButtonClick(info)}
                        >
                          <div className="my-lg-2 my-2">
                            {info?.service_type == "무료강의" ||
                            info?.service_type === "유료강의" ? (
                              <span
                                className="px-lg-2 py-lg-1 px-2"
                                style={{
                                  borderRadius: "5px",
                                  color: `#fbb60d`,
                                  background: "#fef4e5",
                                }}
                              >
                                강의
                              </span>
                            ) : (
                              <span
                                className="px-lg-2 py-lg-1 px-2"
                                style={{
                                  borderRadius: "5px",
                                  color: `#ff3d0d`,
                                  background: "#fef4e5",
                                }}
                              >
                                서비스
                              </span>
                            )}
                            <span className="mx-lg-3 mx-3 small text-muted">
                              {regDate?.toLocaleDateString().slice(0, -1)}
                            </span>
                            <div className="d-flex align-items-center pt-1">
                              <span className="flex-grow-1 border-bottom pt-1" />
                            </div>
                          </div>
                          <div>
                            {info?.service_type !== "무료강의" ? (
                              <>
                                <div className="d-flex justify-content-between my-lg-2 my-2">
                                  <span className="small text-muted">
                                    주문번호: {info?.order_number}
                                  </span>
                                  <span className="small text-muted">
                                    리뷰작성일:{" "}
                                    {savedDate
                                      ?.toLocaleDateString()
                                      .slice(0, -1)}
                                  </span>
                                </div>
                                <div className="my-lg-1 my-1">
                                  [{info?.service_type}] {info?.service_name}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="text-end my-lg-2 my-2">
                                  <span className="small text-muted">
                                    리뷰작성일:{" "}
                                    {savedDate
                                      ?.toLocaleDateString()
                                      .slice(0, -1)}
                                  </span>
                                </div>
                                <div className="my-lg-1 my-1">
                                  {info?.service_name}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="d-flex justify-content-end pt-lg-3">
                            <div
                              className="me-lg-2 me-2"
                              style={{ color: "#387bfe" }}
                            >
                              수정하기 &gt;&gt;
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedReview && (
          <ReviewUpdateModal
            reviewInfo={selectedReview}
            show={reviewModalShow}
            onHide={() => setReviewModalShow(false)}
          />
        )}
      </section>
    </main>
  );
}
