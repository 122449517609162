import axios from "../configs/axiosInstance";

const userApi = {};

userApi.login = async ({ email, password }) => {
  const response = await axios.post("/login", {
    email,
    password,
  });

  return response.data;
};

userApi.signup = async ({ name, phone, email, password, marketing }) => {
  const response = await axios.post("/signup", {
    name,
    phone,
    email,
    password,
    marketing,
  });

  return response.data;
};

userApi.googleLogin = async (accessToken) => {
  const response = await axios.get("/login/google", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data;
};

userApi.naverLogin = async (accessToken) => {
  const response = await axios.get("/login/naver", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data;
};

userApi.addInfo = async (userInfo) => {
  const response = await axios.post("/add/info", userInfo);

  return response.data;
};

userApi.addGoogleAdditionalInfo = async (userInfo) => {
  const response = await axios.post("/add/googleUser/additionalInfo", userInfo);

  return response.data;
};

userApi.kakaoLogin = async (accessCode) => {
  const response = await axios.get("/login/kakao", {
    headers: {
      Authorization: `Bearer ${accessCode}`,
    },
  });

  return response.data;
};

userApi.updateUserInfo = async ({ id, name, phone, officeName, marketing }) => {
  const response = await axios.put("/update/userInfo", {
    id,
    name,
    phone,
    officeName,
    marketing,
  });

  return response.data;
};

userApi.deleteUserInfo = async (id) => {
  const response = await axios.delete("/delete/userInfo", {
    data: {
      userId: id,
    },
  });

  return response.data;
};

userApi.changePassword = async ({ user_idx, old_password, password }) => {
  const response = await axios.put("/user/changePassword", {
    user_idx,
    old_password,
    password,
  });

  return response.data;
};

userApi.getUserInfo = async () => {
  const response = await axios.get("/user/info");

  return response.data;
};

userApi.findPassword = async ({ email, name }) => {
  const response = await axios.put("/resetpw", {
    email,
    name,
  });

  return response.data;
};

export default userApi;
