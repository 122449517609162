import React, { useState } from "react";

import Button from "../Button";
import TextInput from "../TextInput";

export default function Funding({
  applyData,
  isEmpty,
  setIsEmpty,
  handleChange,
  handleChangeStage,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const handleNextButtonClick = () => {
    if (!applyData?.fundingRound.length) {
      setErrorMessage("✓ 필수 입력란을 확인해주세요.");
    } else {
      setErrorMessage("");
    }

    if (
      !applyData?.totalFund.replaceAll(" ", "").length ||
      !applyData?.fundingAmount.replaceAll(" ", "").length
    ) {
      setIsEmpty(true);

      return;
    }

    if (
      applyData?.fundingRound.length &&
      applyData?.totalFund.replaceAll(" ", "").length &&
      applyData?.fundingAmount.replaceAll(" ", "").length
    ) {
      handleChangeStage((prevState) => ({
        ...prevState,
        funding: false,
        founderTeam: true,
      }));
    }
  };

  return (
    <>
      {" "}
      <div className="my-3">
        <div>
          <p className="mb-0" style={{ marginLeft: "89%" }}>
            🌱
          </p>
          <div className="progress height-10">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: "90%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
        <div className="w-lg-100 flex-center my-3">
          <div className="w-lg-50 mt-lg-3">
            <div className="my-lg-3">
              <span style={{ color: "#f44336" }}>▶︎</span>
              <span className="fw-bolder"> 자금</span>
              <p style={{ color: "#f44336" }}>
                ✓ 모든 정보는 영어로 작성해주셔야 합니다. 한글로 작성시 지원은
                무효가 됩니다.
              </p>
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>총 자본금</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
                <span className="small"> (USD 기준)</span>
              </div>
              <TextInput
                name="totalFund"
                type="text"
                placeholder="100000"
                icon="bx-dollar"
                value={applyData?.totalFund || ""}
                emptyValidation={isEmpty}
                emptyMessage="✓ 총 자본금을 입력해주세요."
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>현재 라운드</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
                <span className="small"> (현재 완료된 기준)</span>
              </div>
              <select
                name="fundingRound"
                value={applyData?.fundingRound || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
              >
                <option value="" disabled>
                  현재 라운드
                </option>
                <option value="No Funding">No Funding</option>
                <option value="Pre-Seed/Angel">Pre-Seed/Angel</option>
                <option value="Seed">Seed</option>
                <option value="Pre-Series A/Bridge">Pre-Series A/Bridge</option>
                <option value="Series A">Series A</option>
                <option value="Series B">Series B</option>
                <option value="Above Series B">Above Series B</option>
              </select>
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>현재 라운드 투자금액</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
                <span className="small"> (USD, 최근 완료된 기준)</span>
              </div>
              <TextInput
                name="fundingAmount"
                type=""
                placeholder="100000"
                icon="bx-dollar-circle"
                value={applyData?.fundingAmount || ""}
                emptyValidation={isEmpty}
                emptyMessage="✓ 총 자본금을 입력해주세요."
                onChange={handleChange}
              />
            </div>
            <div className="ms-1 mb-3">
              <small className="text-danger">{errorMessage}</small>
            </div>
            <div className="w-lg-100 w-100 flex-center mt-lg-3">
              <Button
                width="18"
                text="NEXT"
                border="none"
                background="#f44336"
                color="#ffffff"
                onClick={handleNextButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
