import React from "react";
import { useParams } from "react-router-dom";

import introImage from "../assets/img/image/finance_intro.png";
import TossPayments from "../components/tossPayments";

export default function Ebook() {
  // const { id } = useParams();

  // const ebook = useRecoilValue(selectedEbookState(id));

  // const content = ebook.explanation.split("/");

  return (
    <main>
      <section className="position-relative d-flex align-items-center text-white">
        <img src={introImage} alt="Service image" className="bg-image" />
        <div className="container position-relative py-8 py-lg-10">
          <div className="row align-items-center my-lg-3">
            <div className="mb-3 mb-lg-0">
              <h2 className="display-5 mb-5">법인 설립 부스터(가이드북)</h2>
              <p className="fs-5 lh-sm mb-lg-0">
                복잡한 법인 설립 과정을 이해할 수 있도록 쉽고, 간단한 설명.
                <br />
                실제 법인 설립 시 꼭 필요한 내용과 TIP을 담았습니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative d-flex align-items-center">
        <div className="container position-relative py-lg-10 py-5 px-4">
          <div className="row">
            <div className="col-lg-7 px-lg-4">
              <h1 className="mb-lg-7 my-3">About the Guide book</h1>
              <h3 className="my-lg-5 my-4 fw-bold">
                혼자서 하는 법인 설립, 어렵지 않습니다!
              </h3>
              <h6 className="lh-sm my-lg-3 my-3">
                절차과 준비물만 확실히 파악하면, 혼자서도 쉽게 해낼 수 있습니다.
                <br />
                아무 준비 없이 법인 설립을 진행하면 대행 수수료보다 비용이 배로
                들어가고 시간 또한 많이 소요될 수 있습니다
              </h6>
              <h6 className="lh-sm my-lg-3">
                법인 설립 부스터를 활용해 각 과정을 이해하며 빠르고, 쉽게 법인
                설립을 진행할 수 있습니다.
              </h6>
              <h5 className="my-lg-5 my-4 fw-bold">
                &apos;법인 설립 부스터&apos;는?
              </h5>
              <div className="mx-lg-4 ms-3 my-lg-4 my-3">
                <h6>• 실제 법인 설립 절차에 맞춰서 내용을 구성하였습니다</h6>
                <h6>
                  • 설립 여정 별 필요한 준비물 및 준비사항을 알기 쉽게
                  담았습니다
                </h6>
                <h6>
                  • 처음 법인 설립을 하시는 분들이 놓칠 수 있는 TIP을 담았습니다
                </h6>
              </div>
              <h6 className="lh-sm my-lg-3 my-3">
                누구나 처음 접하는 일들이 어렵게 느껴집니다.
              </h6>
              <h6 className="lh-sm my-lg-3 my-3">
                그렇게 시행착오를 겪으며 여러 과정들을 마무리 하고 돌아보면
                지나온 과정들이 쉽게 느껴지기 마련인데요.
              </h6>
              <h6 className="lh-sm my-lg-3 my-3">
                ‘법인 설립 부스터’는 법인 설립 과정을 초행길이 아닌 익숙한
                지름길로 느껴지도록 안내해드립니다.
              </h6>
            </div>
            <div className="col-lg-5 mt-3">
              <TossPayments
                primeCost="30000"
                price="15000"
                discount="0"
                title="법인 설립 가이드북"
                type="Ebook"
                productCode="B-23P-GBIN-01"
                pageCode="B-23P-GBIN-01"
                pageTitle="법인 설립 가이드북"
                url="ebook/1"
              >
                <div className="py-lg-3 py-3">
                  <div>
                    <span className="fw-bolder small text-muted">분야</span>
                    <span className="ms-lg-2 ms-2 small text-muted">
                      법인설립
                    </span>
                  </div>
                  <div>
                    <span className="fw-bolder small text-muted">이용방법</span>
                    <span className="ms-lg-2 ms-2 small text-muted">
                      결제 &gt; 이메일 발송 &gt; 첨부된 PDF 파일 다운로드
                    </span>
                  </div>
                  <div>
                    <span className="fw-bolder small text-muted">기간</span>
                    <span className="ms-lg-2 ms-2 small text-muted">
                      7일 이내 다운받으시면 영구적으로 소장 가능합니다.
                    </span>
                  </div>
                </div>
              </TossPayments>
            </div>
            <div className="d-flex align-items-center py-3">
              <span className="flex-grow-1 border-bottom pt-1" />
            </div>
            <div>
              <p className="fw-bold">[주의사항 및 환불규정]</p>
              <p className="fw-bold">• 이용방법</p>
              <p>
                - 본 도서는 PDF 포맷으로 Adobe Acrobat Reader 및 그 밖의 PDF
                리더 또는 웹브라우저로 열람이 가능합니다.
                <br /> - 본 도서는 결제 후 다운로드 버튼을 누르면 발송된 메일에
                첨부된 상품을 다운로드하여 열람이 가능합니다.
              </p>
              <p className="fw-bold">• 서비스 제공기간</p>
              <p>
                - 본 도서는 PDF 포맷으로 구매 후 7일 이내에 다운받으시면
                영구적으로 소장 가능합니다.
              </p>
              <p className="fw-bold">• 환불규정</p>
              <p>
                - 환불금액은 정가가 아닌 실제 결제금액을 기준으로 계산됩니다.
                <br />- 쿠폰을 사용하여 도서를 결제하신 후 취소/환불 시 쿠폰은
                복구되지 않습니다.
              </p>
              <p className="mx-lg-3">
                - 결제 후 7일 이내 <br />: 결제 후 &quot;상품이 포함된 메일
                발송이 되지 않은 경우&quot; 100% 환불 가능합니다. <br />: 결제
                후 &quot;상품이 포함된 메일 발송이 된 경우&quot;에는 환불
                불가합니다.
              </p>
              <p className="mx-lg-3">
                - 결제 후 7일 경과 시 <br />: 결제 후 7일 경과 후 환불
                불가합니다. (메일 발송 여부와 상관없이 환불 불가)
              </p>
              <p>
                ※ 환불 불가 사유
                <br /> - 복제 가능한 상품이 포함된 메일이 발송된 경우
                <br /> - 환불 요청 가능 기간이 초과한 경우 (결제시점으로부터 7일
                초과 시)
                <br /> - 전자상거래 등에서의 소비자보호에 관한 법률이 정하는
                소비자 청약철회 제한 내용에 해당되는 경우
              </p>
              <p className="fw-bold">• 주의사항</p>
              <p className="fw-bold mb-0">
                BOOSTAR 가이드북 공유 금지 정책 안내
              </p>
              <p>
                BOOSTAR 가이드북은 주식회사 그라운드업벤처스의 지적재산으로 무단
                전재 및 복제, 공유가 불가능합니다. 모든 가이드북에는 무단 전재
                및 복제, 공유를 예방하기 위해 구매자의 일부 정보가 DRM 기술을
                통해 추가되오니 이용에 주의를 바랍니다.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
