import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import userApi from "../api/user";

import Button from "../components/Button";
import ModalContainer from "../components/Modal";
import TextInput from "../components/TextInput";

import { EMAIL_REGEXP, NAME_REGEXP } from "../constants/RegExp";

export default function FindPassword() {
  const [isModalShow, setIsModalShow] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isResetPw, setIsResetPw] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [finderInfo, setFinderInfo] = useState({
    email: "",
    name: "",
  });

  const navigate = useNavigate();

  const isValidEmail = EMAIL_REGEXP.test(finderInfo.email);
  const isValidName = NAME_REGEXP.test(finderInfo.name);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setFinderInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setIsEmpty(false);
  });

  const handleFindButtonClick = async (event) => {
    event.preventDefault();

    if (
      !finderInfo.email.replaceAll(" ", "").length ||
      !finderInfo.name.replaceAll(" ", "").length
    ) {
      setIsEmpty(true);

      return;
    }

    try {
      const response = await userApi.findPassword({
        email: finderInfo.email,
        name: finderInfo.name,
      });

      if (response.result === "success") {
        setModalContent([
          "새로운 비밀번호를 위의 이메일로 전달했습니다.",
          "확인 후 비밀번호를 변경해주시길 바랍니다.",
        ]);
        setIsResetPw(true);
        setIsModalShow(true);
      }

      if (response.message === "no_user") {
        setModalContent([
          "기입해주신 정보와 일치하는 계정이 없습니다.",
          "확인 후 다시 기입해주세요.",
        ]);
        setIsResetPw(false);
        setIsModalShow(true);
      }
    } catch (err) {
      if (err) {
        setModalContent([
          "새로운 비밀번호 발급이 중단되었습니다.",
          "잠시 후 이용 부탁드립니다.",
        ]);
        setIsResetPw(false);
        setIsModalShow(true);
      }
    }
  };

  return (
    <main className="position-relative">
      <div className="container py-lg-10 py-5 px-lg-11">
        <div className="d-flex flex-center">
          <form onSubmit={handleFindButtonClick} className="w-lg-40">
            <div>
              <span>이메일</span>
              <TextInput
                name="email"
                type="text"
                placeholder="user@ground-up.kr"
                icon="bx-envelope"
                value={finderInfo.email}
                pattern={EMAIL_REGEXP}
                errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                emptyValidation={isEmpty}
                emptyMessage="✓ 이메일을 정확하게 작성했는지 다시 한 번 확인해주세요."
                onChange={handleChange}
              />
            </div>
            <div>
              <span>이름</span>
              <TextInput
                name="name"
                type="text"
                placeholder="이름(실명)"
                icon="bx-user"
                value={finderInfo.name || ""}
                pattern={NAME_REGEXP}
                errorMessage="✓ 이름을 확인해주세요."
                emptyValidation={isEmpty}
                emptyMessage="✓ 이름을 정확하게 작성했는지 다시 한 번 확인해주세요."
                onChange={handleChange}
              />
            </div>
            {/* <div className="mb-5">
              <small className="text-danger">{errorMessage}</small>
            </div> */}
            <div className="d-flex flex-center">
              <Button
                width="10"
                text="비밀번호 찾기"
                background="#fef4e5"
                color="#fbb60d"
                border="none"
              />
            </div>
          </form>
        </div>
      </div>
      <ModalContainer show={isModalShow} onHide={() => setIsModalShow(false)}>
        <div className="mt-lg-3 flex-center flex-column">
          <div className="mb-2">
            {modalContent.map((content, index) => (
              <p key={index} className="mb-0 text-center">
                {content}
              </p>
            ))}
          </div>
          <Button
            width="10"
            text="확인"
            border="none"
            background="#fef4e5"
            color="#fbb60d"
            onClick={
              isResetPw ? () => navigate("/") : () => setIsModalShow(false)
            }
          />
        </div>
      </ModalContainer>
    </main>
  );
}
