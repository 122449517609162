import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ReactHtmlParser from "html-react-parser";

import DayTag from "../components/DayTag";
import resourceApi from "../api/resource";

const ListContainer = styled.div`
  border-bottom: 1px solid #c8c8c8;
`;

export default function SupportProgramDetail() {
  const [programData, setProgramData] = useState(undefined);
  const [files, setFiles] = useState([]);

  const { idx } = useParams();

  const baseUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SERVER_URL
      : process.env.REACT_APP_LOCAL_SERVER_URL;

  useEffect(() => {
    const getProgramDetail = async () => {
      try {
        const response = await resourceApi.getProgramDetail(idx);

        if (response.result === "success") {
          setProgramData(response.data.data);
          setFiles(response.data.filelist);
        }
      } catch (err) {
        if (err) {
          alert(
            "데이터를 불러오는데 문제가 생겼습니다. 잠시 후 다시 이용바랍니다."
          );
        }
      }
    };

    getProgramDetail();
  }, []);

  return (
    <main>
      <section className="position-relative">
        <img
          src="https://cdn-s3.boostar.co.kr/boostar/image/support_program_detail_top_banner.png"
          alt="지원사업배너"
          className="bg-image img-fluid w-lg-100 h-100"
        />
        <div className="container position-relative">
          <h1 className="fs-3 fw-bolder text-center text-white py-lg-11 py-4">
            {programData?.title}
          </h1>
        </div>
      </section>
      <section className="position-relative px-2">
        <div className="container position-relative py-lg-4 py-4 px-lg-11 px-1">
          <div className="d-flex flex-center">
            <div
              className="w-lg-70 w-100 shadow px-lg-7 px-4 py-lg-6"
              style={{ background: "#fbfbfb", borderRadius: "0.375rem" }}
            >
              <ListContainer className="row">
                <div className="col-lg-3 col-2 fw-bolder ps-lg-7 ps-0 ps-1 py-lg-3 py-2 d-flex align-items-center">
                  마감일
                </div>
                <div className="col-lg-9 col-9 py-lg-3 py-2 d-inline-flex align-items-center">
                  <div className="w-lg-20 w-30 me-lg-3 me-3">
                    {programData?.end_date && (
                      <DayTag date={programData?.end_date} />
                    )}{" "}
                  </div>
                  {programData?.end_date.replaceAll("-", ".")}
                </div>
              </ListContainer>
              <ListContainer className="row">
                <div className="col-lg-3 col-2 fw-bolder ps-lg-7 ps-0 py-lg-3 py-2 ">
                  소관부처
                </div>
                <div className="col-lg-9 col-9 py-lg-3 py-2">
                  {programData?.city_gov}
                </div>
              </ListContainer>
              <ListContainer className="row">
                <div className="col-lg-3 col-2 fw-bolder ps-lg-7 ps-0 py-lg-3 py-2 ">
                  수행기관
                </div>
                <div className="col-lg-9 col-9 py-lg-3 py-2">
                  {programData?.agency}
                </div>
              </ListContainer>
              <ListContainer className="row">
                <div className="col-lg-3 col-2 fw-bolder ps-lg-7 ps-0 py-lg-3 py-2 ">
                  사업내용
                </div>
                <div className="col-lg-9 col-9 py-lg-3 py-2">
                  {programData && ReactHtmlParser(programData?.detail)}
                </div>
              </ListContainer>
              <div className="row">
                <div className="col-lg-3 col-2 fw-bolder ps-lg-7 ps-0 py-lg-3 py-2 ">
                  첨부파일
                </div>
                <div className="col-lg-9 col-9 py-lg-3 py-2 d-flex flex-column">
                  {files.map((file) => (
                    <a
                      key={file.idx}
                      href={`${baseUrl}/resource/bizinfo/fileDownload/${file.idx}`}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      📎 {file.file_name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
