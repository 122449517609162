import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import userApi from "../api/user";

import kakaoSymbol from "../assets/img/brands/kakao_symbol.png";
import kakaoIcon from "../assets/img/brands/kakao_logo_icon.png";

const KakaoLoginButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.3rem;
  margin: 0.5rem 0;
  background-color: #fee500;
  border: 0;
  border-radius: 12px;
`;

const KakaoIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 12px;
  background: url(${kakaoSymbol}) no-repeat center;
  background-size: 23px;
`;

const KakaoTitle = styled.span`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

const KakaoIconButton = styled.div`
  display: flex;
  justify-content: center;

  .button {
    cursor: pointer;
  }
`;

export default function KakaoLogin({ url }) {
  const [access, setAccess] = useState(undefined);

  const { Kakao } = window;
  const kakaoAppKey = process.env.REACT_APP_KAKAO_APP_KEY;

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const accessCode = params.get("code");

  const handleLoginButtonClick = async (event) => {
    event.preventDefault();

    Kakao.init(kakaoAppKey);

    const isInitialized = Kakao.isInitialized();

    const DEV_KAKAO_REDIRECT = window.location.origin + "/login";

    if (isInitialized) {
      try {
        Kakao.Auth.authorize({
          redirectUri:
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_KAKAO_REDIRECT
              : DEV_KAKAO_REDIRECT,
          prompts: "login",
        });

        setAccess(accessCode);
      } catch (err) {
        alert("로그인중 에러가 발생했습니다. 잠시 후 다시 시도해주세요.");
      }
    }
  };

  if (accessCode) {
    const getUserInfo = async () => {
      try {
        const response = await userApi.kakaoLogin(accessCode);

        if (response.result === "success") {
          const accessToken = response.data.accessToken;

          localStorage.setItem("accessToken", accessToken);

          const retUrl = localStorage.getItem("retUrl");

          if (retUrl !== null) {
            localStorage.removeItem("retUrl");
            navigate(retUrl);
          } else {
            navigate("/");
          }
        }
      } catch (err) {
        if (err.response?.data.error.message === "User") {
          alert("이미 등록된 이메일입니다.");
          navigate("/login");
        }

        if (err.response?.data.error.message === "DB ERROR") {
          alert("로그인중 에러가 발생했습니다. 잠시후 다시 시도해주세요.");
          navigate("/login");
        }
      }
    };

    getUserInfo();
  }

  return (
    <>
      {!url ? (
        <KakaoLoginButton onClick={handleLoginButtonClick}>
          <KakaoIcon alt="kakao icon" />
          <KakaoTitle className="">카카오로 로그인하기</KakaoTitle>
        </KakaoLoginButton>
      ) : (
        <KakaoIconButton onClick={handleLoginButtonClick}>
          <img src={kakaoIcon} className="img-fluid w-lg-50 w-50 button" />
        </KakaoIconButton>
      )}
    </>
  );
}
