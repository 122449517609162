import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import Button from "../Button";
import ModalContainer from "../Modal";
import { myApplicationState, userInfoState } from "../../store/userState";
import applyApi from "../../api/apply";

export default function Apply() {
  const [isModalShow, setIsModalShow] = useState(false);

  const token = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(token));
  const [myApplication, setMyApplication] = useRecoilState(myApplicationState);

  const navigate = useNavigate();

  const handleApplyButtonClick = () => {
    if (!userInfo) {
      setIsModalShow(true);

      return;
    }

    if (userInfo?.user_idx) {
      navigate("/startup_wheel_2023/apply_form");
    }
  };

  const handleUpdateButtonClick = async () => {
    try {
      const response = await applyApi.getMyApplication();

      if (response.result === "success") {
        setMyApplication(response.data);
        navigate("/startup_wheel_2023/my_application");
      }

      if (response.result === "fail" && response.message === "no data") {
        alert("참가 신청 이력이 없습니다.");
      }
    } catch (err) {
      if (err.response.data.error.message === "jwt must be provided") {
        setIsModalShow(true);
      }
    }
  };

  return (
    <section>
      <div className="container d-flex flex-center flex-column">
        <div className="mx-lg-5 w-lg-90 mb-lg-5 mt-5">
          <div className="fw-bolder mb-lg-4" style={{ color: "#2196F3" }}>
            APPLY
          </div>
          <div className="px-lg-10">
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/image/startup_wheel_thumbnail.jpg"
              className="img-fluid w-lg-100 w-100"
            />
          </div>
          <div className="d-flex justify-content-center py-lg-3 py-3">
            <performance> 참가 신청 기간이 마감되었습니다 🥲</performance>
            {/* <Button
              width="10"
              text="참가하기"
              border="none"
              background="#f44336"
              color="#ffffff"
              onClick={handleApplyButtonClick}
            />
            <Button
              width="10"
              text="신청서 확인하기"
              border="none"
              background="#f44336"
              color="#ffffff"
              onClick={handleUpdateButtonClick}
            /> */}
          </div>
        </div>
      </div>
      <ModalContainer
        show={isModalShow}
        content="서비스를 시작하시려면 로그인을 해주세요 🙂"
        onHide={() => setIsModalShow(false)}
      >
        <>
          <div className="d-flex flex-center mt-lg-4">
            <Button
              width="8"
              background="#f4f4f5"
              color="#797979"
              text="가입하기"
              border="none"
              onClick={() => navigate("/signup")}
            />
            <Button
              width="8"
              background="#fef4e5"
              color="#fbb60d"
              text="로그인하기"
              border="none"
              onClick={() => navigate("/login?retUrl=" + location.pathname)}
            />
          </div>
        </>
      </ModalContainer>
    </section>
  );
}
