import React from "react";

export default function Banner() {
  return (
    <section className="position-relative">
      <div className="container px-lg-10 py-lg-6 py-2">
        <div
          className="flex-center"
          onClick={() => window.open("http://taxforest.kr/", "_blank")}
        >
          <img
            src="https://cdn-s3.boostar.co.kr/boostar/partner_slide/forest_main_ad.jpg"
            alt="banner"
            className="img-fluid w-lg-100 px-lg-3 w-100"
            style={{
              borderRadius: "5px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </section>
  );
}
