import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../../components/ServiceBox";

export default function MarketingList() {
  const navigate = useNavigate();

  const marketingListTopImage =
    "https://cdn-s3.boostar.co.kr/boostar/marketing/marketing_list_top_banner.jpg";

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      {/* <div className="container">
        <img
          src={marketingListTopImage}
          className="img-fluid w-lg-100 mb-lg-4 px-lg-10"
        />
      </div> */}
      <div className="container py-lg-6 py-4 px-lg-11">
        <div
          className="mb-lg-4 mb-3"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <h3 className="fw-bolder ps-2 ">
            <span style={{ color: "#fbb60d" }}>홍보 및 마케팅 지원</span>
            <br />
            서비스
          </h3>
        </div>
        <div className="row">
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/pr_list/datz_list_thumbnail.jpg"
            title="제품-서비스 홍보 영상 풀패키지"
            info="홍보 및 미디어"
            rating="0.0"
            review="0"
            onClick={() => navigate("/pr_full_package")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/marketing/big_data_list_thumbnail_230504.jpg"
            title="빅데이터 마케팅 솔루션"
            info="마케팅 및 광고"
            salePrice="330,000 원"
            price="500,000 원"
            discount="34%"
            rating="0.0"
            review="0"
            onClick={() => navigate("/marketing/big_data")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/pr_list/media_list_thumbnail(230321).jpg"
            title="초특가 언론 홍보 패키지"
            info="홍보 및 미디어"
            rating="0.0"
            review="0"
            onClick={() => navigate("/pr_media_pack")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/pr_list/pr_video_pack_list_thumbnail_230504.jpg"
            title="기업 맞춤 올인원 영상 팩"
            info="홍보 및 미디어"
            rating="0.0"
            review="0"
            onClick={() => navigate("/pr_video_pack")}
          />
        </div>
      </div>
    </main>
  );
}
