import { atom, selectorFamily } from "recoil";
import userApi from "../api/user";

export const userInfoState = selectorFamily({
  key: "userInfoState",
  get: (accessToken) => async () => {
    if (!accessToken) return undefined;

    try {
      const response = await userApi.getUserInfo();

      if (response.result === "success") {
        return response.data;
      }

      if (response.result === "fail") {
        localStorage.removeItem("accessToken");
        return undefined;
      }
    } catch (err) {
      localStorage.removeItem("accessToken");
      return undefined;
    }
  },
});

export const myReviewState = atom({
  key: "myReviewState",
  default: undefined,
});

export const myApplicationState = atom({
  key: "myApplicationState",
  default: undefined,
});
