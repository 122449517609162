import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import ModalContainer from "../components/Modal";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import contactApi from "../api/contact";

import { EMAIL_REGEXP, NAME_REGEXP, PHONE_REGEXP } from "../constants/RegExp";

import step1Image from "../assets/img/graphics/icons/write1.png";
import step2Image from "../assets/img/graphics/icons/partnership1.png";
import step3Image from "../assets/img/graphics/icons/interaction1.png";

export default function ContactPartner() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isMarketingModalShow, setIsMarketingModalShow] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    officeName: "",
    proposal: "",
  });

  const navigate = useNavigate();

  const isValidName = NAME_REGEXP.test(contactData.name);
  const isValidEmail = EMAIL_REGEXP.test(contactData.email);
  const isValidPhone = PHONE_REGEXP.test(contactData.phone);

  const steps = [
    {
      image: step1Image,
      content: "작성 폼 제출 후 파트너쉽을 위한 안내 메일이 발송됩니다",
    },
    {
      image: step2Image,
      content: "답변 내용을 검토합니다",
    },
    {
      image: step3Image,
      content: "파트너등록 여부를 메일이나 유선으로 안내드립니다",
    },
  ];

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setContactData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setIsDisabled(true);

    if (
      !contactData.name ||
      !contactData.phone ||
      !contactData.email ||
      !contactData.officeName.replaceAll(" ", "").length
    ) {
      setIsDisabled(false);
      setIsEmpty(true);

      return;
    }

    if (isValidName && isValidEmail && isValidPhone) {
      setIsDisabled(true);

      try {
        const response = await contactApi.contactPartner({
          name: contactData.name,
          phone: contactData.phone.replaceAll("-", ""),
          email: contactData.email,
          officeName: contactData.officeName,
          proposal: contactData.proposal,
        });

        if (response.result === "success") {
          setModalShow(true);
        }
      } catch (err) {
        if (
          err.response.data.error.message === "Not Saved" ||
          err.response.data.error.message === "Database Error"
        ) {
          alert("상담을 등록하는데 문제가 생겼습니다.");
        }
      }
    }
  };

  useEffect(() => {
    if (contactData.phone?.length === 11) {
      setContactData((prevSate) => ({
        ...prevSate,
        phone: contactData.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }

    if (contactData.phone?.length === 13) {
      setContactData((prevState) => ({
        ...prevState,
        phone: contactData.phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }
  }, [contactData.phone]);

  return (
    <main>
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container px-lg-11 py-8 py-lg-6">
          <div className="row align-items-center">
            <div className="col-lg-6 me-auto mb-5 justify-content-center">
              <h2 className="mb-lg-5 mx-lg-3 mb-5">
                BOOSTAR 파트너로 등록하고,
                <br />
                새로운 기회를 만드세요!
              </h2>
              <p className="fs-5 mb-lg-10 mx-lg-3">
                당신의 도움이 필요한 스타트업과 연결해드립니다.
              </p>
              {steps.map((step, index) => (
                <div
                  className="d-inline-flex w-lg-90 w-100"
                  style={{ borderBottom: "1px solid #d3d3d3" }}
                  key={index}
                >
                  <img
                    src={step.image}
                    className="img-fluid px-lg-3 py-lg-3 px-4 py-4"
                    alt="write"
                  />
                  <div className="me-2">
                    <p
                      className="mb-0"
                      style={{ color: "#FEC72F", fontWeight: "700" }}
                    >
                      {`Step ${index + 1}`}
                    </p>
                    <p className="mb-0">{step.content}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-6">
              <div
                className="py-10 px-7 shadow w-lg-90 mx-lg-11"
                style={{
                  background: "#ffffff",
                  borderRadius: "55px",
                }}
              >
                <form onSubmit={handleFormSubmit}>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>이름</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="name"
                      type="text"
                      placeholder="이름(실명)"
                      icon="bx-user"
                      value={contactData.name || ""}
                      pattern={NAME_REGEXP}
                      errorMessage="✓ 이름을 확인해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이름을 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>회사명</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="officeName"
                      type="text"
                      placeholder="Boostar"
                      icon="bx-buildings"
                      value={contactData.officeName || ""}
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 회사 이름을 작성해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>연락처</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="phone"
                      type="text"
                      placeholder="010-1234-5678"
                      icon="bx-phone"
                      value={contactData.phone || ""}
                      maxLength={13}
                      pattern={PHONE_REGEXP}
                      errorMessage="✓ 핸드폰번호를 확인해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 핸드폰번호를 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>이메일</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="email"
                      type="text"
                      placeholder="user@boostar.co.kr"
                      icon="bx-envelope"
                      value={contactData.email || ""}
                      pattern={EMAIL_REGEXP}
                      errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이메일을 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>
                        전문분야 및 제안 내용을 간단히 작성해주세요!
                      </span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <div className="form-floating">
                      <textarea
                        name="proposal"
                        className="form-control"
                        style={{ height: "100px" }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <div className="form-check">
                      <label className="form-check-label small">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          required
                        />
                        <Link
                          to="/privacy"
                          className="fw-semibold link-decoration"
                          target="_blank"
                        >
                          개인정보취급방침
                        </Link>
                        에 동의합니다.
                      </label>
                      <p style={{ fontSize: "0.75rem" }} className="text-muted">
                        (상담신청을 위하여 남겨주신 개인정보는
                        개인정보취급방침에 의거하여 100% 안전하게 보호됩니다.)
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      width="15"
                      border="none"
                      background="#fef4e5"
                      color="#fbb60d"
                      text="제출"
                      isDisabled={isDisabled}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ModalContainer show={modalShow} onHide={() => setModalShow(false)}>
          <div className="mt-lg-3">
            <div className="my-lg-4 d-flex flex-column align-items-center text-center">
              <div>제출이 완료되었습니다.</div>
              <div className="mb-lg-3 mb-3">
                검토 후 빠르게 연락드리겠습니다
              </div>
              <Button
                width="10"
                text="돌아가기"
                border="none"
                background="#fef4e5"
                color="#fbb60d"
                onClick={() => navigate("/")}
              />
            </div>
          </div>
        </ModalContainer>
        <ModalContainer
          show={isMarketingModalShow}
          content="마케팅 수신 정보 이용 동의"
          onHide={() => setIsMarketingModalShow(false)}
        >
          <p className="small">
            회원님들께 더 나은 서비스를 제공하고자 광고 ∙ 마케팅 목적(이벤트
            안내, 공지사항, 할인행사)의 개인정보 수집 및 이용에 대한 동의를
            받고자 합니다. 내용을 자세히 읽으신 후, 동의를 원하시면 원래의
            화면으로 돌아가 “동의”, “계속”, “동의하고 진행” 등 해당 화면의
            안내에 따라 버튼을 눌러 주시기 바랍니다. 보다 자세한 내용은 회사의
            이용 약관을 참조하여 주시기 바랍니다.
          </p>
          <p className="small mt-lg-3 ps-lg-2 mt-3 px-2">
            • 목적 : BOOSTAR에서 운영하는 서비스에 대한 광고 ∙ 홍보 ∙ 프로모션
            제공 <br />
            • 정보 수신 채널 : 이메일, SMS, 카카오톡 <br />• 개인정보 보유 및
            이용기간 : 광고 ∙ 마케팅 목적의 개인정보 수집 ∙ 이용에 대한 동의
            시부터 동의 철회 시 또는 회원 탈퇴 시까지
            <br />• 광고 ∙ 마케팅 항목은 선택사항이므로 동의를 거부하는 경우에도
            BOOSTAR 서비스의 이용에는 영향이 없습니다. 또한, 광고 ∙ 마케팅
            동의는 고객센터를 통해 언제든지 철회할 수 있습니다.
          </p>
          <div className="d-flex flex-center mt-lg-3 mt-3">
            <Button
              width="13"
              text="닫기"
              border="none"
              background="#fec230"
              color="#000000"
              onClick={() => setIsMarketingModalShow(false)}
            />
          </div>
        </ModalContainer>
      </section>
    </main>
  );
}
