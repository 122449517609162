import React, { useState } from "react";

import Button from "../Button";
import TextInput from "../TextInput";

import countries from "../../util/country.json";

export default function OfficeInformation1({
  applyData,
  isEmpty,
  setIsEmpty,
  handleChange,
  handleChangeStage,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const handleNextButtonClick = () => {
    if (
      !applyData?.location.length ||
      !applyData?.establishmentDate.length ||
      !applyData?.officeDescription.replaceAll(" ", "").length
    ) {
      setErrorMessage("✓ 필수 입력란을 확인해주세요.");
    } else {
      setErrorMessage("");
    }

    if (!applyData?.officeName.replaceAll(" ", "").length) {
      setIsEmpty(true);

      return;
    }

    if (
      applyData?.location.length &&
      applyData?.establishmentDate.length &&
      applyData?.officeDescription.replaceAll(" ", "").length &&
      applyData?.officeName.replaceAll(" ", "").length
    ) {
      handleChangeStage((prevState) => ({
        ...prevState,
        officeInformation1: false,
        officeInformation2: true,
      }));
    }
  };

  return (
    <>
      {" "}
      <div className="my-3">
        <div>
          <p className="mb-0" style={{ marginLeft: "23.5%" }}>
            🌱
          </p>
          <div className="progress height-10">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: "25%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
        <div className="w-lg-100 flex-center my-3">
          <div className="w-lg-50 mt-lg-3">
            <div className="my-lg-3">
              <span style={{ color: "#f44336" }}>▶︎</span>
              <span className="fw-bolder"> 회사 정보</span>
              <p style={{ color: "#f44336" }}>
                ✓ 모든 정보는 영어로 작성해주셔야 합니다. 한글로 작성시 지원은
                무효가 됩니다.
              </p>
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>회사 이름</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <TextInput
                name="officeName"
                type="text"
                placeholder="회사명 (영문)"
                icon="bx-buildings"
                value={applyData?.officeName || ""}
                emptyValidation={isEmpty}
                emptyMessage="✓ 회사명을 영문으로 기입해주세요."
                onChange={handleChange}
              />
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>
                  회사의 간단한 소개(200자 내외)
                </span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <textarea
                name="officeDescription"
                className="w-lg-100 w-100 height-13x mb-lg-1"
                placeholder="200자 내외로 작성해주세요."
                maxLength={200}
                value={applyData?.officeDescription || ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
              />
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>본사 위치</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <select
                name="location"
                value={applyData?.location || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
              >
                <option value="" disabled>
                  본사 위치
                </option>
                {countries.map((country, index) => (
                  <option key={index} value={country.english}>
                    {country.korean}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>설립연도</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <input
                name="establishmentDate"
                type="date"
                icon="bx-envelope"
                value={applyData?.establishmentDate || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
              />
            </div>
            <div className="ms-1 mb-3">
              <small className="text-danger">{errorMessage}</small>
            </div>
            <div className="w-lg-100 w-100 flex-center mt-lg-3">
              <Button
                width="18"
                text="NEXT"
                border="none"
                background="#f44336"
                color="#ffffff"
                onClick={handleNextButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
