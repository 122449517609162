import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import CashReceiptModalCard from "../components/CashReceiptModal";
import Suspender from "../components/EmailSuspender";
import Button from "../components/Button";
import ReviewModal from "../components/ReviewModal";
import ModalContainer from "../components/Modal";
import orderApi from "../api/order";
import resourceApi from "../api/resource";
import { orderState } from "../store/orderState";
import checkRefundDate from "../util/checkRefundDate";

export default function OrderDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [cashReceiptModalShow, setCashReceiptModalShow] = useState(false);
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [isRefundable, setIsRefundable] = useState(false);

  const [orderData, setOrderData] = useRecoilState(orderState);

  const { orderNumber } = useParams();
  const navigate = useNavigate();

  const orderDate = new Date(orderData?.approved_at);
  const canceledDate = new Date(orderData?.canceled_at);

  useEffect(() => {
    const getOrderData = async () => {
      try {
        const result = await orderApi.getOrderDetail(orderNumber);

        if (result.result === "success") {
          setOrderData(result.data.orderInfo);

          const isRefundableDate = checkRefundDate(
            result.data.orderInfo.approved_at
          );

          setIsRefundable(isRefundableDate);
        }
      } catch (err) {
        alert("에러가 발생했습니다. 잠시 후에 다시 이용 부탁드립니다.");
      }
    };

    getOrderData();
  }, []);

  const handleReceiptButtonClick = (event) => {
    event.preventDefault();

    window.open(
      orderData?.receipt,
      "_blank",
      "width=600, height=700, left=150, top=150"
    );
  };

  const handleDownloadButtonClick = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const response = await resourceApi.postDownloadEbook({
      name: orderData?.customer_name,
      email: orderData?.customer_email,
    });

    if (response.result === "success") {
      try {
        setIsLoading(false);
        setModalShow(true);
      } catch (err) {
        if (err.response.data.error.message === "Failed to send mail") {
          alert(
            "PDF 파일 다운로드 신청에 문제가 생겼습니다. 다시 한 번 신청해주세요."
          );
        }
      }
    }
  };

  const handleCashReceiptButtonClick = (event) => {
    event.preventDefault();

    if (orderData?.cash_receipt !== "null") {
      window.open(
        orderData?.cash_receipt,
        "_blank",
        "width=600, height=700, left=150, top=150"
      );
    }

    if (orderData?.cash_receipt === "null") {
      setCashReceiptModalShow(true);
    }
  };

  const handleBackButtonClick = (event) => {
    event.preventDefault();

    navigate("/");
  };

  return (
    <main className="position-relative">
      {isLoading ? (
        <Suspender title="메일을 전송하고 있습니다" />
      ) : (
        <div className="container py-lg-10">
          <div className="mt-lg-4 mb-lg-8 my-3">
            <h4
              className="pb-lg-3 pb-3"
              style={{ borderBottom: "3px solid black" }}
            >
              주문 상세
            </h4>
            <div className="my-lg-3 my-3 ms-lg-2">
              <span className="fw-bold">{orderDate?.toLocaleDateString()}</span>
              <span className="text-muted">
                &nbsp;&nbsp;&nbsp;주문번호 {orderData?.order_number}
              </span>
            </div>
            <div
              style={{ border: "1px solid #e8e8e8", borderRadius: "10px" }}
              className="d-flex justify-content-between py-lg-3 py-3 px-lg-5 px-2"
            >
              <div className="flex-center">
                <span>
                  [{orderData?.service_type}] {orderData?.service_name}
                </span>
              </div>
              <div className="">
                {orderData?.service_type === "Ebook" &&
                  orderData?.status === "DONE" && (
                    <div className="my-1">
                      <Button
                        width="8"
                        text="다운받기"
                        border="none"
                        background="#fef4e5"
                        color="#fbb60d"
                        onClick={handleDownloadButtonClick}
                      />
                    </div>
                  )}
                {orderData?.status === "DONE" &&
                  orderData?.review === 0 &&
                  orderData?.service_type !== "유료강의" &&
                  orderData?.service_type !== "무료강의" && (
                    <div className="my-1">
                      <Button
                        width="8"
                        text="리뷰쓰기"
                        border="2px solid #fef4e5"
                        background="none"
                        color="#fbb60d"
                        onClick={() => setReviewModalShow(true)}
                      />
                    </div>
                  )}
                {isRefundable && orderData?.status === "DONE" && (
                  <Button
                    width="8"
                    text="환불요청"
                    border="none"
                    background="#f4f4f5"
                    color="#797979"
                    onClick={() =>
                      navigate(`/order/cancel/${orderData?.order_number}`, {
                        state: {
                          serviceType: orderData?.service_type,
                        },
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-lg-4 mb-lg-8 my-3">
            <h4
              className="mb-lg-4 mb-3 pb-lg-3 pb-3"
              style={{ borderBottom: "3px solid black" }}
            >
              구매자 정보
            </h4>
            <div
              style={{ border: "1px solid #e8e8e8", borderRadius: "10px" }}
              className="py-lg-3 px-lg-5"
            >
              <div className="row align-items-center py-lg-2 py-2 px-2">
                <div className="col-lg-2 col-5">주문자</div>
                <div className="col-lg-10 col-7">
                  {orderData?.customer_name}
                </div>
                <div className="col-lg-2 col-5">이메일</div>
                <div className="col-lg-10 col-7">
                  {orderData?.customer_email}
                </div>
                <div className="col-lg-2 col-5">휴대폰 번호</div>
                <div className="col-lg-10 col-7">
                  {orderData?.customer_phone}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-lg-4 mb-lg-8 my-3">
            <h4
              className="mb-lg-4 mb-3 pb-lg-3 pb-3"
              style={{ borderBottom: "3px solid black" }}
            >
              결제 정보
            </h4>
            <div
              style={{ border: "1px solid #e8e8e8", borderRadius: "10px" }}
              className="py-lg-3 py-2 px-lg-5 px-2"
            >
              <div className="row align-items-center py-lg-2">
                <div className="col-lg-2 col-5">결제 금액</div>
                <div className="col-lg-10 col-7">
                  {Number(orderData?.amount).toLocaleString("ko-KR")} 원
                </div>
                <div className="col-lg-2 col-5">결제 수단</div>
                {orderData?.method === "카드" ||
                orderData?.method === "간편결제" ? (
                  <div className="col-lg-10 col-7">
                    {orderData?.pay_type} /&nbsp;
                    {orderData.installment_plan_month === "undefined" ||
                    !orderData.installment_plan_month
                      ? "일시불"
                      : `${orderData?.installment_plan_month} 개월`}
                  </div>
                ) : (
                  <div className="col-lg-10 col-7">{orderData?.pay_type}</div>
                )}
                <div className="col-lg-2 col-5">결제 일시</div>
                <div className="col-lg-10 col-7">
                  {orderDate.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          {orderData?.status === "CANCELED" && (
            <div className="mt-lg-4 mb-lg-8 my-3">
              <h4
                className="mb-lg-4 mb-3 pb-lg-3 pb-3"
                style={{ borderBottom: "3px solid black" }}
              >
                환불 정보
              </h4>
              <div
                style={{ border: "1px solid #e8e8e8", borderRadius: "10px" }}
                className="py-lg-3 py-2 px-lg-5 px-2"
              >
                <div className="row align-items-center py-lg-2">
                  <div className="col-lg-2 col-5">환불 금액</div>
                  <div className="col-lg-10 col-7">
                    {Number(orderData?.cancel_amount).toLocaleString("ko-KR")}{" "}
                    원
                  </div>
                  <div className="col-lg-2 col-5">취소 사유</div>
                  <div className="col-lg-10 col-7">
                    {orderData?.cancel_reason}
                  </div>
                  <div className="col-lg-2 col-5">취소 일시</div>
                  <div className="col-lg-10 col-7">
                    {canceledDate.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end pe-lg-3 mb-3">
            <Button
              width="8"
              background="#f4f4f5"
              color="#797979"
              border="none"
              text="돌아가기"
              onClick={handleBackButtonClick}
            />
            {orderData?.method === "계좌이체" &&
              orderData?.status === "DONE" && (
                <Button
                  width="8"
                  text="현금영수증"
                  border="none"
                  background="#fef4e5"
                  color="#fbb60d"
                  onClick={handleCashReceiptButtonClick}
                />
              )}
            {orderData?.status === "DONE" && (
              <Button
                width="8"
                text="영수증"
                border="none"
                background="#fef4e5"
                color="#fbb60d"
                onClick={handleReceiptButtonClick}
              />
            )}
          </div>
        </div>
      )}
      <ModalContainer
        show={modalShow}
        content="가입해주신 이메일로 가이드북이 발송되었습니다."
        buttonTitle="닫기"
        onHide={() => setModalShow(false)}
      >
        <button
          className="btn btn-dark width-13x mt-lg-3 mb-lg-2"
          onClick={() => setModalShow(false)}
        >
          닫기
        </button>
      </ModalContainer>
      <ModalContainer
        show={cashReceiptModalShow}
        content="현금영수증 신청 정보 변경"
      >
        <CashReceiptModalCard
          orderData={orderData}
          onHide={setCashReceiptModalShow}
        />
      </ModalContainer>
      <ReviewModal
        orderInfo={orderData}
        show={reviewModalShow}
        onHide={() => setReviewModalShow(false)}
      />
    </main>
  );
}
