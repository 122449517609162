import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import ModalContainer from "../components/Modal";
import Button from "../components/Button";
import Suspender from "../components/EmailSuspender";
import { userInfoState } from "../store/userState";
import resourceApi from "../api/resource";

export default function OrderSuccess() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modal, setModal] = useState({
    title: "",
    content: "",
    buttonTitle: "",
  });

  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  const location = useLocation();
  const navigate = useNavigate();
  const { orderNumber } = useParams();

  const handleDownloadButtonClick = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    const response = await resourceApi.postDownloadEbook({
      name: userInfo.name,
      email: userInfo.email,
    });

    if (response.result === "success") {
      try {
        setIsLoading(false);
        setModalShow(true);
        setModal((prevState) => ({
          ...prevState,
          content: "가입해주신 이메일로 가이드북이 발송되었습니다.",
          buttonTitle: "돌아가기",
        }));
      } catch (err) {
        if (err.response.data.error.message === "Failed to send mail") {
          alert(
            "PDF 파일 다운로드 신청에 문제가 생겼습니다. 다시 한 번 신청해주세요."
          );
        }
      }
    }
  };

  const handleOrderDetailButtonClick = () => {
    navigate(`/order/detail/${orderNumber}`);
  };

  return (
    <main className="position-relative text-dark">
      {isLoading ? (
        <Suspender title="메일을 전송하고 있습니다" />
      ) : (
        <>
          <div className="container py-lg-10 py-8">
            <div className="d-flex flex-column align-items-center py-lg-10">
              <div
                className="col-lg-8 d-flex flex-column align-items-center px-3"
                style={{ border: "1px solid #e4e4e4", borderRadius: "10px" }}
              >
                <h4 className="mt-lg-10 mb-lg-5 mt-5 mb-3">
                  주문이 정상적으로 완료되었습니다.
                </h4>
                <p className="fs-5" style={{ borderBottom: "1px solid black" }}>
                  주문번호: {orderNumber}
                </p>
                {location.state.serviceType === "Ebbok" && (
                  <p className="small px-9">
                    &apos;마이페이지 &gt;&gt; 결제내역&apos; 에서도 구매하신
                    상품을 다운로드 받으실 수 있습니다.
                  </p>
                )}
                <div className="d-inline mb-lg-10 mt-lg-5 mt-3 mb-5">
                  {location.state.serviceType === "Ebook" && (
                    <Button
                      width="9"
                      text="다운로드"
                      background="#fef4e5"
                      color="#fbb60d"
                      border="none"
                      onClick={handleDownloadButtonClick}
                    />
                  )}
                  <Button
                    width="9"
                    text="결제내역 확인"
                    background="#f4f4f5"
                    color="#797979"
                    border="none"
                    onClick={handleOrderDetailButtonClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <ModalContainer
            show={modalShow}
            title={modal.title}
            content={modal.content}
            onHide={() => setModalShow(false)}
          >
            <div className="mt-lg-3 mb-lg-2">
              <Button
                width="10"
                text={modal.buttonTitle}
                border="none"
                background="#f4f4f5"
                color="#797979"
                onClick={() => navigate("/")}
              />
            </div>
          </ModalContainer>
        </>
      )}
    </main>
  );
}
