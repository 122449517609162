export default function checkBusinessNumber(number) {
  const regKey = [1, 3, 7, 1, 3, 7, 1, 3, 5];
  const businessArray = number.replaceAll("-", "").split("");
  let sumRegNumber = 0;

  for (let i = 0; i < regKey.length; i++) {
    sumRegNumber += regKey[i] * businessArray[i];
  }

  sumRegNumber += parseInt((regKey[8] * businessArray[8]) / 10, 10);

  const isChecked =
    Math.floor(businessArray[9]) === (10 - (sumRegNumber % 10)) % 10;

  return isChecked;
}
