import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "../components/Button";
import ModalContainer from "../components/Modal";
import orderApi from "../api/order";
import { userInfoState } from "../store/userState";
import { tossPayments } from "../configs/tossConfig";

import classIcon from "../assets/img/graphics/icons/class.png";
import classApi from "../api/class";

export default function ClassPay() {
  const [modalShow, setModalShow] = useState(false);
  const [isSuccessModalShow, setIsSuccessModalShow] = useState(false);
  const [isPay, setIsPay] = useState(false);

  const payInfo = JSON.parse(localStorage.getItem("payInfo"));
  const token = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(token));
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const amount = params.get("amount");
  const orderId = params.get("orderId");
  const paymentKey = params.get("paymentKey");
  const createdOrderId = Math.random().toString(36).slice(2);
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (1 + date.getMonth())).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const randomNumber = Math.floor(Math.random() * 1000000);

  const handlePayButtonClick = async (event) => {
    event.preventDefault();
    if (!userInfo) {
      localStorage.removeItem("accessToken");
      setModalShow(true);

      return;
    }

    if (userInfo?.id) {
      if (event.target.payment.value === "카드") {
        try {
          await tossPayments.requestPayment("카드", {
            amount: payInfo.classInfo.cost,
            orderId: createdOrderId,
            orderName: payInfo.classInfo.title,
            customerName: userInfo.name,
            successUrl:
              process.env.NODE_ENV === "production"
                ? `https://boostar.co.kr/pay/class/${payInfo.applyIdx}`
                : `${window.location.origin}/pay/class/${payInfo.applyIdx}`,
            failUrl:
              process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_ERROR_SERVER_URL
                : process.env.REACT_APP_ERROR_URL,
          });
        } catch (err) {
          if (err.message === "orderName: 상품 명은 필수 입니다.") {
            alert("결제하실 상품을 선택해주세요.");

            return;
          }

          alert(err.message);
        }
      }

      if (event.target.payment.value === "계좌이체") {
        try {
          await tossPayments.requestPayment("계좌이체", {
            amount: payInfo.classInfo.cost,
            orderId: createdOrderId,
            orderName: payInfo.classInfo.title,
            successUrl:
              process.env.NODE_ENV === "production"
                ? `https://boostar.co.kr/pay/class/${payInfo.applyIdx}`
                : `${window.location.origin}/pay/class/${payInfo.applyIdx}`,
            failUrl:
              process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_ERROR_SERVER_URL
                : process.env.REACT_APP_ERROR_URL,
          });
        } catch (err) {
          alert(err.message);
        }
      }
    } else {
      setModalShow(true);
    }
  };

  const approvePayment = (async () => {
    if (amount && !isPay) {
      try {
        const response = await orderApi.payment({
          orderId,
          paymentKey,
          amount,
          orderNumber: year + month + day + randomNumber,
          serviceType: "유료강의",
          serviceName: payInfo.classInfo.title,
          productCode: `B-23L-PASL-${payInfo.classId}`,
          customer: userInfo,
        });

        if (response.result === "success") {
          setIsPay(true);

          try {
            const updateApply = await classApi.updateClassApply({
              applyIdx: payInfo.applyIdx,
              orderIdx: response.data.orderIdx,
              classCost: payInfo.classInfo.cost,
              isPaid: payInfo.classInfo.isPaid,
            });

            if (updateApply.result === "success") {
              setIsSuccessModalShow(true);

              return;
            }
          } catch (err) {
            alert(
              "강의신청하는데 오류가 생겼습니다. 잠시 후 다시 이용부탁드립니다."
            );

            return;
          }
        }
      } catch (err) {
        if (err.response.data.error.message === "이미 처리된 결제 입니다.") {
          alert("이미 처리된 결제입니다.");

          return;
        }

        alert("결제중 에러가 발생했습니다. 잠시 후 다시 이용바랍니다.");
      }
    }
  })();

  return (
    <main>
      <section className="position-relative">
        <div className="container py-lg-6 px-lg-11">
          <div className="row">
            <div className="col-lg-6 me-auto mb-5 justify-content-center">
              <div className="px-lg-3 px-3">
                <img src={classIcon} className="w-lg-20 w-30" />
                <h4 className="mb-lg-7 mb-5">
                  BOOSTAR 에서
                  <br /> 드리는 특별한 강의
                </h4>
              </div>
              <div
                className="mx-lg-1 mx-3 mt-lg-6 mt-4 pt-lg-6 pt-4"
                style={{ borderTop: "1px solid #cfcfcf" }}
              >
                👉 <span className="fw-bold">강의 주제:</span>{" "}
                {payInfo.classInfo.title}
                <br />
                👉 <span className="fw-bold">강연자:</span>{" "}
                {payInfo.classInfo.lecturer}
                <br />
                👉 <span className="fw-bold">진행 일시:</span>{" "}
                {payInfo.classInfo.lectureDate}
                (강의 종료 후 Q&A 진행)
                <br />
                <div className="d-flex">
                  👉 <span className="fw-bold ms-1">진행 장소:</span>{" "}
                  <span className="ms-1">
                    {payInfo.classInfo.place.split("/").map((data, index) => (
                      <p key={index}> {data}</p>
                    ))}
                  </span>
                </div>
                {payInfo.classInfo.isPaid === "Y" && (
                  <>
                    {" "}
                    👉 <span className="fw-bold">수강료:</span>{" "}
                    {payInfo.classInfo.cost} 원
                  </>
                )}
                <p className="small">
                  <br />
                  ✔️ 환불규정
                  <br />-{" "}
                  <span className="fw-bolder">수업을 시작하기 24시간 이전</span>
                  에 취소 요청을 하는 경우에는 결제대금이 100% 환불됩니다.{" "}
                  <br />-{" "}
                  <span className="fw-bolder">수업 시작 24시간 이내</span>에
                  취소 의사를 밝히거나 강의가 종료된 이후에는 결제 금액은 전액
                  환불되지 않습니다. 해당 금액은 강의 연구 및 장소 대여,
                  참여인원 미확보에 대한 명목으로 BOOSTAR와 강사에게 귀속됩니다.
                  <br />- 단, 다음과 같은 사유 등으로 인해 강의가 폐강될 수
                  있으며, 폐강 시 아래 규정에 따라 반환 사유 발생일로부터 5
                  영업일 이내에 수강료를 환불해드립니다.
                  <br /> ① 모집된 수강인원이 10명 이하일 경우 <br />② 강사의
                  갑작스러운 사고 및 건강 상의 이유 <br />③ 천재지변
                  <br />
                  <p className="ms-lg-3 ms-3">
                    모객 부진으로 폐강 시에는 최소 개강일로부터 5일 전(영업일
                    기준)에는 폐강 여부를 안내해드립니다.
                  </p>
                </p>
                <p className="small mt-lg-2" style={{ color: "#ff3d0d" }}>
                  ✔️ 수강 선정 후 무단 불참하시는 경우, 다음 강의 참가가
                  제한됩니다. 신청하시기 전 일정을 반드시 확인해주세요.
                </p>
              </div>
            </div>
            <div className="col-lg-6 px-lg-8 py-4">
              <div
                style={{
                  border: "1px solid #c8c8c8",
                  borderRadius: "5px",
                }}
              >
                <div className="px-lg-3 px-3 py-lg-4 py-4 fs-5 fw-bold">
                  결제 금액
                </div>
                <div className="d-flex justify-content-between px-lg-3 px-3">
                  <p className="text-muted fw-bold">총 상품 금액</p>
                  <p className="fw-bold">
                    {Number(payInfo.classInfo.cost).toLocaleString("ko-KR")} 원
                  </p>
                </div>
                <div className="d-flex justify-content-between px-lg-3 px-3 py-lg-1">
                  <p className="text-muted fw-bold">할인 금액</p>
                  <p className="fw-bold">0 원</p>
                </div>
                <div className="d-flex justify-content-between px-lg-3 px-3 py-lg-1">
                  <p className="text-muted fw-bold">쿠폰 사용</p>
                  <p className="fw-bold">0 원</p>
                </div>
                <div className="d-flex justify-content-between px-lg-3 px-3 py-lg-1">
                  <p className="text-muted fw-bold">포인트 사용</p>
                  <p className="fw-bold">0 원</p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between px-lg-3 px-3 py-lg-3 py-3 mx-lg-3 mx-3"
                  style={{ borderTop: "1px solid #c8c8c8" }}
                >
                  <p className="fs-5 fw-bold mb-0">최종 결제 금액</p>
                  <p className="fw-bold mb-0">
                    {Number(payInfo.classInfo.cost).toLocaleString("ko-KR")} 원
                  </p>
                </div>
                <div
                  className="d-flex justify-content-center pt-lg-3 mb-lg-5 mb-5 mx-lg-3 mx-3"
                  style={{ borderTop: "1px solid #c8c8c8" }}
                >
                  <form onSubmit={handlePayButtonClick}>
                    <div className="d-flex flex-center mb-lg-2 my-3">
                      <label className="mx-lg-4 mx-4">
                        <input type="radio" name="payment" value="카드" />
                        신용카드
                      </label>
                      <label className="mx-lg-4 mx-4">
                        <input type="radio" name="payment" value="계좌이체" />
                        계좌이체
                      </label>
                    </div>
                    <div className="form-check">
                      <label
                        className="form-check-label small"
                        htmlFor="flexCheckDefault"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="이용약관"
                          required
                        />
                        강의 정보를 확인했으며, 결제에 동의합니다. (필수) &nbsp;
                      </label>
                    </div>
                    <div className="d-flex flex-column flex-center mt-lg-2 mt-3">
                      <div className="my-lg-1 my-1">
                        <Button
                          width="18"
                          border="none"
                          text="결제하기"
                          background="#fec230"
                          color="#000000"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <ModalContainer
              show={modalShow}
              onHide={() => setModalShow(false)}
              content="서비스를 구매하시려면 로그인을 해주세요 🙂"
            >
              <div className="mt-lg-3">
                <Button
                  width="10"
                  text="확인"
                  border="none"
                  background="#fef4e5"
                  color="#fbb60d"
                  onClick={() => navigate("/login?retUrl=" + location.pathname)}
                />
              </div>
            </ModalContainer>
          </div>
        </div>
        <ModalContainer
          show={isSuccessModalShow}
          onHide={() => setIsSuccessModalShow(false)}
        >
          <div className="my-lg-4 d-flex flex-column align-items-center">
            <div>신청해주셔서 감사합니다.</div>
            <div className="mb-lg-3">
              사업에 도움이 되는 좋은 강의 준비하겠습니다 🙂
            </div>
            <Button
              width="8"
              background="#fef4e5"
              color="#fbb60d"
              text="확인"
              border="none"
              onClick={() => {
                localStorage.removeItem("payInfo");
                navigate("/class");
              }}
            />
          </div>
        </ModalContainer>
      </section>
    </main>
  );
}
