import React, { useCallback, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import GoogleLogin from "../components/GoogleLogin";
import NaverLogin from "../components/NaverLogin";
import TextInput from "../components/TextInput";
import KakaoLogin from "../components/KakaoLogin";
import userApi from "../api/user";
import { EMAIL_REGEXP, PASSWORD_REGEXP } from "../constants/RegExp";

export default function Login() {
  const [isEmpty, setEmpty] = useState(false);
  const [loginFailMessage, setLoginFailMessage] = useState("");
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  // 리턴 url이 있는 경우
  let retUrl = "";

  if (localStorage.getItem("accessToken")) {
    window.location.href = "/";
  }

  if (location.search.indexOf("retUrl") > 0) {
    retUrl = location.search.split("retUrl=")[1];
    localStorage.setItem("retUrl", retUrl);
  }

  const isValidEmail = EMAIL_REGEXP.test(userData.email);
  const isValidPassword = PASSWORD_REGEXP.test(userData.password);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setLoginFailMessage("");

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  });

  const handleLoginButtonClick = async (event) => {
    event.preventDefault();

    if (!userData.email || !userData.password) {
      return setEmpty(true);
    }

    if (isValidEmail && isValidPassword) {
      try {
        const response = await userApi.login({
          email: userData.email,
          password: userData.password,
        });

        if (response.result === "success") {
          const accessToken = response.data.accessToken;

          localStorage.setItem("accessToken", accessToken);

          if (retUrl === "") {
            navigate("/");
          } else {
            localStorage.removeItem("retUrl");
            navigate(retUrl);
          }
        }
      } catch (err) {
        if (err.response.data.error.message === "Not user") {
          setLoginFailMessage(
            "✓ 입력하신 정보와 일치하는 계정이 없습니다. 다시 한 번 확인해주세요."
          );
        }

        if (err.response.data.error.message === "Wrong password") {
          setLoginFailMessage(
            "✓ 비밀번호가 일치하지 않습니다. 다시 한 번 확인해주세요."
          );
        }
      }
    }
  };

  return (
    <main>
      <section className="position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-4 col-11 my-lg-10 my-10 py-lg-9 py-7 px-lg-6 px-4 shadow"
              style={{ borderRadius: "55px" }}
            >
              <form
                className="needs-validation"
                noValidate
                onSubmit={handleLoginButtonClick}
              >
                <TextInput
                  name="email"
                  type="text"
                  placeholder="user@boostar.co.kr"
                  icon="bx-envelope"
                  value={userData.email || ""}
                  pattern={EMAIL_REGEXP}
                  errorMessage="✓ 올바른 이메일 형식이 아닙니다."
                  emptyValidation={isEmpty}
                  emptyMessage="✓ 이메일을 확인해주세요."
                  onChange={handleChange}
                />
                <TextInput
                  name="password"
                  type="password"
                  placeholder="********"
                  icon="bx-lock-open"
                  value={userData.password || ""}
                  pattern={PASSWORD_REGEXP}
                  errorMessage="✓ 영문 + 숫자 + 특수문자, 8~16자리로 입력해주세요."
                  emptyValidation={isEmpty}
                  emptyMessage="✓ 비밀번호를 확인해주세요."
                  onChange={handleChange}
                />
                <div className="mb-5">
                  <small className="text-danger">{loginFailMessage}</small>
                </div>
                <div className="mb-3 text-end pe-lg-2">
                  <div>
                    <label className="text-end d-block small mb-0">
                      <a
                        href="/find_password"
                        className="text-muted link-decoration"
                      >
                        비밀번호 찾기
                      </a>
                    </label>
                  </div>
                </div>
                <div className="d-grid" style={{ justifyItems: "center" }}>
                  <button
                    className="btn btn-secondary mx-lg-2 w-lg-100 w-100 fw-bold"
                    type="submit"
                    style={{
                      border: "none",
                      background: "#fef4e5",
                      color: "#fbb60d",
                    }}
                  >
                    로그인
                  </button>
                </div>
              </form>
              <div className="d-flex align-items-center py-3">
                <span className="flex-grow-1 border-bottom pt-1"></span>
                <span className="d-inline-flex flex-center lh-1 width-2x height-2x rounded-circle bg-white text-mono">
                  or
                </span>
                <span className="flex-grow-1 border-bottom pt-1"></span>
              </div>
              <div className="d-grid" style={{ justifyItems: "center" }}>
                <KakaoLogin />
                <NaverLogin />
                <GoogleLogin />
              </div>
              <p className="pt-4 small text-muted">
                아직 계정이 없으신가요?
                <Link
                  to="/signup"
                  className="ms-2 text-dark fw-semibold link-underline"
                >
                  회원가입
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
