import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import TextInput from "../TextInput";
import Button from "../Button";
import ModalContainer from "../Modal";
import applyApi from "../../api/apply";
import { myApplicationState } from "../../store/userState";
import { EMAIL_REGEXP, PHONE_REGEXP } from "../../constants/RegExp";

export default function UpdateApplication() {
  const myApplicationData = useRecoilValue(myApplicationState);

  const [errorMessage, setErrorMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [applyData, setApplyData] = useState({
    name: myApplicationData?.full_name,
    gender: myApplicationData?.gender,
    email: myApplicationData?.email,
    birth: myApplicationData?.date_of_birth,
    phone: myApplicationData?.phone.replaceAll("-", ""),
    currentTitle: myApplicationData?.current_title,
    officeName: myApplicationData?.company_name,
    officeDescription: myApplicationData?.company_short_desc,
    location: myApplicationData?.hq_location,
    establishmentDate: myApplicationData?.est_date,
    businessModel: myApplicationData?.bm,
    developmentStage: myApplicationData?.dev_stage,
    mainIndustry: myApplicationData?.main_industry,
    mainTechnology: myApplicationData?.main_technology,
    pitchDeckLink: myApplicationData?.pitch_deck,
    pitchingVideoLink: myApplicationData?.pitch_video_link,
    certification: myApplicationData?.business_certification,
    patent: myApplicationData?.is_patent,
    totalFund: myApplicationData?.total_fund,
    fundingRound: myApplicationData?.most_recently_funding_round,
    fundingAmount: myApplicationData?.funding_amount_of_round,
    numberOfFounders: myApplicationData?.number_of_founders,
    foundersProfile: myApplicationData?.founders_profile,
    vietnamTeam: myApplicationData?.is_team_in_vietnam,
    howToStartupWheel: myApplicationData?.where_did_you_here,
    howToBoostar: myApplicationData?.how_know_boostar,
  });

  const navigate = useNavigate();

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setApplyData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleUpdateButtonClick = async () => {
    const isValidPhone = PHONE_REGEXP.test(applyData?.phone);

    if (
      !applyData?.foundersProfile.replaceAll(" ", "").length ||
      applyData?.phone.replaceAll("-", "").length < 11 ||
      !applyData?.officeDescription.replaceAll(" ", "").length
    ) {
      setErrorMessage("✓ 필수 입력란을 확인해주세요.");

      return;
    } else {
      setErrorMessage("");
    }
    if (
      !applyData?.email.replaceAll(" ", "").length ||
      !applyData?.phone.replaceAll(" ", "").length ||
      !applyData?.currentTitle.replaceAll(" ", "").length ||
      !applyData?.pitchDeckLink.replaceAll(" ", "").length ||
      !applyData?.totalFund.replaceAll(" ", "").length ||
      !applyData?.fundingAmount.replaceAll(" ", "").length
    ) {
      setIsEmpty(true);

      return;
    }

    if (
      applyData?.email.replaceAll(" ", "").length &&
      isValidPhone &&
      applyData?.currentTitle.replaceAll(" ", "").length &&
      applyData?.officeDescription.replaceAll(" ", "").length &&
      applyData?.pitchDeckLink.replaceAll(" ", "").length &&
      applyData?.totalFund.replaceAll(" ", "").length &&
      applyData?.fundingAmount.replaceAll(" ", "").length &&
      applyData?.foundersProfile.length
    )
      try {
        const response = await applyApi.updateApplication({
          user_idx: myApplicationData?.user_idx,
          full_name: applyData.name,
          gender: applyData.gender,
          email: applyData.email,
          date_of_birth: applyData.birth,
          phone: applyData.phone.replaceAll("-", ""),
          current_title: applyData.currentTitle,
          company_name: applyData.officeName,
          company_short_desc: applyData.officeDescription,
          hq_location: applyData.location,
          est_date: applyData.establishmentDate,
          bm: applyData.businessModel,
          dev_stage: applyData.developmentStage,
          main_industry: applyData.mainIndustry,
          main_technology: applyData.mainTechnology,
          pitch_deck: applyData.pitchDeckLink,
          pitch_video_link: applyData.pitchingVideoLink,
          business_certification: applyData.certification,
          is_patent: applyData.patent,
          total_fund: applyData.totalFund,
          most_recently_funding_round: applyData.fundingRound,
          funding_amount_of_round: applyData.fundingAmount,
          number_of_founders: applyData.numberOfFounders,
          founders_profile: applyData.foundersProfile,
          is_team_in_vietnam: applyData.vietnamTeam,
          where_did_you_here: applyData.howToStartupWheel,
          how_know_boostar: applyData.howToBoostar,
        });

        if (response.result === "success") {
          setIsModalShow(true);
        }
      } catch (err) {
        alert(
          "신청서를 수정하면서 오류가 발생했습니다. 잠시 후 다시 이용바랍니다."
        );
      }
  };

  return (
    <section>
      <div className="container d-flex flex-center flex-column">
        <div className="mx-lg-5 w-lg-90">
          <div className="fw-bolder mb-lg-4 mt-6" style={{ color: "#2196F3" }}>
            My Application
          </div>
          <div>
            <div className="my-3">
              <div className="w-lg-100 flex-center my-3">
                <div className="w-lg-50 mt-lg-3">
                  <p style={{ color: "#f44336" }}>
                    ✓ 모든 정보는 영어로 작성해주셔야 합니다. 한글로 작성시
                    지원은 무효가 됩니다.
                  </p>
                  <div className="my-lg-3">
                    <span style={{ color: "#f44336" }}>▶︎</span>
                    <span className="fw-bolder"> 개인정보</span>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>이름</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.name}</p>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>성별</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.gender}</p>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>이메일</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="email"
                      type="text"
                      placeholder="user@boostar.co.kr"
                      icon="bx-envelope"
                      value={applyData.email || ""}
                      pattern={EMAIL_REGEXP}
                      errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이메일을 입력해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>생일</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">
                      {applyData?.birth.replaceAll("-", ".")}
                    </p>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>연락처</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="phone"
                      placeholder="01012345678"
                      icon="bx-phone"
                      value={applyData.phone || ""}
                      maxLength={13}
                      pattern={PHONE_REGEXP}
                      errorMessage="✓ 핸드폰번호를 확인해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 핸드폰번호를 정확하게 입력해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-0">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>직함</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                      <span className="small"> (CEO, CTO, 매니저... 등)</span>
                    </div>
                    <TextInput
                      name="currentTitle"
                      type="text"
                      placeholder="CEO"
                      icon="bx-id-card"
                      value={applyData.currentTitle || ""}
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 현재 본인의 직합을 기입해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="my-lg-3">
                    <span style={{ color: "#f44336" }}>▶︎</span>
                    <span className="fw-bolder"> 회사 정보</span>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>회사 이름</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.officeName}</p>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>
                        회사의 간단한 소개(200자 내외)
                      </span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <textarea
                      name="officeDescription"
                      className="w-lg-100 w-100 height-13x mb-lg-1"
                      placeholder="200자 내외로 작성해주세요."
                      maxLength={200}
                      value={applyData?.officeDescription || ""}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>본사 위치</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.location}</p>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>설립연도</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">
                      {applyData?.establishmentDate.replaceAll("-", ".")}
                    </p>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>비즈니스 모델</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.businessModel}</p>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>개발 단계</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <select
                      name="developmentStage"
                      value={applyData?.developmentStage || ""}
                      style={{
                        width: "100%",
                        height: "3rem",
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        개발 단계
                      </option>
                      <option value="Prototype/MVP">Prototype/MVP</option>
                      <option value="Roll out">Roll out</option>
                      <option value="Rapid growth">Rapid growth</option>
                      <option value="Expansion">Expansion</option>
                      <option value="Maturity">Maturity</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>주요 산업</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.mainIndustry}</p>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>주요 기술</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <select
                      name="mainTechnology"
                      value={applyData?.mainTechnology || ""}
                      style={{
                        width: "100%",
                        height: "3rem",
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        주요 기술
                      </option>
                      <option value="None">None</option>
                      <option value="3D printing">3D printing</option>
                      <option value="5G">5G</option>
                      <option value="APIs & Enablers">APIs & Enablers</option>
                      <option value="Artificial Intelligence">
                        Artificial Intelligence
                      </option>
                      <option value="Augmented Reality">
                        Augmented Reality
                      </option>
                      <option value="Autonomous Vehicles">
                        Autonomous Vehicles
                      </option>
                      <option value="Beacons & Bluetooth">
                        Beacons & Bluetooth
                      </option>
                      <option value="Big Data">Big Data</option>
                      <option value="Bioinformatics">Bioinformatics</option>
                      <option value="Biotech">Biotech</option>
                      <option value="Blockchain">Blockchain</option>
                      <option value="Chemical Engineering">
                        Chemical Engineering
                      </option>
                      <option value="Cloud">Cloud</option>
                      <option value="Electronics, Digital & Apps">
                        Electronics, Digital & Apps
                      </option>
                      <option value="Gene Technology">Gene Technology</option>
                      <option value="Geolocation & Mapping">
                        Geolocation & Mapping
                      </option>
                      <option value="Human-Computer Interaction">
                        Human-Computer Interaction
                      </option>
                      <option value="Image & Face Recognition">
                        Image & Face Recognition
                      </option>
                      <option value="IoT/IoE & Sensors">
                        IoT/IoE & Sensors
                      </option>
                      <option value="Language Recognition & Translation">
                        Language Recognition & Translation
                      </option>
                      <option value="Machine Learning">Machine Learning</option>
                      <option value="Mechatronics">mechatronics</option>
                      <option value="Medical Technologies">
                        Medical Technologies
                      </option>
                      <option value="Nanotech">Nanotech</option>
                      <option value="Photonics">Photonics</option>
                      <option value="Quantum Computing">
                        Quantum Computing
                      </option>
                      <option value="Virtual Reality">Virtual Reality</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>
                        Pitch Deck/비즈니스 프레젠테이션 링크
                      </span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                      <p className="small mb-0 ms-1">
                        링크주소를 기입해주시면 됩니다.
                      </p>
                    </div>
                    <TextInput
                      name="pitchDeckLink"
                      type="text"
                      placeholder="link.com"
                      icon="bx-link"
                      value={applyData?.pitchDeckLink || ""}
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 링크 주소를 기입해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>
                        Pitching 영상 링크
                      </span>
                      <p className="small mb-0 ms-1">
                        영상이 있으시다면 링크주소를 기입해주시면 됩니다.
                      </p>
                    </div>
                    <TextInput
                      name="pitchingVideoLink"
                      type="text"
                      placeholder="link.com"
                      icon="bx-link"
                      value={applyData?.pitchingVideoLink || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>
                        사업자 등록증 링크
                      </span>
                      <p className="small mb-0 ms-1">
                        등록증이 있으시다면 링크주소를 기입해주시면 됩니다.
                      </p>
                    </div>
                    <TextInput
                      name="certification"
                      type="text"
                      placeholder="link.com"
                      icon="bx-link"
                      value={applyData?.certification || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>특허권 여부</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <select
                      name="patent"
                      value={applyData?.patent || ""}
                      style={{
                        width: "100%",
                        height: "3rem",
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        YES OR NO
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>총 자본금</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                      <span className="small"> (USD 기준)</span>
                    </div>
                    <TextInput
                      name="totalFund"
                      type="text"
                      placeholder="100000"
                      icon="bx-dollar"
                      value={applyData?.totalFund || ""}
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 총 자본금을 입력해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>현재 라운드</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                      <span className="small"> (현재 완료된 기준)</span>
                    </div>
                    <select
                      name="fundingRound"
                      value={applyData?.fundingRound || ""}
                      style={{
                        width: "100%",
                        height: "3rem",
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        현재 라운드
                      </option>
                      <option value="No Funding">No Funding</option>
                      <option value="Pre-Seed/Angel">Pre-Seed/Angel</option>
                      <option value="Seed">Seed</option>
                      <option value="Pre-Series A/Bridge">
                        Pre-Series A/Bridge
                      </option>
                      <option value="Series A">Series A</option>
                      <option value="Series B">Series B</option>
                      <option value="Above Series B">Above Series B</option>
                    </select>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>
                        현재 라운드 투자금액
                      </span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                      <span className="small"> (USD, 최근 완료된 기준)</span>
                    </div>
                    <TextInput
                      name="fundingAmount"
                      type=""
                      placeholder="100000"
                      icon="bx-dollar-circle"
                      value={applyData?.fundingAmount || ""}
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 총 자본금을 입력해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="my-lg-3">
                    <span style={{ color: "#f44336" }}>▶︎</span>
                    <span className="fw-bolder"> 창업자 & 팀</span>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>창업자 수</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.numberOfFounders}</p>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>창업자 프로필</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                      <span className="small"> (Linked in, CV, etc,..)</span>
                    </div>
                    <textarea
                      name="foundersProfile"
                      className="w-lg-100 w-100 height-13x mb-lg-1"
                      value={applyData?.foundersProfile || ""}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>
                        베트남에 회사의 대표나 팀이 있으신가요?
                      </span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <select
                      name="vietnamTeam"
                      value={applyData?.vietnamTeam || ""}
                      style={{
                        width: "100%",
                        height: "3rem",
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        YES OR NO
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>
                        Startup Wheel을 어떻게 아시게 되었나요?
                      </span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.howToStartupWheel}</p>
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>
                        부스타는 어떻게 아시게 되었나요?
                      </span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <p className="fw-bolder">{applyData?.howToBoostar}</p>
                  </div>
                  <div className="ms-1 mb-3">
                    <small className="text-danger">{errorMessage}</small>
                  </div>
                  <div className="w-lg-100 w-100 flex-center mt-lg-3">
                    <Button
                      width="18"
                      text="수정하기"
                      border="none"
                      background="#f44336"
                      color="#ffffff"
                      onClick={handleUpdateButtonClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalContainer
        show={isModalShow}
        content="수정이 완료되었습니다."
        onHide={() => setIsModalShow(false)}
      >
        <div className="mt-lg-3 mb-lg-2">
          <Button
            width="10"
            text="돌아가기"
            border="none"
            background="#f44336"
            color="#ffffff"
            onClick={() => navigate("/startup_wheel_2023")}
          />
        </div>
      </ModalContainer>
    </section>
  );
}
