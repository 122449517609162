import React from "react";

import Intro2 from "../components/Service/Intro2";
import More from "../components/More";

import introImage from "../assets/img/image/marketer_intro.png";
import marketerImage from "../assets/img/image/marketer_image.png";

export default function Marketer() {
  return (
    <main>
      <Intro2
        image={introImage}
        title1="BOOSTAR에서"
        title2="최고의 마케터와 함께하세요"
        subTitle="BOOSTAR에서 최고의 마케팅 전문가와 함께, 비즈니스의 성장 속도를 높여보세요"
        url="/contact"
      />
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                스타트업이 필요한 마케터
              </h1>
              <h5 className="mb-3 mb-lg-6">
                이미 시장에 자리잡고 있는 일반 기업의 마케팅과 스타트업의
                마케팅은 차별화가 필요합니다.
              </h5>
              <h5 className="mb-3 mb-lg-6">
                스타트업의 경우 시장에 진입하거나 시장에 진입했지만 굳건하게
                자리를 잡아야 하는 경우가 많습니다.
                <br />
                그래서 스타트업의 마케터는 브랜딩 능력과 타겟 시장을 빠르게
                포착할 수 있는 능력이 필요합니다.
              </h5>
              <h5 className="mb-3 mb-lg-4">
                BOOSTAR에서 스타트업이 필요로 하는 마케팅 전문가를 찾을 수
                있습니다.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={marketerImage} className="img-fluid w-lg-90" />
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <div
                className="px-lg-3 px-3 py-lg-10 py-2 w-lg-90"
                style={{ background: "#fec72f" }}
              >
                <h1 className="lh-base mb-lg-5 fs-4 mb-2">
                  어떤 마케팅이 필요하신가요?
                </h1>
                <h6 className="lh-base mb-2 mb-lg-3">
                  마케팅의 분야와 채널, 그리고 산업별로, <br />
                  여러분의 비즈니스에 최적화된 마케터를 연결해드립니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  지금, BOOSTAR에서 마케팅 전문가를 찾아보세요
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
