import axios from "../configs/axiosInstance";

const applyApi = {};

applyApi.saveApplication = async ({
  user_idx,
  full_name,
  gender,
  email,
  date_of_birth,
  phone,
  current_title,
  company_name,
  company_short_desc,
  hq_location,
  est_date,
  bm,
  dev_stage,
  main_industry,
  main_technology,
  pitch_deck,
  pitch_video_link,
  business_certification,
  is_patent,
  total_fund,
  most_recently_funding_round,
  funding_amount_of_round,
  number_of_founders,
  founders_profile,
  is_team_in_vietnam,
  where_did_you_here,
  how_know_boostar,
}) => {
  const response = await axios.post("/apply/saveApplication", {
    user_idx,
    full_name,
    gender,
    email,
    date_of_birth,
    phone,
    current_title,
    company_name,
    company_short_desc,
    hq_location,
    est_date,
    bm,
    dev_stage,
    main_industry,
    main_technology,
    pitch_deck,
    pitch_video_link,
    business_certification,
    is_patent,
    total_fund,
    most_recently_funding_round,
    funding_amount_of_round,
    number_of_founders,
    founders_profile,
    is_team_in_vietnam,
    where_did_you_here,
    how_know_boostar,
  });

  return response.data;
};

applyApi.getMyApplication = async () => {
  const response = await axios.get("/apply/getMyApplication");

  return response.data;
};

applyApi.updateApplication = async ({
  user_idx,
  full_name,
  gender,
  email,
  date_of_birth,
  phone,
  current_title,
  company_name,
  company_short_desc,
  hq_location,
  est_date,
  bm,
  dev_stage,
  main_industry,
  main_technology,
  pitch_deck,
  pitch_video_link,
  business_certification,
  is_patent,
  total_fund,
  most_recently_funding_round,
  funding_amount_of_round,
  number_of_founders,
  founders_profile,
  is_team_in_vietnam,
  where_did_you_here,
  how_know_boostar,
}) => {
  const response = await axios.put("/apply/updateApplication", {
    user_idx,
    full_name,
    gender,
    email,
    date_of_birth,
    phone,
    current_title,
    company_name,
    company_short_desc,
    hq_location,
    est_date,
    bm,
    dev_stage,
    main_industry,
    main_technology,
    pitch_deck,
    pitch_video_link,
    business_certification,
    is_patent,
    total_fund,
    most_recently_funding_round,
    funding_amount_of_round,
    number_of_founders,
    founders_profile,
    is_team_in_vietnam,
    where_did_you_here,
    how_know_boostar,
  });

  return response.data;
};

applyApi.submitEventApply = async ({
  userIdx,
  eventTitle,
  name,
  email,
  phone,
  companyName,
  gender,
  age,
  turnout,
}) => {
  const response = await axios.post("/apply/saveEventApply", {
    userIdx,
    eventTitle,
    name,
    email,
    phone,
    companyName,
    gender,
    age,
    turnout,
  });

  return response.data;
};

export default applyApi;
