import React from "react";

import More from "../components/More";
import ExpertIntro from "../components/Service/Intro2";

import introImage from "../assets/img/image/lawyer_intro.png";
import lawyerImage from "../assets/img/image/lawyer_image.png";

export default function Lawyer() {
  return (
    <main>
      <ExpertIntro
        image={introImage}
        title1="BOOSTAR에서"
        title2="최고의 스타트업 변호사와 함께하세요"
        subTitle="스타트업 법률 자문을 통해 성장에만 집중할 수 있도록
        도와드립니다."
        url="/contact"
      />
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-5 fs-3">
                스타트업 법률 자문이 필요한 이유
              </h1>
              <h5 className="mb-3 mb-lg-3">
                법률 이슈 없이 스타트업을 운영하는 것은 불가능합니다. <br />
                사소한 이슈부터 중요한 사업 이슈까지 관련 법에 대한 이해를
                기반으로 사업을 운영해야 합니다.
              </h5>
              <h5 className="mb-3 mb-lg-3">
                초기 스타트업의 경우 사업을 시작하는 단계라 법적 분쟁에 휘말리게
                되면 사업의 존폐가 좌우될 수 있습니다.
                <br />
                법무팀을 꾸리는 것이 여건상 어렵다면 법률 자문을 통해 미리 법적
                분쟁을 예방할 수 있어야 합니다.
              </h5>
            </div>
            <div className="my-2 mt-lg-1 mb-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-5 fs-3">
                어떤 법률 자문이 필요하신가요?
              </h1>
              <h5 className="mb-3 mb-lg-3">
                지식재산권 보호, 개인정보, 온갖 종류의 계약서 검토, 투자 유치를
                위한 법률 자문까지 <br /> 스타트업에 특화된 법률 자문을 받을 수
                있습니다.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={lawyerImage} className="img-fluid w-lg-90" />
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <div
                className="px-lg-3 px-3 py-lg-9 py-2 w-lg-90"
                style={{ background: "#fec72f" }}
              >
                <h1 className="lh-base fs-4 mb-2 mb-lg-5">
                  스타트업 전문 변호사를 만나보세요
                </h1>
                <h6 className="lh-base mb-2 mb-lg-3">
                  고객에 대한 이해를 기반으로 한 법률 서비스를 제공합니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  같은 분야의 법률 자문이라도, 일반 기업과 스타트업을 대상으로
                  하는 법률 자문은 다를 수 있습니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  BOOSTAR에서 스타트업 전문 변호사를 빠르게 연결해드립니다.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
