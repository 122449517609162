import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import Button from "../components/Button";
import ReviewModal from "../components/ReviewModal";
import classApi from "../api/class";
import { userInfoState } from "../store/userState";

export default function MyClassList() {
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [selectedClass, setSelectedClass] = useState(undefined);
  const [myClass, setMyClass] = useState(undefined);

  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  useEffect(() => {
    const getMyClassList = async () => {
      try {
        const getClassList = await classApi.getMyClassList({
          user_idx: userInfo.user_idx,
        });

        if (getClassList.result === "success") {
          setMyClass(getClassList.data);
        }
      } catch (err) {
        alert(
          "강의내역을 불러오는데 오류가 생겼습니다. 잠시 후 다시 시도해주세요."
        );
      }
    };

    getMyClassList();
  }, []);

  const handleReviewButtonClick = (classInfo) => {
    setSelectedClass(classInfo);
    setReviewModalShow(true);
  };

  return (
    <main>
      <section className="position-relative">
        <div className="container position-relative">
          <div className="position-relative pt-9 pb-9 pb-lg-11">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column w-lg-50 w-100">
                <nav className="nav nav-pills">
                  <a href="/my_page" className="nav-link">
                    <i className="bx bx-user-circle me-2"></i>내 프로필
                  </a>
                  <a href="/order/list" className="nav-link">
                    <i className="bx bx-credit-card me-2"></i>결제 내역
                  </a>
                  <a href="/my_class/list" className="nav-link active">
                    <i className="bx bx-rocket me-2" />
                    강의 신청 내역
                  </a>
                  <a href="/review/list" className="nav-link">
                    <i className="bx bx-comment-detail me-2" />
                    내가 쓴 리뷰
                  </a>
                </nav>
                <div className="d-flex align-items-center pt-1 pb-3">
                  <span className="flex-grow-1 border-bottom pt-1" />
                </div>
                <div className="h-100">
                  {myClass?.map((info, index) => {
                    const regDate = new Date(info.reg_date);
                    return (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between py-lg-3 py-3 px-lg-3 px-2 my-lg-3 my-3"
                          style={{
                            border: "1px solid #e8e8e8",
                            borderRadius: "10px",
                          }}
                          key={index}
                        >
                          <div className="my-lg-2 my-2">
                            {info.is_paid === "Y" ? (
                              <span
                                className="px-lg-2 py-lg-1 px-2"
                                style={{
                                  borderRadius: "5px",
                                  color: `#ff3d0d`,
                                  background: "#fef4e5",
                                }}
                              >
                                유료강의
                              </span>
                            ) : (
                              <span
                                className="px-lg-2 py-lg-1 px-2"
                                style={{
                                  borderRadius: "5px",
                                  color: `#fbb60d`,
                                  background: "#fef4e5",
                                }}
                              >
                                무료강의
                              </span>
                            )}

                            <span className="mx-lg-3 mx-3 small text-muted">
                              {info?.class_date}
                            </span>
                            <div className="d-flex align-items-center pt-1">
                              <span className="flex-grow-1 border-bottom pt-1" />
                            </div>
                          </div>
                          <div>
                            <div className="my-lg-1 my-1 small text-muted text-end">
                              강의 신청일:
                              {regDate.toLocaleDateString().slice(0, -1)}
                            </div>
                            <div className="my-lg-1 my-1">
                              {info?.class_name}
                            </div>
                          </div>
                          <div className="d-flex justify-content-end pt-lg-3">
                            {info?.review === 0 && (
                              <Button
                                width="8"
                                text="리뷰쓰기"
                                border="2px solid #fef4e5"
                                background="none"
                                color="#fbb60d"
                                onClick={() => handleReviewButtonClick(info)}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReviewModal
        classInfo={selectedClass}
        show={reviewModalShow}
        onHide={() => setReviewModalShow(false)}
      />
    </main>
  );
}
