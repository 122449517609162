import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../components/ServiceBox";

export default function EstablishmentCertificationList() {
  const navigate = useNavigate();

  const lifeStyleBanner =
    "https://cdn-s3.boostar.co.kr/boostar/establishment%26certification/es%26ce_list_top_banner.jpg";

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      <div className="container">
        <img
          src={lifeStyleBanner}
          className="img-fluid w-lg-100 mb-lg-4 px-lg-10"
        />
      </div>
      <div className="container py-lg-6 py-4 px-lg-11">
        <div
          className="mb-lg-4 mb-3"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <h3 className="fw-bolder ps-2">
            <span style={{ color: "#fbb60d" }}>기업인증</span> <br />
            설립
          </h3>
        </div>

        <div className="row">
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/establishment%26certification/venture_list_thumbnail.jpg"
            title="쉽고 빠른 벤처기업인증"
            info="기업인증/설립"
            salePrice="900,000 원"
            price="1,200,000 원"
            discount="25%"
            rating="0.0"
            review="0"
            onClick={() => navigate("/venture_certification")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/establishment%26certification/establishment_RI_list_thumbnail_230327.jpg"
            title="아무도 몰랐던 절세 혜택"
            info="기업인증/설립"
            salePrice="550,000 원"
            price="700,000 원"
            discount="21%"
            rating="0.0"
            review="0"
            onClick={() => navigate("/establishment_research_institute")}
          />
        </div>
      </div>
    </main>
  );
}
