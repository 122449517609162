import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import businessGrowth from "../../assets/img/graphics/icons/business_growth.png";
import ebook from "../../assets/img/graphics/icons/ebook.png";
import blog from "../../assets/img/graphics/icons/blog_icon.png";
import boostarClass from "../../assets/img/graphics/icons/boostar_class.png";
import incorportaion from "../../assets/img/graphics/icons/incorporation.png";
import infra from "../../assets/img/graphics/icons/hr.png";
import supporProgram from "../../assets/img/graphics/icons/support_program.png";
import marketing from "../../assets/img/graphics/icons/marketing.png";
import fundRasing from "../../assets/img/graphics/icons/government_support.png";

const CategoryContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    border: 1px solid #e4e4e4;
    border-radius: 10px;
  }
`;

export default function Category() {
  const navigate = useNavigate();

  return (
    <section className="position-relative text-dark">
      <div className="container px-lg-10 px-3 py-lg-3 py-0">
        <div className="row">
          <div className="">
            <div className="row mt-lg-3">
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/free_incorporation")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img
                    src={incorportaion}
                    alt="incorportaion"
                    className="w-lg-50 w-40 mt-2"
                  />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    무료법인설립
                  </p>
                </div>
              </CategoryContainer>
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/service/list/fund_rasing")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img
                    src={fundRasing}
                    alt="fund rasing"
                    className="w-lg-50 w-40 mt-2"
                  />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    자금 조달
                  </p>
                </div>
              </CategoryContainer>
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/service/list/business_growth")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img
                    src={businessGrowth}
                    alt="business growth"
                    className="w-lg-50 w-40 mt-2"
                  />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    사업 성장
                  </p>
                </div>
              </CategoryContainer>
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/service/list/infra")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img src={infra} alt="infra" className="w-lg-50 w-40 mt-2" />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    인프라/리소스
                  </p>
                </div>
              </CategoryContainer>
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/service/list/marketing")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img
                    src={marketing}
                    alt="marketing"
                    className="w-lg-50 w-40 mt-2"
                  />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    홍보/마케팅
                  </p>
                </div>
              </CategoryContainer>
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/ebook")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img src={ebook} alt="ebook" className="w-lg-50 w-40 mt-2" />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    EBOOK
                  </p>
                </div>
              </CategoryContainer>
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/blog")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img src={blog} alt="blog" className="w-lg-50 w-40 mt-2" />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    스타트업 네비게이터
                  </p>
                </div>
              </CategoryContainer>
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/class")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img
                    src={boostarClass}
                    alt="boostar class"
                    className="w-lg-50 w-40 mt-2"
                  />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    BOOSTAR 특강
                  </p>
                </div>
              </CategoryContainer>
              <CategoryContainer
                className="col-lg-1 col-md-2 col-4 mx-lg-3 flex-center hover-lift"
                onClick={() => navigate("/support_program")}
              >
                <div className="flex-center flex-column w-lg-100 w-80 px-lg-1 py-lg-2 py-2 btn:hover button">
                  <img
                    src={supporProgram}
                    alt="support program"
                    className="w-lg-50 w-40 mt-2"
                  />
                  <p
                    className="mt-lg-2 mt-2 text-center"
                    style={{ fontSize: "0.75rem", marginBottom: 0 }}
                  >
                    정부지원사업
                  </p>
                </div>
              </CategoryContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
