import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import TextInput from "../components/TextInput";
import Button from "../components/Button";
import ModalContainer from "../components/Modal";
import SnsLogin from "../components/GoogleLogin";
import NaverLogin from "../components/NaverLogin";
import KakaoLogin from "../components/KakaoLogin";
import classApi from "../api/class";
import { userInfoState } from "../store/userState";

import { EMAIL_REGEXP, NAME_REGEXP, PHONE_REGEXP } from "../constants/RegExp";

import classIcon from "../assets/img/graphics/icons/class.png";
import closedImage from "../assets/img/image/closed_image.png";

export default function EnrollInClass() {
  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  const [isDisabled, setIsDisabled] = useState(false);
  const [payModalShow, setPayModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [enrollee, setEnrollee] = useState({
    name: userInfo?.name,
    email: userInfo?.email,
    phone: "",
    officeName: "",
    officeInfo: "",
    qna: "",
    inflow: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const isValidName = NAME_REGEXP.test(enrollee.name);
  const isValidEmail = EMAIL_REGEXP.test(enrollee.email);
  const isValidPhone = PHONE_REGEXP.test(enrollee.phone);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setEnrollee((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setIsDisabled(true);

    if (
      !enrollee.name ||
      enrollee.phone.replaceAll(" ", "").length !== 13 ||
      !enrollee.email ||
      enrollee.officeName.replaceAll(" ", "").length === 0 ||
      enrollee.officeInfo.replaceAll(" ", "").length === 0 ||
      enrollee.inflow.replaceAll(" ", "").length === 0
    ) {
      setIsDisabled(false);
      setErrorMessage("✓ 필수입력란을 확인해주세요.");

      return;
    }

    if (
      isValidName &&
      isValidPhone &&
      isValidEmail &&
      enrollee.phone.replaceAll("-", "").length === 11 &&
      enrollee.officeName &&
      enrollee.officeInfo &&
      enrollee.inflow
    ) {
      try {
        const response = await classApi.registerClass({
          classIdx: location.state.classIdx,
          classDate: location.state.date.replaceAll("-", "."),
          className: location.state.title,
          name: enrollee.name,
          phone: enrollee.phone.replaceAll("-", ""),
          email: enrollee.email,
          officeName: enrollee.officeName,
          officeInfo: enrollee.officeInfo,
          qna: enrollee.qna,
          inflow: enrollee.inflow,
          isPaid: location.state.isPaid,
        });

        if (location.state.isPaid === "Y" && response.result === "success") {
          localStorage.setItem(
            "payInfo",
            JSON.stringify({
              applyIdx: response.apply_idx,
              classId: id,
              classInfo: location.state,
            })
          );

          navigate(`/pay/class/${response.apply_idx}`);
        }

        if (location.state.isPaid === "N" && response.result === "success") {
          setIsSuccess(true);
          setModalShow(true);
        }
      } catch (err) {
        alert("에러가 발생했습니다. 잠시 후 다시 신청해주세요.");
      }
    }
  };

  useEffect(() => {
    if (!userInfo && !accessToken) {
      setModalContent("로그인 후 신청해주세요 🙂");
      setModalShow(true);

      return;
    }
  }, [userInfo]);

  useEffect(() => {
    if (enrollee.phone?.length === 11) {
      setEnrollee((prevSate) => ({
        ...prevSate,
        phone: enrollee.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }

    if (enrollee.phone?.length === 13) {
      setEnrollee((prevState) => ({
        ...prevState,
        phone: enrollee.phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }
  }, [enrollee.phone]);

  return (
    <main>
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8 py-lg-10 px-lg-10">
          {location.state == null && location.search === "" ? (
            <div>
              <img
                src={closedImage}
                alt="closed image"
                className="w-lg-100 w-100"
              />
              <h4 className="text-center fw-bolder">마감된 강의입니다 😢</h4>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-6 me-auto mb-5 justify-content-center">
                <div className="px-lg-3 px-3">
                  <img src={classIcon} className="w-lg-20 w-30" />
                  <h4 className="mb-lg-7 mb-5">
                    BOOSTAR 에서
                    <br /> 드리는 특별한 강의
                  </h4>
                </div>
                <div className="mb-lg-4 mb-4 px-lg-3 px-3">
                  부스타 회원님들을 위해 준비했습니다. <br />
                  스타트업 대표님들의 고민을 덜어줄{" "}
                  <span className="fw-bold">실전 노하우</span>
                  <br />
                  BOOSTAR 최고의 전문가가 전부 공개합니다!
                  <br />
                  (정부지원사업 가이드부터 세무, 재무, 노무, 법무 등)
                </div>
                <div className="mb-lg-4 mb-4 px-lg-3 px-3">
                  대표님의 사업성장을 돕겠습니다!
                  <br />
                  <span className="fw-bold" style={{ color: "#fec230" }}>
                    부스타
                  </span>
                  에서 바로 신청하세요!
                </div>
                <div
                  className="mx-lg-3 mx-3 mt-lg-6 mt-4 pt-lg-6 pt-4"
                  style={{ borderTop: "1px solid #cfcfcf" }}
                >
                  👉 <span className="fw-bold">강의 주제:</span>{" "}
                  {location.state.title}
                  <br />
                  👉 <span className="fw-bold">강연자:</span>{" "}
                  {location.state.lecturer}
                  <br />
                  👉 <span className="fw-bold">진행 일시:</span>{" "}
                  {location.state?.lectureDate}
                  (강의 종료 후 Q&A 진행)
                  <br />
                  <div className="d-flex">
                    👉 <span className="fw-bold ms-1">진행 장소:</span>{" "}
                    <span className="ms-1">
                      {location.state.place.split("/").map((data, index) => (
                        <p key={index}> {data}</p>
                      ))}
                    </span>
                  </div>
                  {location.state?.isPaid === "Y" && (
                    <>
                      {" "}
                      👉 <span className="fw-bold">수강료:</span>{" "}
                      {location.state?.cost} 원
                    </>
                  )}
                  <p className="small" style={{ color: "#ff3d0d" }}>
                    ✔️ 수강 선정 후 무단 불참하시는 경우, 다음 강의 참가가
                    제한됩니다. 신청하시기 전 일정을 반드시 확인해주세요.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="py-7 px-3 shadow w-lg-100 mx-lg-11"
                  style={{
                    background: "#ffffff",
                    borderRadius: "55px",
                  }}
                >
                  <form onSubmit={handleFormSubmit}>
                    <div>
                      <div className="mb-2">
                        <span style={{ color: "#3bb6d5" }}>이름</span>
                        <span style={{ color: "#ff3d0d" }}>*</span>
                        <div className="small text-muted">
                          - 특강에 신청하실 신청자의 이름을 기재해주세요.
                        </div>
                      </div>
                      <TextInput
                        name="name"
                        type="text"
                        placeholder="이름(실명)"
                        icon="bx-user"
                        value={enrollee.name || ""}
                        pattern={NAME_REGEXP}
                        errorMessage="✓ 이름을 확인해주세요."
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <div className="mb-2">
                        <span style={{ color: "#3bb6d5" }}>연락처</span>
                        <span style={{ color: "#ff3d0d" }}>*</span>
                        <div className="small text-muted">
                          - 신청하신 특강 장소와 링크를 받으실 연락처를
                          기재해주세요.
                        </div>
                      </div>
                      <TextInput
                        name="phone"
                        type="text"
                        placeholder="01012345678"
                        icon="bx-phone"
                        value={enrollee.phone || ""}
                        maxLength={13}
                        pattern={PHONE_REGEXP}
                        errorMessage="✓ 핸드폰번호를 확인해주세요."
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <div className="mb-2">
                        <span style={{ color: "#3bb6d5" }}>이메일</span>
                        <span style={{ color: "#ff3d0d" }}>*</span>
                        <div className="small text-muted">
                          - 신청하신 특강 장소와 링크를 받으실 이메일 주소를
                          기재하여 주세요.
                        </div>
                      </div>
                      <TextInput
                        name="email"
                        type="text"
                        placeholder="user@boostar.co.kr"
                        icon="bx-envelope"
                        value={enrollee.email || ""}
                        pattern={EMAIL_REGEXP}
                        errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <div className="mb-2">
                        <span style={{ color: "#3bb6d5" }}>기업명</span>
                        <span style={{ color: "#ff3d0d" }}>*</span>
                        <div className="small text-muted">
                          - 기업명을 기재하여주세요. 예비창업자의 경우 예비라고
                          기재해주세요.
                        </div>
                      </div>
                      <TextInput
                        name="officeName"
                        type="text"
                        placeholder="Boostar"
                        icon="bx-buildings"
                        value={enrollee.officeName || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <div className="mb-2">
                        <span style={{ color: "#3bb6d5" }}>사업 내용</span>
                        <span style={{ color: "#ff3d0d" }}>*</span>
                        <div className="small text-muted">
                          - 현재 운영하시거나 창업준비중이신 사업내용을 간단하게
                          남겨주세요.
                        </div>
                      </div>
                      <TextInput
                        name="officeInfo"
                        type="text"
                        maxLength={100}
                        icon="bx-message-alt-detail"
                        value={enrollee.officeInfo || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <div className="mb-2">
                        <span style={{ color: "#3bb6d5" }}>
                          {location.state?.qna}
                        </span>
                        <div className="small text-muted">
                          - Q&A받고 싶은 내용을 기재하여 주세요.
                        </div>
                      </div>
                      <TextInput
                        name="qna"
                        type="text"
                        maxLength={100}
                        icon="bx-tired"
                        value={enrollee.qna || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <div className="mb-2">
                        <span style={{ color: "#3bb6d5" }}>특강 신청 경로</span>
                        <span style={{ color: "#ff3d0d" }}>*</span>
                        <div className="small text-muted">
                          - 특강 내용을 어디서 보고 신청하게
                          되셨나요?(부스타웹페이지, 서울핀테크랩,
                          글로벌게임허브센터, 블로그, 인스타그램, 페이스북 등)
                        </div>
                      </div>
                      <TextInput
                        name="inflow"
                        type="text"
                        maxLength={100}
                        icon="bx-trip"
                        value={enrollee.inflow || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 text-center">
                      <small style={{ color: "#ff3d0d" }}>{errorMessage}</small>
                    </div>
                    {location.state.isPaid === "Y" && (
                      <p className="text-center" style={{ color: "#ff3d0d" }}>
                        ✔️ 결제까지 하셔야 수강신청이 완료 됩니다.
                      </p>
                    )}
                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        width="18"
                        background="#000000"
                        color="#fec230"
                        border="none"
                        text="신청하기"
                        isDisabled={isDisabled}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
        <ModalContainer
          show={modalShow}
          content={modalContent}
          onHide={() => setModalShow(false)}
        >
          {userInfo?.name && isSuccess ? (
            <div className="my-lg-4 d-flex flex-column align-items-center">
              <div>신청해주셔서 감사합니다.</div>
              <div className="mb-lg-3">
                사업에 도움이 되는 좋은 강의 준비하겠습니다 🙂
              </div>
              <Button
                width="8"
                background="#fef4e5"
                color="#fbb60d"
                text="확인"
                border="none"
                onClick={() => navigate("/class")}
              />
            </div>
          ) : (
            <>
              <div className="d-flex flex-center mt-lg-4">
                <Button
                  width="8"
                  background="#f4f4f5"
                  color="#797979"
                  text="가입하기"
                  border="none"
                  onClick={() => navigate("/signup")}
                />
                <Button
                  width="8"
                  background="#fef4e5"
                  color="#fbb60d"
                  text="로그인하기"
                  border="none"
                  onClick={() => navigate("/login")}
                />
              </div>
              <div className="d-flex align-items-center py-3">
                <span className="flex-grow-1 border-bottom pt-1"></span>
                <span className="d-inline-flex flex-center lh-1 width-2x height-2x rounded-circle bg-white text-mono">
                  or
                </span>
                <span className="flex-grow-1 border-bottom pt-1"></span>
              </div>
              <p className="text-center">3초안에 빠르게 회원가입/로그인 하기</p>
              <div className="d-flex justify-content-center px-lg-10 my-lg-2">
                <KakaoLogin url="/enroll_in/class/230118" />
                <NaverLogin url="enroll_in/class/230118" />
                <SnsLogin url="/enroll_in/class/230118" />
              </div>
            </>
          )}
        </ModalContainer>
      </section>
    </main>
  );
}
