import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "../components/Button";
import ModalContainer from "../components/Modal";
import TextInput from "../components/TextInput";
import { userInfoState } from "../store/userState";
import orderApi from "../api/order";

export default function OrderCancel() {
  const [reason, setReason] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({
    content: "",
    buttonTitle: "",
  });

  const accessToken = localStorage.getItem("accessToken");
  const user = useRecoilValue(userInfoState(accessToken));

  const { orderNumber } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = useCallback((event) => {
    setReason(event.target.value);
  });

  const handleRefundButtonClick = async (event) => {
    event.preventDefault();

    try {
      if (reason.replaceAll(" ", "").length !== 0) {
        const response = await orderApi.postRefund({
          email: user.email,
          orderNumber,
          serviceType: location.state.serviceType,
          reason,
        });

        if (response.data.isDownloader) {
          setModalShow(true);
          setModalData((prevState) => ({
            ...prevState,
            content: "Ebook을 다운받은 기록이 있어 환불이 어렵습니다.",
            buttonTitle: "닫기",
          }));
        }

        if (!response.data.isDownloader) {
          setModalShow(true);
          setModalData((prevState) => ({
            ...prevState,
            content:
              "환불신청이 완료되었습니다. 진행사항은 MY PAGE에서 확인하실 수 있습니다.",
            buttonTitle: "돌아가기",
          }));
        }
      }
    } catch (err) {
      alert("문제가 발생했습니다. 잠시후 다시 이용해주시기 바랍니다.");
    }
  };

  return (
    <main>
      <div className="container py-lg-10">
        <div className="mt-lg-4 mb-lg-8 my-3">
          <h4
            className="pb-lg-3 pb-3"
            style={{ borderBottom: "3px solid black" }}
          >
            환불 신청
          </h4>
          <div
            style={{ border: "1px solid #e8e8e8", borderRadius: "10px" }}
            className="py-lg-3 px-lg-5"
          >
            <form
              className="row align-items-center py-lg-2 py-2 px-2"
              onSubmit={handleRefundButtonClick}
            >
              <div className="col-lg-1">
                <p className="mx-1">환불사유</p>
              </div>
              <div className="col-lg-11">
                <TextInput
                  name="reason"
                  type="text"
                  placeholder="환불사유를 50자 이내로 작성해주세요"
                  maxLength={50}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="d-flex justify-content-end mt-lg-1">
                <Button
                  width="8"
                  background="none"
                  border="1px solid #42445c"
                  text="환불신청"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalContainer
        show={modalShow}
        content={modalData.content}
        onHide={() => setModalShow(false)}
      >
        <button
          className="btn btn-dark width-13x mt-lg-3 mb-lg-2"
          onClick={() => navigate("/order/list")}
        >
          {modalData.buttonTitle}
        </button>
      </ModalContainer>
    </main>
  );
}
