import React from "react";

export default function LeftImage({ image, title, paragraph1, paragraph2 }) {
  return (
    <>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 ph-lg-10">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3">
              <div className="w-lg-90">
                <h1 className="lh-base fs-3 mb-2 mb-lg-5">{title}</h1>
                <h6 className="lh-base mb-2 mb-lg-4">{paragraph1}</h6>
                <h6 className="lh-base mb-2 mb-lg-4">{paragraph2}</h6>
              </div>
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 d-flex justify-content-end">
              <img src={image} className="img-fluid w-lg-90" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
