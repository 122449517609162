import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import TextInput from "../TextInput";
import Button from "../Button";
import ModalContainer from "../Modal";

import { userInfoState } from "../../store/userState";
import applyApi from "../../api/apply";
import {
  EMAIL_REGEXP,
  NAME_REGEXP,
  PHONE_REGEXP,
} from "../../constants/RegExp";

const Select = styled.select`
  width: 100%;
  height: 3rem;
`;

export default function ApplyForm() {
  const accessToken = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(accessToken));

  const [isEmpty, setIsEmpty] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalText, setModalText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [applyData, setApplyData] = useState({
    name: userInfo?.name,
    companyName: "",
    email: userInfo?.email,
    phone: userInfo?.phone,
    gender: "",
    age: "",
    turnout: "",
  });

  const navigate = useNavigate();

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setApplyData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleApplyFormSubmit = async (event) => {
    event.preventDefault();

    const isValidName = NAME_REGEXP.test(applyData?.name);
    const isValidEmail = EMAIL_REGEXP.test(applyData?.email);
    const isValidPhone = PHONE_REGEXP.test(applyData?.phone);

    if (
      !applyData?.name.replaceAll(" ", "").length ||
      !applyData?.email.replaceAll(" ", "") ||
      !applyData?.phone.replaceAll(" ", "") ||
      !applyData?.companyName.replaceAll(" ", "")
    ) {
      setIsEmpty(true);
    }

    if (
      !applyData?.gender.length ||
      !applyData?.age.length ||
      !applyData?.turnout.length
    ) {
      setErrorMessage("✓ 필수 입력란을 확인해주세요!");
    }

    if (
      isValidName &&
      isValidPhone &&
      isValidEmail &&
      applyData?.companyName.replaceAll(" ", "") &&
      applyData?.gender.length &&
      applyData?.age.length &&
      applyData?.turnout.length
    ) {
      try {
        const response = await applyApi.submitEventApply({
          userIdx: userInfo.user_idx,
          eventTitle: "HCMC",
          name: applyData.name,
          email: applyData.email,
          phone: applyData.phone.replaceAll("-", ""),
          companyName: applyData.companyName,
          gender: applyData.gender,
          age: applyData.age,
          turnout: applyData.turnout,
        });

        if (response.result === "success") {
          setModalContent("정상적으로 신청되셨습니다.");
          setModalText(
            "접수 마감 후, 선정되신 분들께는 기입하신 연락처로 안내 메세지를 보내드릴 예정입니다 😊"
          );
          setIsModalShow(true);

          return;
        }
      } catch (err) {
        if (err.response.data.message === "duplicate entry") {
          setModalContent("이미 신청하신 기록이 있습니다.");
          setModalText(
            "문의사항이 있으신 분은 관리자에게 문의 부탁드립니다 😊"
          );
          setIsModalShow(true);

          return;
        }

        if (err) {
          alert(
            "신청하시면서 오류가 발생했습니다. 잠시 후 다시 시도해주시길 바랍니다."
          );
        }
      }
    }
  };

  useEffect(() => {
    if (applyData.phone?.length === 11) {
      setApplyData((prevSate) => ({
        ...prevSate,
        phone: applyData.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }

    if (applyData.phone?.length === 13) {
      setApplyData((prevState) => ({
        ...prevState,
        phone: applyData.phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }
  }, [applyData.phone]);

  return (
    <section>
      <div className="container">
        <div className="mx-lg-5 mt-6">
          <div
            className="fw-bolder w-lg-20 text-center py-lg-1 py-1"
            style={{
              background: "#f4f4f5",
              color: "#2196F3",
              borderRadius: "5px",
            }}
          >
            APPLY
          </div>
          <div className="d-flex flex-center">
            <div className="my-lg-5 my-5 w-lg-50">
              <div className="my-lg-2 my-2">
                <span style={{ color: "#F44336" }}>▶︎</span>
                <span className="fw-bolder"> 참가 신청</span>
              </div>
              <div className="my-lg-4 my-4">
                <form className="">
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>이름</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="name"
                      type="text"
                      placeholder="본명을 작성해주세요"
                      icon="bx-user"
                      value={applyData?.name || ""}
                      pattern={NAME_REGEXP}
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이름을 기입해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>이메일</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="email"
                      type="text"
                      placeholder="user@boostar.co.kr"
                      icon="bx-envelope"
                      value={applyData.email || ""}
                      pattern={EMAIL_REGEXP}
                      errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이메일을 기입해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>연락처</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="phone"
                      placeholder="01012345678"
                      icon="bx-phone"
                      value={applyData.phone || ""}
                      maxLength={13}
                      pattern={PHONE_REGEXP}
                      errorMessage="✓ 핸드폰번호를 확인해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 핸드폰번호를 기입해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>회사 이름</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="companyName"
                      type="text"
                      placeholder="회사명"
                      icon="bx-buildings"
                      value={applyData?.companyName || ""}
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 회사명을 기입해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>성별</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <Select
                      name="gender"
                      value={applyData?.gender || ""}
                      style={{
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        성별
                      </option>
                      <option value="Female">여성</option>
                      <option value="Male">남성</option>
                    </Select>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>연령</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <Select
                      name="age"
                      value={applyData?.age || ""}
                      style={{
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        연령
                      </option>
                      <option value="2030">20대 - 30대</option>
                      <option value="4050">40대 - 50대</option>
                      <option value="60">60대 이상</option>
                    </Select>
                  </div>
                  <div className="mb-3">
                    <div className="mb-0">
                      <span style={{ color: "#3bb6d5" }}>참가자 수</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <Select
                      name="turnout"
                      value={applyData?.turnout || ""}
                      style={{
                        border: "1px solid #e5e6f3",
                        borderRadius: "0.375rem",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        참가자 수
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5인 이상</option>
                    </Select>
                  </div>
                </form>
                <div className="ms-1 mb-3">
                  <small className="text-danger">{errorMessage}</small>
                </div>
                <div className="d-flex flex-center mt-lg-3">
                  <Button
                    width="18"
                    text="신청하기"
                    border="none"
                    background="#f44336"
                    color="#ffffff"
                    onClick={handleApplyFormSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalContainer
        show={isModalShow}
        content={modalContent}
        onHide={setIsModalShow}
      >
        <div className="d-felx flex-center flex-column">
          <p className="text-center mb-lg-3 mb-2">{modalText}</p>
          <Button
            width="13"
            background="#fef4e5"
            color="#fbb60d"
            text="확인"
            border="none"
            onClick={() => navigate("/hcmc_2023")}
          />
        </div>
      </ModalContainer>
    </section>
  );
}
