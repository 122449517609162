import React from "react";
import { useNavigate } from "react-router";

export default function News({ contents }) {
  const news = contents?.filter((content) => content.blog_type_cd === "S_NEWS");

  const navigate = useNavigate();

  const NewsBox = ({ image, date, title, idx }) => {
    return (
      <div className="col-lg-4 col-12">
        <div
          className="mx-lg-4 my-lg-3 my-2 hover-lift"
          style={{
            border: "1px solid #c8c8c8",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/blog/content/${idx}`)}
        >
          <img
            src={image}
            className="img-fluid w-lg-100 w-100"
            style={{ borderRadius: "5px 5px 0 0" }}
          />
          <div className="py-2 px-2" style={{ height: "6.75rem" }}>
            <p className="small text-muted my-lg-1 ps-lg-1 ps-1">
              {date.replaceAll("-", ".")}
            </p>
            <p className="small ps-lg-1 ps-1">{title}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="position-relative text-dark">
      <div className="container px-lg-11 py-lg-4 pt-5 pb-1">
        <div className="mt-lg-0">
          <div className="lh-sm mb-lg-2 mb-3">
            <h5 className="">
              <span className="">스타트업 </span>
              <span className="fw-bold">Today</span>
            </h5>
            <p className="small text-muted">
              스타트업들의 생생한 뉴스를 만나보세요!
            </p>
          </div>
          {news && (
            <>
              <div className="row justify-content-center px-lg-0">
                {news.map((content, index) => {
                  if (index < 3) {
                    return (
                      <NewsBox
                        key={index}
                        image={content.thumbnail}
                        date={content.display_date}
                        title={content.title}
                        idx={content.idx}
                      />
                    );
                  }
                })}
              </div>
            </>
          )}
        </div>
        <div className="d-flex flex-center mt-lg-4 mt-3">
          <p
            className="width-10x small text-muted text-mono text-center py-lg-1"
            style={{
              border: "1px solid #c8c8c8",
              borderRadius: "50px",
              fontSize: "0.8rem",
              cursor: "pointer",
            }}
            onClick={() => navigate("/blog/startup-news")}
          >
            더보기
          </p>
        </div>
      </div>
    </section>
  );
}
