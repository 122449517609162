import React from "react";
import { useNavigate } from "react-router-dom";

import Tag from "./Blog/Tag";

export default function ContentCard({ contentInfo }) {
  const { idx, title, blog_type, topic, display_date, thumbnail } = contentInfo;

  const navigate = useNavigate();

  const tags = [];
  tags.push(blog_type, topic);

  return (
    <>
      <div className="col-lg-3 col-md-4 col-sm-6 mb-4 mb-md-4 my-md-4">
        <div
          className="card card-hover hover-lift hover-shadow-lg border-1 border-dark overflow-hidden rounded-4"
          onClick={() => navigate(`/blog/content/${idx}`)}
        >
          <img
            className="card-img-top img-fluid img-zoom"
            src={thumbnail}
            alt="content thumbnail"
          />
          <div className="card-body d-flex flex-column start-0 top-0 w-100 h-100 justify-content-end pb-lg-3 px-4">
            <p className="small">
              <span>{display_date.replaceAll("-", ".")}</span>
            </p>
            <p className="mb-3" style={{ height: "2rem" }}>
              <span>
                {title.length > 27 ? title.slice(0, 28) + "..." : title}
              </span>
            </p>
            <span className="flex-grow-1 border-bottom pt-2 mb-2"></span>
            <div className="d-flex align-items-center mt-2">
              {tags.map((item, index) => (
                <Tag title={item} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
