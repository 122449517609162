import axios from "../configs/axiosInstance";

const contactApi = {};

// contactApi.contact = async (contactData) => {
//   const response = await axios.post("/contact", contactData);

//   return response.data;
// };

contactApi.contact = async (userData) => {
  const response = await axios.post("/contact", userData);

  return response.data;
};

contactApi.contactExpert = async (userData) => {
  const response = await axios.post("/contact/expert", userData);

  return response.data;
};

contactApi.contactInvestor = async (contactData) => {
  const response = await axios.post("/contact/investor", contactData);

  return response.data;
};

contactApi.contactPartner = async (contactData) => {
  const response = await axios.post("/contact/partner", contactData);

  return response.data;
};

contactApi.contactService = async ({
  service,
  name,
  officeName,
  phone,
  email,
  inquiry,
  marketing,
}) => {
  const response = await axios.post("/contact/service", {
    service,
    name,
    officeName,
    phone,
    email,
    inquiry,
    marketing,
  });

  return response.data;
};

export default contactApi;
