import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import Review from "../components/Review";
import ScrollToTop from "../components/ScrollToTop";
import ServiceBox from "../components/ServiceBox";
import Button from "../components/Button";
import Hit from "../components/Hit";
import { selectedServiceState } from "../store/reviewState";

const TabContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #fec000;
  }
`;

export default function Incorporation() {
  const infoRef = useRef(null);
  const reviewRef = useRef(null);
  const detailInfoRef = useRef(null);
  const refundInfoRef = useRef(null);

  const navigate = useNavigate();

  const reviews = useRecoilValue(selectedServiceState("PC01S01"));

  const onClickTap = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main className="position-relative pt-lg-0 pt-3">
      <div className="container py-lg-6 px-lg-11">
        <ScrollToTop />
        <div className="row align-items-start">
          <div className="col-lg-7 flex-center">
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_thumbnail_230517.jpg"
              alt="incorporation image"
              className="img-fluid w-lg-70 w-70 mb-lg-3"
              style={{ borderRadius: "5px" }}
            />
          </div>
          <div className="col-lg-5">
            <div
              className="fw-bold pb-2 pt-lg-0 pt-3"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              <p className="small text-muted mb-0">
                인프라 및 리소스 서비스 &gt; 회사 설립 지원 서비스
              </p>
              <span className="fs-5 fw-bolder">맞춤형 법인설립</span>
              <Hit pageCode="B-23C-IRCE-01" />
            </div>
            <div
              className="pt-lg-2 pb-lg-2 py-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              <div className="d-flex align-items-center">
                <span className="fs-5 fw-bolder ms-lg-3 ms-3">무료</span>
                <span
                  className="fw-bolder text-center ms-lg-3 ms-3"
                  style={{
                    // background: "#1f375b",
                    color: "#ff3d0d",
                    borderRadius: "5px",
                  }}
                >
                  로켓진행 🚀
                </span>
              </div>
            </div>
            <div
              className="py-lg-3 py-3"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              <div>
                <p className="fw-bolder">
                  🎁 회원가입 후 법인설립을 하시면 아래와 같은 혜택들이
                  제공됩니다!
                </p>
                <p className="ps-lg-2 ps-2 small">
                  • 부스타 컨설팅 30% 할인 (세무, 인사노무, 법률, 마케팅)
                  <br />
                  • 법인 필수 서류 10종 증정
                  <br />• 부스타 오프라인 세미나 우선 참석권 (1회)
                </p>
              </div>
              <div className="mt-lg-1">
                <p className="fw-bolder">🎁 (추가) 기간 한정 혜택 제공까지!</p>
                <p className="ps-lg-2 ps-2 small">
                  • 정부지원사업 컨설팅 1회 무료
                  <br />• 스타트업 재무관리 솔루션 OKCEO 6개월 사용권
                </p>
              </div>
            </div>
            <div className="py-lg-3 py-3">
              <div>
                <span className="fw-bolder small text-muted">분야</span>
                <span className="ms-lg-2 ms-2 small text-muted">
                  국내/법인/조합/외국인/해외 등 (모든 기업 유형)
                </span>
              </div>
              <div>
                <span className="fw-bolder small text-muted">진행 전문가</span>
                <span className="ms-lg-2 ms-2 small text-muted">
                  기업별 맞춤형 법률 전문가
                </span>
              </div>
              <div>
                <span className="fw-bolder small text-muted">진행 과정</span>
                <span className="ms-lg-2 ms-2 small text-muted">
                  사전 상담 &gt; 전문가 상담 &gt; 법인 설립
                </span>
              </div>
              <div>
                <span className="fw-bolder small text-muted">기간</span>
                <span className="ms-lg-2 ms-2 small text-muted">
                  5일 내 설립
                </span>
              </div>
              <div>
                <span className="fw-bolder small text-muted">안내사항</span>
                <span className="ms-lg-2 ms-2 small text-muted">
                  기업/전문가의 스케줄과 기업 유형에 따라 기간은 변경될 수
                  있습니다.
                </span>
              </div>
            </div>
            <div className="w-lg-100 w-100 flex-center mb-3">
              <Button
                width="18"
                border="none"
                text="무료 상담하기"
                background="#000000"
                color="#fec230"
                onClick={() =>
                  navigate("/contact/incorporation", {
                    state: {
                      service: "무료법인설립",
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div
            className="row my-lg-4 my-2 py-lg-0 py-2 mx-lg-0 mx-0"
            style={{ border: "1px solid #c8c8c8", borderRadius: "5px" }}
          >
            <TabContainer
              className="col-lg-4 col-4 px-0"
              onClick={() => onClickTap(infoRef)}
            >
              <div
                className="py-lg-1 text-center button"
                style={{ borderRight: "1px solid #c8c8c8" }}
              >
                서비스 설명
              </div>
            </TabContainer>
            <TabContainer
              className="col-lg-4 col-4 px-0"
              onClick={() => onClickTap(reviewRef)}
            >
              <div
                className="py-lg-1 px-0 text-center button"
                style={{ borderRight: "1px solid #c8c8c8" }}
              >
                리뷰
              </div>
            </TabContainer>
            <TabContainer
              className="col-lg-4 col-4 px-0"
              onClick={() => onClickTap(detailInfoRef)}
            >
              <div className="py-lg-1 text-center button">상세정보</div>
            </TabContainer>
          </div>
          <div className="pb-lg-5 d-flex flex-center flex-column" ref={infoRef}>
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_1_230511.png"
              className="w-lg-70 w-100"
            />
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_2_button_230511.png"
              className="w-lg-70 w-100 button"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/contact/incorporation", {
                  state: {
                    service: "무료법인설립",
                  },
                })
              }
            />
            <img
              src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/incorporation_list/incorporation_3_230511.png"
              className="w-lg-70 w-100"
            />
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_4_button_230511.png"
              className="w-lg-70 w-100 button"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/contact/incorporation", {
                  state: {
                    service: "무료법인설립",
                  },
                })
              }
            />
            <img
              src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/incorporation_list/incorporation_5_230511.png"
              className="w-lg-70 w-100"
            />
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_6_button_230511.png"
              className="w-lg-70 w-100 button"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/contact/incorporation", {
                  state: {
                    service: "무료법인설립",
                  },
                })
              }
            />
            <img
              src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/incorporation_list/incorporation_7_230511.png"
              className="w-lg-70 w-100"
            />
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_8_button_230511.png"
              className="w-lg-70 w-100 button"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/contact/incorporation", {
                  state: {
                    service: "무료법인설립",
                  },
                })
              }
            />
            <img
              src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/incorporation_list/incorporation_9_230511.png"
              className="w-lg-70 w-100"
            />
          </div>
          <div className="mt-3">
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2 mb-lg-3 mb-3"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              다른 고객이 함께 구매한 상품
            </h5>
            <div className="row">
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/business_plan/business_plan_list_thumbnail_230406.jpg"
                title="사업계획서 컨설팅"
                info="성장 전략 컨설팅"
                salePrice="330,000 원"
                price="500,000 원"
                discount="34%"
                rating="5.0"
                review="1"
                onClick={() => navigate("/business_plan")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/ir/ir_list_thumbnail_230327.jpg"
                title="3,000억 투자유치의 비밀: 에센셜팩"
                info="투자 유치 컨설팅"
                rating="0.0"
                review="0"
                onClick={() => navigate("/ir_pack")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/hr/hr_lsit_thumbnail_230609.jpg"
                title="[인사 노무 팩] 인사 노무 폭탄 제거"
                info="HR 지원 서비스"
                salePrice="330,000 원"
                price="500,000 원"
                discount="34%"
                rating="5.0"
                review="1"
                onClick={() => navigate("/hr_pack")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/financing/ffin_list_thumbnail_230504.jpg"
                title="Fiin정부보증대출 사전심사서비스"
                info="자금조달 설계 컨설팅"
                rating="0.0"
                review="0"
                onClick={() => navigate("/fiin")}
              />
            </div>
          </div>
          <div className="my-lg-3 my-3" ref={reviewRef}>
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              리뷰
            </h5>
            <div className="px-lg-2 px-2 py-lg-3 py-3">
              {reviews.map((review, index) => (
                <Review
                  key={index}
                  rating={Number(review.rating)}
                  content={review.content}
                  email={review.email}
                  createAt={review.create_at}
                />
              ))}
            </div>
          </div>
          <div className="my-lg-3 my-3" ref={detailInfoRef}>
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              상세정보
            </h5>
            <div className="px-lg-2 px-2 py-lg-3 py-3">
              <div className="">
                <p className="mb-lg-2">
                  • 본 서비스는 기업별 맞춤 무료 법인 설립 컨설팅입니다.
                </p>
                <div className="mb-lg-2">
                  <p className="fw-bold">[컨설팅 분야]</p>
                  <p className="ms-lg-1">
                    국내/해외/개인/법인/조합 등 (기업별 맞춤 법인 설립)
                  </p>
                </div>
                <div className="mb-lg-2">
                  <p className="fw-bold">[컨설팅 담당 전문가]</p>
                  <p className="ms-lg-1">기업별 맞춤형 법률 전문가</p>
                </div>
                <div className="mb-lg-2">
                  <p className="fw-bold">[컨설팅 과정]</p>
                  <p className="ms-lg-1">
                    • 상담신청을 하시면 전담 매니저와 1:1 유선으로 사전 상담이
                    진행됩니다.
                    <br />
                    (유선상담은 오전 9시에서 오후 19시 사이 언제든 가능하시며,
                    원하시는 시간대를 신청서에 기재해주시면 담당 매니저가 연락을
                    드립니다.)
                    <br /> • 사전 상담한 내용을 토대로 법인 설립 전문가와 상담이
                    진행 됩니다.
                    <br />
                    • 업무 착수 일주일내로 상담을 기반으로 한 결과물을 받아보실
                    수 있습니다. (단, 기업/전문가 스케줄, 기업 유형에 따라
                    기간은 변경될 수 있습니다.)
                    <br />
                  </p>
                </div>
                <div>
                  <p className="fw-bold">[컨설팅 기간]</p>
                  <p>• 5일</p>
                  <p className="mt-lg-2">
                    <span style={{ color: "#ff3d0d" }}>✓</span> 기업/전문가의
                    스케줄과 기업 유형에 따라 기간은 변경될 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
