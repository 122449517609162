import React from "react";
import { useNavigate } from "react-router";

import MainCard from "../MainCard";

export default function Contents({ ebook, contents }) {
  const blogQna = contents?.filter((content) => content.blog_type_cd === "QNA");

  const navigate = useNavigate();

  return (
    <section className="position-relative text-dark">
      <div className="container px-lg-11 py-lg-4 pt-5 pb-1">
        <div className="row">
          <div className="mt-lg-0">
            <h5 className="lh-base my-3">
              <span className="">CEO </span>
              <span className="fw-bold">Q&A</span>
            </h5>
          </div>
          {contents && (
            <>
              <MainCard
                key={blogQna[0]?.idx}
                thumbnail={blogQna[0]?.thumbnail}
                title={blogQna[0]?.title}
                type="Blog"
                url={`/blog/content/${blogQna[0].idx}`}
              />
              <MainCard
                thumbnail={blogQna[1]?.thumbnail}
                title={blogQna[1]?.title}
                type="Blog"
                url={`/blog/content/${blogQna[1]?.idx}`}
              />
              <MainCard
                thumbnail={blogQna[2]?.thumbnail}
                title={blogQna[2]?.title}
                type="Blog"
                url={`/blog/content/${blogQna[2]?.idx}`}
              />
              <MainCard
                thumbnail={blogQna[3]?.thumbnail}
                title={blogQna[3]?.title}
                type="Blog"
                url={`/blog/content/${blogQna[3]?.idx}`}
              />
            </>
          )}
          {/* {ebook && (
            <MainCard
              thumbnail={ebook[0]?.thumbnail}
              title={ebook[0]?.title}
              type="Guide book"
              url="/ebook/1"
            />
          )} */}
          <div className="d-flex flex-center mt-lg-4 mt-3">
            <p
              className="width-10x small text-muted text-mono text-center py-lg-1"
              style={{
                border: "1px solid #c8c8c8",
                borderRadius: "50px",
                fontSize: "0.8rem",
                cursor: "pointer",
              }}
              onClick={() => navigate("/blog/qna")}
            >
              더보기
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
