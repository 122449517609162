import { atom, selector, selectorFamily } from "recoil";
import resourceApi from "../api/resource";

export const contentsState = atom({
  key: "contentsState",
  default: null,
});

export const ebooksState = selector({
  key: "ebooksState",
  get: async () => {
    const response = await resourceApi.getEbookList();

    return response.data;
  },
});

export const selectedEbookState = selectorFamily({
  key: "selectedEbookState",
  get:
    (index) =>
    ({ get }) => {
      const ebookList = get(ebooksState);
      const selectedEbook = ebookList.find(
        (ebook) => ebook.idx === Number(index)
      );

      return selectedEbook;
    },
});

export const blogState = selector({
  key: "blogState",
  get: async () => {
    const response = await resourceApi.getContents();

    return response.data;
  },
});
