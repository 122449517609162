import React from "react";

export default function FreeIncorporation() {
  return (
    <main className="position-relative ">
      <div className="container py-lg-6 px-lg-11">
        <div className="row">
          <div className="col-lg-7 flex-center my-2">
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_thumbnail.jpg"
              alt="ir image"
              className="w-lg-80 w-100"
            />
          </div>
          <div className="col-lg-5">
            <div className="mx-lg-2 small text-muted">
              IR 디자인 컨설팅 &gt;
            </div>
            <h5 className="mb-lg-4 px-lg-2">
              3,000억 투자유치의 비밀: 에센셜 팩
            </h5>
            {/* <TossPayments
              price="1000000"
              title="IR PACK"
              type="IR service"
              url="ir_pack"
            /> */}
          </div>
        </div>
      </div>
    </main>
  );
}
