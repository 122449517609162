import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import TossPayments from "../components/tossPayments";
import Review from "../components/Review";
import ScrollToTop from "../components/ScrollToTop";
import ServiceBox from "../components/ServiceBox";
import { selectedServiceState } from "../store/reviewState";

const TabContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #fec000;
  }
`;

export default function PrFullPack() {
  const [isChecked, setIsChecked] = useState([]);
  const [checkedService, setCheckedService] = useState(undefined);
  const [checkedTitle, setCheckedTitle] = useState("");
  const [checkedProductCode, setCheckedProductCode] = useState("");

  const infoRef = useRef(null);
  const reviewRef = useRef(null);
  const detailInfoRef = useRef(null);
  const refundInfoRef = useRef(null);

  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const amount = params.get("amount");

  // const reviews = useRecoilValue(selectedServiceState("B-23C-PRMP-01"));

  const services = [
    { name: "2D 스탠다드", primeCost: "2000000", price: "1000000" },
    { name: "2D 프리미엄", primeCost: "2500000", price: "2500000" },
    { name: "3D 스탠다드", primeCost: "5000000", price: "3000000" },
    { name: "3D 프리미엄", primeCost: "10000000", price: "5000000" },
  ];

  const onClickTap = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onClickCheckOne = (event, name, primeCost, price) => {
    if (event.currentTarget.checked) {
      setIsChecked([name]);
      setCheckedService({ name, primeCost, price });
    } else {
      setIsChecked(isChecked.filter((check) => check !== name));
      setCheckedService(checkedService.filter((check) => check !== name));
    }
  };

  useEffect(() => {
    switch (amount) {
      case "1000000":
        setCheckedTitle("2D 스탠다드");
        setCheckedProductCode("B-23C-PMPM-06");
        break;
      case "2500000":
        setCheckedTitle("2D 프리미엄");
        setCheckedProductCode("B-23C-PMPM-07");
        break;
      case "3000000":
        setCheckedTitle("3D 스탠다드");
        setCheckedProductCode("B-23C-PMPM-08");
        break;
      case "5000000":
        setCheckedTitle("3D 프리미엄");
        setCheckedProductCode("B-23C-PMPM-09");
        break;
    }
  }, [amount]);

  return (
    <main
      className="position-relative pt-lg-0 pt-3"
      data-bs-spy="scroll"
      data-bs-target="#navbar-secondary"
    >
      <div className="container py-lg-6 px-lg-11">
        <ScrollToTop />
        <div className="row align-items-start">
          <div className="col-lg-7 flex-center">
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/pr_list/datz_thumbnail.jpg"
              alt="datz pack image"
              className="img-fluid w-lg-70 w-70 mb-lg-3"
              style={{ borderRadius: "5px" }}
            />
          </div>
          <div className="col-lg-5">
            <TossPayments
              primeCost={!isChecked.length ? 0 : checkedService?.primeCost}
              price={!isChecked.length ? 0 : checkedService?.price}
              discount={
                !isChecked.length
                  ? 0
                  : Number(checkedService?.primeCost) -
                    Number(checkedService?.price)
              }
              title={!isChecked.length ? checkedTitle : checkedService?.name}
              type="홍보 및 마케팅 지원 > 홍보 및 미디어"
              productCode={checkedProductCode}
              // page code DB 변경해야해서 안바꿈
              pageCode="B-23C-PMPM-00"
              pageTitle="제품-서비스 홍보 영상 풀패키지"
              url="pr_full_package"
            >
              <div
                className="my-lg-2 my-2 px-lg-3 px-2 py-lg-2 py-1"
                style={{
                  border: "1px solid #c8c8c8",
                  borderRadius: "5px",
                }}
              >
                {services.map((service, index) => (
                  <div className="form-check my-1" key={index}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      name={service.name}
                      id={service.name}
                      checked={isChecked.includes(service.name)}
                      onChange={(event) =>
                        onClickCheckOne(
                          event,
                          service.name,
                          service.primeCost,
                          service.price
                        )
                      }
                    />
                    <label className="form-check-label" htmlFor={service.name}>
                      {service.name}{" "}
                      <span
                        className="ms-2"
                        style={{
                          color: "#ff3d0d",
                          textDecoration: "line-through",
                        }}
                      >
                        {" "}
                        {Number(service.primeCost).toLocaleString("ko-KR")} 원
                      </span>{" "}
                      → {Number(service.price).toLocaleString("ko-KR")} 원
                    </label>
                  </div>
                ))}
                <p className="mb-0">
                  ∙ 컨셉 및 기획 , 성우 나레이션 삽입, 촬영 요청 시 비용 협의
                </p>
              </div>
              <div
                className="py-lg-3 py-3"
                style={{ borderBottom: "1px solid #c8c8c8" }}
              >
                <div>
                  <p className="fw-bold">
                    🎁 회원가입 후 서비스를 구매하시면 아래와 같은 혜택들이
                    제공됩니다!
                  </p>
                  <p className="ps-lg-2 ps-2 small">
                    • 부스타 컨설팅 30% 할인 (세무, 인사노무, 법률, 마케팅)
                    <br /> • 스타트업 무료 세무 상담 제공 (1회)
                    <br />• 부스타 오프라인 세미나 우선 참석권 (1회)
                  </p>
                </div>
              </div>
              <div className="py-lg-3 py-3">
                <div>
                  <span className="fw-bolder small text-muted">분야</span>
                  <span className="ms-lg-2 ms-2 small text-muted">
                    제품 소개 영상(2D/3D), 회사 소개 영상(2D/촬영), 서비스(기술)
                    소개 영상(2D
                  </span>
                </div>
                <div>
                  <span className="fw-bolder small text-muted">
                    진행 전문가
                  </span>
                  <span className="ms-lg-2 ms-2 small text-muted">
                    닷즈커뮤니케이션
                  </span>
                </div>
                <div>
                  <span className="fw-bolder small text-muted">진행 과정</span>
                  <span className="ms-lg-2 ms-2 small text-muted">
                    유선 상담 &gt; 비용 협의 및 사전조사 &gt; 영상 콘텐츠 제작
                  </span>
                </div>
                <div>
                  <span className="fw-bolder small text-muted">기간</span>
                  <span className="ms-lg-2 ms-2 small text-muted">
                    전문가와 협의
                  </span>
                </div>
                <div>
                  <span className="fw-bolder small text-muted">안내사항</span>
                  <span className="ms-lg-2 ms-2 small text-muted">
                    전문가의 업무스케줄에 따라 업무 착수일이 늦어질 수 있습니다.
                  </span>
                </div>
              </div>
            </TossPayments>
          </div>
        </div>
        <div>
          <div
            className="row my-lg-4 my-1 py-lg-0 py-2 mx-lg-0 mx-0"
            style={{ border: "1px solid #c8c8c8", borderRadius: "5px" }}
          >
            <TabContainer
              className="col-lg-3 col-3 px-0"
              onClick={() => onClickTap(infoRef)}
            >
              <div
                className="py-lg-1 text-center button"
                style={{ borderRight: "1px solid #c8c8c8" }}
              >
                서비스 설명
              </div>
            </TabContainer>
            <TabContainer
              className="col-lg-3 col-3 px-0"
              onClick={() => onClickTap(reviewRef)}
            >
              <div
                className="py-lg-1 px-0 text-center button"
                style={{ borderRight: "1px solid #c8c8c8" }}
              >
                리뷰
              </div>
            </TabContainer>
            <TabContainer
              className="col-lg-3 col-3 px-0"
              style={{ borderRight: "1px solid #c8c8c8" }}
              onClick={() => onClickTap(detailInfoRef)}
            >
              <div className="py-lg-1 text-center button">상세정보</div>
            </TabContainer>
            <TabContainer
              className="col-lg-3 col-3 px-0"
              onClick={() => onClickTap(refundInfoRef)}
            >
              <div className="py-lg-1 text-center button">취소환불</div>
            </TabContainer>
          </div>
          <div className="pb-lg-5 d-flex flex-center flex-column" ref={infoRef}>
            <img
              src="https://cdn-s3.boostar.co.kr/boostar/pr_list/datz_detail.jpg"
              className="w-lg-70 w-100"
            />
          </div>
          <div className="mt-3">
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2 mb-lg-3 mb-3"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              다른 고객이 함께 구매한 상품
            </h5>
            <div className="row">
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/pr_list/pr_video_pack_list_thumbnail_230504.jpg"
                title="기업 맞춤 올인원 영상 팩"
                info="홍보 및 미디어"
                rating="0.0"
                review="0"
                onClick={() => navigate("/pr_video_pack")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/ir/ir_list_thumbnail_230327.jpg"
                title="3,000억 투자유치의 비밀: 에센셜팩"
                info="투자 유치 컨설팅"
                rating="0.0"
                review="0"
                onClick={() => navigate("/ir_pack")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/global_business/global_pr_list_thumbnail_230609.jpg"
                title="1분만에 글로벌 진출하기"
                info="해외 진출 지원"
                rating="0.0"
                review="0"
                onClick={() => navigate("/global_pr_pack")}
              />
              <ServiceBox
                image="https://cdn-s3.boostar.co.kr/boostar/ir/ir_pitching_list_thumbnail.jpg"
                title="투자 유치 받는 IR 피칭"
                info="투자 유치 컨설팅"
                salePrice="400,000 원"
                price="600,000 원"
                discount="33%"
                rating="0"
                review="0"
                onClick={() => navigate("/ir_pitching")}
              />
            </div>
          </div>
          {/* <div className="my-lg-3 my-3" ref={reviewRef}>
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              리뷰
            </h5>
            <div className="px-lg-2 px-2 py-lg-3 py-3">
              {reviews.map((review, index) => (
                <Review
                  key={index}
                  rating={Number(review.rating)}
                  content={review.content}
                  email={review.email}
                  createAt={review.create_at}
                />
              ))}
            </div>
          </div> */}
          <div className="my-lg-3 my-3" ref={detailInfoRef}>
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              상세정보
            </h5>
            <div className="px-lg-2 px-2 py-lg-3 py-3">
              <div className="">
                <p className="mb-lg-2">
                  • 본 서비스는 기업 홍보물(영상 콘텐츠,언론 기사,사진)을
                  제작·제공하는 서비스입니다.
                </p>
                <div className="mb-lg-2">
                  <p className="fw-bold">[서비스 분야]</p>
                  <p className="ms-lg-1">
                    제품 소개 영상(2D/3D), 회사 소개 영상(2D/촬영), 서비스(기술)
                    소개 영상(2D)
                  </p>
                </div>
                <div className="mb-lg-2">
                  <p className="fw-bold">[서비스 과정]</p>
                  <p className="ms-lg-1">
                    • 결제를 완료하시면 결제완료 메일과 함께 영수증과 계약서가
                    발송됩니다. <br />
                    • 결제후 1영업일 이내에 부스타 담당자가 연락을 드려 문제
                    내용을 확인하고, 서비스 일정 조율을 해드립니다.
                    <br />• 상담이후 정해진 시간에 전문가와 상담을 받으실수
                    있습니다.
                  </p>
                </div>
                <div>
                  <p className="fw-bold">[서비스 기간]</p>
                  <p>• 서비스 제공 일정은 사전 협의에 따라 결정됩니다.</p>
                  <p className="mt-lg-2">
                    <span style={{ color: "#ff3d0d" }}>✓</span> 기업/전문가의
                    스케줄과 기업 유형에 따라 기간은 변경될 수 있습니다.
                  </p>
                </div>
                <div>
                  <p className="fw-bold">[서비스 담당 전문가]</p>
                  <p>(주) 닷즈커뮤니케이션</p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-lg-3 my-3" ref={refundInfoRef}>
            <h5
              className="px-lg-2 px-2 pb-lg-2 pb-2"
              style={{ borderBottom: "1px solid #c8c8c8" }}
            >
              취소/환불
            </h5>
            <div className="px-lg-2 px-2 py-lg-3 py-3">
              <div>
                <p className="fw-bold mb-0">[기본 환불 규정]</p>
                <p className="px-lg-1 px-3">
                  • 구매일로부터 7일 이내에 서비스 또는 서비스가 제공되지
                  않았다면 전문가와 의뢰인의 상호 협의 하에 청약 철회 및 환불이
                  가능합니다. 단 서비스 또는 서비스가 개시되었다면, 이용한
                  일수를 제외하고 일할 계산으로 환불이 진행됩니다.
                  <br />• 컨설팅이 완료된 이후 또는 자료, 프로그램 등 서비스가
                  제공된 이후에는 환불이 불가합니다. (소비자보호법 17조 2항의
                  5조. 용역 또는 「문화산업진흥 기본법」 제2조제5호의
                  디지털콘텐츠의 제공이 개시된 경우에 해당)
                </p>
              </div>
              <div>
                <p className="fw-bold mb-0">[전문가 책임 사유]</p>
                <p className="px-lg-1 px-3">
                  • 전문가의 귀책사유로 당초 약정했던 서비스 미이행 혹은
                  보편적인 관점에서 심각하게 잘못 이행한 경우 결제 금액 전체
                  환불이 가능합니다.
                </p>
              </div>
              <div>
                <p className="fw-bold mb-0">[의뢰인 책임 사유]</p>
                <p className="px-lg-1 px-3">
                  • 서비스 진행 도중 의뢰인의 귀책사유로 인해 환불을 요청할
                  경우, 사용 금액을 아래와 같이 계산 후 총 금액의 10%를 공제하여
                  환불합니다.
                  <br /> • 총 서비스 시간의 1/3 경과 전 : 이미 납부한 요금의
                  2/3해당액
                  <br /> • 총 서비스 시간의 1/2 경과 전 : 이미 납부한 요금의
                  1/2해당액
                  <br /> • 총 서비스 시간의 1/2 경과 후 : 반환하지 않음 <br />
                  <p className="mt-lg-2">
                    <span style={{ color: "#ff3d0d" }}>✓</span> 상담 진행 중
                    의뢰인의 폭언, 욕설 등이 있을 경우 상담은 종료되며 잔여
                    이용요금은 환불되지 않습니다.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
