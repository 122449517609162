import React from "react";

export default function Prizes() {
  return (
    <section>
      <div className="container d-flex flex-center flex-column">
        <div className="mx-lg-5 w-lg-90">
          <div className="fw-bolder mb-lg-4 mt-5" style={{ color: "#2196F3" }}>
            PRIZES
          </div>
          <div className="">
            <h1 className="fs-1 mb-lg-4 my-3 fw-bolder">총 $100,000의 혜택!</h1>
            <img
              src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/pirzes.jpeg"
              className="img-fluid mb-lg-4"
            />
            <div className="mb-lg-2 mt-5 mb-2">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 우승자</span>
            </div>
            <div>
              <p className="ms-lg-2">
                • 현금 $2,000 <br />
                • 1년간 오피스 지원 패키지 ($4,000) <br />
                • 디지털 투자 플랫폼에서 자금 모금을 위한 1일 무료 스타트업
                쇼케이스
                <br />
                • 베트남 스타트업 데이에서 2일간 무료 전시 부스 ($2,000 달러
                상당)
                <br />• 팀원 4명까지 2023 베트남 스타트업 데이에 참가할 수 있는
                무료 티켓 제공
                <br /> • Startup Wheel과 베트남의 주요 미디어 파트너들을 통해
                로컬 노출 기회 제공
              </p>
            </div>
            <div className="mb-lg-2 mt-5 mb-2">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> International Track Top 5</span>
            </div>
            <div>
              <p className="ms-lg-2">
                • 디지털 투자 플랫폼에서 자금 모금을 위한 1일 무료 스타트업
                쇼케이스
                <br />
                • 베트남 스타트업 데이에서 2일간 무료 전시 부스 ($2,000 달러
                상당)
                <br />
                • 팀원 4명까지 2023 베트남 스타트업 데이에 참가할 수 있는 무료
                티켓 제공
                <br />• Startup Wheel과 베트남의 주요 미디어 파트너들을 통해
                로컬 노출 기회 제공
              </p>
            </div>
            <div className="mb-lg-2 mt-5 mb-2">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> International Track Top 50</span>
            </div>
            <div>
              <p className="ms-lg-2">
                • 디지털 투자 플랫폼에서 자금 모금을 위한 1일 무료 스타트업
                쇼케이스
                <br />
                • 베트남 스타트업 데이에서 2일간 전시 부스 75% 할인
                <br />
                • 팀원 4명까지 2023 베트남 스타트업 데이에 참가할 수 있는 무료
                티켓 제공
                <br />• Startup Wheel과 베트남의 주요 미디어 파트너들을 통해
                로컬 노출 기회 제공
              </p>
            </div>
            <div className="mb-lg-2 mt-5 mb-2">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder">
                {" "}
                Business office support at the center of HCM Cỉty
              </span>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <img
                  src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/bssc_co_working_space.jpeg"
                  className="img-fluid"
                />
                <p className="fw-bolder text-center mt-lg-2 mt-2">
                  BSSC Co-working Space
                </p>
              </div>
              <div className="col-lg-4">
                <img
                  src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/ys_office.jpeg"
                  className="img-fluid"
                />
                <p className="fw-bolder text-center mt-lg-2 mt-2">Y5 Office</p>
              </div>
              <div className="col-lg-4">
                <img
                  src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/phunhuan.jpeg"
                  className="img-fluid"
                />
                <p className="fw-bolder text-center mt-lg-2 mt-2">
                  Phú Nhuận Co-working Space
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
