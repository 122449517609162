import React from "react";

import Intro2 from "../components/Service/Intro2";
import RightImage from "../components/RightImage";
import LeftImage from "../components/LeftImage";
import More from "../components/More";

import introImage from "../assets/img/image/nongoverment_intro.png";
import inHouseImage from "../assets/img/image/in_house_program.png";
import educationImage from "../assets/img/image/education_program.png";
import innovationImage from "../assets/img/image/innovation_program.png";

export default function Nongovernment() {
  return (
    <main>
      <Intro2
        image={introImage}
        title1="BOOSTAR for"
        title2="민간부문"
        subTitle="BOOSTAR는 기업, 투자사와 협약을 맺고 다양한 프로그램을 제공합니다."
        url="/contact"
      />
      <RightImage
        image={inHouseImage}
        title="사내 벤처 프로그램 운영"
        paragraph1="내용삽입"
      />
      <LeftImage
        image={educationImage}
        title="교육 프로그램 제공"
        paragraph1="내용삽입"
      />
      <RightImage
        image={innovationImage}
        title="오픈 이노베이션 프로그램 운영"
        paragraph1="내용삽입"
      />
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
