import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "./Button";
import SnsLogin from "./GoogleLogin";
import KakaoLogin from "./KakaoLogin";
import ModalContainer from "./Modal";
import NaverLogin from "./NaverLogin";

export default function LoginModal({ userInfo }) {
  const [modalShow, setModalShow] = useState(false);

  const accessToken = localStorage.getItem("accessToken");

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      return;
    }

    if (!userInfo && !accessToken) {
      setModalShow(true);
    }
  }, [userInfo]);
  return (
    <ModalContainer
      show={modalShow}
      content="로그인 정보가 만료되어 로그아웃되었습니다. 다시 로그인 후 서비스를 이용해주세요 "
      onHide={() => setModalShow(false)}
    >
      <div className="d-flex flex-center mt-lg-4">
        <Button
          width="8"
          background="#f4f4f5"
          color="#797979"
          text="가입하기"
          border="none"
          onClick={() => navigate("/signup")}
        />
        <Button
          width="8"
          background="#fef4e5"
          color="#fbb60d"
          text="로그인하기"
          border="none"
          onClick={() => navigate("/login")}
        />
      </div>
      <div className="d-flex align-items-center py-3">
        <span className="flex-grow-1 border-bottom pt-1"></span>
        <span className="d-inline-flex flex-center lh-1 width-2x height-2x rounded-circle bg-white text-mono">
          or
        </span>
        <span className="flex-grow-1 border-bottom pt-1"></span>
      </div>
      <p className="text-center">3초안에 빠르게 회원가입/로그인 하기</p>
      <div className="d-flex justify-content-center px-lg-10 my-lg-2">
        <KakaoLogin url="/" />
        <NaverLogin url="/" />
        <SnsLogin url="/" />
      </div>
    </ModalContainer>
  );
}
