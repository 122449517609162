import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import ModalContainer from "../components/Modal";
import TextInput from "../components/TextInput";
import GoogleLogin from "../components/GoogleLogin";
import NaverLogin from "../components/NaverLogin";
import KakaoLogin from "../components/KakaoLogin";
import Button from "../components/Button";
import userApi from "../api/user";

import {
  EMAIL_REGEXP,
  NAME_REGEXP,
  PASSWORD_REGEXP,
  PHONE_REGEXP,
} from "../constants/RegExp";
import step1Image from "../assets/img/graphics/icons/write1.png";
import step2Image from "../assets/img/graphics/icons/partnership1.png";
import step3Image from "../assets/img/graphics/icons/interaction1.png";

export default function Signup() {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [isMarketingModalShow, setIsMarketingModalShow] = useState(false);
  const [userData, setUserData] = useState({
    name: location.state ? location.state.name : "",
    phone: location.state ? location.state.phone : "",
    email: location.state ? location.state.email : "",
    password: "",
    checkedPassword: "",
  });

  const steps = [
    {
      image: step1Image,
      content: "회원가입을 해주세요",
    },
    {
      image: step2Image,
      content: "궁금하신 점에 대해 상담을 신청해주세요",
    },
    { image: step3Image, content: "신청 후 빠른 시일 내에 상담이 진행됩니다" },
  ];

  const { name, phone, email, password, checkedPassword } = userData;

  const isValidName = NAME_REGEXP.test(name);
  const isValidEmail = EMAIL_REGEXP.test(email);
  const isValidPhone = PHONE_REGEXP.test(phone);
  const isValidPassword = PASSWORD_REGEXP.test(password);
  const isValidCheckedPassword = PASSWORD_REGEXP.test(checkedPassword);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorMessage("");
  });

  const handleMarketingClick = () => {
    setMarketing(!marketing);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (password !== checkedPassword) {
      setErrorMessage("✓ 비밀번호가 일치하지 않습니다.");

      return;
    }

    if (
      !name.replaceAll(" ", "").length ||
      !phone.replaceAll(" ", "").length ||
      !email.replaceAll(" ", "").length ||
      !password.replaceAll(" ", "").length ||
      !checkedPassword.replaceAll(" ", "").length
    ) {
      setIsEmpty(true);

      return;
    }

    if (
      isValidName &&
      isValidEmail &&
      isValidPhone &&
      isValidPassword &&
      isValidCheckedPassword
    ) {
      try {
        const response = await userApi.signup({
          name: name,
          phone: phone.replaceAll("-", ""),
          email: email,
          password: password,
          marketing: marketing,
        });

        if (response.result === "success") {
          const accessToken = response.data.accessToken;

          localStorage.setItem("accessToken", accessToken);

          setModalShow(true);
        }
      } catch (err) {
        if (err.response.data.error.message === "User") {
          setErrorMessage("✓ 이미 등록된 이메일입니다.");
        }
      }
    }
  };

  useEffect(() => {
    if (phone?.length === 11) {
      setUserData((prevSate) => ({
        ...prevSate,
        phone: phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }

    if (phone?.length === 13) {
      setUserData((prevState) => ({
        ...prevState,
        phone: phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }
  }, [phone]);

  return (
    <main>
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container px-lg-11 py-8 py-lg-6">
          <div className="row align-items-center">
            <div className="col-lg-6 me-auto mb-5 justify-content-center">
              <h2 className="mx-lg-3 mb-lg-3 mb-5">
                스타트업 성장에 대한 고민,
                <br /> BOOSTAR가 바로 상담해드립니다
              </h2>
              <p className="fs-5 mb-lg-10 mx-lg-3">
                사이트에 회원가입을 하시면,
                <br /> 다양한 투자유치 관련 콘텐츠와 정보를 얻으실 수 있습니다.
              </p>
              {steps.map((step, index) => (
                <div
                  className="d-inline-flex w-lg-70 w-100"
                  style={{ borderBottom: "1px solid #d3d3d3" }}
                  key={index}
                >
                  <img
                    src={step.image}
                    className="img-fluid px-lg-3 py-lg-3 px-4 py-4"
                    alt="write"
                  />
                  <div className="me-2">
                    <p
                      className="mb-0"
                      style={{ color: "#FEC72F", fontWeight: "700" }}
                    >
                      {`Step ${index + 1}`}
                    </p>
                    <p className="mb-0">{step.content}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-6">
              <div
                className="py-10 px-7 shadow w-lg-90 mx-lg-11"
                style={{
                  background: "#ffffff",
                  borderRadius: "55px",
                }}
              >
                <form className="needs-validation" onSubmit={handleFormSubmit}>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>연락처</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="phone"
                      type="text"
                      placeholder="01012345678"
                      icon="bx-phone"
                      value={userData.phone || ""}
                      maxLength={13}
                      pattern={PHONE_REGEXP}
                      errorMessage="✓ 핸드폰번호를 확인해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 핸드폰번호를 확인해주세요."
                      onChange={handleChange}
                      autofocus
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>이름</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="name"
                      type="text"
                      placeholder="이름(실명)"
                      icon="bx-user"
                      value={userData.name || ""}
                      pattern={NAME_REGEXP}
                      errorMessage="✓ 이름을 확인해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이름을 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>이메일</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="email"
                      type="text"
                      placeholder="user@boostar.co.kr"
                      icon="bx-envelope"
                      value={userData.email || ""}
                      pattern={EMAIL_REGEXP}
                      errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 이메일을 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>비밀번호</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="password"
                      type="password"
                      placeholder="영문 + 숫자 + 특수문자, 8~16자리"
                      icon="bx-lock-open"
                      value={userData.password || ""}
                      pattern={PASSWORD_REGEXP}
                      errorMessage="✓ 영문 + 숫자 + 특수문자, 8~16자리로 입력해주세요."
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 비밀번호를 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-3">
                      <span style={{ color: "#3bb6d5" }}>비밀번호 확인</span>
                      <span style={{ color: "#ff3d0d" }}>*</span>
                    </div>
                    <TextInput
                      name="checkedPassword"
                      type="password"
                      placeholder="비밀번호 확인"
                      icon="bx-lock-open"
                      value={userData.checkedPassword || ""}
                      emptyValidation={isEmpty}
                      emptyMessage="✓ 비밀번호를 확인해주세요."
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-5">
                    <small className="text-danger">{errorMessage}</small>
                  </div>
                  <div className="mt-3 mb-5 d-flex flex-column justify-content-between">
                    <div className="form-check">
                      <label
                        className="form-check-label small text-muted"
                        htmlFor="flexCheckDefault"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="이용약관"
                          required
                        />
                        이용약관 (필수) &nbsp;
                      </label>
                      <Link
                        to="/agreement"
                        className="fw-semibold link-decoration small"
                      >
                        자세히
                      </Link>
                    </div>
                    <div className="form-check">
                      <label
                        className="form-check-label small text-muted"
                        htmlFor="flexCheckDefault"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="이용약관"
                          required
                        />
                        개인정보 수집 및 이용 (필수) &nbsp;
                      </label>
                      <Link
                        to="/privacy"
                        className="fw-semibold link-decoration small"
                      >
                        자세히
                      </Link>
                    </div>
                    <div className="mb-3 d-flex justify-content-between">
                      <div className="form-check">
                        <label className="form-check-label small text-muted">
                          <input
                            className="form-check-input"
                            name="newsLetterAssignment"
                            onChange={handleMarketingClick}
                            checked={marketing}
                            type="checkbox"
                          />
                          마케팅 수신 정보 이용 동의 (선택){" "}
                        </label>
                        <span
                          className="small ps-lg-1 ps-1 link-decoration"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsMarketingModalShow(true)}
                        >
                          자세히
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button
                      className="btn"
                      style={{
                        border: "none",
                        background: "#fef4e5",
                        color: "#fbb60d",
                      }}
                    >
                      회원가입
                    </button>
                  </div>
                </form>
                <div className="d-flex align-items-center py-3">
                  <span className="flex-grow-1 border-bottom pt-1"></span>
                  <span className="d-inline-flex flex-center lh-1 width-2x height-2x rounded-circle bg-white text-mono">
                    or
                  </span>
                  <span className="flex-grow-1 border-bottom pt-1"></span>
                </div>
                <div className="mb-lg-1" />
                <div className="d-grid" style={{ justifyItems: "center" }}>
                  <KakaoLogin />
                  <NaverLogin />
                  <GoogleLogin />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalContainer
        show={modalShow}
        title="환영합니다 🙂"
        content="회원가입이 완료되었습니다."
        onHide={() => setModalShow(false)}
      >
        <div className="mt-lg-3 mb-lg-2">
          <Button
            width="10"
            text="돌아가기"
            border="none"
            background="#f4f4f5"
            color="#797979"
            onClick={() => navigate("/")}
          />
        </div>
      </ModalContainer>
      <ModalContainer
        show={isMarketingModalShow}
        content="마케팅 수신 정보 이용 동의"
        onHide={() => setIsMarketingModalShow(false)}
      >
        <p className="small">
          회원님들께 더 나은 서비스를 제공하고자 광고 ∙ 마케팅 목적(이벤트 안내,
          공지사항, 할인행사)의 개인정보 수집 및 이용에 대한 동의를 받고자
          합니다. 내용을 자세히 읽으신 후, 동의를 원하시면 원래의 화면으로
          돌아가 “동의”, “계속”, “동의하고 진행” 등 해당 화면의 안내에 따라
          버튼을 눌러 주시기 바랍니다. 보다 자세한 내용은 회사의 이용 약관을
          참조하여 주시기 바랍니다.
        </p>
        <p className="small mt-lg-3 ps-lg-2 mt-3 px-2">
          • 목적 : BOOSTAR에서 운영하는 서비스에 대한 광고 ∙ 홍보 ∙ 프로모션
          제공 <br />
          • 정보 수신 채널 : 이메일, SMS, 카카오톡 <br />• 개인정보 보유 및
          이용기간 : 광고 ∙ 마케팅 목적의 개인정보 수집 ∙ 이용에 대한 동의
          시부터 동의 철회 시 또는 회원 탈퇴 시까지
          <br />• 광고 ∙ 마케팅 항목은 선택사항이므로 동의를 거부하는 경우에도
          BOOSTAR 서비스의 이용에는 영향이 없습니다. 또한, 광고 ∙ 마케팅 동의는
          고객센터를 통해 언제든지 철회할 수 있습니다.
        </p>
        <div className="d-flex flex-center mt-lg-3 mt-3">
          <Button
            width="13"
            text="닫기"
            border="none"
            background="#fec230"
            color="#000000"
            onClick={() => setIsMarketingModalShow(false)}
          />
        </div>
      </ModalContainer>
    </main>
  );
}
