import React from "react";

import logo from "../assets/img/logo/paperlogy.jpg";
import Button from "../components/Button";

export default function PaperlogyDetail() {
  return (
    <main>
      <section className="positive-relative d-flex align-items-center text-white">
        <div className="w-100" style={{ background: "#000000" }}>
          <div className="container position-relative py-8 py-lg-6">
            <div className="row col-lg-8 align-items-center my-lg-3">
              <div className="mb-3 mb-lg-0">
                <h2 className="display-5 mb-2" style={{ color: "#fec230" }}>
                  페이퍼로지 (와이즈라이온)
                </h2>
                <p className="lh-sm mb-lg-4 ps-lg-2">비즈니스 컨설팅, 마케팅</p>
                <Button
                  width="10"
                  text="Contact"
                  background="fef4e5"
                  color="#fbb60d"
                  border="none"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@paperlogy/featured",
                      "_blank"
                    )
                  }
                />
                {/* <p className="fs-5 lh-sm mb-lg-4">비즈니스 컨설팅, 마케팅</p>
                <h2 className="display-5 mb-5">페이퍼로지 (와이즈라이온)</h2>
                <a href="#" target="_blank" rel="noreferrer">
                  <button
                    className="btn text-dark width-13x mt-3 mt-lg-2"
                    style={{ background: "#fec72f", borderRadius: "0.37" }}
                  >
                    Contact
                  </button>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative mt-lg-10 mb-lg-8">
        <div className="container">
          <div className="row flex-lg-row-reverse justify-content-center">
            <div className="col-lg-3 my-5">
              <img
                src={logo}
                alt="ground-up ventures logo"
                className="w-lg-80 w-100 mb-lg-6"
              />
              <div className="mb-lg-5 my-3 ps-lg-3 px-3">
                <h4 className="fs-4 fw-bold" style={{ color: "#fec72f" }}>
                  비즈니스 타입
                </h4>
                <p>비즈니스 컨설팅, 마케팅</p>
              </div>
              <div className="mb-lg-5 my-3 ps-lg-3 px-3">
                <h4 className="fs-4 fw-bold" style={{ color: "#fec72f" }}>
                  웹사이트
                </h4>
                <a href="#" target="_blank" rel="noreferrer"></a>
              </div>
            </div>
            <div className="col-lg-9 px-lg-6 px-3">
              <div className="ratio ratio-16x9 mb-lg-5">
                <iframe
                  width="853"
                  height="480"
                  src="https://www.youtube.com/embed/ttL8jWfxQIQ"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
              <div className="mb-lg-5">
                <h3 className="display-6 mb-lg-5 mb-3">소개</h3>
                <h4 className="fs-4 fw-bold mb-lg-5 mb-3">
                  상위 1%의 피피티를 위한 모든 노하우!
                </h4>
                <p>
                  안녕하세요! 유튜브 채널 ‘페이퍼로지’를 운영하고 있는 기획자
                  김도균입니다! 저는 현재 대표 기획자로서 회사를 운영하고
                  있어요! 기획과 PPT, 그리고 그것을 돈으로 만들기까지! 자기
                  생각을 타인에게 효과적으로 보여주는 것에 성공의 길이 있다고
                  믿으며 이를 끊임없이 연구하는 중이에요! 『돈되는 기획』,
                  『싸가지 없는 영어책』, 『걔네가 쓰는 영어』 등을 출간했으며,
                  회사 안팎에서 성공하는 기획자로 남기 위해 하루하루를 열심히
                  살아가고 있어요!
                </p>
              </div>
              <div className="mb-lg-5">
                <h4 className="fs-4 fw-bold mb-lg-5 mb-3">커리어</h4>
                <p>
                  • 現 WISELION, CEO
                  <br />
                  • 現 WISELION JAPAN, CEO
                  <br />
                  • 2016 휴먼카인드 원, CEO
                  <br />
                  • 2015 도미노 코리아 PM
                  <br />
                  • 2012 이노션 월드와이드 기획 (AE)
                  <br />
                  • 2010 레오버넷 코리아 기획 (AE)
                  <br />
                  • 2009 SM 엔터테인먼트 전략기획실
                  <br />• 한양대학교 광고홍보학 학사, 석사
                </p>
              </div>
              <div className="mb-lg-5">
                <h4 className="fs-4 fw-bold mb-lg-5 mb-3">Awards & Scores</h4>
                <p>
                  • 2011 클리오 (CLIO) 국제광고상 Silver & Bronze
                  <br />
                  • 2010 더알엑스 국제광고상 Gold & Exellence
                  <br />
                  • 2010 한양대학교 광고전공 석사 최우수 논문상
                  <br />
                  • Amazon Japan 외국어분야 베스트셀러 1 위<br />
                  • 알라딘, 교보문고 기획분야 베스트셀러 1 위<br />• 교보문고
                  SAM 외국어분야 베스트셀러 1 위 등
                </p>
              </div>
              <div className="mb-lg-5">
                <h4 className="fs-4 fw-bold mb-lg-5 mb-3">저서</h4>
                <p>
                  • 가장 쉬운 독학 보고서 첫걸음 (동양북스, 출간예정)
                  <br />
                  • 돈 되는 기획 (한빛비즈)
                  <br />
                  • 싸가지 없는 영어책 (휴먼카인드북스)
                  <br />
                  • 걔네가 쓰는 영어 (휴먼카인드북스)
                  <br />
                  • 知らないと危険な英語表現 (IBC)
                  <br />• スピーキングのための英熟語 (IBC)
                </p>
              </div>
              <div className="mb-lg-5">
                <h4 className="fs-4 fw-bold mb-lg-5 mb-3">강의</h4>
                <p>
                  • 포스코그룹 (POSCO)
                  <br />
                  • 마이다스아이티
                  <br />
                  • 패스트캠퍼스 1, 2, 3<br />
                  • K-리그
                  <br />
                  • 항공우주연구원
                  <br />
                  • 한국PR 기업협회
                  <br />
                  • 건강보험심사평가원
                  <br />
                  • KIRD
                  <br />
                  • 서울대학교
                  <br />
                  • 고려대학교
                  <br />
                  • 연세대학교
                  <br />
                  • 동국대학교
                  <br />
                  • 가톨릭대학교
                  <br />
                  • 인하대학교
                  <br />• 젠지글로벌아카데미 등 다수
                </p>
              </div>
              <div className="mb-lg-5">
                <h3 className="display-6 mb-lg-5 mb-3">업무 분야</h3>
                <div>
                  <h4 className="fs-4 fw-bold mb-lg-3 mb-3">
                    #기획서 #PPT #발표 #파워포인트 #프레젠테이션 #제안서
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
