import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, CloseButton } from "react-bootstrap";

import Button from "./Button";
import Rating from "./Rating";
import ModalContainer from "./Modal";
import reviewApi from "../api/review";

export default function ReviewUpdateModal(props) {
  const { onHide, reviewInfo, show } = props;

  const [review, setReview] = useState("");
  const [rating, setRating] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCheckModalShow, setIsCheckModalShow] = useState(false);

  const navigate = useNavigate();

  const handleTextareaChange = (event) => {
    setErrorMessage("");
    setReview(event.target.value);
  };

  const handleReviewUpdate = async (event) => {
    event.preventDefault();

    if (
      rating === 0 ||
      review.replaceAll(" ", "").length === 0 ||
      review.length < 10
    ) {
      setErrorMessage("✓ 별점과 리뷰를 10자 이상 남겨주세요");
    }
    try {
      if (
        review.replaceAll(" ", "").length !== 0 &&
        rating !== 0 &&
        review.length >= 10
      ) {
        const response = await reviewApi.updateReview({
          userId: reviewInfo.user_id,
          id: reviewInfo.idx,
          orderNumber: !reviewInfo.order_number
            ? undefined
            : reviewInfo.order_number,
          rating: rating,
          content: review,
        });

        if (response.result === "success") {
          onHide();
          setIsCheckModalShow(true);
          navigate("/review/list");
        }
      }
    } catch (err) {
      if (err.response.data.error.message === "DB ERROR") {
        alert(
          "리뷰를 수정하면서 문제가 발생했습니다. 잠시 후 다시 등록해주세요."
        );
      }
    }
  };

  useEffect(() => {
    if (rating !== 0) {
      setErrorMessage("");
    }
  }, [rating]);

  useEffect(() => {
    setRating(reviewInfo.rating);
    setReview(reviewInfo.content);
  }, [reviewInfo]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header
          className="justify-content-end py-lg-1 py-0"
          style={{ background: "#fffff", border: 0 }}
        >
          <CloseButton onClick={onHide} className="fs-4">
            <span className="input-icon">
              <i className={`bx bx-x`} />
            </span>
          </CloseButton>
        </Modal.Header>
        <Modal.Body
          className="my-lg-0 mx-lg-0 py-lg-0 px-lg-0 py-0 px-0"
          style={{ background: "#ffffff", borderRadius: "15px" }}
        >
          <div className="px-lg-5 px-3">
            <p className="text-center fw-bolder mb-0">리뷰 수정하기</p>
            <hr className="mt-2"></hr>
            <div className="text-center">
              <span>[{reviewInfo?.service_type}] </span>
              <span> {reviewInfo?.service_name}</span>
            </div>
            <div className="d-flex flex-column flex-center mt-lg-4">
              <p className="text-center">
                서비스는 어떠셨나요? 서비스에 대한{" "}
                <span className="fw-bolder" style={{ color: "#fec230" }}>
                  별점
                </span>
                을 매겨주세요 🙂
              </p>
              <Rating rating={rating} onClickStar={setRating} />
            </div>
            <p className="text-end mt-lg-5 mb-0">
              <span style={{ color: "#ff3d0d" }}>{review?.length}</span>/500
            </p>
            <textarea
              className="w-lg-100 w-100 height-13x mb-lg-1"
              placeholder="최소 10자 이상 남겨주세요."
              maxLength={500}
              value={review || ""}
              onChange={handleTextareaChange}
            />
          </div>
          <div className="px-lg-5 px-3 text-muted small mb-3">
            <small>• 최초 등록한 리뷰 기준으로 포인트가 지급됩니다.</small>
            <br />
            <small>
              • 상품과 무관하거나, 비속어가 포함된 리뷰, 동일한 문자의 반복 등
              부적합한 리뷰 작성은 통보없이 삭제 및 적립 혜택이 회수됩니다.
            </small>
            <br />
            <small>• 경고 누적 시 리뷰 작성이 제한될 수 있습니다.</small>
          </div>
          <div className="text-center mb-lg-2 mb-2">
            <p className="text-danger">{errorMessage}</p>
          </div>
          <div className="d-flex flex-center mb-lg-4 mb-3">
            <Button
              width="15"
              text="수정하기"
              border="none"
              background="#fef4e5"
              color="#fbb60d"
              onClick={handleReviewUpdate}
            />
          </div>
        </Modal.Body>
      </Modal>
      <ModalContainer
        show={isCheckModalShow}
        onHide={() => setIsCheckModalShow(false)}
        content="리뷰 수정이 완료되었습니다. 🙂"
      >
        <div className="mt-lg-3">
          <Button
            width="10"
            text="확인"
            border="none"
            background="#fef4e5"
            color="#fbb60d"
            onClick={() => setIsCheckModalShow(false)}
          />
        </div>
      </ModalContainer>
    </>
  );
}
