import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../components/ServiceBox";

export default function PrList() {
  const navigate = useNavigate();

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      <div className="container">
        <img
          src="https://cdn-s3.boostar.co.kr/boostar/pr_list/pr_list_image.jpg"
          className="img-fluid w-lg-100 mb-lg-4 px-lg-10"
        />
      </div>
      <div className="container py-lg-6 py-4 px-lg-11">
        <div className="mb-lg-4 mb-3">
          <h3
            className="fw-bolder ps-2"
            style={{ borderBottom: "1px solid #c8c8c8" }}
          >
            <span style={{ color: "#fbb60d" }}>기업</span> <br />
            홍보
          </h3>
        </div>

        <div className="row">
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/pr_list/media_list_thumbnail(230321).jpg"
            title="초특가 언론 홍보 패키지"
            info="기업 홍보"
            rating="0.0"
            review="0"
            onClick={() => navigate("/pr_media_pack")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/pr_list/pr_video_pack_list_thumbnail_230504.jpg"
            title="기업 맞춤 올인원 영상 팩"
            info="기업 홍보"
            rating="0.0"
            review="0"
            onClick={() => navigate("/pr_video_pack")}
          />
        </div>
      </div>
    </main>
  );
}
