import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "./Button";
import ModalContainer from "./Modal";

export default function Contact({ url, serviceName }) {
  const [isModalShow, setIsModalShow] = useState(false);

  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");

  const handleContactButtonClick = (event) => {
    event.preventDefault();

    if (!accessToken) {
      setIsModalShow(true);

      return;
    } else {
      navigate(url, {
        state: {
          service: serviceName,
        },
      });
    }
  };
  return (
    <div className="d-flex flex-center">
      <form onSubmit={handleContactButtonClick}>
        <div className="mt-lg-2">
          <div className="my-lg-1 my-1">
            <Button
              width="18"
              border="none"
              text="무료 상담하기"
              background="#000000"
              color="#fec230"
            />
          </div>
        </div>
      </form>
      <ModalContainer
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
        content="서비스를 시작하시려면 로그인을 해주세요 🙂"
      >
        <div className="mt-lg-3">
          <Button
            width="10"
            text="확인"
            border="none"
            background="#fef4e5"
            color="#fbb60d"
            onClick={() => navigate("/login?retUrl=" + location.pathname)}
          />
        </div>
      </ModalContainer>
    </div>
  );
}
