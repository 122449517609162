import React, { useState } from "react";
import { useRecoilValue } from "recoil";

import Button from "../Button";
import TextInput from "../TextInput";
import { userInfoState } from "../../store/userState";
import applyApi from "../../api/apply";

export default function FounderTeam({
  applyData,
  isEmpty,
  setIsEmpty,
  handleChange,
  handleChangeStage,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(token));

  const handleNextButtonClick = async () => {
    if (
      !applyData?.foundersProfile.replaceAll(" ", "").length ||
      !applyData?.vietnamTeam.length ||
      !applyData?.howToStartupWheel.length
    ) {
      setErrorMessage("✓ 필수 입력란을 확인해주세요.");
    } else {
      setErrorMessage("");
    }

    if (
      !applyData?.numberOfFounders.replaceAll(" ", "").length ||
      !applyData?.howToBoostar.replaceAll(" ", "").length
    ) {
      setIsEmpty(true);

      return;
    }

    if (
      applyData?.foundersProfile.length &&
      applyData?.vietnamTeam.length &&
      applyData?.howToStartupWheel.length &&
      applyData?.numberOfFounders.replaceAll(" ", "").length &&
      applyData?.howToBoostar.replaceAll(" ", "").length
    ) {
      try {
        const response = await applyApi.saveApplication({
          user_idx: userInfo?.user_idx,
          full_name: applyData.name,
          gender: applyData.gender,
          email: applyData.email,
          date_of_birth: applyData.birth,
          phone: applyData.phone.replaceAll("-", ""),
          current_title: applyData.currentTitle,
          company_name: applyData.officeName,
          company_short_desc: applyData.officeDescription,
          hq_location: applyData.location,
          est_date: applyData.establishmentDate,
          bm: applyData.businessModel,
          dev_stage: applyData.developmentStage,
          main_industry: applyData.mainIndustry,
          main_technology: applyData.mainTechnology,
          pitch_deck: applyData.pitchDeckLink,
          pitch_video_link: applyData.pitchingVideoLink,
          business_certification: applyData.certification,
          is_patent: applyData.patent,
          total_fund: applyData.totalFund,
          most_recently_funding_round: applyData.fundingRound,
          funding_amount_of_round: applyData.fundingAmount,
          number_of_founders: applyData.numberOfFounders,
          founders_profile: applyData.foundersProfile,
          is_team_in_vietnam: applyData.vietnamTeam,
          where_did_you_here: applyData.howToStartupWheel,
          how_know_boostar: applyData.howToBoostar,
        });

        if (response.result === "success") {
          handleChangeStage((prevState) => ({
            ...prevState,
            founderTeam: false,
            result: true,
          }));
        }
      } catch (err) {
        if (err) {
          alert(
            "이미 신청서를 제출하셨습니다. 신청서 확인하기에서 확인바랍니다."
          );
        }
      }
    }
  };

  return (
    <>
      {" "}
      <div className="my-3">
        <div>
          <p className="mb-0" style={{ marginLeft: "95%" }}>
            🌱
          </p>
          <div className="progress height-10">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: "96%" }}
              aria-valuenow="96"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
        <div className="w-lg-100 flex-center my-3">
          <div className="w-lg-50 mt-lg-3">
            <div className="my-lg-3">
              <span style={{ color: "#f44336" }}>▶︎</span>
              <span className="fw-bolder"> 창업자 & 팀</span>
              <p style={{ color: "#f44336" }}>
                ✓ 모든 정보는 영어로 작성해주셔야 합니다. 한글로 작성시 지원은
                무효가 됩니다.
              </p>
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>창업자 수</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <TextInput
                name="numberOfFounders"
                type="text"
                placeholder="3"
                icon="bx-group"
                value={applyData?.numberOfFounders || ""}
                emptyValidation={isEmpty}
                emptyMessage="✓ 창업자가 모두 몇명인지 입력해주세요."
                onChange={handleChange}
              />
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>창업자 프로필</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
                <span className="small"> (Linked in, CV, etc,..)</span>
              </div>
              <textarea
                name="foundersProfile"
                className="w-lg-100 w-100 height-13x mb-lg-1"
                value={applyData?.foundersProfile || ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
              />
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>
                  베트남에 회사의 대표나 팀이 있으신가요?
                </span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <select
                name="vietnamTeam"
                value={applyData?.vietnamTeam || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
              >
                <option value="" disabled>
                  YES OR NO
                </option>
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>
                  Startup Wheel 을 어떻게 아시게 되었나요?
                </span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <select
                name="howToStartupWheel"
                value={applyData?.howToStartupWheel || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
              >
                <option value="" disabled></option>
                <option value="Nominated by Corporation/Accelerators/Incubators/VCs">
                  기업, 액셀러레이터, 보육기관, 벤처캐피탈 등으로부터 추천
                </option>
                <option value="Social Media of BSSC and Startup Wheel(Facebook, Linkedin, EM,... ">
                  BSSC와 Startup Wheel의 소셜 미디어(Facebook, LinkedIn, EM 등)
                </option>
                <option value="Newspapers">기사</option>
                <option value="Other">기타</option>
              </select>
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>
                  부스타는 어떻게 아시게 되었나요?
                </span>
                <span style={{ color: "#ff3d0d" }}>*</span>
                <p className="small mb-0 ms-1">
                  (부스타웹페이지, 서울핀테크랩, 글로벌게임허브센터, 블로그,
                  인스타그램, 페이스북 등)
                </p>
              </div>
              <TextInput
                name="howToBoostar"
                type="text"
                placeholder=""
                icon="bx-rocket"
                value={applyData?.howToBoostar || ""}
                emptyValidation={isEmpty}
                emptyMessage="✓ 어떻게 아시게 되었는지 입력해주세요."
                onChange={handleChange}
              />
            </div>
            <div className="ms-1 mb-3">
              <small className="text-danger">{errorMessage}</small>
            </div>
            <p className="fw-bolder mt-5" style={{ color: "#f44336" }}>
              ✓ 입력해주신 모든 정보는 &apos;2023 베트남 Startup Wheel&apos;
              대회 심사의 목적으로만 사용되며, 다른 용도로는 사용되지 않습니다.{" "}
            </p>
            <div className="w-lg-100 w-100 flex-center mt-lg-3">
              <Button
                width="18"
                text="참가 신청하기"
                border="none"
                background="#f44336"
                color="#ffffff"
                onClick={handleNextButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
