import React from "react";
import { useNavigate } from "react-router-dom";

import boostarLogo from "../assets/img/logo/boostar_logo.png";
import Button from "./Button";

export default function Footer() {
  const date = new Date().getFullYear();
  const navigate = useNavigate();

  return (
    <footer
      id="footer"
      className="footer bg-white position-relative text-dark"
      style={{ borderTop: "2px solid #42445c" }}
    >
      <div className="container pt-lg-5 pt-3 pb-0">
        <div className="row flex-lg-row-reverse">
          <div className="col-lg-2 col-md-3 col-4 col-sm-4 ms-md-auto mb-5">
            <p className="fw-bold mb-3">About Us</p>
            <nav className="nav flex-column">
              <a className="nav-link" href="/about/boostar">
                About Boostar
              </a>
              <a className="nav-link" href="/partners">
                About Partners
              </a>
            </nav>
          </div>
          <div className="col-lg-2 col-4 col-sm-4 mb-5">
            <p className="fw-bold mb-3 ">Explore</p>
            <nav className="nav flex-column">
              <a
                className="nav-link"
                href="https://blog.naver.com/thepitchkorea"
                target="_black"
              >
                블로그
              </a>
            </nav>
          </div>
          <div className="col-lg-2 col-md-3 col-4 col-sm-4 mb-5">
            <p className="fw-bold mb-3">Join BOOSTAR</p>
            <nav className="nav flex-column">
              <a className="nav-link" href="contact">
                스타트업
              </a>
              <a className="nav-link" href="/contact">
                전문가
              </a>
              <a className="nav-link" href="/contact/partner">
                파트너되기
              </a>
            </nav>
          </div>
          <div className="col-lg-6 col-md-5 mb-3">
            <img
              src={boostarLogo}
              alt="boostar logo"
              className="w-lg-30 w-30 mb-3 mb-lg-2"
            />
            <p className="small mb-1">
              서울시 강남구 학동로 212, 포스트빌 505호 | 대표번호: 02-6956-1903
            </p>
            <p className="small mb-1">
              사업자번호: 594-86-02322 | 통신판매번호: 2022-서울강남-00536
            </p>
            <p className="small mb-1">주식회사 메타웨이 | 대표: 강호천</p>
            <p className="small mb-2">이메일 문의: info@metawayltd.com</p>
            <Button
              width="10"
              text="광고문의하기"
              border="none"
              background="#fef4e5"
              color="#fbb60d"
              onClick={() => navigate("/contact")}
            />
          </div>
          <div>
            <hr className="mt-2"></hr>
            <div className="row text-black">
              <div className="col-sm-7 ms-lg-10 ms-0 my-4">
                <span className="d-block lh-sm small">
                  {`© ${date}. All Right Reserved, 메타웨이`}
                </span>
              </div>
              <div className="col-sm-3 me-lg-3 my-4">
                <div className="d-flex justify-content-sm-end">
                  <a className="small me-3 text-muted" href="/privacy">
                    <p>개인정보처리방침</p>
                  </a>
                  <a className="small text-muted" href="/agreement">
                    <p>이용약관</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
