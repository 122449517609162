import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import resourceApi from "../../api/resource";

export default function MainIntro() {
  const [index, setIndex] = useState(0);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const getBannerList = async () => {
      try {
        const response = await resourceApi.getBannerList();

        setBanners(response.data);
      } catch (err) {
        if (err) {
          alert(
            "데이터를 불러오는데 오류가 생겼습니다. 잠시 후 다시 이용부탁드립니다."
          );
        }
      }
    };

    getBannerList();
  }, []);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <section className="position-relative z-index-0 mb-lg-6">
      <div className="container px-lg-13 pb-lg-1 pb-3">
        <div className="row">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            {banners?.map((banner, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={banner.img_url}
                  alt={banner.alt_text}
                  style={{ borderRadius: "13px" }}
                />
                <a
                  href={banner.link_url}
                  className="stretched-link"
                  target={banner.is_outlink === "Y" ? "_blank" : ""}
                  rel="noreferrer"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
