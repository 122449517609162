import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

const Wrap = styled.div`
  margin: 0;
  padding: 0 0 0 0;
  width: 100%;

  .slick-prev:before {
    opacity: 1; // 기존에 숨어있던 화살표 버튼이 보이게
    color: black; // 버튼 색은 검은색으로
    left: 0;
  }
  .slick-next:before {
    opacity: 1;
    color: black;
  }
`;

export default function Services() {
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#000000",
        }}
        onClick={onClick}
      />
    );
  };

  const slideSetting = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      // 반응형 웹 구현 옵션
      {
        breakpoint: 1200, // 화면 사이즈 1200px
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    // <section className="position-relative">
    <div className="container px-lg-10 py-lg-6 pt-5 pb-0">
      <div>
        <div className="lh-sm">
          <h5 className="ps-lg-3">
            <span className="">부스타 인기 </span>
            <span className="fw-bold">서비스</span>
          </h5>
          <p className="small text-muted ps-lg-3">
            스타트업이 꼭 필요한 서비스만 모았어요!
          </p>
        </div>
        <div className="row px-lg-0 px-6">
          <Wrap>
            <Slider {...slideSetting}>
              <div className="px-lg-0 px-2">
                <Link to="/free_incorporation">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_thumbnail_230517.jpg"
                      alt="incorporation"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bolder mb-0" style={{ color: "#fbb60d" }}>
                    [신청•문의 폭주]
                    <br />
                  </span>{" "}
                  스타트업 맞춤 무료 법인 설립
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/business_plan">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/business_plan/business_plan_thumbnail_230406.jpg"
                      alt="business planImage"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [초창 원픽] <br />
                  </span>{" "}
                  사업계획서 컨설팅
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/ir_pack">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/ir/ir_thumbnail_230414.jpg"
                      alt="ir"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [고객 만족도 120%] <br />
                  </span>{" "}
                  3,000억 투자유치의 비밀
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/ir_pitching">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/ir/ir_pitching_thumbnail.jpg"
                      alt="ir pitching"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [피칭 맛집 소개시켜드릴게요] <br />
                  </span>
                  투자 유치 받는 IR 피칭
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/fiin">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/financing/ffin_thumbnail_230504.jpg"
                      alt="fiin image"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [브리프리포트는 무료!]{" "}
                  </span>{" "}
                  <br />
                  Fiin정부보증대출 사전심사서비스
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/pr_full_package">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/pr_list/datz_thumbnail.jpg"
                      alt="pr full package"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [감각적이고 트렌디한!] <br />
                  </span>{" "}
                  제품-서비스 홍보 영상 풀패키지
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/venture_certification">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/establishment%26certification/venture_thumbnail.jpg"
                      alt="venture certification image"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [합법적으로 쉽고 빠르게]{" "}
                  </span>{" "}
                  <br />
                  쉼고 빠른 벤처기업인증
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/establishment_research_institute">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/establishment%26certification/establishment_RI_thumbnail_230327.jpg"
                      alt="연구소 설립 image"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [궁금하시다면 클릭]{" "}
                  </span>{" "}
                  <br />
                  아무도 몰랐던 절세 혜택
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/global_pr_pack">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/global_business/global_pr_thumbnail_230609.jpg"
                      alt="글로벌 PR Pack"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [부스타가 해외로 보내드립니다]{" "}
                  </span>{" "}
                  <br />
                  1분만에 글로벌 진출하기
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/pr_media_pack">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/pr_list/media_thumbnail(230321).jpg"
                      alt="언론홍보"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [어떤걸 좋아하실지 몰라 다 가져왔어요]{" "}
                  </span>{" "}
                  <br />
                  초특가 언론홍보
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/marketing/big_data">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/marketing/big_data_thumbnail_230504.jpg"
                      alt="marketing service"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [선택이 아닌 필수] <br />
                  </span>{" "}
                  빅데이터 마케팅 솔루션
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/pr_video_pack">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/pr_list/pr_video_pack_thumbnail_230504.jpg"
                      alt="pr video service"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [요새는 영상이 대세] <br />
                  </span>{" "}
                  기업 맞춤 올인원 영상 팩
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/develop_service">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/business_strategies/develop_thumbnail_230327.jpg"
                      alt="develop service"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [개발자 없는 대표님 주목] <br />
                  </span>{" "}
                  서비스 개발 성공 비법 컨설팅
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/usa_incorporation">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/global_business/usa_incorporation_thumbnail_230609.png"
                      alt="human resource"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [부스타에서만 가능한]
                    <br />
                  </span>{" "}
                  ONE-STOP 미국법인설립
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/hr_pack">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/hr/hr_thumbnail_230609.jpg"
                      alt="human resource"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [이거 하나면 끝] <br />
                  </span>{" "}
                  인사 노무 폭탄 제거
                </p>
              </div>
              <div className="px-lg-0 px-2">
                <Link to="/meditation">
                  <div className="flex-center">
                    <img
                      src="https://cdn-s3.boostar.co.kr/boostar/life_style_list/meditaion_thumbnail_230609.png"
                      alt="meditation"
                      className="w-lg-80 w-100 my-3"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </Link>
                <p className="text-center mb-0 lh-sm">
                  <span className="fw-bold" style={{ color: "#fbb60d" }}>
                    [체험 후기 베스트] <br />
                  </span>{" "}
                  찾아가는 명상워크숍
                </p>
              </div>
            </Slider>
          </Wrap>
          {/* <div className="col-lg-3 col-6 px-lg-0 px-2">
              <Link to="/free_incorporation">
                <div className="flex-center">
                  <img
                    src={incorporationImage}
                    alt="incorporation"
                    className="w-lg-90w-100 my-3"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Link>
              <p className="text-center">
                <span className="fw-bolder" style={{ color: "#fbb60d" }}>
                  [법무]{" "}
                </span>{" "}
                맞춤 무료 법인 설립
              </p>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/ir_pack">
                <div className="flex-center">
                  <img
                    src={irImage}
                    alt="ir"
                    className="w-lg-90 w-100 my-3"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Link>
              <p className="text-center mb-0 lh-sm">
                <span className="fw-bold" style={{ color: "#fbb60d" }}>
                  [IR 디자인 컨설팅]{" "}
                </span>{" "}
                3,000억 <br />
                투자유치의 비밀
              </p>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/business_plan">
                <div className="flex-center">
                  <img
                    src={businessPlanImage}
                    alt="business planImage"
                    className="w-lg-90 w-100 my-3"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Link>
              <p className="text-center mb-0 lh-sm">
                <span className="fw-bold" style={{ color: "#fbb60d" }}>
                  [투자 유치]{" "}
                </span>{" "}
                사업계획서 컨설팅
              </p>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/hr_pack">
                <div className="flex-center">
                  <img
                    src={humanResourceImage}
                    alt="human resource"
                    className="w-lg-90 w-100 my-3"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Link>
              <p className="text-center mb-0 lh-sm">
                <span className="fw-bold" style={{ color: "#fbb60d" }}>
                  [인사 노무 팩]{" "}
                </span>{" "}
                인사 노무 폭탄 제거
              </p>
            </div> */}
        </div>
      </div>
    </div>
    // </section>
  );
}
