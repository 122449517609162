import React from "react";

export default function InnoEx() {
  const innoExContentArr = [
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-001.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-002.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-003.jpg",
  ];

  const innoExContent1Arr = [
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-004.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-005.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-006.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-007.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-008.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-009.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-010.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-011.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-012.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-013.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-014.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-015.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-016.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-017.jpg",
  ];

  const innoExContent2Arr = [
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-018.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-019.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-020.jpg",
  ];

  const innoExContent3Arr = [
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-021.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-022.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-023.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-024.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-025.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-026.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-027.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-028.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-029.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-030.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-031.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-032.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-033.jpg",
    "https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/innoex/InnoEx_Intro+Deck-page-034.jpg",
  ];

  return (
    <section>
      <div className="container">
        <div className="mx-lg-5 mt-6">
          <div
            className="fw-bolder w-lg-20 text-center py-lg-1 py-1"
            style={{
              background: "#f4f4f5",
              color: "#2196F3",
              borderRadius: "5px",
            }}
          >
            INNOEX
          </div>
          <p className="mt-lg-2 mt-2 small">
            InnoEX는 Policymakers(정책 결정권자), Government Agencies(정부 기관
            관계자), 지역 VC 및 PE 펀드, 대기업 및 빠르게 성장하는 스타트업의
            창업가 및 CEO, 그리고 영향력 있는 커뮤니티의 연례 모임 및
            회의입니다.
            <br /> InnoEX는 최신 기술 트렌드, 기업 혁신 전략, 새로운 성장 엔진,
            그리고 혁신적인 제품과 서비스의 공유와 학습을 통해 다양한 분야와
            시장 간에 의미 있는 협력을 촉진하는 베트남 최대 행사입니다.
          </p>
          <div className="my-lg-5 my-5">
            <div>
              {innoExContentArr.map((image, index) => (
                <img key={index} src={image} className="img-fluid w-lg-100" />
              ))}
            </div>
            <div className="my-lg-4 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> InnoEx Overview</span>
            </div>
            <div>
              {innoExContent1Arr.map((image, index) => (
                <img key={index} src={image} className="img-fluid w-lg-100" />
              ))}
            </div>
          </div>
          <div className="my-lg-5 my-5">
            <div className="my-lg-4 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder">
                {" "}
                Media Coverage & Country Pavilion Packages
              </span>
            </div>
            <div>
              {innoExContent2Arr.map((image, index) => (
                <img key={index} src={image} className="img-fluid w-lg-100" />
              ))}
            </div>
          </div>
          <div className="my-lg-5 my-5">
            <div className="my-lg-2 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> InnoEx Activities</span>
            </div>
            <div>
              {innoExContent3Arr.map((image, index) => (
                <img key={index} src={image} className="img-fluid w-lg-100" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
