import React from "react";
import CountUp from "react-countup";

import More from "../components/More";

import introImage from "../assets/img/image/market_place_intro.png";
import founderImage from "../assets/img/graphics/icons/founder.png";
import expertImage from "../assets/img/graphics/icons/expert.png";
import investorImage from "../assets/img/graphics/icons/investor.png";
import investorImage2 from "../assets/img/graphics/icons/investor2.png";
import startupImage from "../assets/img/graphics/icons/startup.png";

export default function MarketPlace() {
  const contents = [
    {
      image: founderImage,
      name: "창업자",
      description: "혁신적인 아이디어를 가지고 빠른 성장을 추구합니다.",
    },
    {
      image: expertImage,
      name: "전문가",
      description:
        "실력과 경험을 겸비한 다양한 분야의 전문가가 스타트업의 성장을 돕습니다.",
    },
    {
      image: investorImage,
      name: "투자자",
      description:
        "높은 성장 가능성을 가지고 있는 스타트업을 발굴하고 투자를 결정합니다.",
    },
  ];

  return (
    <main>
      <section className="position-relative d-flex align-items-center text-white">
        <img src={introImage} alt="intro image" className="bg-image" />
        <div className="container position-relative py-8 py-lg-10">
          <div className="row col-lg-8 align-items-center my-lg-3">
            <div className="mb-3 mb-lg-0">
              <h2 className="display-5 mb-5">BOOSTAR 마켓플레이스</h2>
              <p className="fs-5 lh-sm mb-lg-4">
                BOOSTAR는 국내 최초 스타트업 부스팅 마켓플레이스로 창업자와
                전문가, 투자자가 한 곳에 모여 성장 프로세스를 구축해나갑니다.
              </p>
              <p className="fs-5 lh-sm mb-lg-4">
                창업자는 성장에 필요한 도움과 자금을 얻고, 전문가는 창업자에게
                전문 역량을 제공합니다. 투자자는 혁신적인 스타트업을 발굴하고
                투자처를 확보할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-5 fs-3">
                BOOSTAR 마켓플레이스는
                <br />
                스타트업의 성장에 필요한 모든 것을 제공합니다
              </h1>
              <h5 className="mb-3 mb-lg-3">
                사업 아이템이 어떤 단계에 있더라도 BOOSTAR는 여러분의 아이디어를
                빠르게 현실화하는 통합 솔루션을 제공할 수 있습니다.
                <br />
                <br />
                또한 창업자, 전문가, 투자자가 함께하는 네트워크를 구축하여
                스타트업이 빠르게 성장할 수 있는 환경을 조성합니다.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-2 py-lg-10">
          <div className="row justify-content-center">
            {contents.map((content, index) => (
              <div className="col-lg-3 col-sm-6 mx-lg-7 my-3" key={index}>
                <div
                  className="d-flex flex-column align-items-center py-13 py-lg-13 shadow"
                  style={{
                    background: "#ffffff",
                    height: "31rem",
                    borderRadius: "150px",
                  }}
                >
                  <img
                    src={content.image}
                    className="img-fluid mb-4"
                    alt="image"
                  />
                  <p className="fs-3">{content.name}</p>
                  <p className="w-lg-80 w-80 text-center">
                    {content.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8 py-lg-10">
          <div className="row justify-content-center">
            <div className="text-center fs-3 mb-lg-10 mb-8">
              스타트업의 성장을 위한 모든 것이 모이는 곳
            </div>
            <div className="row justify-content-around">
              <div className="col-lg-3 col-sm-6 col-4 text-center">
                <img
                  src={expertImage}
                  alt="expert image"
                  className="mb-lg-2 mb-2"
                />
                <div className="fs-1 fw-bold">
                  <CountUp end={500} duration={2} />+
                </div>
                <div className="fs-3 mt-lg-2">전문가</div>
              </div>
              <div className="col-lg-3 col-sm-6 col-4 text-center">
                <img
                  src={startupImage}
                  alt="startup image"
                  className="mb-lg-2 mb-2"
                />
                <div className="fs-1 fw-bold">
                  <CountUp end={1000} duration={2} />s
                </div>
                <div className="fs-3 mt-lg-2">스타트업</div>
              </div>
              <div className="col-lg-3 col-sm-6 col-4 text-center">
                <img
                  src={investorImage2}
                  alt="expert image"
                  className="mb-lg-2 mb-2"
                />
                <div className="fs-1 fw-bold">
                  <CountUp end={300} duration={2} />+
                </div>
                <div className="fs-3 mt-lg-2">투자자</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="저희와 함께할 준비되셨나요?"
        subContent="BOOSTAR와 시작하세요, 그리고 성장하세요"
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
