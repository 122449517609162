import axios from "../configs/axiosInstance";

const orderApi = {};

orderApi.payment = async ({
  orderId,
  paymentKey,
  amount,
  orderNumber,
  serviceType,
  serviceName,
  productCode,
  customer,
}) => {
  const response = await axios.post("/payment", {
    orderId,
    paymentKey,
    amount,
    orderNumber,
    serviceType,
    serviceName,
    productCode,
    customer,
  });

  return response.data;
};

orderApi.getOrderDetail = async (orderNumber) => {
  const response = await axios.get(`/payment/order/detail/${orderNumber}`);

  return response.data;
};

orderApi.getOrderList = async (email) => {
  const response = await axios.get(`/payment/order/list/${email}`);

  return response.data;
};

orderApi.requestCashReceipt = async ({
  orderId,
  orderName,
  amount,
  type,
  customerIdentityNumber,
}) => {
  const response = await axios.post("/payment/cash/receipt", {
    orderId,
    orderName,
    amount,
    type,
    customerIdentityNumber,
  });

  return response.data;
};

orderApi.postRefund = async ({ email, orderNumber, serviceType, reason }) => {
  const response = await axios.post("/payment/order/refund", {
    email,
    orderNumber,
    serviceType,
    reason,
  });

  return response.data;
};

export default orderApi;
