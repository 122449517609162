import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "../Button";

export default function Result() {
  const navigate = useNavigate();

  return (
    <div className="my-3">
      <div>
        <p className="mb-0 fs-3" style={{ marginLeft: "98%" }}>
          🍀
        </p>
        <div className="progress height-10">
          <div
            className="progress-bar bg-success"
            role="progressbar"
            style={{ width: "100%" }}
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <h5 className="fs-5 text-center my-lg-5 my-5">
          참가 신청이 완료되었습니다 😊
        </h5>
        <div className="d-flex flex-center flex-column">
          <p className="text-center">
            제출하신 내용은 접수 마감일인{" "}
            <span className="fw-bolder">4월 14일(금) 오후 6시까지</span> 행사
            신청 페이지를 통해 수정 가능합니다.
            <br /> 접수 마감 후,{" "}
            <span className="fw-bolder">선발된 기업에게는</span> 접수 시
            기입하신 이메일 주소로 안내 메일을 송부해드릴 예정입니다.
            <br /> 선정 결과는 <span className="fw-bolder">6월</span> 중 발표
            예정입니다.
            <br />
            감사합니다.
          </p>
          <p
            className="w-lg-100 text-center pt-lg-3 pt-3"
            style={{ borderTop: "1px solid #e5e6f3 " }}
          >
            <sapn className="fw-bolder">[문의처]</sapn>
            <br /> boostar@ground-up.kr
            <br /> 02-6956-1903
          </p>
        </div>
        <div className="w-lg-100 w-100 flex-center mt-lg-3">
          <Button
            width="18"
            text="돌아가기"
            border="none"
            background="#f44336"
            color="#ffffff"
            onClick={() => navigate("/startup_wheel_2023")}
          />
        </div>
      </div>
    </div>
  );
}
