import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import SupportProgramList from "../components/SupportProgramList";
import {
  bestProgramState,
  regionProgramState,
  totalProgramState,
  typedProgramState,
} from "../store/supportProgramState";

export default function SupportProgram() {
  const [programList, setProgramList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("");
  const [totalPage, setTotalPage] = useState(undefined);
  const [typeCode, setTypeCode] = useState("");
  const [region, setRegion] = useState("");
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("total");

  const bestProgramList = useRecoilValue(bestProgramState);
  const totalProgramList = useRecoilValue(
    totalProgramState({ currentPage, order, search, filterType })
  );
  const typedProgramList = useRecoilValue(
    typedProgramState({ typeCode, currentPage, order, search, filterType })
  );
  const regionProgramList = useRecoilValue(
    regionProgramState({ region, currentPage, order, search, filterType })
  );

  const pageCount = 10;
  let pageGroup = Math.ceil(currentPage / pageCount);
  let lastNumber = pageGroup * pageCount;
  lastNumber = lastNumber >= totalPage ? totalPage : lastNumber;
  let firstNumber = pageGroup * pageCount - 9;

  const category = [
    { name: "R&D", code: "01" },
    { name: "사업화 지원", code: "02" },
    { name: "보조금 지원", code: "03" },
    { name: "인건비 지원", code: "04" },
    { name: "정책 자금", code: "05" },
    { name: "컨설팅•교육", code: "06" },
    { name: "인증•출원•지정", code: "07" },
    { name: "판로 지원", code: "08" },
    { name: "입주 모집", code: "09" },
    { name: "행사•프로그램", code: "10" },
  ];

  useEffect(() => {
    if (filterType === "total") {
      setProgramList(totalProgramList?.data);
      setTotalPage(totalProgramList?.pageNumber);
    }

    if (filterType === "category") {
      setProgramList(typedProgramList?.data);
      setTotalPage(typedProgramList?.pageNumber);
    }

    if (filterType === "region") {
      setProgramList(regionProgramList?.data);
      setTotalPage(regionProgramList?.pageNumber);
    }
  }, [typeCode, region, currentPage, filterType, order, search]);

  const handelTypedButtonClick = (code) => {
    setCurrentPage(1);
    setTypeCode(code);
    setRegion("");
    setOrder("");
    setSearch("");
    setFilterType("category");
  };

  const handleSelectedRegion = (event) => {
    setCurrentPage(1);
    setRegion(event.target.value);
    setTypeCode("");
    setOrder("");
    setSearch("");
    setFilterType("region");
  };

  const handleTotalButtonClick = () => {
    setCurrentPage(1);
    setRegion("");
    setTypeCode("");
    setOrder("");
    setSearch("");
    setFilterType("total");
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    setSearch(event.target.search.value);
    event.target.search.value = "";
  };

  const handlePrevButtonClick = () => {
    if (pageGroup === 1) {
      return;
    }

    setCurrentPage(currentPage - 10);
  };

  const handleNextButtonClick = () => {
    const totalPageGroup = Math.ceil(totalPage / pageCount);

    if (pageGroup === totalPageGroup) {
      return;
    }
    setCurrentPage(lastNumber + 1);
  };

  const PageNumber = () => {
    const pages = [];

    for (let i = firstNumber; i <= lastNumber; i++) {
      pages.push(i);
    }

    return (
      <div className="w-lg-100 d-flex flex-center mt-lg-3 mt-3 ">
        <div className="d-flex flex-row">
          <i
            className="bx bx-chevrons-left d-flex flex-center"
            style={{ cursor: "pointer" }}
            onClick={handlePrevButtonClick}
          />
          {pages.map((number, index) => (
            <p
              className="mx-lg-3 d-flex flex-center"
              key={index}
              onClick={() => setCurrentPage(number)}
              style={{
                width: "2rem",
                height: "2rem",
                background: `${currentPage === number ? "#fec230" : "#ffffff"}`,
                borderRadius: "100%",
                cursor: "pointer",
              }}
            >
              {number}
            </p>
          ))}
          <i
            className="bx bx-chevrons-right d-flex flex-center"
            style={{ cursor: "pointer" }}
            onClick={handleNextButtonClick}
          />
        </div>
      </div>
    );
  };

  return (
    <main>
      <div>
        <img
          src="https://cdn-s3.boostar.co.kr/boostar/image/support_program_top_banner.png"
          className="img-fluid w-lg-100 w-100"
        />
      </div>
      <div className="container py-lg-6 py-3 w-100">
        <div className="mb-lg-6">
          <div className="fw-bolder fs-5 py-2">🔥 지금 가장 HOT한 지원사업</div>
          <SupportProgramList list={bestProgramList} />
        </div>
        <div className="mb-lg-6">
          <div className="fw-bolder fs-5 py-2">✨ NEW 새로 올라온 지원사업</div>
          <div className="row mt-lg-3 mb-lg-2 my-3 d-flex justify-content-between">
            <p
              className="col-lg-auto col-5 px-2 mx-lg-0 mx-2 my-1 px-lg-3 py-lg-2 fw-bolder text-center "
              style={{
                border: "2px solid #fec230",
                borderRadius: "0.375rem",
                cursor: "pointer",
              }}
              onClick={handleTotalButtonClick}
            >
              전체
            </p>
            {category.map((type, index) => (
              <p
                key={index}
                className="col-lg-auto col-5 px-2 my-1 mx-lg-0 mx-2 px-lg-3 py-lg-2 fw-bolder text-center"
                style={{
                  border: "2px solid #fec230",
                  borderRadius: "0.375rem",
                  background: typeCode === type.code ? "#fec230" : "#ffffff",
                  cursor: "pointer",
                }}
                onClick={() => handelTypedButtonClick(type.code)}
              >
                {type.name}
              </p>
            ))}
          </div>
          <div className="d-flex justify-content-end row w-lg-100 px-2">
            <select
              value={region || ""}
              onChange={handleSelectedRegion}
              className="mx-lg-2 my-1 mt-lg-4 py-lg-0 py-1 col-lg-auto"
            >
              <option value="" disabled>
                지역
              </option>
              <option value="서울특별시">서울특별시</option>
              <option value="인천광역시">인천광역시</option>
              <option value="경기도">경기도</option>
              <option value="강원도">강원도</option>
              <option value="충청남도">충청남도</option>
              <option value="충청북도">충청북도</option>
              <option value="대전광역시">대전광역시</option>
              <option value="전라북도">전라북도</option>
              <option value="전라남도">전라남도</option>
              <option value="광주광역시">광주광역시</option>
              <option value="경상북도">경상북도</option>
              <option value="경상남도">경상남도</option>
              <option value="대구광역시">대구광역시</option>
              <option value="울산광역시">울산광역시</option>
              <option value="부산광역시">부산광역시</option>
              <option value="제주특별자치도">제주특별자치도</option>
            </select>
            <select
              value={order || ""}
              className="mx-lg-2 mt-lg-4 my-1 col-lg-auto py-lg-0 py-1"
              onChange={(event) => setOrder(event.target.value)}
            >
              <option value="">업데이트순</option>
              <option value="asc">마감일 오름차순</option>
              <option value="desc">마감일 내림차순</option>
            </select>
            <form
              className="col-lg-auto col-12 mt-lg-4 my-2"
              onSubmit={handleSearchSubmit}
            >
              <input name="search" type="text" className="col-lg-auto col-9" />
              <button
                className="mx-lg-2 ms-2 px-lg-3 px-4 py-lg-1 py-1"
                style={{ border: "none", borderRadius: "0.175rem" }}
              >
                검색
              </button>
            </form>
          </div>
          <SupportProgramList list={programList} />
          {totalPage && <PageNumber />}
        </div>
      </div>
    </main>
  );
}
