import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const SliderContainer = styled.div`
  .slider {
    height: 1.5rem;
    overflow: hidden;
  }

  .slider-view {
    height: 100%;
  }

  .slider-item {
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default function Slider({ items }) {
  const sliderRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!sliderRef.current) {
        return;
      }

      sliderRef.current.style.transition = `all 0.7s ease-in-out`;
      sliderRef.current.style.transform = `translateY(-${selectedIndex}00%)`;

      if (selectedIndex === items.length) {
        setTimeout(() => {
          if (!sliderRef.current) {
            return;
          }

          sliderRef.current.style.transition = `0s`;
          sliderRef.current.style.transform = `translateY(0)`;
        }, 701);

        setSelectedIndex(1);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    }, 1300);

    return () => {
      clearInterval(interval);
    };
  }, [selectedIndex, items]);

  const SliderItem = ({ item }) => {
    return <div className="slider-item fw-bolder">{item}</div>;
  };

  return (
    <SliderContainer>
      <div className="slider">
        <div className="slider-view" ref={sliderRef}>
          {items.map((item, idx) => (
            <SliderItem key={idx} item={item} />
          ))}
          <SliderItem item={items[0]} />
        </div>
      </div>
    </SliderContainer>
  );
}
