import React from "react";

export default function Agreement() {
  const addCondition = "<부칙>";

  return (
    <main className="main-content" id="main-content">
      <section className="position-relative">
        <div className="container py-9 py-lg-11">
          <div className="row pt-4 pt-lg-9 justify-content-center">
            <div className="col-xl-9">
              <div className="shadow-lg rounded-4 px-4 px-lg-6 py-5 py-lg-7">
                <div className="mb-4 pb-4 border-bottom">
                  <h1>서비스 이용약관</h1>
                </div>
                <div>
                  <h3 className="my-lg-4">제1장 총칙</h3>
                  <div>
                    <h5 className="my-lg-3">제1조 (목적)</h5>
                    <p>
                      1. 본 이용약관은 ㈜메타웨이 (이하 “회사”)가 운영하는
                      (boostar.co.kr)사이트 (이하 “회사 사이트”)의 서비스 및
                      기타 정보 서비스(이하 “서비스”)를 이용함에 있어 회사와
                      회원 및 이용자 사이의 권리, 의무 및 책임사항 등을
                      구체적으로 규정함을 목적으로 합니다. 당사는 언제든지 이용
                      약관을 수정할 수 있으므로 이용자는 이용 약관을 정기적으로
                      검토할 것을 권장합니다.
                    </p>
                    <p>
                      본 약관의 마지막 부분에 있는 날짜는 본 약관의 최신
                      업데이트를 나타내며 발행일로부터 적용됩니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제2조 (목적)</h5>
                    <p>1. “회사”라 함은 주식회사 메타웨이 말합니다.</p>
                    <p>
                      2. “사이트”라 함은 “회사”가 운영하는 인터넷 서비스 사이트
                      (https://boostar.co.kr)를 말합니다.
                    </p>
                    <p>
                      3. &ldquo;이용자&ldquo;라 함은 사이트에 접속하여 이 약관에
                      따라 “회사”가 제공하는 서비스를 받는 “회원” 및 “비회원” 을
                      말하며, 본 약관에 따라 회사가 제공하는 서비스를 이용하는
                      자 (개인, 법인, 단체)를 통칭합니다.
                    </p>
                    <p>
                      4. &ldquo;파트너사&ldquo;라 함은 &ldquo;회사&ldquo;와
                      파트너 협약을 맺은 회사로서, &ldquo;마켓플레이스&ldquo;에
                      상품을 등록하고 판매할 수 있으며 고객이 상품을 결제할 경우
                      해당 상품의 컨설팅 서비스를 제공하는 자를 말합니다.
                    </p>
                    <p>
                      5. &ldquo;마켓플레이스&ldquo;라 함은 파트너사”에서
                      제공하는 무형의 컨설팅 서비스에 대한 내용을 “회사”에서
                      제공하는 일정한 양식에 따라 회사의 사이트(”Boostar”)에
                      등록하고, “이용자”가 금액을 결제하여 “파트너사”에게 무형의
                      컨설팅을 제공받는 서비스를 말합니다.
                    </p>
                    <p>
                      6. “콘텐츠”는 회사 사이트 내에 게재 되어있는 각종 무료
                      또는 유료의 IR 사례, 디자인템플릿 소스 등을 말합니다.
                    </p>
                    <p>
                      7. “서비스”란 회원이 회사 사이트 내에서 콘텐츠를 무료 또는
                      유료로 이용하는 행위를 말하며, 이용약관을 준수하여야
                      합니다.
                    </p>
                    <p>
                      8. “유료서비스”라 함은 “회사”가 “회원”에게 유료로 제공하는
                      각종 서비스를 말합니다.
                    </p>
                    <p>
                      9. &ldquo;구매&ldquo;라 함은 “회사”의 사이트를 통하여
                      서비스를 구매하는 행위를 말합니다.
                    </p>
                    <p>
                      10. “ID”는 회원 식별과 서비스 이용을 위하여 회원이
                      신청하고 회사가 승인한 이메일을 말합니다.
                    </p>
                    <p>
                      11. “비밀번호”는 회원이 승인 받는 ID와 일치된 회원임을
                      확인하고, 회원의 비밀보호를 위해 회원 자신이 설정한 문자와
                      숫자의 조합을 말합니다.
                    </p>
                    <p>
                      12. “해지”는 회사 또는 회원이 서비스 계약을 해약하는 것을
                      말합니다.
                    </p>
                    <p>
                      13. 위 항에서 정의되지 않은 약관 상의 용어의 의미는
                      일반적인 거래관행에 따릅니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">
                      제3조 (약관의 명시와 효력 및 개정)
                    </h5>
                    <p>
                      1. 회사는 본 약관의 내용과 상호, 영업소 소재지 주소,
                      전화번호, 대표자의 성명, 사업자등록번호,
                      통신판매업신고번호, 개인정보관리책임자 등을 회원이 쉽게
                      확인할 수 있도록 사이트의 초기 화면 하단에 게시합니다.
                      다만, 약관의 구체적 내용은 회원이 연결화면을 통하여 볼 수
                      있도록 합니다.
                    </p>
                    <p>
                      2. 회사는 『전자상거래 등에서의 소비자보호에 관한 법률』,
                      『약관의 규제에 관한 법률』, 『전자문서 및
                      전자거래기본법』, 『전자금융거래법』, 『전자서명법』,
                      『정보통신망 이용촉진 및 정보보호 등에 관한 법률』,
                      『소비자기본법』 등 관련 법령을 위배하지 않는 범위에서 이
                      약관을 개정할 수 있습니다.
                    </p>
                    <p>
                      3. 회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를
                      명시하여 현행 약관과 함께 그 적용일자 7일 이전부터
                      적용일자 전일까지 사이트의 초기 화면 등에 공지합니다.
                      다만, 회원에게 불리하게 약관 내용을 변경하는 경우에는
                      최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 회사가
                      개정약관을 공지하면서 회원에게 30일 기간 내에 의사표시를
                      하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게
                      공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지
                      아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                    </p>
                    <p>
                      4. 제3항에 의해 변경된 약관은 법령에 특별한 규정이나 기타
                      부득이한 사유가 없는 한 그 적용일자 이전으로 소급하여
                      적용되지 않습니다.
                    </p>
                    <p>
                      5. 회원은 변경된 약관에 동의하지 않을 권리가 있으며,
                      변경된 약관에 동의하지 않을 경우 언제든지 자유롭게 서비스
                      이용을 중단하고 탈퇴할 수 있습니다.
                    </p>
                    <p>
                      6. 회원이 카카오계정을 연결하여 서비스를 이용하는 경우에는
                      해당 회원의 카카오계정에 적용되는 이용약관(카카오
                      통합서비스약관, 카카오 통합 약관, 카카오 서비스 약관 중
                      하나가 됩니다. 이하 총칭하여 ‘카카오 약관’이라 합니다)과
                      그에 따른 운영정책 등이 본 약관과 함께 적용됩니다.
                      서비스의 이용과 관련하여 본 약관과 카카오 약관의 내용이
                      충돌하는 경우 본 약관의 내용이 우선합니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제4조 (약관 외 준칙)</h5>
                    <p>
                      이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
                      『전자상거래 등에서의 소비자 보호에 관한 법률』, 『약관의
                      규제에 관한 법률』, 공정거래위원회가 정하는 『전자상거래
                      등에서의 소비자 보호지침』 및 관계법령 및 상관례에
                      따릅니다.
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="my-lg-4">제2장 회원가입 및 이용계약</h3>
                  <div>
                    <h5 className="my-lg-3">제5조 (회원 이용계약의 성립)</h5>
                    <p>
                      1. 회원의 이용계약은 이용자가 본 약관, 개인정보처리방침에
                      동의하면서 제5조에 따른 이용신청을 하고, 회사가 이용을
                      승낙함으로써 성립합니다. 회원의 이용계약의 성립시기는
                      회사의 승낙이 회원에게 도달한 시점으로 하되, 유료회원의
                      경우 해당 서비스에 따른 회원 가입비의 입금확인이 되면
                      이용계약이 성립합니다.
                    </p>
                    <p>
                      2. 회원의 본 약관에 대한 동의는 이용신청 시 회사 사이트의
                      회원가입 절차 중 약관 동의 메뉴에서 “이용약관에
                      동의합니다.” 를 체크함으로써 의사표시를 합니다.
                    </p>
                    <p>
                      3. 민법상 미성년자가 유료 서비스를 이용할 경우, 결제 전
                      법정대리인의 동의를 받아야 하며, 그렇지 않았을 경우 본인
                      또는 법정대리인이 계약을 취소할 수 있습니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제6조 (회원의 이용신청)</h5>
                    <p>
                      1. 회원으로 가입하고자 하는 이용자는 반드시 회사에서
                      요청하는 제반정보 (성명 또는 상호, 연락처, 전자우편 등)를
                      사실대로 정확하게 등록하여야 합니다. 회사에 등록한
                      제반정보와 일치하지 않을 경우 이용자는 일체의 권리를
                      주장할 수 없습니다.
                    </p>
                    <p>
                      2. 타인의 명의나 ID, 비밀번호를 도용하여 이용신청을 한
                      회원의 모든 ID는 삭제되며, 관계법령에 따라 처벌을 받을 수
                      있습니다.
                    </p>
                    <p>
                      3. 회사는 회사에서 제공하는 서비스를 이용하는 회원에
                      대하여 콘텐츠의 사용 목적과 용도 등에 따라 등급별로
                      구분하여 가입비, 서비스 관련 사항에 차등을 둘 수 있습니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제7조(회원의 의무)</h5>
                    <p>
                      1. 회원은 관련 법령, 이 약관의 규정, 이용안내 등 회사가
                      통지하는 사항을 준수하여야 하며, 기타 회사 업무에 방해되는
                      행위를 하여서는 안됩니다.
                    </p>
                    <p>
                      2. 회원은 다음 각호에 해당하는 행위를 하여서는 안되며,
                      해당 행위를 하는 경우에 회사는 회원의 서비스 이용 제한 및
                      적법 조치를 포함한 제재를 가할 수 있습니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스
                      이용 외의 상업적 목적으로 복제하는 행위
                      <br />
                      나. 범죄와 연관된다고 판단되는 행위
                      <br />
                      다. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는
                      이용조건을 위반하는 행위
                      <br />
                      라. 서비스에 유해를 가하거나 고의로 방해하는 행위
                      <br />
                      마. 비정상적 다운로드 행위(지속적인 일정 간격의 초 단위
                      다운로드, 사용 목적을 현저히 벗어난 대량 수집보관형
                      다운로드 등 정상적인 이용행위로 볼 수 없는 행위 등)
                      <br />
                      바. 기타 관계법령에 위배되는 행위
                      <br />
                      사. 기타 회사의 업무에 방해되는 행위
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">
                      제8조 (회원의 아이디 및 비밀번호에 대한 의무)
                    </h5>
                    <p>
                      1. 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며,
                      이를 소홀히 하여 발생한 모든 민∙형사상의 책임은 회원
                      자신에게 있습니다.
                    </p>
                    <p>
                      2. 회원은 이용신청 또는 회원정보 변경 시 모든 사항을
                      사실대로 등록하여야 합니다. 만일 허위의 사실이나 타인의
                      정보를 이용하여 등록한 경우에는 일체의 권리를 주장하거나
                      보호받을 수 없습니다.
                    </p>
                    <p>
                      3. 회원은 주소, 연락처, 전자우편주소 등 이용계약사항이
                      변경된 경우에 해당 절차를 거쳐 이를 회사에 즉시 알려야
                      합니다. 특히 회사 사이트에서 제공하는 콘텐츠에 저작권 등
                      권리 침해 문제가 발생 시 이를 홈페이지 공지사항 또는
                      전자우편(E-Mail)을 통해 공지하므로 항상 회원(담당자)의
                      정확한 전자우편(E-Mail)을 제공하여야 합니다.
                    </p>
                    <p>
                      4. 회원의 ID를 타인에게 대여∙공유하거나 유출해서는 안
                      됩니다. ID, 비밀번호 관리 소홀로 인한 ID유출, 제3자의
                      부정사용 등의 책임은 회원에게 있으며, 법률적 책임을 져야
                      합니다.
                    </p>
                    <p>
                      5. 회원이 자신의 ID 또는 비밀번호를 도난 당하거나 제3자가
                      사용하고 있음을 인지한 경우에는 바로 회사에 통보하고
                      회사의 안내가 있는 경우에는 그에 따라야 합니다.
                    </p>
                    <p>
                      6. 회원은 서비스의 이용 권한, 계약상의 지위 등을 타인에게
                      양도∙증여할 수 없으며 이를 담보로 제공할 수 없습니다.
                    </p>
                    <p>
                      7. 회원이 제6항에 따라 비밀번호의 변경을 하지 않거나
                      회사의 조치에 응하지 아니하여 발생하는 모든 불이익 및
                      제2항 내지 제5항의 부정이용에 대한 책임은 회원에게
                      있습니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">
                      제9조(회원 탈퇴 및 자격 상실 등)
                    </h5>
                    <p>
                      1. 회원이 이용기간 중 탈퇴를 원할 경우 사이트 관리자에게
                      요청할 수 있으며, 회사는 확인 절차를 거쳐 즉시 회원탈퇴를
                      처리합니다.
                    </p>
                    <p>
                      2. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는
                      회원자격을 제한 및 정지시킬 수 있습니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 가입신청 시에 허위 내용을 등록하거나 타인의 정보를
                      도용한 경우나. 회사가 제공하는 서비스의 원활한 운영을
                      방해하는 행위를 하거나 시도한 경우
                      <br />
                      나. 사이트를 통해 상품 등을 구매한 후 정당한 이유 없이
                      상습 또는 반복적으로 취소 또는 환불하여 회사의 업무를
                      방해하는 경우
                      <br />
                      다. 다른 사람의 사이트 이용을 방해하거나 그 정보를
                      도용하는 등 전자상거래 질서를 위협하는 경우
                      <br />
                      라. 사이트를 이용하여 법령 또는 이 약관이 금지하거나
                      공서양속에 반하는 행위를 하는 경우사. 기타 회사가 합리적인
                      판단에 의하여 필요하다고 인정하는 경우
                      <br />
                      마. 기타 회사가 위 각 호에 준하는 사유로서 합리적인 판단에
                      의하여 필요하다고 인정하는 경우
                    </p>
                    <p>
                      3. 회사가 회원자격을 상실시키는 경우에는 회원등록을
                      말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소
                      전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
                      부여합니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제10조(회원에 대한 통지)</h5>
                    <p>
                      1. 회사가 회원에 대한 통지를 하는 경우, 회원이 회원가입 시
                      지정한 전자우편(E-Mail) 주소 또는 연락처로 통지합니다.
                      따라서 회원은 전자우편(E-Mail) 주소 또는 연락처가 변경될
                      경우 반드시 회원 본인이 회사 사이트에서 직접 변경하여야
                      하며, 이 경우 회사는 회원이 변경한 전자우편(E-Mail) 주소
                      또는 연락처로 통지합니다.
                    </p>
                    <p>
                      2. 회사는 본 조에 명시한 방법으로 회원에게 통지함으로써,
                      회원에 대한 모든 통지 의무를 다 한 것으로 봅니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제11조(회사의 의무)</h5>
                    <p>
                      1. 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는
                      행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고,
                      안정적으로 서비스를 제공하는데 최선을 다하여야 합니다.
                    </p>
                    <p>
                      2. 회사는 계속적이고 안정적인 서비스의 제공을 위하여
                      설비에 장애가 생기거나 멸시될 때에는 부득이한 사유가 없는
                      한 지체 없이 이를 수리 또는 복구하여야 합니다.
                    </p>
                    <p>
                      3. 회사는 개인정보 보호를 위해 보안시스템을 구축하며
                      “개인정보처리방침”을 공시하고 준수합니다.
                    </p>
                    <p>
                      4. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하고
                      객관적으로 인정될 경우에는 적절한 절차를 거쳐 지체 없이
                      처리하여야 합니다. 다만 즉시 처리가 곤란한 경우는 회원에게
                      그 사유와 처리 일정을 통보하여야 합니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제12조(개인정보의 보호 및 사용)</h5>
                    <p>
                      1. 회사는 관계법령에 의거하여 회원 등록정보를 포함한
                      이용자의 개인정보를 보호하기 위해 노력하며, 이용자
                      개인정보의 보호 및 사용에 대해서는 『정보통신망 이용촉진
                      및 정보보호 등에 관한 법률』 등 관계법령 및 회사의
                      &quot;개인정보처리방침&quot;이 적용됩니다. 그러나 이용
                      회원의 귀책 사유로 인한 경우는 일체의 책임을 지지
                      않습니다.
                    </p>
                    <p>
                      2. 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수
                      없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게
                      제공하는 경우에는 이용 및 제공단계에서 해당 회원에게 그
                      목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리
                      정함이 있는 경우에는 예외로 합니다.
                    </p>
                    <p>
                      3. 회사는 다음과 같은 경우에 법이 허용하는 범위 내에서
                      회원의 개인정보를 제3자에게 제공할 수 있습니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 수사기관이나 기타 정부기관으로부터 정보제공을 요청받은
                      경우
                      <br /> 나. 회원이 법령 또는 약관의 위반을 포함하여
                      부정행위 확인 등의 정보보호업무를 위해 필요한 경우
                      배송업체 등에게 거래 및 배송 등에 필요한 최소한의 회원의
                      정보(성명, 주소, 전화번호 등)를 제공하는 경우
                      <br /> 다. 구매가 성사된 때에 그 이행을 위하여 필요한
                      경우와 구매가 종료된 이후에도 취소, 반품, 교환, 환불 등을
                      위하여 필요한 경우
                      <br /> 라. 기타 법령을 근거로 정보의 공개를 요구하거나
                      법령에 의하여 정보의 제공이 가능한 경우
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="my-lg-4">제3장 서비스의 제공 및 이용</h3>
                  <div>
                    <h5 className="my-lg-3">제13조(서비스의 제공 및 변경)</h5>
                    <p>1. 회사는 다음과 같은 서비스를 제공합니다.</p>
                    <p className="ms-lg-2">
                      가. 파트너사의 컨설팅 서비스에 대한 정보 제공
                      <br />
                      나. 이용자의 결제 내역을 파트너사에 제공
                      <br />
                      다. 파트너사에게 마켓 플레이스에 상품을 등록해주고 판매
                      수단 제공
                      <br />
                      라. 이용자에게 마켓플레이스 상품 구매를 중개 <br />
                      마. 회사가 운영하는 사이트에서 명시하는 서비스에 대한
                      전자상거래 서비스 및 기타 부수 서비스
                      <br />
                      바. 기타 회사가 정하는 서비스 또는 업무
                    </p>
                    <p>
                      2. 회사는 상품 등의 품절 또는 기술적 사양의 변경 등의
                      사유로 장차 체결되는 계약에 의해 제공할 상품 등의 내용을
                      변경할 수 있습니다. 이 경우에는 변경된 상품 등의 내용 및
                      제공일자를 명시하여 현재의 상품 등의 내용을 게시한 곳에
                      즉시 공지합니다.
                    </p>
                    <p>
                      3. 회사가 제공하기로 회원과 계약을 체결한 서비스의 내용을
                      상품 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할
                      경우에는 회원에게 즉시 통지합니다.
                    </p>
                    <p>
                      4. 전항의 경우 회사는 이로 인하여 회원이 입은 인과관계가
                      입증된 실제 손해를 배상합니다. 다만, 회사가 고의 또는
                      과실이 없음을 입증하는 경우에는 그러하지 않습니다.
                    </p>
                    <p>
                      5. 유료서비스의 이용 요금 및 결제 방식은 해당 서비스에
                      명시되어 있는 규정에 따릅니다.
                    </p>
                    <p>
                      6. 회사는 통신판매중개업자로서 서비스 요금이 유료인 서비스
                      이용과 관련하여 파트너사와 이용자들 사이에서 발생한 거래와
                      관련된 손해에 대해서는 회사에 고의 또는 중대한 과실이 있는
                      경우를 제외하고는 책임을 지지 않습니다.
                    </p>
                    <p>
                      7. 회사는 이용자, 결제대행업체, 금융기관 등과의 사이에
                      발생한 분쟁은 당사자 간의 해결을 원칙으로 하며, 회사는
                      이와 관련한 어떠한 책임도 지지 않으며, 해당 사안의 결제
                      대행 업체 또는 금융기관의 약관이 우선됩니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제14조(서비스의 중단)</h5>
                    <p>
                      1. 서비스 제공은 회사의 업무상 또는 기술상 특별한 지장이
                      없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다.
                      그러나 회사는 시스템 정기점검, 증설 및 교체를 위한 경우,
                      또는 운영상 상당한 이유가 있는 경우 회사가 정한 날이나
                      시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는
                      작업으로 인한 서비스 일시 중단은 사이트를 통해 사전에
                      공지합니다.
                    </p>
                    <p>
                      2. 회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한
                      사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수
                      있으며, 이 경우 사후에 공지할 수 있습니다. 또한 새로운
                      서비스로의 교체 등 회사가 적절하다고 판단하는 사유가 있을
                      경우에는 사이트에 사전 공지 후 현재 제공되는 서비스를
                      일시적으로 완전히 중단할 수 있습니다.
                    </p>
                    <p>
                      3. 회사는 국가비상사태, 정전 등의 경우 서비스의 전부 또는
                      일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유
                      및 기간 등을 회원에게 사전 또는 사후에 공지합니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제15조(콘텐츠의 관리)</h5>
                    <p>
                      1. 회사에서 제공하는 콘텐츠는 회사의 독자적 판단에 따라
                      임의로 삭제∙변경될 수 있습니다.
                    </p>
                    <p>
                      2. 저작권 문제 등을 포함한 이유들로 콘텐츠에 문제가
                      발생했을 때 회사는 이를 공지(사이트 공지 또는 E-Mail
                      통지)함으로써 회원들에 대한 고지의무를 다하는 것으로 하며,
                      회원은 그 사용을 중지해야 합니다. 공지 후 해당 콘텐츠
                      사용으로 인한 모든 법적 책임은 회사에 있지 않고 사용한
                      회원에게 있습니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">16조(콘텐츠 저작권)</h5>
                    <p>
                      1. 회사 사이트에서 제공되는 콘텐츠의 무단복제, 무단사용
                      또는 회사와 사전협의 없이 회사 사이트에 게시된 규정을
                      벗어나는 행위에 대해서는 저작권법 및 관련법률에 의한 권리
                      침해로 간주됩니다. 따라서 회원은 이 약관에서 명시적으로
                      허용하는 사용 이외의 이미지 사용으로 인하여 발생하는 모든
                      종류의 손해 또는 책임에 대해 회사와 제3자에게 손해를
                      배상하여야 합니다. 또한 제3자의 청구에 대해서는 자신의
                      비용으로 회사를 면책시켜야 합니다.
                    </p>
                    <p>
                      2. 회사 사이트에서 제공되는 일부 콘텐츠의 피사체(인물,
                      건물, 장소 등)에 대한 초상권, 재산권, 저작권, 상표권,
                      특허권, 디자인권(이하 ‘저작권 등’)은 회사가 보유하지 않은
                      것이 있을 수 있습니다. (예: 인물의 초상권, 사물의 이미지,
                      배경 건물의 재산권 등). 따라서 일부 콘텐츠는 사용 형태에
                      따라서 회원이 사용 전에 직접 해당 권리를 취득해야 하는
                      경우가 있을 수 있으므로, 사용 시 저작권 등 권리의 존재가
                      의심이 되는 일부 콘텐츠에 대하여는 사전에 회사에
                      문의하셔야 합니다. 만일 회사와 사전협의 없이 사용하여
                      제3자와 이들 권리에 대한 분쟁이 발생할 경우 회사는 일체의
                      책임을 지지 않으며, 모든 책임은 회원에게 있습니다. 회원은
                      이러한 사용으로 인한 제3자의 청구에 대해 회사를 면책시켜야
                      합니다.
                    </p>
                    <p>
                      3. 일부 콘텐츠에 포함되어 있는 로고, 심벌, 캐릭터,
                      트레이드마크, 서비스마크 등에 대한 권리는 회사가 아닌 해당
                      권리자에게 있으므로 해당 권리의 침해가 되는 형태로
                      사용하려 할 경우 권리자로부터 사전에 승인을 받아야 합니다.
                    </p>
                    <p>
                      4. 제작한 제작물에 대하여는 회원의 이용기간이 만료된
                      후에도 재사용하거나 편집되지 않는 범위 내에서 저작권의
                      보호를 받습니다. (단, 소프트웨어, POD, 온라인샘플 등에
                      사용한 콘텐츠의 계속 게시는 불가합니다.)
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">
                      제17조(콘텐츠 이용 시 제한 사항)
                    </h5>
                    <p>
                      1. 회사 사이트에서 서비스하는 모든 콘텐츠는 대상을 꾸미기
                      위한 디자인 요소로써만 사용되어야 하며, 국내 비독점 사용을
                      전제로 제공합니다. 회사는 아래 각호에 해당하는 행위를
                      금지합니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 회원에게 부여되는 콘텐츠 사용에 대한 권리를 타인에게
                      발행, 판매, 재판매, 배급, 양도, 대여, 임대, 임차하는 행위
                      <br />
                      나. 원본 또는 이를 가공(변경, 모방 포함)한 콘텐츠의
                      재배포, 재판매, 공유(웹사이트, P2P, SNS, 웹하드, 공유
                      네트워크, 온라인 백업 시스템 등 그 형태 불문)하는 행위
                      <br />
                      다. 회사와 협의 없는 회사 사이트 링크 및 콘텐츠 직접 게시,
                      회사의 서비스와 경쟁하는 방식의 이용 <br />
                      라. 원본 또는 이를 활용한 2차제작물의 지적재산권 권리 등록
                      <br />
                      마. 외설적인, 중상모략적인, 음성적인, 타인을 비방하는 용도
                      또는 기타 비합법적인 용도의 사용 또는 타인에의 사용 허가
                      <br />
                      바. 회원기간 중 다운로드 받았던 콘텐츠를 회원 기간이
                      만료된 이후 새로이 사용
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">
                      제18조 (정보의 제공 및 광고의 게재)
                    </h5>
                    <p>
                      1. 회사는 회원에게 서비스 이용에 필요가 있다고 인정되는
                      각종 정보를 전자우편 (e-mail)이나 서신우편 또는
                      SMS(문자메시지) 등의 방법으로 회원에게 제공할 수 있습니다.
                    </p>
                    <p>
                      2. 회사는 서비스 개선 및 회원 대상의 서비스 소개 등의
                      목적으로 회원의 동의 하에 추가적인 정보를 요구할 수
                      있습니다.
                    </p>
                    <p>
                      3. 회사는 회원의 콘텐츠 사용에 관한 권리 보호를 위해
                      계속해서 불법사용자를 단속하고 있습니다. 이러한 단속의
                      일환으로 회사 또는 회사의 법률대리인이 회원에게 해당
                      콘텐츠 사용에 관한 구입경로 및 사용경로 등의 확인요청을 할
                      수 있으며, 이에 대해 회원은 성실히 답변해야 합니다. 확인
                      요청 시 답변을 회피하거나 거부할 경우 불법사용자로 오인
                      받을 수 있으니 성실히 답변해 주셔야 합니다.
                    </p>
                    <p>
                      4. 회사는 서비스 및 전자우편 등에 광고를 게재할 수
                      있습니다. 단, 회원이 원하지 않는다면 광고성 전자우편을
                      발송하지 않습니다.
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="my-lg-4">제4장 중개서비스의 이용</h3>
                  <div>
                    <h5 className="my-lg-3">
                      제 19 조 (중개서비스의 종류 및 이용)
                    </h5>
                    <p>
                      1. 회사가 제공하는 중개서비스는 일반 거래, 합의 거래 등이
                      있습니다. 중개서비스의 종류와 내용은 회사의 정책에 따라
                      추가, 변경될 수 있습니다.
                    </p>
                    <p>
                      2. 회사는 중개서비스의 종류에 따라 각 매매의 특성, 절차 및
                      결제방법에 대한 사항을 서비스화면을 통하여 공지합니다.
                    </p>
                    <p>
                      3. 회원은 아이디(ID)와 비밀번호를 통하여 회사가 제공하는
                      중개서비스를 이용할 수 있습니다.
                    </p>
                    <p>
                      4. 파트너사는 부스타를 통해 서비스를 제공하기 위해
                      자격요건을 심사받아야 합니다. 심사 요건은 아래와 같습니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 기본 프로필 확인(학위, 전공, 경력)
                      <br />
                      나. 서류심사 (국가인증자격증, 전문경력확인)
                      <br />
                      다. 인터뷰
                      <br />
                      라. 전문가 인증
                      <br />
                      마. 고객서비스 등록
                      <br />
                    </p>
                    <p>
                      5. 중개서비스로인해 발생하는 거래대금은 반드시 부스타를
                      통해 거래 완료되어야 하며, 회사는 이용자와 파트너사의
                      당사자 거래에 대해서는 책임지지 않습니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">
                      제 20 조 (중개서비스 이용정지, 이용제한)
                    </h5>
                    <p>
                      1. 회사는 중개서비스를 통한 거래의 안전성과 신뢰성을
                      위하여 이 약관에서 정한 바에 따라 이용자의 자격을
                      정지하거나 서비스의 이용 제한, 기타 필요한 조치를 취할 수
                      있습니다. 자격이 정지된 이용자는 중개서비스를 이용할 수
                      없으며, 진행중인 판매/구매관리 서비스를 제외한 모든
                      서비스를 이용할 수 없습니다.
                    </p>
                    <p>
                      2. 회사가 이용자의 자격을 정지하거나 판매/구매 서비스의
                      이용 제한, 기타 필요한 조치를 취할 경우 이 약관에 특별한
                      규정이 없는 한 사전에 이용자에게 유선 또는 메일(e-mail),
                      기타의 방법을 통하여 통보합니다. 다만, 회사가 빠른 조치가
                      필요하다고 판단하는 경우에는 선 조치 후 통보할 수
                      있습니다. 이용자는 회사의 조치에 대해 이의를 제기할 수
                      있으며, 회사는 이용자의 이의 제기가 타당하다고 판단되는
                      경우 조치를 해제할 수 있습니다.
                    </p>
                    <p>
                      3. 이용자가 이용정지 중에 이용계약을 임의 해지 하거나 다른
                      계정을 통해 재이용을 신청한 것이 확인되면 이 약관 제9조에
                      기하여 이용신청에 대한 승낙을 거부할 수 있습니다.
                    </p>
                    <p>
                      4. 회원과 파트너사는 이용 중에 다음 각호의 행위를 할 수
                      없습니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 직접결제
                      <p className="mx-lg-2">
                        - 회사가 제공하는 거래 프로세스를 이용하지 않고,
                        외부에서 거래하는 행위
                        <br />
                        - 기타 혜택 제안 등을 통해 직접 결제를 유도하는 행위
                        <br />- 위의 각호의 행위에 준하는 일체의 직접 결제 유도
                        행위
                      </p>
                      나. 거래 부정행위
                      <p className="mx-lg-2">
                        - 거래 의사 없이 구매, 취소를 반복하는 행위
                        <br />
                        - 재판매 목적의 구매 행위
                        <br />- 위의 각호의 행위에 준하는 일체의 거래 부정행위
                      </p>
                      다. 결제 부정행위
                      <p className="mx-lg-2">
                        - 결제 수단의 현금화 행위
                        <br />
                        - 부정한 목적의 분할 결제 행위
                        <br />- 결제 수단의 명의도용
                        <br />- 위의 각호의 행위에 준하는 일체의 결제 부정행위
                      </p>
                      라. 시스템 부정행위
                      <p className="mx-lg-2">
                        - 회사의 서비스·시스템을 이용한 스팸 정보 전송 또는
                        악성코드 프로그램 유포 등의 범죄 및 불법행위
                        <br />
                        - 회사의 소프트웨어를 무단으로 상업화하는 행위
                        <br />- 회사의 시스템에 비정상적인 방법으로 접근하거나
                        공격하는 행위
                        <br />- 회사의 정보·데이터를 정당한 권한 없이 스스로
                        또는 제3자를 통하여 복사, 퍼가기, 스크래핑하거나 기타의
                        방법으로 상업적으로 이용하는 행위
                        <br />- 회사의 서비스·시스템을 미수출 관리 법령과 게시된
                        규칙, 제한을 위반하여 수출 또는 재수출하는 행위
                        <br />- 위의 각호의 행위에 준하는 일체의 시스템 부정행위
                      </p>
                      마. 비매너 행위
                      <p className="mx-lg-2">
                        - 서비스 및 거래와 관련하여 일방적인 요구를 지속하는
                        행위
                        <br />
                        - 이용자 간의 협박·욕설·비방·모욕 행위
                        <br />- 회사의 임직원·상담원·피용자·업무수탁자에게
                        모욕감을 줄 수 있는 행위
                        <br />- 위의 각호의 행위에 준하는 일체의 비매너·업무
                        방해 행위
                      </p>
                      바. 회사가 제공하는 문의·답변 기능을 악용하는 행위
                      <p className="mx-lg-2">
                        - 특정 서비스 및 상품의 홍보행위
                        <br />
                        - 구인·구직 등의 제안 행위
                        <br />- 공공질서 및 미풍양속에 위반되는 내용 및
                        첨부파일을 발송하는 행위
                        <br />- 상대방의 업무를 방해할 목적으로 스팸성 메시지를
                        발송하는 행위
                        <br />- 위의 각호의 행위에 준하는 일체의 문의·답변
                        기능의 악용 행위
                      </p>
                      사. 연락 두절·회피 행위
                      <p className="mx-lg-2">
                        - 상대방의 문의에 대한 일체의 답변·응대가 진행되지 않는
                        경우
                        <br />
                        - 진행 중인 작업에 대한 일체의 피드백·응대가 진행되지
                        않는 경우
                        <br />- 특정 회원의 문의에 대한 답변·응대를 의도적으로
                        회피함이 확인되는 경우
                        <br />- 회사의 연락에 대한 답변·응대를 의도적으로
                        회피함이 확인되는 경우
                        <br />- 위의 각 호의 행위에 준하는 일체의 무응답 행위
                      </p>
                      아. 매매 부적합 서비스
                      <p className="mx-lg-2">
                        - 상대방에게 매매 부적합 서비스의 거래를 요청하는 행위
                        <br />
                        - 회사의 시스템에 매매 부적합 서비스를 판매 등록하는
                        행위
                        <br />- 기타 법령과 공공질서 및 미풍양속에 어긋나는 거래
                        행위
                        <br />- 위의 각호의 행위에 준하는 매매 부적합 서비스의
                        문의·거래 행위
                      </p>
                      자. 타인의 정보를 통해 서비스를 이용하는 행위
                      <p className="mx-lg-2">
                        - 제재 회피 등 부정한 목적으로 회원 정보의 수정을
                        거부하거나 해태한 경우
                        <br />
                        - 거래의 제반 행위를 통해 저작권·초상권 등의 제3자의
                        권리를 침해하는 경우
                        <br />- 회사의 개인정보처리방침 또는 관련 법령을
                        위배하여 다른 회원의 개인정보를 침해하는 행위
                        <br />- 타인의 명예 또는 신용, 정당한 이익을 훼손하는
                        행위
                        <br />- 회사의 명예 또는 신용, 정당한 이익을 훼손하는
                        행위
                        <br />- 위의 각호의 행위에 준하는 일체의 권리침해 행위
                      </p>
                    </p>
                    <p>
                      5. 회사는 그 외 약관 또는 법령에 위반되거나 위법·부당한
                      행위가 있는 것으로 의심될 만한 상당한 이유가 있는 경우,
                      서비스의 이용 제한 및 기타 필요한 조치를 취할 수 있습니다.
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="my-lg-4">제5장 전자상거래 서비스</h3>
                  <div>
                    <h5 className="my-lg-3">제 21 조 (구매신청)</h5>
                    <p>
                      1. 회원은 본 약관 및 회사가 정한 규정에 따라 구매를
                      신청하여야 하며 회사는 회원이 구매신청을 함에 있어서
                      다음의 내용을 알기 쉽게 제공하여야 합니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 상품 등의 검색 및 선택
                      <br />
                      나. 받는 사람, 주소, 연락처 등의 입력
                      <br />
                      다. 약관 내용, 청약철회권이 제한되는 서비스, 배송료 등의
                      비용 부담과 관련한 내용에 대한 확인
                      <br />
                      라. 상품 등의 구매신청 및 이에 관한 확인
                      <br />
                      마. 기타 결제 시 유의사항에 대한 확인
                      <br />
                      바. 결제방법의 선택
                    </p>
                    <p>
                      2. 회사가 제3자에게 구매자 개인정보를 제공 및 위탁할
                      필요가 있는 경우 실제 구매신청 시 구매자의 동의를 받아야
                      하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다.
                      이때 회사는 제공되는 개인정보 항목, 제공받는 자, 제공받는
                      자의 개인정보 이용목적 및 보유•이용기간 등을 구매자에게
                      명시하여야 합니다. 다만, 『정보통신망이용촉진 및 정보보호
                      등에 관한 법률』 제25조 제1항에 의한 개인정보 취급위탁의
                      경우 동 관련 법령에 달리 정함이 있는 경우에는 그에
                      따릅니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">
                      제 22 조 (대금지급 및 대금지급방법)
                    </h5>
                    <p>
                      1. 서비스의 이용료는 해당 서비스에 명시되어 있는 금액에
                      부가세를 더한 금액이며, 별도의 서비스 이용료를 지급하지
                      않습니다.
                    </p>
                    <p>
                      2. 사이트에서 구매한 상품 등에 대한 대금지급방법은 다음 각
                      호의 방법 중 가용한 방법으로 할 수 있습니다. 단, “회사”는
                      “회원”의 지급방법에 대하여 “재화등”의 대금에 어떠한 명목의
                      수수료도 징수할 수 없습니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 직불카드, 신용카드 등의 각종 카드 결제
                      <br />
                      나. 실시간 계좌이체
                      <br />
                      다. 무통장입금
                    </p>
                    <p>
                      3. 회원이 구매대금의 결제와 관련하여 입력한 정보 및 그
                      정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이
                      부담합니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제 23 조 (청약철회 등)</h5>
                    <p>
                      1. 회사의 상품 등의 구매 취소 및 환불 규정은 『전자상거래
                      등에서의 소비자보호에 관한 법률』, 『학원법』 및 동법
                      시행령 등 관련 법령을 준수합니다.
                    </p>
                    <p>
                      2. 회사와 상품 등의 구매에 관한 계약을 체결한 회원은
                      『전자상거래 등에서의 소비자보호에 관한 법률』 제17조
                      제1항에 따른 계약내용에 관한 서면(수신확인통지)을 받은
                      날로(그 서면을 받은 때보다 상품 등의 공급이 늦게 이루어진
                      경우에는 상품 등을 공급받거나 상품 등의 공급이 시작된 날을
                      말합니다)부터 7일 이내에는 청약을 철회할 수 있습니다. 단,
                      7일이 지나더라도 상품이 개시되지 않았다면 전문가와
                      의뢰인의 상호협의 하에 청약 철회가 가능합니다. 또한,
                      청약철회에 관하여 『학원법』 및 동법 시행령에 달리 정함이
                      있는 경우에는 동 법 규정에 따릅니다.
                    </p>
                    <p>
                      3. 회사는 공급사 또는 회사의 사정 등으로 인하여 상품 등의
                      공급이 어려운 경우에는 그 사유를 회원에게 고지하고 사유가
                      발생한 날로부터 3영업일 이내에 환급하거나 환급에 필요한
                      조치를 취합니다.
                    </p>
                    <p>
                      4. 회원은 상품 등을 제공받은 경우 다음 각 호의 1에
                      해당하는 경우에는 청약철회를 할 수 없습니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 소비자에게 책임이 있는 사유로 재화등이 멸실되거나
                      훼손된 경우. 다만, 재화등의 내용을 확인하기 위하여 포장
                      등을 훼손한 경우는 제외합니다.
                      <br />
                      나. 소비자의 사용 또는 일부 소비로 재화등의 가치가 현저히
                      감소한 경우
                      <br />
                      다. 시간이 지나 다시 판매하기 곤란할 정도로 재화등의
                      가치가 현저히 감소한 경우
                      <br />
                      라. 복제가 가능한 재화등의 포장을 훼손한 경우
                      <br />
                      마. 용역 또는 「문화산업진흥 기본법」 제2조제5호의
                      디지털콘텐츠의 제공이 개시된 경우. 다만, 가분적 용역 또는
                      가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이
                      개시되지 아니한 부분에 대하여는 그러하지 않습니다.
                      <br />
                      바. 그 밖에 거래의 안전을 위하여 대통령령으로 정하는 경우
                    </p>
                    <p>
                      5. 소비자는 제2항 및 제3항에도 불구하고 재화등의 내용이
                      표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된
                      경우에는 그 재화등을 공급받은 날부터 3개월 이내, 그 사실을
                      안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회등을 할
                      수 있습니다. 다만, 제2항 또는 4항에 따른 청약철등을
                      서면으로 하는 경우에는 그 의사표시가 적힌 서면을 발송한
                      날에 그 효력이 생깁니다.
                    </p>
                    <p>
                      6. 통신판매업자는 제2항제2호부터 제5호까지의 규정에 따라
                      청약철회등이 불가능한 재화등의 경우에는 그 사실을 재화등의
                      포장이나 그 밖에 소비자가 쉽게 알 수 있는 곳에 명확하게
                      표시하거나 시험 사용 상품을 제공하는 등의 방법으로
                      청약철회등의 권리 행사가 방해받지 아니하도록 조치하여야
                      합니다. 다만, 제2항제5호 중 디지털콘텐츠에 대하여 소비자가
                      청약철회등을 할 수 없는 경우에는 청약철회등이 불가능하다는
                      사실의 표시와 함께 대통령령으로 정하는 바에 따라 시험 사용
                      상품을 제공하는 등의 방법으로 청약철회등의 권리 행사가
                      방해받지 아니하도록 하여야 합니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제 24조 (청약철회 등의 효과)</h5>
                    <p>
                      1. 회사는 회원으로부터 상품 등을 반환받은 날로부터 3영업일
                      이내에 이미 지급받은 상품 등의 대금을 환급합니다. 이 경우
                      회사가 회원에게 상품 등의 환급을 지연한 때에는 그
                      지연기간에 대하여 『전자상거래 등에서의 소비자보호에 관한
                      법률』 제18조의 2항에서 정하는 지연이자를 지급합니다.
                    </p>
                    <p>
                      2. 회사는 제1항에 따라 대금을 환급할 때 회원이 신용카드
                      또는 전자화폐 등의 결제수단으로 상품 등의 대금을 지급한
                      경우에는 지체 없이 해당 결제수단을 제공한 사업자에게 상품
                      등의 대금 청구를 정지하거나 취소하도록 요청합니다.
                    </p>
                    <p>
                      3. 회사는 이미 상품 등이 일부 사용 또는 일부 소비된
                      경우에는 일부 소비에 의하여 회원이 얻은 이익 또는 상품
                      등의 공급에 소요된 비용에 상당하는 금액으로서 아래의 각
                      호에 해당하는 금액의 지급을 회원에게 청구할 수 있습니다.
                    </p>
                    <p className="ms-lg-2">
                      가. 상품 등의 사용으로 인하여 소모성 부품의 재판매가
                      곤란하거나 재판매 가격이 현저히 하락하는 경우에는 당해
                      소모성 부품의 공급에 소요되는 비용
                      <br />
                      나. 물건의 성질이나 가격이 현저하게 손상되지 않고 분할이
                      가능한 상품 등의 경우에는 회원의 일부 소비로 인하여 소비된
                      부분의 공급에 소요되는 비용
                    </p>
                    <p>
                      4. 청약철회 등의 경우 공급받은 상품 등의 반환에 필요한
                      비용은 회원이 부담합니다. 회사는 회원에게 청약철회 등을
                      이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만,
                      상품 등의 내용이 표시∙광고 내용과 다르거나 계약내용과
                      다르게 이행되어 청약철회 등을 하는 경우 상품 등의 반환에
                      필요한 비용은 회사가 부담합니다.
                    </p>
                    <p>
                      5. 회원의 단순변심에 의한 취소일 경우 환불처리에 발생하는
                      수수료와 기타 제반 비용은 회원이 부담합니다.
                    </p>
                    <p>
                      6. 기타 이 약관 및 사이트의 이용안내에 규정되지 않은 취소
                      및 환불에 대한 사항에 대해서는 『전자상거래 등에서의
                      소비자보호에 관한 법률』 및 『소비자분쟁 해결기준』에서
                      정한 바에 따릅니다.
                    </p>
                  </div>
                </div>
                <div>
                  <h5 className="my-lg-3">제 25 조 (환불)</h5>
                  <p>
                    1. 회사는 회원이 구매 신청한 상품 등이 품절, 천재지변 등의
                    불가항력적인 사유 등으로 인도 또는 제공할 수 없을 때에는
                    지체 없이 그 사유를 회원에게 통지하고, 사전에 상품 등의
                    대금을 받은 경우에는 사유가 발생한 날로부터 3영업일 이내에
                    환급하거나 환급에 필요한 조치를 취합니다.
                  </p>
                  <p>
                    2. 계약 이후 상품이 개시되지 아니한 경우 전문가와 의뢰인의
                    상호협의 하에 청약 철회가 가능합니다.
                  </p>
                  <p>
                    3. 다만, 컨설팅이 완료된 이후 또는 자료, 프로그램 등
                    서비스가 제공된 이후에는 환불이 불가합니다.(소비자보호법
                    17조 2항의 5조, 용역 또는 「문화산업진흥 기본법」
                    제2조제5호의 디지털콘텐츠의 제공이 개시된 경우에 해당)
                  </p>
                  <p>
                    4. 회사에 귀책사유가 있는 경우 상품이 개시되었는지 여부에
                    따라 부분적으로 환불이 가능합니다.
                  </p>
                  <p>
                    5. 전문가의 귀책사유로 당초 약정했던 서비스 미이행 혹은
                    보편적인 관점에서 심각하게 잘못 이행한 경우 결제 금액 전체
                    환불이 가능합니다.
                  </p>
                  <p>
                    6. 서비스 진행 도중 의뢰인의 귀책사유로 인해 환불을 요청할
                    경우, 사용 금액을 아래와 같이 계산 후 총 금액의 10%를
                    공제하여 환불합니다.
                    <div className="mx-lg-2">
                      총 컨설팅 시간의 1/3 경과 전 : 이미 납부한 요금의
                      2/3해당액
                      <br /> 총 컨설팅 시간의 1/2 경과 전 : 이미 납부한 요금의
                      1/2해당액
                      <br /> 총 컨설팅 시간의 1/2 경과 후 : 반환하지 않음
                      <br /> 상담 진행 중 의뢰인의 폭언, 욕설 등이 있을 경우
                      상담은 종료되며 잔여 이용요금은 환불되지 않습니다.
                    </div>
                  </p>
                </div>
                <div>
                  <h5 className="my-lg-3">제26조(계약 해제 또는 해지)</h5>
                  <p>
                    이용자와 파트너사 전문가는 언제든 계약의 해제 또는 해지가
                    가능하며, 서비스가 개시된 경우 개시된 시간만큼 공제하여
                    환불합니다. 단, 이용자나 파트너사의 전문가에게 귀책사유가
                    있는 경우 위약금 및 이용대금을 공제하고 환불합니다.
                  </p>
                </div>
                <div>
                  <h3 className="my-lg-4">제6장 손해배상 및 면책</h3>
                  <div>
                    <h5 className="my-lg-3">제27조(손해배상)</h5>
                    <p>
                      1. 회사 사이트에서 제공되는 콘텐츠의 무단 복제, 무단 사용
                      또는 본 약관에 규정된 내용을 벗어나는 행위 (사용범위 초과
                      사용 또는 콘텐츠 이용 시 제한 사항의 위반 등)는 저작권법
                      및 관련 법률에 의한 저작권 침해에 해당합니다. 이 경우
                      회사에게 발생한 손해를 배상하여야 하며, 회사가 이를
                      초과하는 손해를 입은 경우 그 초과 손해도 배상해야 합니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제28조(회사의 면책)</h5>
                    <p>
                      1. 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로
                      인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한
                      책임이 면제됩니다.
                    </p>
                    <p>
                      2. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나
                      정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이
                      면제됩니다.
                    </p>
                    <p>
                      3. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등
                      부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
                    </p>
                    <p>
                      4. 회원들의 접속 폭주로 인한 서비스 속도의 지체나 일시적
                      오류 그리고 회사의 서비스개선을 위한 정비 등으로 인한
                      서비스의 일시 중단에 대하여는 환불 또는 배상하지 않습니다.
                    </p>
                    <p>
                      5. 회원의 귀책사유로 인한 서비스의 이용의 장애 또는 손해에
                      대하여 회사는 책임이 없습니다.
                    </p>
                    <p>
                      6. 회사는 회원이 신상정보 및 전자우편(E-Mail) 주소를
                      부실하게 기재하여 손해가 발생한 경우 또는 회원의 컴퓨터
                      오류에 의해 손해가 발생한 경우에는 책임을 지지 않습니다.
                    </p>
                    <p>
                      7. 회사는 회원의 편의를 위하여 각 콘텐츠와 함께 제공하는
                      정보(콘텐츠의 키워드, 캡션, 콘텐츠 내의 문구, 디자인 등)의
                      정확성 및 내용의 신뢰도를 보증하지 않으며, 이에 대한
                      책임을 지지 않습니다. 각 정보를 확인할 의무는 회원에게
                      있습니다.
                    </p>
                    <p>
                      8. 회사는 회원에게 무료로 제공하는 서비스의 이용과
                      관련해서는 어떠한 손해도 책임을 지지 않습니다.
                    </p>
                    <p>
                      9. 회사는 회원 간 또는 회원과 제3자 상호 간에 회사의
                      서비스를 매개로 하여 거래 등을 한 경우에는 책임이
                      면제됩니다.
                    </p>
                    <p>
                      10. 콘텐츠 제휴사의 고유 권한과 재량에 따라 일부 콘텐츠의
                      서비스를 중지하거나 변경 또는 비슷한 콘텐츠로 교체할 수
                      있습니다. 회사가 이메일 공지로 교체 사실을 통보할 경우,
                      이전 콘텐츠에 대한 이용 허락이 취소되고, 교체된 콘텐츠에
                      효력이 발생합니다. 회사는 회원이 기존 콘텐츠를 이용한
                      창작물 내지 2차 저작물의 사용을 중단하고, 사용 중단 및
                      종료에 필요한 모든 작업을 수행하기로 동의한 것으로
                      간주합니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제29조 (분쟁해결)</h5>
                    <p>
                      1. 회사는 회원으로부터 제출되는 불만사항 및 의견을
                      처리해야 합니다. 다만, 신속한 처리가 곤란한 경우에는
                      회원에게 그 사유를 통보합니다.
                    </p>
                    <p>
                      2. 만약 본 약관의 환불 등 일부 규정이 대한민국의
                      강행법규와 상충되는 경우에는 그 강행법규에 따르기로 하고,
                      이로 인해 본 약관 중 일부 조항의 효력이 정지되는 경우에도
                      다른 조항의 효력에는 영향을 미치지 않습니다.
                    </p>
                  </div>
                  <div>
                    <h5 className="my-lg-3">제30조 (재판권 및 준거법)</h5>
                    <p>
                      1. 이 약관의 해석 및 회사와 회원 간의 분쟁에 대하여는
                      대한민국의 법률을 적용합니다.
                    </p>
                    <p>
                      2. 이 약관 및 서비스 이용과 관련하여 회사와 회원 사이에
                      분쟁이 발생하여 소송이 제기되는 경우에는 회사의 본사
                      소재지를 관할하는 법원을 관할법원으로 정합니다.
                    </p>
                  </div>
                </div>
                <div>
                  <p>부칙</p>
                  <p>1. 본 약관은 2022년 12월 26일부터 시행합니다.</p>
                  <p>
                    2. 이전에 시행되던 종전의 약관은 본 약관으로 대체합니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
