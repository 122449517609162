import React from "react";

export default function VisitPlace() {
  return (
    <section>
      <div className="container">
        <div className="mx-lg-5 mt-6">
          <div
            className="fw-bolder w-lg-20 text-center py-lg-1 py-1"
            style={{
              background: "#f4f4f5",
              color: "#2196F3",
              borderRadius: "5px",
            }}
          >
            VISIT
          </div>
          <div className="my-lg-5 my-5">
            <div className="my-lg-2 mt-2 mb-3">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 방문 기관 및 기업</span>
            </div>
            <div>
              <div
                className="py-lg-1 py-1 ps-lg-3 ps-2 pe-lg-1"
                style={{ background: "#f4f4f5" }}
              >
                <p className="fw-bold">현지 기관 및 코워킹 스페이스</p>
              </div>
              <p className="small text-muted ps-lg-3 ps-2 mt-lg-1 mt-1">
                # 방문 기관 및 기업은 현지 사정에 의해 변경될 수 있습니다.
              </p>
              <div className="row">
                <div className="col-lg-6 my-lg-3 my-3">
                  <div
                    className="w-lg-30 ps-lg-3 ps-2 py-lg-1 py-1 mb-lg-2 mb-2"
                    style={{ background: "#3d5891", color: "#ffffff" }}
                  >
                    BSSC
                  </div>
                  <div>
                    <img
                      className="img-fluid w-lg-100 w-100"
                      src="https://cdn-s3.boostar.co.kr/boostar/image/hcmc_bssc.png"
                    />
                    <div
                      className="py-lg-3 py-2 px-lg-3 px-2 mt-lg-3 mt-2 small"
                      style={{ background: "#797979", color: "#ffffff" }}
                    >
                      <p>
                        <span
                          className="fw-bolder"
                          style={{ color: "#fec230" }}
                        >
                          비즈니스 창업 지원 센터(BSSC)
                        </span>
                        는 호치민시 인민위원회의 결정에 따라 설립된 베트남
                        스타트업 생태계 강화를 위한 7개 기능 센터 중 하나입니다.
                        BSSC는 투자자, 기업 등과 스타트업을 연결하는 가교 역할을
                        하며, 베트남 스타트업 생태계를 전반적으로 육성합니다.
                        또한, BSSC는 베트남 스타트업이 글로벌 시장으로 진출,
                        글로벌 스타트업이 베트남의 스타트업 생태계와 연결되는
                        창구 역할을 합니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-lg-3 my-3">
                  <div
                    className="w-lg-30 ps-lg-3 ps-2 py-lg-1 py-1 mb-lg-2 mb-2"
                    style={{ background: "#3d5891", color: "#ffffff" }}
                  >
                    현지 AC/VC
                  </div>
                  <div>
                    <div className="d-flex flex-center">
                      <img
                        className="img-fluid w-lg-100 w-100"
                        src="https://cdn-s3.boostar.co.kr/boostar/image/hcmc_ac_vc.png"
                      />
                    </div>
                    <div
                      className="py-lg-3 py-2 px-lg-3 px-2 mt-lg-3 mt-2 small"
                      style={{ background: "#797979", color: "#ffffff" }}
                    >
                      <p>
                        <span
                          className="fw-bolder"
                          style={{ color: "#fec230" }}
                        >
                          베트남 이노베이티브 스타트업 엑셀러레이터 (비자/VIISA)
                        </span>
                        는 베트남에서 글로벌 수준의 스타트업을 육성하기 위해
                        투자하는{" "}
                        <span className="fw-bold" style={{ color: "#fec230" }}>
                          비즈니스 엑셀러레이션 프로그램 및 시드 스테이지 투자
                          기업
                        </span>
                        입니다. 베트남에서 급성장하는 중산층과 디지털 보급률의
                        증가, 깊이 있는 기술 인재 풀의 확장과 더불어,
                        비자(VIISA)는 창업가들이 이 기회를 활용하여 베트남을
                        시장을 변화시키길 바랍니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mt-lg-5 mt-4 py-lg-1 py-1 ps-lg-3 ps-2 pe-lg-1"
                style={{ background: "#f4f4f5" }}
              >
                <p className="fw-bold">현지 스타트업 및 대기업 방문</p>
              </div>
              <p className="small text-muted ps-lg-3 ps-2 mt-lg-1 mt-1">
                # 방문 기관 및 기업은 현지 사정에 의해 변경될 수 있습니다.
              </p>
              <div className="row">
                <div className="col-lg-6 my-lg-3 my-3">
                  <div
                    className="w-lg-30 ps-lg-3 ps-2 py-lg-1 py-1 mb-lg-2 mb-2"
                    style={{ background: "#3d5891", color: "#ffffff" }}
                  >
                    현지 스타트업
                  </div>
                  <div>
                    <img
                      className="img-fluid w-lg-100 w-100"
                      src="https://cdn-s3.boostar.co.kr/boostar/image/hcmc2_startup.png"
                    />
                    <div
                      className="py-lg-3 py-2 px-lg-3 px-2 mt-lg-3 mt-2 small"
                      style={{ background: "#797979", color: "#ffffff" }}
                    >
                      <p>
                        <span
                          className="fw-bolder"
                          style={{ color: "#fec230" }}
                        >
                          럭스테이(Luxstay)는 숙박 및 여행 분야의 베트남의
                          스타트업입니다
                        </span>
                        . 럭스테이의 플랫폼은 전통적인 호텔을 넘어 아파트, 빌라,
                        홈스테이 등의 다양한 대안 숙박 시설을 여행자들과
                        연결해줍니다. 럭스테이는 숙박시설에 대해 철저한 품질,
                        안전, 그리고 특별한 경험에 대한 검사와 인증을 실시하여
                        베트남 여행자들 사이에서 인기가 있으며, 24시간 고객
                        지원을 제공합니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-lg-3 my-3">
                  <div
                    className="w-lg-30 ps-lg-3 ps-2 py-lg-1 py-1 mb-lg-2 mb-2"
                    style={{ background: "#3d5891", color: "#ffffff" }}
                  >
                    현지 대기업
                  </div>
                  <div>
                    <div className="d-flex flex-center">
                      <img
                        className="img-fluid w-lg-100 w-100"
                        src="https://cdn-s3.boostar.co.kr/boostar/image/hcmc2_large_corp.png"
                      />
                    </div>
                    <div
                      className="py-lg-3 py-2 px-lg-3 px-2 mt-lg-3 mt-2 small"
                      style={{ background: "#797979", color: "#ffffff" }}
                    >
                      <p>
                        <span
                          className="fw-bolder"
                          style={{ color: "#fec230" }}
                        >
                          베트남 국영 통신 및 IT 기업인 Viettel Group은 모바일,
                          인터넷 및 디지털 서비스를 제공합니다.
                        </span>
                        글로벌 시장으로도 진출하였으며 기술 혁신과 인프라 개발로
                        잘 알려져 있습니다. 또한, IT와 금융 서비스와 같은 다양한
                        분야에 진출하여 비즈니스 포트폴리오를 다각화하고 있으며,
                        사회 사업을 통해 교육, 의료, 기반 시설 분야에서도
                        긍정적인 영향을 미치고 있습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mt-lg-5 mt-4 py-lg-1 py-1 ps-lg-3 ps-2 pe-lg-1"
                style={{ background: "#f4f4f5" }}
              >
                <p className="fw-bold">스타트업 Event & 행사</p>
              </div>
              <p className="small text-muted ps-lg-3 ps-2 mt-lg-1 mt-1">
                # 방문 기관 및 기업은 현지 사정에 의해 변경될 수 있습니다.
              </p>
              <div className="row">
                <div className="col-lg-6 my-lg-3 my-3">
                  <div
                    className="w-lg-30 ps-lg-3 ps-2 py-lg-1 py-1 mb-lg-2 mb-2"
                    style={{ background: "#3d5891", color: "#ffffff" }}
                  >
                    InnoEX
                  </div>
                  <div>
                    <img
                      className="img-fluid w-lg-100 w-100"
                      src="https://cdn-s3.boostar.co.kr/boostar/image/hcmc_innoex.png"
                    />
                    <div
                      className="py-lg-3 py-2 px-lg-3 px-2 mt-lg-3 mt-2 small"
                      style={{ background: "#797979", color: "#ffffff" }}
                    >
                      <p>
                        <span
                          className="fw-bolder"
                          style={{ color: "#fec230" }}
                        >
                          InnoEx는 베트남과 동남아시아를 위한 혁신과 신규
                          비즈니스 성장 동력을 위한 연례 국제 행사입니다.
                        </span>
                        <br />
                        <span
                          className="fw-bolder"
                          style={{ color: "#fec230" }}
                        >
                          베트남에서 가장 큰 기술 박람회
                        </span>
                        인 이 InnoEx에 약 20,000명의 업계 관계자들이 2일간
                        참여합니다. <br />
                        행사에는 국가관, 런치패드, 키노트, 네크워킹 및 피칭 세션
                        등 다양한 이벤트가 진행됩니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-lg-3 my-3">
                  <div
                    className="w-lg-40 ps-lg-3 ps-2 py-lg-1 py-1 mb-lg-2 mb-2"
                    style={{ background: "#3d5891", color: "#ffffff" }}
                  >
                    Startup Wheel
                  </div>
                  <div>
                    <div className="d-flex flex-center">
                      <img
                        className="img-fluid w-lg-100 w-100"
                        src="https://cdn-s3.boostar.co.kr/boostar/image/hcmc_starup_wheel.png"
                      />
                    </div>
                    <div
                      className="py-lg-3 py-2 px-lg-3 px-2 mt-lg-3 mt-2 small"
                      style={{ background: "#797979", color: "#ffffff" }}
                    >
                      <p>
                        2013년에 시작된 Startup Wheel은 매년 1,500개 이상의
                        베트남 및 글로벌 스타트업들이 참여하는{" "}
                        <span
                          className="fw-bolder"
                          style={{ color: "#fec230" }}
                        >
                          베트남 최대 규모의 스타트업 대회
                        </span>{" "}
                        입니다.
                        <br /> 6개월간의 프로그램은 스타트업들이 베트남에서
                        사업을 발전시키고 지역 생태계와 연결될 수 있도록 돕기
                        위한 일련의 활동을 포함하고 있습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
