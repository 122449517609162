import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Apply from "../components/StartupWheel.js/Apply";
import ApplyForm from "../components/StartupWheel.js/ApplyForm";
import Introduction from "../components/StartupWheel.js/Introduction";
import Prizes from "../components/StartupWheel.js/Prizes";
import Rules from "../components/StartupWheel.js/Rules";
import TimeLine from "../components/StartupWheel.js/Timeline";
import UpdateApplication from "../components/StartupWheel.js/UpdateApplication";

const MenuContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #2196f3;
  }
`;

export default function EventProgram() {
  const navigate = useNavigate();
  const { menu } = useParams();

  return (
    <main className="position-relative">
      <div>
        <img
          src="https://cdn-s3.boostar.co.kr/boostar/image/startup_wheel_top_banner_230404.jpg"
          className="img-fluid w-lg-100 w-100"
          alt="startup wheel banner"
        />
      </div>
      <div className="position-relative container py-lg-6 py-3">
        <div className="row">
          <div className="col-lg-3 mt-lg-6">
            <div
              className="px-lg-3 shadow"
              style={{ border: "1px solid #c8c8c8", borderRadius: "5px" }}
            >
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/startup_wheel_2023")}
                  style={{ cursor: "pointer" }}
                >
                  소개
                </div>
              </MenuContainer>
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/startup_wheel_2023/timeline")}
                  style={{ cursor: "pointer" }}
                >
                  일정
                </div>
              </MenuContainer>
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/startup_wheel_2023/prizes")}
                  style={{ cursor: "pointer" }}
                >
                  상금 및 혜택
                </div>
              </MenuContainer>
              <MenuContainer>
                <div
                  className="border-bottom text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/startup_wheel_2023/rules")}
                  style={{ cursor: "pointer" }}
                >
                  규칙 및 자격
                </div>
              </MenuContainer>
              <MenuContainer>
                <div
                  className="text-center py-lg-3 py-2 fw-bolder button"
                  onClick={() => navigate("/startup_wheel_2023/apply")}
                  style={{ cursor: "pointer" }}
                >
                  참가 신청
                </div>
              </MenuContainer>
            </div>
          </div>
          <div className="col-lg-9">
            {!menu && <Introduction />}
            {menu === "timeline" && <TimeLine />}
            {menu === "prizes" && <Prizes />}
            {menu === "rules" && <Rules />}
            {menu === "apply" && <Apply />}
            {menu === "apply_form" && <ApplyForm />}
            {menu === "my_application" && <UpdateApplication />}
          </div>
        </div>
      </div>
    </main>
  );
}
