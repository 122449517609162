import React from "react";

import errorImage from "../assets/img/graphics/illustration/error.svg";

export default function Fail({ message, errorCode }) {
  return (
    <>
      <section className="position-relative overflow-hidden">
        <div className="container pt-14 pt-lg-15 pb-9">
          <div className="row">
            <div className="col-md-10 col-lg-8 mx-auto text-center position-relative">
              <div className=" position-relative z-index-1">
                <div className="text-danger mb-5">
                  <img src={errorImage} className="width-18x mx-auto" alt="" />
                </div>
                <h1 className="display-1 mb-2">{errorCode}</h1>
                <h2 className="mb-4">{message}</h2>
                <a href="/" className="fw-semibold">
                  <i className="bx bx-left-arrow-alt lh-1 fw-normal me-2"></i>
                  Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
