import React from "react";

export default function Review({ rating, content, email, createAt }) {
  const id = email.split("@")[0];
  const mask = "*".repeat(id.length - 3);
  const maskingId = id.substring(0, 3) + mask;
  const date = new Date(createAt).toLocaleDateString();

  return (
    <>
      <div
        className="px-lg-3 px-2 py-lg-3 py-3"
        style={{ borderBottom: "1px solid #f4f4f5" }}
      >
        <div className="d-flex flex-row">
          <div className="small me-lg-3">{maskingId}</div>
          <div className="small px-0 pb-0">
            {date.substring(0, date.length - 1)}
          </div>
        </div>
        <div className="d-flex flex-row">
          {[1, 2, 3, 4, 5].map((num, index) => (
            <div key={index} className="">
              {rating >= num ? (
                <i className="bx bxs-star small" style={{ color: "#fec230" }} />
              ) : (
                <i className="bx bxs-star small" style={{ color: "#f4f4f5" }} />
              )}
            </div>
          ))}
        </div>
        <div className="col-lg-12 text-break">{content}</div>
      </div>
    </>
  );
}
