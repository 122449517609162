import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../../components/ServiceBox";

import irListImage from "../../assets/img/image/ir_list_image.jpg";

export default function FundrasingList() {
  const navigate = useNavigate();

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      {/* <div className="container">
        <img
          src={irListImage}
          className="img-fluid w-lg-100 mb-lg-4 px-lg-10"
        />
      </div> */}
      <div className="container py-lg-6 py-4 px-lg-11">
        <div
          className="mb-lg-4 mb-3"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <h3 className="fw-bolder ps-2">
            <span style={{ color: "#fbb60d" }}>자금 조달 및 지원</span> <br />
            서비스
          </h3>
        </div>
        <div className="row">
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/ir/ir_list_thumbnail_230327.jpg"
            title="3,000억 투자유치의 비밀: 에센셜팩"
            info="투자 유치 컨설팅"
            rating="0.0"
            review="0"
            onClick={() => navigate("/ir_pack")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/ir/ir_pitching_list_thumbnail.jpg"
            title="투자 유치 받는 IR 피칭"
            info="투자 유치 컨설팅"
            salePrice="400,000 원"
            price="600,000 원"
            discount="33%"
            rating="0"
            review="0"
            onClick={() => navigate("/ir_pitching")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/financing/ffin_list_thumbnail_230504.jpg"
            title="Fiin정부보증대출 사전심사서비스"
            info="자금조달 설계 컨설팅"
            rating="0.0"
            review="0"
            onClick={() => navigate("/fiin")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_list_thumbnail_230519.jpg"
            title="무료법인설립"
            info="정부지원사업 지원 컨설팅"
            rating="0.0"
            review="0"
            onClick={() => navigate("/free_incorporation")}
          />
        </div>
      </div>
    </main>
  );
}
