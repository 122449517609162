import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ClassBox from "../components/ClassBox";
import ModalContainer from "../components/Modal";
import Button from "../components/Button";

import class1Image from "../assets/img/image/class1_lecturer.png";
import classApi from "../api/class";

export default function ClassList() {
  const [modalShow, setModalShow] = useState(false);
  const [openClasses, setOpenClasses] = useState([]);
  const [closedClasses, setClosedClasses] = useState([]);
  const [imgUrl, setImgUrl] = useState(
    window.innerWidth > 991
      ? "https://cdn-s3.boostar.co.kr/boostar/ad/class_groundup_ad_banner.png"
      : "https://cdn-s3.boostar.co.kr/boostar/ad/groundup_banner_rep1.png"
  );

  const navigate = useNavigate();

  useEffect(() => {
    const getClassList = async () => {
      try {
        const response = await classApi.getClassList();

        const openClass = response.data.filter(
          (classData) => classData.is_close === "N"
        );
        setOpenClasses(openClass);

        const closedClass = response.data.filter(
          (classData) => classData.is_close === "Y"
        );
        setClosedClasses(closedClass);
      } catch (err) {
        alert(
          "강의 리스트를 불러오는데 오류가 생겼습니다. 잠시 후 다시 시도해주시길 바랍니다."
        );
      }
    };
    getClassList();
  }, []);

  const handleImage = () => {
    if (window.innerWidth > 991) {
      setImgUrl(
        "https://cdn-s3.boostar.co.kr/boostar/ad/class_groundup_ad_banner.png"
      );
    } else {
      setImgUrl(
        "https://cdn-s3.boostar.co.kr/boostar/ad/groundup_banner_rep1.png"
      );
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleImage);

    return () => {
      window.removeEventListener("resize", handleImage);
    };
  }, []);

  return (
    <main className="position-relative" style={{ background: "#f4f4f5" }}>
      <div className="container py-lg-6 py-4 px-lg-11">
        <div className="row">
          <div className="col-lg-2">
            <h3 className="fw-bolder py-lg-0 py-3">
              <span style={{ color: "#fbb60d" }}>BOOSTAR</span> 특강
            </h3>
            <div
              className="mb-4"
              onClick={() => window.open("https://ground-up.kr/", "_blank")}
              style={{ cursor: "pointer" }}
            >
              <img
                src={imgUrl}
                className="mt-lg-10 img-fluid w-lg-90"
                style={{ borderRadius: "5px" }}
              />
            </div>
          </div>
          <div className="col-lg-9">
            <div className="mb-lg-3">
              <h5
                className="px-lg-2 pb-lg-2 pb-2 mb-lg-3 mb-3"
                style={{ borderBottom: "1px solid #c8c8c8" }}
              >
                신규 강의
              </h5>
              <div className="row">
                {openClasses?.reverse().map((data, index) => {
                  return (
                    <ClassBox
                      key={index}
                      image={data.list_thumbnail}
                      title={data.title.replace("/", " ")}
                      info={data.lecturer}
                      onClick={() => navigate(`/class/${data.id}`)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mt-lg-4 mt-3 mb-lg-3 mt-3">
              <h5
                className="px-lg-2 pb-lg-2 py-2 mb-lg-3 mb-3"
                style={{ borderBottom: "1px solid #c8c8c8" }}
              >
                마감된 강의
              </h5>
              <div className="row">
                {closedClasses?.map((data, index) => {
                  return (
                    <ClassBox
                      key={index}
                      image={data.list_thumbnail}
                      title={data.title.replace("/", " ")}
                      info={data.lecturer}
                      onClick={() => navigate(`/class/${data.id}`)}
                    />
                  );
                })}
                <ClassBox
                  image={class1Image}
                  title="투자받기 좋은 재무재표 만드는법"
                  info="재무제표 | 세무법인 숲 김홍정 세무사"
                  url="#"
                  onClick={() => setModalShow(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalContainer
        show={modalShow}
        title="이미 마감된 강의입니다."
        onHide={() => setModalShow(false)}
      >
        <div className="d-flex flex-column align-items-center">
          <div>앞으로 오픈되는 강의들로 찾아뵙겠습니다.</div>
          <div className="mb-lg-2">많은 관심 부탁드립니다 🙂</div>
          <Button
            width="15"
            text="확인"
            border="none"
            background="#fef4e5"
            color="#fbb60d"
            onClick={() => setModalShow(false)}
          />
        </div>
      </ModalContainer>
    </main>
  );
}
