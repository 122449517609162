import React from "react";
import styled from "styled-components";

const ServiceBoxContainer = styled.div`
  .button {
    cursor: pointer;
  }

  .button:hover {
    color: #fec230;
  }
`;

export default function ServiceBox({
  image,
  title,
  info,
  salePrice,
  price,
  discount,
  rating,
  review,
  onClick,
}) {
  return (
    <ServiceBoxContainer className="col-lg-3 col-6 my-2">
      <div onClick={onClick} style={{ cursor: "pointer" }}>
        <img src={image} className="img-fluid" />
        <p className="small mt-lg-2 mt-2 mb-0" style={{ color: "#797979" }}>
          {info}
        </p>
        <p className="lh-sm mt-lg-1 mb-0 mt-2">{title}</p>
        <div className="row my-lg-3 my-2">
          {salePrice && (
            <span className="col-lg-5 col-12 ps-lg-3 ps-2 pe-0 fw-bolder">
              {salePrice}
            </span>
          )}
          {price && (
            <>
              <span
                className="col-lg-4 col-6 text-muted small ps-2 pe-0"
                style={{ textDecoration: "line-through" }}
              >
                {price}
              </span>
              <span className="col-lg-3 col-6 text-danger ps-lg-3 ps-7 pe-0 fw-bolder">
                {discount}
              </span>
            </>
          )}
        </div>
        <p className="small text-end mt-lg-2">
          <i className="bx bxs-star" style={{ color: "#fec230" }}></i>
          <span style={{ color: "#797979" }}>
            {" "}
            {rating} | {review}개의 평가
          </span>
        </p>
      </div>
    </ServiceBoxContainer>
  );
}
