import React from "react";
import { CloseButton, Modal } from "react-bootstrap";

export default function ModalContainer(props) {
  const { title, content, onHide, buttonTitle, children } = props;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      {title && (
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter" className="mb-0">
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className="container">
          <div className="d-flex flex-column align-items-center">
            <div className="mb-lg-0 mb-3 text-center">{content}</div>
            <div>{children}</div>
            {/* <button className="btn btn-dark width-13x" onClick={onHide}>
              {buttonTitle}
            </button> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
