import React, { useEffect } from "react";

export default function Map({ latitude, longitude }) {
  const lat = latitude;
  const lon = longitude;

  const { kakao } = window;

  useEffect(() => {
    const container = document.getElementById("map");
    const options = {
      center: new kakao.maps.LatLng(lat, lon),
      level: 4,
    };
    const map = new kakao.maps.Map(container, options);

    const marker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(lat, lon),
    });

    marker.setMap(map);
  }, [lat, lon]);

  return (
    <>
      <div id="map" style={{ width: "100%", height: "35vh" }}></div>
    </>
  );
}
