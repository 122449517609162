import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";

import resourceApi from "../api/resource";

import logo from "../assets/img/logo/boostar_16x16.png";
import "../assets/css/blog.css";

export default function BlogDetail() {
  const [blogDetail, setBlogDetail] = useState(undefined);

  const { idx } = useParams();

  useEffect(() => {
    const getBlogDetail = async () => {
      try {
        const response = await resourceApi.getBlogContentDetail(idx);

        if (response.result === "success") {
          setBlogDetail(response.data);
        }
      } catch (err) {
        if (err) {
          alert(
            "블로그를 불러오는데 실패했습니다. 잠시 후 다시 이용부탁드립니다."
          );

          return;
        }
      }
    };

    getBlogDetail();
  }, []);

  return (
    <main className="position-relative">
      <section className="py-lg-12 py-5 mx-lg-15 px-lg-15 px-4">
        <div style={{ borderBottom: "1px solid #797979" }}>
          <p className="text-muted ms-lg-2 ms-1">{blogDetail?.blog_type}</p>
          <h3 className="fs-2 py-lg-2">
            {blogDetail?.topic && <span>[{blogDetail?.topic}]</span>}
            <span> {blogDetail?.title}</span>
          </h3>
          <div className="d-flex justify-content-between mb-lg-3">
            <div>
              <img src={logo} />
              <span className="fw-bolder"> BOOSTAR</span>
            </div>
            <p className="text-end">
              {blogDetail?.display_date.replaceAll("-", ".")}
            </p>
          </div>
        </div>
        <div className="d-flex flex-center my-lg-4 my-3">
          <img
            src={blogDetail?.thumbnail}
            className="img-fluid"
            style={{ background: "#000000" }}
          />
        </div>
        <div className="d-flex flex-center">
          <div className="mt-lg-5">
            {blogDetail && ReactHtmlParser(blogDetail?.content)}
          </div>
        </div>
      </section>
    </main>
  );
}
