import axios from "../configs/axiosInstance";

const serviceApi = {};

serviceApi.getHit = async (service_id) => {
  const response = await axios.get(`/service/${service_id}/hit`);

  return response.data;
};

export default serviceApi;
