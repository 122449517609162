import React from "react";
import { useParams } from "react-router-dom";
import Button from "../components/Button";

import partners from "../util/partners.json";

export default function PartnerDetail() {
  const { officeName } = useParams();

  const partnerData = partners.find(
    (partner) => partner.path.split("/")[1] === officeName
  );

  return (
    <main>
      <section className="positive-relative d-flex align-items-center text-white">
        <div className="w-100" style={{ background: "#000000" }}>
          <div className="container position-relative py-8 py-lg-6">
            <div className="row col-lg-8 align-items-center my-lg-3">
              <div className="mb-3 mb-lg-0">
                <h2 className="display-5 mb-2" style={{ color: "#fec230" }}>
                  {partnerData.officeName}
                </h2>
                <div className="mb-lg-4 ps-lg-">
                  {partnerData.type.map((data, index) => (
                    <span className="lh-sm mb-lg-4 ps-lg-2" key={index}>
                      #{data}
                    </span>
                  ))}
                </div>
                {/* <a href={partnerData.url} target="_blank" rel="noreferrer">
                  <button
                    className="btn text-dark width-13x mt-3 mt-lg-2"
                    style={{
                      background: "#fef4e5",
                      color: "#fbb60d",
                      borderRadius: "0.37",
                    }}
                  >
                    Contact
                  </button> */}
                {/* </a> */}
                <Button
                  width="10"
                  text="Contact"
                  background="fef4e5"
                  color="#fbb60d"
                  border="none"
                  onClick={() => window.open(partnerData.url, "_blank")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative mt-lg-10 mb-lg-8">
        <div className="container">
          <div className="row flex-lg-row-reverse justify-content-center">
            <div className="col-lg-3 my-5">
              <div className="col-12 flex-center">
                <img
                  src={require(`../assets/img/logo/${partnerData.logoImage}`)}
                  alt="logo"
                  className="w-lg-80 w-80 mb-lg-6"
                />
              </div>
              <div className="mb-lg-5 my-3 ps-lg-3 px-3">
                <h4 className="fs-4 fw-bold" style={{ color: "#fec72f" }}>
                  비즈니스 타입
                </h4>
                {partnerData.type.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
              <div className="mb-lg-5 my-3 ps-lg-3 px-3">
                <h4 className="fs-4 fw-bold" style={{ color: "#fec72f" }}>
                  웹사이트
                </h4>
                <a href={partnerData.url} target="_blank" rel="noreferrer">
                  {partnerData.url}
                </a>
              </div>
            </div>
            <div className="col-lg-9 px-lg-6 px-3">
              {partnerData.video && (
                <div className="ratio ratio-16x9 mb-lg-5">
                  <iframe
                    width="853"
                    height="480"
                    src={partnerData.video}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              )}
              <div className="mb-lg-5">
                <h3 className="fw-bolder mb-lg-5 mb-3">소개</h3>
                {partnerData.intro.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
              <div className="mb-lg-5">
                <h3 className="fw-bolder mb-lg-5 mb-3">업무 분야</h3>
                {Object.entries(partnerData.businessField).map(
                  ([title, description], index) => (
                    <div key={index} className="mb-lg-5 mb-5">
                      <h4 className="fs-5 fw-bolder mb-lg-3">{title}</h4>
                      {description.map((paragraph, index) => (
                        <p key={index} className="">
                          {paragraph}
                        </p>
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
