import React, { useState } from "react";

import Button from "../Button";

export default function OfficeInformation2({
  applyData,
  handleChange,
  handleChangeStage,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const handleNextButtonClick = () => {
    if (
      !applyData?.businessModel.length ||
      !applyData?.developmentStage.length ||
      !applyData?.mainIndustry.length ||
      !applyData?.mainTechnology.length
    ) {
      setErrorMessage("✓ 필수 입력란을 확인해주세요.");

      return;
    } else {
      setErrorMessage("");
    }

    if (
      applyData?.businessModel.length &&
      applyData?.developmentStage.length &&
      applyData?.mainIndustry.length &&
      applyData?.mainTechnology.length
    ) {
      handleChangeStage((prevState) => ({
        ...prevState,
        officeInformation2: false,
        officeInformation3: true,
      }));
    }
  };

  return (
    <>
      {" "}
      <div className="my-3">
        <div>
          <p className="mb-0" style={{ marginLeft: "48%" }}>
            🌱
          </p>
          <div className="progress height-10">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: "50%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
        <div className="w-lg-100 flex-center my-3">
          <div className="w-lg-50 mt-lg-3">
            <div className="my-lg-3">
              <span style={{ color: "#f44336" }}>▶︎</span>
              <span className="fw-bolder"> 회사 정보</span>
              <p style={{ color: "#f44336" }}>
                ✓ 모든 정보는 영어로 작성해주셔야 합니다. 한글로 작성시 지원은
                무효가 됩니다.
              </p>
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>비즈니스 모델</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <select
                name="businessModel"
                value={applyData?.businessModel || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  비즈니스 모델
                </option>
                <option value="B2B">B2B</option>
                <option value="B2C">B2C</option>
                <option value="B2B2C">B2B2C</option>
                <option value="B2G">B2G</option>
                <option value="C2C">C2C</option>
              </select>
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>개발 단계</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <select
                name="developmentStage"
                value={applyData?.developmentStage || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  개발 단계
                </option>
                <option value="Prototype/MVP">Prototype/MVP</option>
                <option value="Roll out">Roll out</option>
                <option value="Rapid growth">Rapid growth</option>
                <option value="Expansion">Expansion</option>
                <option value="Maturity">Maturity</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>주요 산업</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <select
                name="mainIndustry"
                value={applyData?.mainIndustry || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  주요 산업
                </option>
                <option value="Agriculture">Agriculture</option>
                <option value="Art, Design & Culture">
                  Art, Design & Culture
                </option>
                <option value="Communication, Media & Entertainment">
                  Communication, Media & Entertainment
                </option>
                <option value="Connectivity & Networks">
                  Connectivity & Networks
                </option>
                <option value="Construction">Construction</option>
                <option value="Consumer Good & Retail">
                  Consumer Good & Retail
                </option>
                <option value="Digital & Professional Service">
                  Digital & Professional Service
                </option>
                <option value="Education">Education</option>
                <option value="Energy">Energy</option>
                <option value="Fashion & Beauty">Fashion & Beauty</option>
                <option value="Finance, Legal & Insurance Service">
                  Finance, Legal & Insurance Service
                </option>
                <option value="Food and Beverage">Food and Beverage</option>
                <option value="Healthcare & Life Sciences">
                  Healthcare & Life Sciences
                </option>
                <option value="Industry & Manufacturing">
                  Industry & Manufacturing
                </option>
                <option value="Lifestyle & Wellbeing">
                  Lifestyle & Wellbeing
                </option>
                <option value="Real Estate, Rental & Leasing">
                  Real Estate, Rental & Leasing
                </option>
                <option value="Social & Sustainable Impact">
                  Social & Sustainable Impact
                </option>
                <option value="Transport & Logistics">
                  Transport & Logistics
                </option>
                <option value="Travel & Tourism">Travel & Tourism</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>주요 기술</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <select
                name="mainTechnology"
                value={applyData?.mainTechnology || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  주요 기술
                </option>
                <option value="None">None</option>
                <option value="3D printing">3D printing</option>
                <option value="5G">5G</option>
                <option value="APIs & Enablers">APIs & Enablers</option>
                <option value="Artificial Intelligence">
                  Artificial Intelligence
                </option>
                <option value="Augmented Reality">Augmented Reality</option>
                <option value="Autonomous Vehicles">Autonomous Vehicles</option>
                <option value="Beacons & Bluetooth">Beacons & Bluetooth</option>
                <option value="Big Data">Big Data</option>
                <option value="Bioinformatics">Bioinformatics</option>
                <option value="Biotech">Biotech</option>
                <option value="Blockchain">Blockchain</option>
                <option value="Chemical Engineering">
                  Chemical Engineering
                </option>
                <option value="Cloud">Cloud</option>
                <option value="Electronics, Digital & Apps">
                  Electronics, Digital & Apps
                </option>
                <option value="Gene Technology">Gene Technology</option>
                <option value="Geolocation & Mapping">
                  Geolocation & Mapping
                </option>
                <option value="Human-Computer Interaction">
                  Human-Computer Interaction
                </option>
                <option value="Image & Face Recognition">
                  Image & Face Recognition
                </option>
                <option value="IoT/IoE & Sensors">IoT/IoE & Sensors</option>
                <option value="Language Recognition & Translation">
                  Language Recognition & Translation
                </option>
                <option value="Machine Learning">Machine Learning</option>
                <option value="Mechatronics">mechatronics</option>
                <option value="Medical Technologies">
                  Medical Technologies
                </option>
                <option value="Nanotech">Nanotech</option>
                <option value="Photonics">Photonics</option>
                <option value="Quantum Computing">Quantum Computing</option>
                <option value="Virtual Reality">Virtual Reality</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="ms-1 mb-3">
              <small className="text-danger">{errorMessage}</small>
            </div>
            <div className="w-lg-100 w-100 flex-center mt-lg-3">
              <Button
                width="18"
                text="NEXT"
                border="none"
                background="#f44336"
                color="#ffffff"
                onClick={handleNextButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
