import React, { useEffect, useState } from "react";

import serviceApi from "../api/service";

export default function Hit({ pageCode }) {
  const [hitNumber, setHitNumber] = useState("");

  useEffect(() => {
    const getHitNumber = async () => {
      try {
        const response = await serviceApi.getHit(pageCode);

        if (response.result === "success") {
          setHitNumber(response.hit);
        }
      } catch (err) {
        alert(
          "정보를 불러오는데 문제가 생겼습니다. 잠시 후 다시 이용부탁드립니다."
        );
      }
    };

    getHitNumber();
  }, []);

  return (
    <>
      <span className="text-muted ms-2" style={{ fontSize: "0.75rem" }}>
        조회수 {Number(hitNumber).toLocaleString("ko-KR")}
      </span>
    </>
  );
}
