import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import PartnerCard from "../components/PartnerCard";
import partners from "../util/partners.json";

export default function Partner() {
  const [checkedType, setCheckedType] = useState([]);

  const navigate = useNavigate();

  const formData = [
    { id: 0, name: "법무", isChecked: false },
    { id: 1, name: "세무", isChecked: false },
    { id: 2, name: "노무", isChecked: false },
    { id: 3, name: "엑셀러레이터", isChecked: false },
    { id: 4, name: "마케팅", isChecked: false },
    { id: 5, name: "비즈니스 컨설팅", isChecked: false },
    { id: 6, name: "제품 소싱", isChecked: false },
    { id: 7, name: "투자사", isChecked: false },
    { id: 8, name: "유통", isChecked: false },
    { id: 9, name: "인사", isChecked: false },
    { id: 10, name: "기관", isChecked: false },
    { id: 11, name: "개발", isChecked: false },
    { id: 11, name: "전시, 컨벤션 및 행사 대행업", isChecked: false },
    { id: 12, name: "기타", isChecked: false },
  ];

  const handleChecked = (name) => {
    const isChecked = checkedType.includes(name);

    if (!isChecked) {
      setCheckedType([...checkedType, name]);
    }

    if (isChecked) {
      setCheckedType(checkedType.filter((type) => type !== name));
    }
  };

  return (
    <main>
      <section className="positive-relative d-flex align-items-center text-white">
        <div className="w-100" style={{ background: "#000000" }}>
          <div className="container position-relative px-lg-11 py-8 py-lg-6">
            <div className="row col-lg-8 align-items-center my-lg-3">
              <div className="mb-3 mb-lg-0">
                <h2
                  className="display-5 ms-2 mb-2"
                  style={{ color: "#ffc230" }}
                >
                  파트너
                </h2>
                <p className="lh-sm mb-lg-4 ms-2">
                  BOOSTAR와 파트너십을 통해 새로운 기회를 창출하세요
                </p>
                <Button
                  width="10"
                  text="파트너십 문의하기"
                  border="none"
                  background="#fef4e5"
                  color="#fbb60d"
                  onClick={() => navigate("/contact/partner")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative" style={{ background: "#f4f4f5" }}>
        <div className="container px-lg-11 py-8 py-lg-6">
          <div className="row flex-lg-row-reverse justify-content-center">
            <div className="col-lg-3">
              <div className="ms-lg-5 ms-5 mb-5">
                <p className="fs-3 mb-lg-6 ms-lg-3 ms-3">파트너 타입</p>
                {formData.map((item, index) => (
                  <div key={index} className="my-lg-3 my-2">
                    <input
                      type="checkbox"
                      id={item.name}
                      onChange={(e) => handleChecked(item.name)}
                      className="form-check-input me-lg-3"
                    />
                    <label htmlFor={item.name}>{item.name}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="row col-lg-9">
              {partners.map((partner, index) => {
                const isChecked = partner.type.map((item) => {
                  return checkedType.includes(item) ? true : false;
                });
                const isVisible = isChecked.includes(true);

                return (
                  <>
                    {checkedType.length === 0 ? (
                      <PartnerCard
                        key={index}
                        image={partner.image}
                        officeName={partner.officeName}
                        path={partner.path}
                        type={partner.type}
                      />
                    ) : (
                      <>
                        {isVisible && (
                          <PartnerCard
                            key={partner.id + 1}
                            image={partner.image}
                            officeName={partner.officeName}
                            path={partner.path}
                            type={partner.type}
                          />
                        )}
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
