export default function getAllCookie() {
  const cookies = document.cookie.split(";");

  const splittedCookie = cookies.map((cookie, index) => {
    const trim = cookie.trim();
    return [index, ...trim.split("=")];
  });

  const cookieArr = splittedCookie.map((cookie) => {
    const [id, key, ...value] = cookie;
    return { id, key, value: value.join("=") };
  });

  return cookieArr;
}
