import React from "react";
import { useNavigate } from "react-router-dom";

import ServiceBox from "../../components/ServiceBox";

import hrListMainImage from "../../assets/img/image/hr_list_top_banner.jpg";

export default function HrList() {
  const navigate = useNavigate();

  return (
    <main className="position-relative" style={{ background: "#ffffff" }}>
      {/* <div className="container">
        <img
          src={hrListMainImage}
          className="img-fluid w-lg-100 mb-lg-4 px-lg-10"
        />
      </div> */}
      <div className="container py-lg-6 py-4 px-lg-11">
        <div
          className="mb-lg-4 mb-3"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <h3 className="fw-bolder ps-2">
            <span style={{ color: "#fbb60d" }}>인프라 및 리소스</span> <br />
            서비스
          </h3>
        </div>
        <div className="row">
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/incorporation_list/incorporation_list_thumbnail_230519.jpg"
            title="무료법인설립"
            info="회사 설립 지원 서비스"
            rating="0.0"
            review="0"
            onClick={() => navigate("/free_incorporation")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/global_business/usa_incorporation_list_thmbnail_230609.png"
            title="ONE-STOP 미국 법인 설립"
            info="회사 설립 지원 서비스"
            rating="0.0"
            review="0"
            onClick={() => navigate("/usa_incorporation")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/hr/hr_lsit_thumbnail_230609.jpg"
            title="[인사 노무 팩] 인사 노무 폭탄 제거"
            info="HR 지원 서비스"
            salePrice="330,000 원"
            price="500,000 원"
            discount="34%"
            rating="5.0"
            review="1"
            onClick={() => navigate("/hr_pack")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/establishment%26certification/venture_list_thumbnail.jpg"
            title="쉽고 빠른 벤처기업인증"
            info="기술,도구 및 인프라 서비스"
            salePrice="900,000 원"
            price="1,200,000 원"
            discount="25%"
            rating="0.0"
            review="0"
            onClick={() => navigate("/venture_certification")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/establishment%26certification/establishment_RI_list_thumbnail_230327.jpg"
            title="아무도 몰랐던 절세 혜택"
            info="기술, 도구 및 인프라 서비스"
            salePrice="550,000 원"
            price="700,000 원"
            discount="21%"
            rating="0.0"
            review="0"
            onClick={() => navigate("/establishment_research_institute")}
          />
          <ServiceBox
            image="https://cdn-s3.boostar.co.kr/boostar/life_style_list/meditaion_list_thumbnail_230609.png"
            title="찾아가는 명상워크숍"
            info="HR 지원 서비스"
            rating="0.0"
            review="0"
            onClick={() => navigate("/meditation")}
          />
        </div>
      </div>
    </main>
  );
}
