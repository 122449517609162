import axios from "../configs/axiosInstance";

const classApi = {};

classApi.registerClass = async ({
  classIdx,
  classDate,
  className,
  name,
  phone,
  email,
  officeName,
  officeInfo,
  qna,
  inflow,
  isPaid,
}) => {
  const response = await axios.post("/class/saveClassApply", {
    classIdx,
    classDate,
    className,
    name,
    phone,
    email,
    officeName,
    officeInfo,
    qna,
    inflow,
    isPaid,
  });

  return response.data;
};

classApi.getClass = async ({ email }) => {
  const response = await axios.get(`/class/${email}/list`);

  return response.data;
};

classApi.getClassList = async () => {
  const response = await axios.get("/class/list");

  return response.data;
};

classApi.updateClassApply = async ({
  applyIdx,
  orderIdx,
  classCost,
  isPaid,
}) => {
  const response = await axios.put("/class/updateClassApply", {
    apply_idx: applyIdx,
    order_idx: orderIdx,
    class_cost: classCost,
    is_paid: isPaid,
  });

  return response.data;
};

classApi.getMyClassList = async ({ user_idx }) => {
  const response = await axios.get(`/class/${user_idx}/list/v2`);

  return response.data;
};

classApi.getClassInfo = async (id) => {
  const response = await axios.get(`/class/${id}/info`);

  return response.data;
};

export default classApi;
