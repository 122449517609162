import React, { useState } from "react";

export default function Tag(type) {
  const [background, setBackground] = useState("");
  const [fontColor, setFontColor] = useState("");

  useState(() => {
    switch (type.title) {
      case "스타트업 뉴스":
        setBackground("4563BF");
        setFontColor("FFFFFF");
        break;
      case "Q&A":
        setBackground("515673");
        setFontColor("FFFFFF");
        break;
      case "지원사업":
        setBackground("0E1826");
        setFontColor("FFFFFF");
        break;
      case "투자유치":
        setBackground("D99A25");
        setFontColor("000000");
        break;
      case "경영":
        setBackground("CBBCD3");
        setFontColor("000000");
        break;
      case "재무":
        setBackground("70AAC2");
        setFontColor("000000");
        break;
      case "법무":
        setBackground("717E79");
        setFontColor("000000");
        break;
      case "노무":
        setBackground("7083C2");
        setFontColor("000000");
        break;
      case "세무":
        setBackground("9DC198");
        setFontColor("000000");
        break;
    }
  }, [type.title]);

  return (
    <>
      <div
        id={type.title}
        className="badge badge-pill me-1 me-lg-2"
        style={{
          background: `#${background}`,
          color: `#${fontColor}`,
        }}
      >
        # {type.title}
      </div>
    </>
  );
}
