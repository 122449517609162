import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import Card from "../components/Card";
import resourceApi from "../api/resource";
import { ebooksState } from "../store/contentState";
import Button from "../components/Button";

export default function EbookList() {
  const [ebooks, setEbooks] = useRecoilState(ebooksState);
  const date = new Date(ebooks?.date);

  const navigate = useNavigate();

  useEffect(() => {
    const getResource = async () => {
      try {
        const response = await resourceApi.getEbookList();

        setEbooks(response.data);
      } catch (err) {
        if (err.response?.data.error.message === "Not exist") {
          navigate("/error");
        }
      }
    };

    getResource();
  }, []);

  return (
    <main>
      <section className="positive-relative d-flex align-items-center text-white">
        <div className="w-100" style={{ background: "#000000" }}>
          <div className="container position-relative px-lg-11 px-3 py-lg-6 py-8">
            <div className="row col-lg-8 align-items-center my-lg-3">
              <div className="mb-3 mb-lg-0">
                <h2 className="display-5 mb-2" style={{ color: "#ffc230" }}>
                  가이드북
                </h2>
                <p className="lh-sm mb-lg-4">
                  BOOSTAR팀이 제작한 가이드북을 활용하여 성장 속도를 한 층
                  높여보세요!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative">
        <div className="container px-lg-11 py-lg-6 py-3">
          <div className="row col-lg-12 my-lg-3">
            {ebooks &&
              ebooks.map((ebook) => {
                const date = new Date(ebook.date)
                  .toLocaleDateString()
                  .slice(0, -1);
                return (
                  <Card
                    key={ebook.idx}
                    id={ebook.idx}
                    thumbnail={ebook.thumbnail}
                    date={date}
                    title={ebook.title}
                    explanation={ebook.subtitle}
                    // price="30,000원"
                  />
                );
              })}
          </div>
        </div>
      </section>
    </main>
  );
}
