import React, { useState } from "react";
import { useNavigate } from "react-router";

import EventCard from "../components/EventCard";
import ModalContainer from "../components/Modal";
import Button from "../components/Button";

export default function EventList() {
  const [isModalShow, setIsModalShow] = useState(false);

  const navigate = useNavigate();

  const handleClosedEventClick = (event) => {
    event.preventDefault();

    setIsModalShow(true);
  };

  return (
    <main className="position-relative">
      <section className="positive-relative d-flex align-items-center text-white">
        <div className="w-100" style={{ background: "#000000" }}>
          <div className="container position-relative px-lg-11 py-8 py-lg-6">
            <div className="row col-lg-8 align-items-center my-lg-3">
              <div className="mb-3 mb-lg-0">
                <h2 className="display-5 mb-2">
                  <span style={{ color: "#ffc230" }}>BOOSTAR</span>
                  <span> 이벤트</span>
                </h2>
                <p className="lh-sm mb-lg-4">
                  부스타에서는 네트워킹, 데모데이 등 다양한 국내외 스타트업 관련
                  행사를 진행하고 있습니다.
                  <br />
                  지금 참가 신청버튼을 누르시고,
                  <br />
                  국내 뿐 아니라 글로벌 시장까지 확장할 수 있는 발판을
                  마련해보세요!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative">
        <div className="container px-lg-11">
          <div className="row py-lg-3">
            <div className="col-lg-3 my-lg-3 my-2">
              <EventCard
                image="https://cdn-s3.boostar.co.kr/boostar/image/event_finance_ac.jpg"
                title="금융데이터 액셀러레이팅 교육"
                subTitle="빅데이터 활용 교육 및 실습"
                deadline="2023. 9. 13 (수)"
                date="2023. 8월 - 2023. 10월"
                isClosed={false}
                onClick={() => window.open("https://bit.ly/45Sfn2G", "_blank")}
              />
            </div>
            <div className="col-lg-3 my-lg-3 my-2">
              <EventCard
                image="https://cdn-s3.boostar.co.kr/boostar/image/event_4050_ac.jpg"
                title="중장년 창업컨설팅 지원사업 2기"
                subTitle="맞춤형 컨설팅 / 창업 교육"
                deadline="2023. 8. 25 (금)"
                date="2023. 8월 - 2023. 11월"
                isClosed={true}
                onClick={handleClosedEventClick}
              />
            </div>
            <div className="col-lg-3 my-lg-3 my-2">
              <EventCard
                image="https://cdn-s3.boostar.co.kr/boostar/image/hcmc_list_banner_2.jpg"
                title="호치민 탐방 프로그램"
                subTitle="현지 네트워킹 / 글로벌 행사"
                deadline="2023. 7. 25 (화) 15시"
                date="2023. 8. 21 - 2023. 8. 25 (4박 5일)"
                isClosed={true}
                onClick={() => navigate("/hcmc_2023")}
              />
            </div>
            <div className="col-lg-3 my-lg-3 my-2">
              <EventCard
                image="https://cdn-s3.boostar.co.kr/boostar/image/startup_wheel_thumbnail.jpg"
                title="베트남 STARTUP WHEEL 2023"
                subTitle="동남아시아 최대 규모 스타트업 경연대회"
                deadline="2023. 4. 14 (금) 18시"
                date="2023. 6월 - 2023. 8월"
                isClosed={true}
                onClick={() => navigate("/startup_wheel_2023")}
              />
            </div>
            <div className="col-lg-3 my-lg-3 my-2">
              <EventCard
                image="https://cdn-s3.boostar.co.kr/boostar/image/banner_innovation_thumbnail.jpg"
                title="대만 Innovation Day"
                subTitle="Cathay Financial Holdings 내부 초청 행사"
                deadline="2023. 2. 15 (수) 18시"
                date="2023. 3. 21 (화)"
                isClosed={true}
                onClick={handleClosedEventClick}
              />
            </div>
            <div className="col-lg-3 my-lg-3 my-2">
              <EventCard
                image="https://cdn-s3.boostar.co.kr/boostar/image/social_venture_thumbnail.jpg"
                title="예비창업패키지 소셜벤처 사업설명회"
                subTitle="예비창업패키지 소셜벤처분야 사업설명회"
                deadline="2023. 3. 7 (화) 18시"
                date="2023. 3. 8 (수) 14시"
                isClosed={true}
                onClick={handleClosedEventClick}
              />
            </div>
          </div>
        </div>
      </section>
      <ModalContainer show={isModalShow} onHide={setIsModalShow}>
        <p className="text-center">해당 행사의 신청기간이 마감되었습니다.</p>
        <p className="text-center">더 유익한 행사들로 찾아오겠습니다 😊</p>
        <div className="d-flex flex-center mt-lg-3 mt-3">
          <Button
            width="18"
            text="닫기"
            border="none"
            background="#000000"
            color="#fec230"
            onClick={() => setIsModalShow(false)}
          />
        </div>
      </ModalContainer>
    </main>
  );
}
