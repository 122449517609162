import React from "react";

import Intro2 from "../components/Service/Intro2";
import More from "../components/More";

import introImage from "../assets/img/image/developer_intro.png";
import developerImage from "../assets/img/image/developer_image.png";

export default function Developer() {
  return (
    <main>
      <Intro2
        image={introImage}
        title1="BOOSTAR에서"
        title2="최고의 개발자와 함께하세요"
        subTitle="여러분의 사업 아이템을 구현해 줄 실력 있는 개발자를 만날 수 있습니다."
        url="/contact"
      />
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                큰 그림을 그릴 수 있는 설계자를 만나야 합니다
              </h1>
              <h5 className="mb-3 mb-lg-6">
                개발 경험이 부족할 경우 설계에 대한 노하우 부족으로 앞을 고려한
                개발이 어렵습니다. <br />
                실제 서비스 초기 모델을 구축하고도, 모델 확장 과정에서 문제가
                생겨 뒤엎고 처음부터 다시 개발을 해야 되는 경우가 많습니다
              </h5>
              <h1 className="lh-base mb-3 mb-lg-4 fs-2">
                비즈니스를 이해하는 개발자
              </h1>
              <h5 className="mb-3 mb-lg-4">
                BOOSTAR 팀 빌딩 서비스를 통해 비즈니스의 성장을 함께할 든든한
                팀을 만들 수 있습니다!
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={developerImage} className="img-fluid w-lg-90" />
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <div
                className="px-lg-3 px-3 py-lg-8 py-2 w-lg-90"
                style={{ background: "#fec72f" }}
              >
                <h1 className="lh-base mb-lg-5 fs-4 mb-2">
                  BOOSTAR에서 실력과 경험을
                  <br />
                  겸비한 개발자를 만나보세요
                </h1>
                <h6 className="lh-base mb-2 mb-lg-3">
                  저희는 모바일 앱, 프론트엔드, 백엔드 개발자를 포함한 여러 개발
                  분야의 실력있는 개발자 네트워크를 보유하고 있습니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-3">
                  BOOSTAR에서 여러분의 사업에 필요한 개발자를 빠르게
                  연결해드립니다.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
