import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import Category from "../components/Main/Category";
import Intro from "../components/Main/Intro";
import Service from "../components/Main/Services";
import Partner from "../components/Main/Partner";
import Review from "../components/Main/Review";
import Contents from "../components/Main/Contents";
import Introduce from "../components/Main/Introduce";
import Banner from "../components/Main/Banner";
import EventModal from "../components/EventModal";
import ModalContainer from "../components/Modal";
import VideoModal from "../components/VideoModal";
import AddSlide from "../components/Main/AddSlide";
import News from "../components/Main/News";

import { blogState, contentsState, ebooksState } from "../store/contentState";
import getCookie from "../util/getCookie";
import resourceApi from "../api/resource";

export default function Main() {
  const [modalShow, setModalShow] = useState(true);
  const [videoModalShow, setVideoModalShow] = useState(true);
  const [ebooks, setEbooks] = useRecoilState(ebooksState);
  const [contents, setContents] = useRecoilState(contentsState);

  const cookie = getCookie("boostar:popup");

  const handleCloseButtonClick = () => {
    setModalShow(false);
  };

  useEffect(() => {
    const getBlogContents = async () => {
      try {
        const response = await resourceApi.getBlogContents();

        if (response.result === "success") {
          setContents(response.data);
        }
      } catch (err) {
        alert(
          "콘텐츠를 불러오는데 에러가 발생했습니다. 잠시 후 다시 이용바랍니다."
        );
      }
    };

    getBlogContents();
  }, []);

  return (
    <main className="mt-lg-3 mt-2">
      <Intro />
      <Service />
      <News contents={contents} />
      {/* <Introduce /> */}
      <AddSlide />
      <Contents ebook={ebooks} contents={contents} />
      <Category />
      <section style={{ background: "#f4f4f5" }}>
        <div className="container pb-0 d-flex flex-center">
          <div className="ratio ratio-16x9 w-lg-70 mb-0">
            <iframe
              src="https://player.vimeo.com/video/815162063?h=046768b4ed?autoplay=1&loop=1&title=0&background=1"
              width="640"
              height="360"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </section>
      {/* <Review title="" color="#f4f4f5" /> */}
      <Partner />
      <Banner />
      {/* {!cookie && (
        <EventModal
          show={modalShow}
          setShow={setModalShow}
          onHide={handleCloseButtonClick}
        />
      )} */}
      {/* <VideoModal show={videoModalShow} onHide={handleSkipButtonClick} /> */}
    </main>
  );
}
