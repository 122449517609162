import React, { useState } from "react";
import styled from "styled-components";

import Button from "../Button";
import TextInput from "../TextInput";
import { EMAIL_REGEXP, PHONE_REGEXP } from "../../constants/RegExp";

const Select = styled.select`
  width: 100%;
  height: 3rem;
`;

export default function PersonalInformation({
  applyData,
  isEmpty,
  setIsEmpty,
  handleChange,
  handleChangeStage,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  let percent = 0;
  // const percentAnimation = setInterval(() => {
  //   percent = percent + 1;
  // }, 10000);
  // console.log(percent);

  // if (percent === 5) {
  //   clearInterval(percentAnimation);
  // }

  const handleNextButtonClick = () => {
    const isValidPhone = PHONE_REGEXP.test(applyData?.phone);

    if (!applyData?.gender.length || !applyData?.birth.length) {
      setErrorMessage("✓ 필수 입력란을 확인해주세요.");
    } else {
      setErrorMessage("");
    }

    if (
      !applyData?.name.replaceAll(" ", "").length ||
      !applyData?.email.replaceAll(" ", "").length ||
      !applyData?.phone.replaceAll(" ", "").length ||
      !applyData?.currentTitle.replaceAll(" ", "").length
    ) {
      setIsEmpty(true);

      return;
    }

    if (
      applyData?.gender.length &&
      applyData?.birth.length &&
      applyData?.name.replaceAll(" ", "").length &&
      applyData?.email.replaceAll(" ", "").length &&
      isValidPhone &&
      applyData?.currentTitle.replaceAll(" ", "").length
    ) {
      handleChangeStage((prevState) => ({
        ...prevState,
        personalInformation: false,
        officeInformation1: true,
      }));
    }
  };

  return (
    <>
      {" "}
      <div className="my-3">
        <div>
          <p className="mb-0" style={{ marginLeft: "2.5%" }}>
            🌱
          </p>
          <div className="progress height-10">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: "4%" }}
              aria-valuenow="4"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
        <div className="w-lg-100 flex-center my-3">
          <div className="w-lg-50 mt-lg-3">
            <div className="my-lg-3">
              <span style={{ color: "#f44336" }}>▶︎</span>
              <span className="fw-bolder"> 개인정보</span>
              <p style={{ color: "#f44336" }}>
                ✓ 모든 정보는 영어로 작성해주셔야 합니다. 한글로 작성시 지원은
                무효가 됩니다.
              </p>
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>이름</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <TextInput
                name="name"
                type="text"
                placeholder="이름(영문)"
                icon="bx-user"
                value={applyData?.name || ""}
                emptyValidation={isEmpty}
                emptyMessage="✓ 이름을 영문으로 기입해주세요."
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>성별</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <Select
                name="gender"
                value={applyData?.gender || ""}
                style={{
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
              >
                <option value="" disabled>
                  성별
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Select>
            </div>
            <div>
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>이메일</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <TextInput
                name="email"
                type="text"
                placeholder="user@boostar.co.kr"
                icon="bx-envelope"
                value={applyData.email || ""}
                pattern={EMAIL_REGEXP}
                errorMessage="✓ 올바른 이메일 양식이 아닙니다."
                emptyValidation={isEmpty}
                emptyMessage="✓ 이메일을 기입해주세요."
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>생일</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <input
                name="birth"
                type="date"
                value={applyData.birth || ""}
                style={{
                  width: "100%",
                  height: "3rem",
                  border: "1px solid #e5e6f3",
                  borderRadius: "0.375rem",
                  paddingLeft: "10px",
                }}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>연락처</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
              </div>
              <TextInput
                name="phone"
                // type="tel"
                placeholder="01012345678"
                icon="bx-phone"
                value={applyData.phone || ""}
                maxLength={13}
                pattern={PHONE_REGEXP}
                errorMessage="✓ 핸드폰번호를 확인해주세요."
                emptyValidation={isEmpty}
                emptyMessage="✓ 핸드폰번호를 기입해주세요."
                onChange={handleChange}
              />
            </div>
            <div className="mb-0">
              <div className="mb-0">
                <span style={{ color: "#3bb6d5" }}>직함</span>
                <span style={{ color: "#ff3d0d" }}>*</span>
                <span className="small"> (CEO, CTO, 매니저... 등)</span>
              </div>
              <TextInput
                name="currentTitle"
                type="text"
                placeholder="CEO"
                icon="bx-id-card"
                value={applyData.currentTitle || ""}
                emptyValidation={isEmpty}
                emptyMessage="✓ 현재 본인의 직합을 기입해주세요."
                onChange={handleChange}
              />
            </div>
            <div className="ms-1 mb-3">
              <small className="text-danger">{errorMessage}</small>
            </div>
            <div className="w-lg-100 w-100 flex-center mt-lg-3">
              <Button
                width="18"
                text="NEXT"
                border="none"
                background="#f44336"
                color="#ffffff"
                onClick={handleNextButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
