import React from "react";

export default function Suspender({ title }) {
  return (
    <section className="position-relative pb-1 pb-lg-2">
      <div className="container position-relative mt-10">
        <div className="d-flex flex-column align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="text-center my-lg-4 my-4">
            <h3>{title}</h3>
          </div>
        </div>
      </div>
    </section>
  );
}
