import React from "react";

export default function Privacy() {
  return (
    <main className="main-content" id="main-content">
      <section className="position-relative">
        <div className="container py-9 py-lg-11">
          <div className="row pt-4 pt-lg-9 justify-content-center">
            <div className="col-xl-9">
              <div className="shadow-lg rounded-4 px-4 px-lg-6 py-5 py-lg-7">
                <div className="mb-4 pb-4 border-bottom">
                  <h1>개인정보처리방침</h1>
                </div>
                <div className="mb-4 mb-lg-5">
                  <p>
                    주식회사 메타웨이 (이하 ‘회사’)의 BOOSATR는 ‘정보통신망 이용
                    촉진 칠 정보보호 등에 관한 법률’, ‘개인정보보호법’ 등
                    개인정보보호법령을 철저히 준수하여, 개인정보의 처리 목적을
                    명확하게 하고, 목적의 필요한 범위에서 최소한의 개인정보만을
                    적법하고 정당하게 수집하며, 개인정보를 안정성을 위해 회사는
                    책임과 의무를 다하기 위해 노력하고 있습니다.
                  </p>
                  <p>
                    아래의 개인정보처리방침은 회사의 정책 변경 등의 이유로
                    변경될 수 있으며, 고객분들께 웹사이트를 통하여 고지되고
                    있습니다.
                  </p>
                  <p>회사의 개인정보처리방침은 아래와 같습니다.</p>
                </div>
                <div className="mb-4 mb-lg-5">
                  <h3 className="mb-4">1. 개인정보의 처리 목적</h3>
                  <p>
                    회사는 다음의 목적을 위하여 이용자의 개인정보를 처리합니다.
                    처리하고 있는 개인정보는 다음의 목적 이외의 용도로는
                    이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보
                    보호법 제 18조, 정보통신망법 제 22조등에 따라 별도의 동의를
                    받는 등 필요한 조치를 이행할 예정입니다.
                  </p>
                  <p className="mb-1">
                    &nbsp; 가. 회원가입, 본인식별·인증, 회원자격 유지·관리,
                    본인확인, 서비스부정이용방지, 법정대리인의 동의여부
                  </p>
                  <p className="mb-1">
                    &nbsp; 나. 상담, 콘텐츠·서비스 제공, 계약서·청구서 발송 등
                  </p>
                  <p className="mb-1">&nbsp; 다. 대금결제·정산, 채권추심 등</p>
                  <p className="mb-1">
                    &nbsp; 라. 고충처리를 위한 신원확인, 고충사항 확인,
                    사실조사를 위한 연락·통지, 처리결과 통보 등
                  </p>
                  <p className="mb-1">
                    &nbsp; 마. 뉴스레터 구독 : BOOSTAR 뉴스레터 발송
                  </p>
                  <p className="mb-5">
                    &nbsp; 바. 이벤트 참여 : 이벤트 당첨 시 본인 확인, 이벤트
                    관련 문의/응대 및 공지 전달, 이벤트 경품 배송
                  </p>
                </div>
                <div className="mb-4 mb-lg-5">
                  <h3 className="mb-4">2. 개인정보의 수집 및 이용 목적</h3>
                  <p>
                    회사는 이용자에게 최적화된 서비스를 원활하게 제공하기 위하여
                    다음의 개인정보 항목을 필수적으로 수집하고 이용합니다.
                  </p>
                  <div>
                    <h5>(1) 수집항목</h5>
                    <p>가. 회원가입 </p>
                    <p className="ms-lg-1">
                      이메일 주소, 이름, 휴대폰 번호, 비밀번호
                    </p>
                    <p>나. 상담</p>
                    <p className="ms-lg-1">
                      [일반] <br />
                      이름, 이메일 주소, 휴대폰 번호, 회사명, 고객 분류
                      <br />
                      [스타트업] <br />
                      이름, 이메일 주소, 휴대폰 번호, 회사명
                      <br />
                      [전문가] <br />
                      이름, 이메일 주소, 휴대폰 번호, 전문분야
                      <br />
                      [투자자] <br />
                      이름, 이메일 주소, 휴대폰 번호, 회사명
                      <br />
                      [협력사] <br />
                      이름, 이메일 주소, 휴대폰 번호, 회사명
                    </p>
                    <p>다. 결제 </p>
                    <p className="ms-lg-1">
                      • 신용카드: 카드사명, 카드번호(일부) 등 <br />
                      • 무통장입금: 은행명, 입금자명 <br />
                      • 현금영수증 발급 정보: 휴대전화번호, 현금영수증카드정보
                      <br />
                      • 세금계산서 발급 정보: 전자세금계산서 발급용 이메일,
                      사업자등록번호, 사업자등록증 사본(이름, 주소, 휴대전화,
                      이메일)
                      <br />
                    </p>
                    <p>
                      라.환불
                      <br />
                      &nbsp;예금주 성명, 은행명, 계좌번호
                    </p>
                    <p>
                      마.서비스 이용기록
                      <br /> &nbsp;단말기정보(OS, 화면사이즈 ,디바이스 아이디,
                      폰기종, 단말기(모델명), IP주소, 쿠키, 방문일시,
                      부정이용기록, 서비스 이용기록 등
                    </p>
                    <p>
                      바.이벤트 등 프로모션 알림을 위해 개인정보를 이용합니다.
                    </p>
                    <p className="mb-lg-5">
                      사.뉴스레터 구독
                      <br /> &nbsp;이메일, BOOSTAR 뉴스레터 수신∙개봉 기록
                    </p>
                  </div>
                  <div>
                    <h5>(2) 수집 방법</h5>
                    <p>
                      가. 회사는 ‘개인(신용)정보 수집, 이용동의’에 대해
                      「동의함」 또는 「동의하지 않음」 버튼을 클릭할 수 있는
                      절차를 마련하여, 「동의함」 버튼을 클릭하면 개인정보
                      수집에 대해 동의한 것으로 봅니다.
                    </p>
                    <p>
                      나. 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
                    </p>
                    <p className="ms-lg-1">
                      • 가입 및 서비스 이용과정에서 고객이 개인정보 수집에 대해
                      동의 후 직접 정보를 입력하는 경우 <br />
                      • 문의 과정에서 웹페이지, 전화, 메일, 팩스, 서면 등
                      <br />• 온·오프라인에서 진행되는 이벤트/행사 등 응모 및
                      참여
                    </p>
                  </div>
                </div>
                <div className="mb-4 mb-lg-5">
                  <h3 className="mb-4">3. 개인정보의 제3자 제공</h3>
                  <p>
                    회사는 고객의 개인정보를 위에서 고지한 범위를 넘어
                    이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다.
                    <br /> 단, 개인정보보호법 제 17조 및 18조 2항에 해당하는
                    경우에는 개인정보를 제 3자에게 제공할 수
                    있습니다.(정보주체의 동의, 법률의 특별한 규정 등)
                  </p>
                </div>
                <div className="mb-4 mb-lg-5">
                  <h3 className="mb-4">4. 개인정보 처리 위탁</h3>
                  <p>
                    회사는 수탁자들이 위탁한 개인정보를 안전하게 처리하고 있는지
                    지속적으로 관리 감독하고 있으며, 수탁업무가 종료된 때에
                    수탁자가 보유하고 있는 개인정보는 즉시 파기하게 하고
                    있습니다.
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          수탁자
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          위탁업무
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          보유 및 이용 기간
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          카카오
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          알림톡 서비스 제공, 고객 상담
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          회원 탈퇴 시 또는 위탁 계약 종료 시 까지
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          스티비
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          알림 발송
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          회원 탈퇴 시 또는 위탁 계약 종료 시 까지
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          토스페이먼츠
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          결제 처리
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          회원 탈퇴 시 또는 위탁 계약 종료 시 까지
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          Amazon Web Service Inc.
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          결제 처리
                        </td>
                        <td
                          className="py-lg-3 py-3 px-lg-2 px-2"
                          style={{ border: "1px solid black" }}
                        >
                          회원 탈퇴 시 또는 위탁 계약 종료 시 까지
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mb-4 mb-lg-5">
                  <h3 className="mb-4">5. 개인정보의 파기절차 및 방법</h3>
                  <p>
                    회사는 수집한 이용자의 개인정보를 해당 이용 목적을 달성한
                    후에는 지체 없이 파기하는 것을 원칙으로 하나, 관계법령의
                    규정에 의하여 보존할 필요가 있는 경우에는 일정기간 보관한 후
                    파기합니다.
                  </p>
                  <p>가. 개인정보의 보유기간</p>
                  <p className="ms-lg-1">
                    &nbsp;• 회원가입정보 : 회원 탈퇴 시까지. 단, 관계법령위반에
                    따른 수사·조사 등이 진행 중이거나 홈페이지 이용에 따른
                    채권·채무관계 잔존 시에는 해당 사유 종료 시까지
                    <br />
                    &nbsp;• 재화 또는 서비스 제공 : 재화·서비스 공급완료,
                    요금결제, 정산완료 시까지.
                    <br />
                    &nbsp;• 뉴스레터 구독 신청에 따른 보관: BOOSTAR 뉴스레터
                    발행 서비스 종료 시점 또는 수신 거부 시까지
                    <br />
                    &nbsp;• 전자상거래 등에서의 소비자 보호에 관한 법률에 따른
                    표시·광고, 계약내용 및 이행 등 거래에 관한 기록
                    <br />
                    &nbsp;&nbsp;(1) 표시·광고에 관한 기록 : 6개월
                    <br />
                    &nbsp;&nbsp;(2) 계약 또는 청약철회 등에 관한 기록 : 5년
                    <br />
                    &nbsp;&nbsp;(3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                    <br />
                    &nbsp;&nbsp;(4) 소비자의 불만 또는 분쟁처리에 관한 기록 :
                    3년
                    <br />
                    &nbsp;• 통신비밀보호법 시행령 제41조에 따른
                    통신사실확인자료(사이트 이용 및 방문기록, 접속로그, 접속IP)
                    : 3개월
                  </p>
                  <p>나. 파기절차</p>
                  <p className="ms-lg-1">
                    &nbsp;• 회원가입 등을 위해 입력한 정보는 목적이 달성된 후
                    내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유
                    및 이용기간 참조) 별도의 DB에 일정 기간 저장된 후 기간 종료
                    시 지체 없이 파기합니다. 동 개인정보는 법률에 의한 경우가
                    아니고서는 보유하는 이외의 다른 목적으로 이용되지 않습니다.
                  </p>
                  <p>다. 파기방법</p>
                  <p className="ms-lg-1">
                    • 종이에 출력된 개인정보: 분쇄기로 분쇄 또는 소각을 통해
                    파기
                    <br />• 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수
                    없는 방법을 사용하여 파기
                  </p>
                </div>
                <div className="mb-4 mb-lg-5">
                  <h3 className="mb-4">6. 회원의 권리와 그 행사 방법</h3>
                  <p>
                    가. 이용자 또는 그 법정대리인은 언제든지 아래의 사항에
                    관하여 개인정보 열람·정정을 요구할 수 있습니다.
                  </p>
                  <p className="ms-lg-1">
                    • 회사가 보유하고 있는 이용자의 개인 정보
                    <br />
                    • 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한
                    내역
                    <br />• 회사에게 개인정보수집, 이용, 제공 등의 동의를 한
                    내역
                  </p>
                  <p>
                    나. 이용자는 직접 자신의 정보를 열람, 정정할 수 있으며,
                    별도로 개인정보보호책임자에게 서면, 전화, 전자우편 등을
                    통하여 개인정보의 열람, 정정을 요청할 수 있습니다.
                  </p>
                  <p>
                    다. 이용자 또는 그 법정대리인은 언제든지 자신의 개인정보처리
                    정지를 요구할 수 있습니다.
                  </p>
                  <p>
                    라. 이용자 또는 그 법정대리인은 언제든지 회원가입 시
                    인정보의 수집, 이용, 제공에 대해 동의하신 내용을 언제든지
                    철회(회원 탈퇴)할 수 있습니다. 회원탈퇴는 [마이페이지]에서
                    직접 탈퇴를 하거나, 별도로 개인정보보호책임자에게 서면,
                    전화, 전자우편 등을 통하여 할 수 있습니다.
                    <br /> 단, 요청 시 서비스의 일부 또는 전체 이용이 제한될 수
                    있으며, 다른 법령에 따라 수집하는 정보의 경우에는 동의 철회,
                    삭제가 어려울 수 있습니다.
                  </p>
                </div>
                <div>
                  <h3 className="mb-4">
                    7. 개인정보 자동수집 장치의 설치•운영 및 그 거부에 관한 사항
                  </h3>
                  <p>
                    회사는 이용자의 정보를 수시로 저장하고 찾아내는
                    ‘쿠키(cookie)’를 사용합니다. 쿠키는 회사의 서버가 회사의
                    웹사이트를 이용하는 이용자의 컴퓨터 브라우저에게 보내는 아주
                    작은 정보 파일로서 이용자의 컴퓨터 하드디스크에 저장될 수
                    있습니다.
                  </p>
                  <p>가. 쿠키의 설치•운영 및 거부</p>
                  <p>
                    이용자는 브라우저의 옵션을 설정함으로써 모든 쿠키를
                    허용하거나, 모든 쿠키의 저장을 거부할수도 있습니다. 다만,
                    쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스 이용에
                    제한이 생길 수 있습니다.
                  </p>
                  <p>나. 쿠키 설정 거부 방법</p>
                  <p className="ms-lg-1">
                    • Internet Explorer: [도구]메뉴에서 [인터넷 옵션]을 선택 →
                    [개인정보] 클릭 → [고급] 클릭 → 쿠키 허용여부를 선택 <br />•
                    Chrome: [설정] → [고급 설정 표시] 선택 → [개인정보의 콘텐츠
                    설정] 클릭 → [쿠키] 섹션에서 설정
                  </p>
                </div>
                <div>
                  <h3 className="mb-4">8. 개인정보의 안전성 확보조치</h3>
                  <p>
                    회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실,
                    도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                    다음과 같은 기술적/관리적/물리적 대책을 강구하고 있습니다.
                  </p>
                  <p>(1) 개인정보의 암호화</p>
                  <p>
                    회사는 관련 법률 규정 또는 내부 정책에 따라 개인정보를
                    암호화하여 안전하게 저장 및 관리하고 있습니다.
                  </p>
                  <p>(2)해킹 등에 대비한 대책</p>
                  <p>
                    회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가
                    유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
                    최신 보안 패치와 방화벽을 이용하여 이용자들의 개인정보나
                    자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화
                    통신인 SSL(Secure Socket Layer)방식 등을 통하여
                    네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
                    있습니다.
                  </p>
                  <p>(3)취급 직원의 최소화 및 교육</p>
                  <p>
                    회사의 개인정보 관련 취급 직원은 담당자에 한정시키고 있고
                    이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고
                    있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의
                    준수를 항상 강조하고 있습니다.
                  </p>
                  <p>(4)물리적 조치</p>
                  <p>
                    회사는 접근권한이 없는 자가 개인정보에 접근할 수 없도록 하기
                    위하여 개인정보를 보관하고 있는 전산실, 자료보관실 등의
                    접근을 통제하고 있습니다.
                  </p>
                  <p>(5)개인 정보 관리</p>
                  <p>
                    개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여
                    문제가 발견된 경우 즉시 수정하고 바로잡을 수 있도록 노력하고
                    있습니다. 단, 이용자 본인의 부주의나 인터넷 상의 문제로
                    아이디, 비밀번호 등 개인정보가 유출 되어 발생한 문제에 대해
                    회사는 일체의 책임을 지지 않습니다.
                  </p>
                </div>
                <div>
                  <h3 className="mb-4">9. 개인정보 보호책임자</h3>
                  <p>
                    회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을
                    처리하기 위하여 아래와 같이 개인정보보호책임자를 지정하고
                    있습니다.
                  </p>
                  <p className="ms-lg-1">
                    • 개인정보관리책임자: 강호천 대표 <br />
                    • 전화번호: 070-4610-4206
                    <br />
                    • 이메일: info@metawayltd.com <br />
                  </p>
                  <p>
                    회사가 제공하는 서비스를 이용하시면서 발생한 모든 개인정보
                    보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
                    보호책임자 및 고객지원센터로 문의하실 수 있습니다.
                  </p>
                  <p>•개인정보 침해에 대한 신고 또는 상담</p>
                  <p className="ms-lg-1">
                    KISA (개인정보 침해신고센터): (국번없이)118 /
                    https://privacy.kisa.or.kr
                    <br />
                    대검찰청 사이버수사과: (국번없이)1301 /
                    https://www.spo.go.kr
                    <br /> 경찰청 사이버수사국: (국번없이) 182 /
                    https://cyberbureau.police.go.kr
                  </p>
                </div>
                <div>
                  <h3 className="mb-4">10. 고지의 의무</h3>
                  <p>
                    이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에
                    따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는
                    변경사항을 확인할 수 있도록 홈페이지 내 공지사항을 통하여
                    고지합니다.
                  </p>
                </div>
                <p>시행일자: 2022.10.18</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
