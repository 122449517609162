import React, { Children, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "./Button";
import ModalContainer from "./Modal";
import orderApi from "../api/order";
import { userInfoState } from "../store/userState";
import { tossPayments } from "../configs/tossConfig";
import Contact from "../components/Contact";
import serviceApi from "../api/service";
import Hit from "./Hit";

export default function TossPayments({
  primeCost,
  price,
  discount,
  title,
  type,
  productCode,
  url,
  children,
  pageCode,
  pageTitle,
}) {
  const [modalShow, setModalShow] = useState(false);

  const token = localStorage.getItem("accessToken");
  const userInfo = useRecoilValue(userInfoState(token));
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const amount = params.get("amount");
  const orderId = params.get("orderId");
  const paymentKey = params.get("paymentKey");
  const createdOrderId = Math.random().toString(36).slice(2);
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (1 + date.getMonth())).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const randomNumber = Math.floor(Math.random() * 1000000);

  const handlePayButtonClick = async (event) => {
    event.preventDefault();

    if (!userInfo) {
      localStorage.removeItem("accessToken");
      setModalShow(true);

      return;
    }

    if (userInfo?.id) {
      if (event.target.payment.value === "카드") {
        try {
          await tossPayments.requestPayment("카드", {
            amount: price,
            orderId: createdOrderId,
            orderName: title,
            customerName: userInfo.name,
            successUrl:
              process.env.NODE_ENV === "production"
                ? `https://boostar.co.kr/${url}`
                : `${window.location.origin}/${url}`,
            failUrl:
              process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_ERROR_SERVER_URL
                : process.env.REACT_APP_ERROR_URL,
          });
        } catch (err) {
          if (err.message === "orderName: 상품 명은 필수 입니다.") {
            alert("결제하실 상품을 선택해주세요.");

            return;
          }

          alert(err.message);
        }
      }

      if (event.target.payment.value === "계좌이체") {
        try {
          await tossPayments.requestPayment("계좌이체", {
            amount: price,
            orderId: createdOrderId,
            orderName: title,
            successUrl:
              process.env.NODE_ENV === "production"
                ? `https://boostar.co.kr/${url}`
                : `${window.location.origin}/${url}`,
            failUrl:
              process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_ERROR_SERVER_URL
                : process.env.REACT_APP_ERROR_URL,
          });
        } catch (err) {
          alert(err.message);
        }
      }
    } else {
      setModalShow(true);
    }
  };

  const approvePayment = (async () => {
    if (amount && title.length) {
      try {
        const response = await orderApi.payment({
          orderId,
          paymentKey,
          amount,
          orderNumber: year + month + day + randomNumber,
          serviceType: type,
          serviceName: title,
          productCode: productCode,
          customer: userInfo,
        });

        if (response.result === "success") {
          navigate(`/order/success/${response?.data.orderNumber}`, {
            state: {
              serviceType: type,
            },
          });
        }
      } catch (err) {
        alert("결제중 에러가 발생했습니다. 잠시 후 다시 이용바랍니다.");
      }
    }
  })();

  return (
    <>
      <div className="">
        <div
          className="fw-bold pb-2 pt-lg-0 pt-3"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <p className="small text-muted mb-0">{type}</p>
          <span className="fs-5 fw-bold">{pageTitle}</span>
          {pageTitle === "개인 결제 서비스" ? "" : <Hit pageCode={pageCode} />}
        </div>
        <div
          className="pt-lg-2 pb-lg-2 py-2"
          style={{ borderBottom: "1px solid #c8c8c8" }}
        >
          <div className="d-flex align-items-center">
            <span
              className="text-muted small"
              style={{ textDecoration: "line-through" }}
            >
              {Number(primeCost).toLocaleString("ko-KR")} 원
            </span>
            <span className="fs-5 fw-bolder ms-lg-3 ms-3">
              {Number(price).toLocaleString("ko-KR")} 원
            </span>
            <span
              className="fw-bolder text-center ms-lg-3 ms-3"
              style={{
                // background: "#1f375b",
                color: "#ff3d0d",
                borderRadius: "5px",
              }}
            >
              {type === "Ebook" ? "" : "로켓진행 🚀"}
            </span>
          </div>
        </div>
        {children}
        <div
          className="d-flex justify-content-center mb-lg-5 mb-5 mx-lg-3 mx-3"
          style={{ borderTop: "1px solid #c8c8c8" }}
        >
          <form onSubmit={handlePayButtonClick}>
            <div className="d-flex flex-center mb-lg-2 my-3">
              <label className="mx-lg-4 mx-4">
                <input type="radio" name="payment" value="카드" />
                신용카드
              </label>
              <label className="mx-lg-4 mx-4">
                <input type="radio" name="payment" value="계좌이체" />
                계좌이체
              </label>
            </div>
            <div className="form-check">
              <label
                className="form-check-label small"
                htmlFor="flexCheckDefault"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="이용약관"
                  required
                />
                주문내용을 확인했으며, 결제에 동의합니다. (필수) &nbsp;
              </label>
            </div>
            <div className="d-flex mt-lg-2 mt-3">
              <Button
                width="10"
                border="none"
                text="결제하기"
                background="#fec230"
                color="#000000"
              />
              {pageTitle === "개인 결제 서비스" ? (
                <Button
                  width="10"
                  border="none"
                  text="돌아가기"
                  background="#000000"
                  color="#fec230"
                  onClick={() => navigate("/")}
                />
              ) : (
                <Button
                  width="10"
                  border="none"
                  text="무료 상담하기"
                  background="#000000"
                  color="#fec230"
                  onClick={() =>
                    navigate("/contact/service", {
                      state: {
                        service: `${title}`,
                      },
                    })
                  }
                />
              )}
            </div>
          </form>
        </div>
      </div>
      <ModalContainer
        show={modalShow}
        onHide={() => setModalShow(false)}
        content="서비스를 구매하시려면 로그인을 해주세요 🙂"
      >
        <div className="mt-lg-3">
          <Button
            width="10"
            text="확인"
            border="none"
            background="#fef4e5"
            color="#fbb60d"
            onClick={() => navigate("/login?retUrl=" + location.pathname)}
          />
        </div>
      </ModalContainer>
    </>
  );
}
