import React from "react";

export default function Schedule() {
  return (
    <section>
      <div className="container">
        <div className="mx-lg-5 mt-6">
          <div
            className="fw-bolder w-lg-20 text-center py-lg-1 py-1"
            style={{
              background: "#f4f4f5",
              color: "#2196F3",
              borderRadius: "5px",
            }}
          >
            SCHEDULE
          </div>
          <div className="my-lg-5 my-5">
            <div className="my-lg-2 my-4">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> 프로그램 일정</span>
              <p className="small text-muted">
                <span className="fw-bold" style={{ color: "#ff3d0d" }}>
                  ✓
                </span>{" "}
                현지 사정에 의해 일정은 변경될 수 있습니다.
              </p>
            </div>
            <div className="my-lg-5">
              <div>
                <div
                  className="row align-items-center mb-3"
                  style={{ background: "#c8c8c8" }}
                >
                  <p
                    className="col-lg-1 col-3 fs-5 text-center py-lg-1"
                    style={{ background: "#797979", color: "#ffffff" }}
                  >
                    1일차
                  </p>
                  <p className="col-lg-2 col-9">08/21 (월)</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-plane-alt fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">07:35</p>
                  <p className="col-lg-10 col-8 small">한국 인천 출국</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-plane-alt fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">11:05</p>
                  <p className="col-lg-10 col-8 small">베트남 호치민 도착</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">11:40</p>
                  <p className="col-lg-10 col-8 small">숙소 이동</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">12:30</p>
                  <p className="col-lg-10 col-8 small">호텔 도착 / 체크인</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">17:00</p>
                  <p className="col-lg-10 col-8 small">OT & Preview</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">18:00</p>
                  <p className="col-lg-10 col-8 small">저녁식사</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">21:00</p>
                  <p className="col-lg-10 col-8 small">자유시간 및 휴식</p>
                </div>
              </div>
              <div className="mt-lg-3 mt-3">
                <div
                  className="row align-items-center mb-3"
                  style={{ background: "#c8c8c8" }}
                >
                  <p
                    className="col-lg-1 col-3 fs-5 text-center py-lg-1"
                    style={{ background: "#797979", color: "#ffffff" }}
                  >
                    2일차
                  </p>
                  <p className="col-lg-2 col-9">08/22 (화)</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">09:00</p>
                  <p className="col-lg-10 col-8 small">
                    Debriefing Schedule & 이동
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">10:00</p>
                  <p className="col-lg-10 col-8 small">
                    세미나 (BSSC 환영사 및 소개 베트남 스타트업 생태계와 한국
                    스타트업, 기관의 기회)
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">12:00</p>
                  <p className="col-lg-10 col-8 small">점심식사</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">13:30</p>
                  <p className="col-lg-10 col-8 small">
                    현지탐방 (BSSC, 현지 AC, VC 및 공유오피스 방문)
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">17:00</p>
                  <p className="col-lg-10 col-8 small">자유시간 및 휴식</p>
                </div>
              </div>
              <div className="mt-lg-3 mt-3">
                <div
                  className="row align-items-center mb-3"
                  style={{ background: "#c8c8c8" }}
                >
                  <p
                    className="col-lg-1 col-3 fs-5 text-center py-lg-1"
                    style={{ background: "#797979", color: "#ffffff" }}
                  >
                    3일차
                  </p>
                  <p className="col-lg-2 col-9">08/23 (수)</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">09:00</p>
                  <p className="col-lg-10 col-8 small">
                    Debriefing Schedule & 이동
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">10:00</p>
                  <p className="col-lg-10 col-8 small">
                    세미나 (베트남 시장, 문화적 특성, 비즈니스 관례 현지 한국
                    투자사 투자 사례 InnoEx/Startup Wheel 소개)
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">12:00</p>
                  <p className="col-lg-10 col-8 small">점심식사</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">13:30 - 18:00</p>
                  <p className="col-lg-10 col-8 small">
                    현지탐방 (베트남 현지 스타트업 / 대기업 방문)
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">19:00 - 20:30</p>
                  <p className="col-lg-10 col-8 small">
                    현지 기관, 기업, 스타트업 네트워킹
                  </p>
                </div>
              </div>
              <div className="mt-lg-3 mt-3">
                <div
                  className="row align-items-center mb-3"
                  style={{ background: "#c8c8c8" }}
                >
                  <p
                    className="col-lg-1 col-3 fs-5 text-center py-lg-1"
                    style={{ background: "#797979", color: "#ffffff" }}
                  >
                    4일차
                  </p>
                  <p className="col-lg-2 col-9">08/24 (목)</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">09:00</p>
                  <p className="col-lg-10 col-8 small">
                    Debriefing Schedule & 이동
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">10:00 - 18:00</p>
                  <p className="col-lg-10 col-8 small">
                    전일 행사 참가 (InnoEx/Startup Wheel)
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">18:00</p>
                  <p className="col-lg-10 col-8 small">결집 및 이동</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">19:00</p>
                  <p className="col-lg-10 col-8 small">자유시간 및 휴식</p>
                </div>
              </div>
              <div className="mt-lg-3 mt-3">
                <div
                  className="row align-items-center mb-3"
                  style={{ background: "#c8c8c8" }}
                >
                  <p
                    className="col-lg-1 col-3 fs-5 text-center py-lg-1"
                    style={{ background: "#797979", color: "#ffffff" }}
                  >
                    5일차
                  </p>
                  <p className="col-lg-2 col-9">08/25 (금)</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">09:00</p>
                  <p className="col-lg-10 col-8 small">
                    Debriefing Schedule & 이동
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">10:00 - 18:00</p>
                  <p className="col-lg-10 col-8 small">
                    전일 행사 참가 (InnoEx/Startup Wheel)
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">18:00</p>
                  <p className="col-lg-10 col-8 small">
                    결집 및 이동(전체 프로그램 종료)
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">18:30 - 20:00</p>
                  <p className="col-lg-10 col-8 small">저녁식사</p>
                </div>
              </div>
              <div className="mt-lg-3 mt-3">
                <div
                  className="row align-items-center mb-3"
                  style={{ background: "#c8c8c8" }}
                >
                  <p
                    className="col-lg-1 col-3 fs-5 text-center py-lg-1"
                    style={{ background: "#797979", color: "#ffffff" }}
                  >
                    6일차
                  </p>
                  <p className="col-lg-2 col-9">08/26 (토)</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">09:00</p>
                  <p className="col-lg-10 col-8 small">
                    Debriefing Schedule & 이동
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-map fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">10:00 - 12:00</p>
                  <p className="col-lg-10 col-8 small">
                    공항으로 이동 및 출국수속
                  </p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-plane-alt fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">12:00</p>
                  <p className="col-lg-10 col-8 small">베트남 출발</p>
                </div>
                <div className="row align-items-center my-lg-2 my-1">
                  <div className="col-lg-1 col-1 text-center py-lg-1">
                    <i
                      className="bx bxs-plane-alt fs-4"
                      style={{ color: "#2196F3" }}
                    />
                  </div>
                  <p className="col-lg-1 col-3 small">18:30</p>
                  <p className="col-lg-10 col-8 small">한국 인천도착</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
