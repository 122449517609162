import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const TopButtonContainer = styled.div`
  position: fixed;
  right: 7%;
  bottom: 15%;
  z-index: 1;

  .top {
    color: #ff3d0d;
    border: 1px solid #ff3d0d;
    border-radius: 100%;
    /* background-color: #ffffff; */
  }

  .top:hover {
    background-color: #f4f4f5;
  }
`;

export default function ScrollToTop() {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleShowButton);

    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  return (
    <>
      {showButton && (
        <TopButtonContainer className="">
          <div className="top px-lg-3 px-2 py-lg-3 py-2" onClick={scrollToTop}>
            <p className="mb-0 fw-bolder small">TOP</p>
          </div>
        </TopButtonContainer>
      )}
    </>
  );
}
