import React from "react";

import Intro from "../components/Service/Intro2";
import More from "../components/More";
import RightImage from "../components/RightImage";
import LeftImage from "../components/LeftImage";

import introImage from "../assets/img/image/government_office.png";
import governmentImage1 from "../assets/img/image/government1.png";
import governmentImage2 from "../assets/img/image/government2.png";

export default function Government() {
  return (
    <main>
      <Intro
        image={introImage}
        title1="BOOSTAR for"
        title2="공공기관"
        subTitle="BOOSTAR는 여러 창업지원기관과 협약을 맺고 다양한 프로그램을 제공합니다."
        url="/contact"
      />
      <RightImage
        image={governmentImage1}
        title="공공 액셀러레이팅 사업 위탁 운영"
        paragraph1="공간 운영부터 인력 채용 프로그램, 판로 개척을 위한 홍보·마케팅
                  교육, 각 분야별 멘토링 프로그램, 투자 연계 프로그램을 제공하고
                  있습니다."
        paragraph2="BOOSTAR는 액셀러레이팅 사업의 시작부터 끝까지 전 과정을 운영할
                  수 있는 프로세스를 갖추고 있어 효율적인 사업 운영이
                  가능합니다."
      />
      <LeftImage
        image={governmentImage2}
        title="다양한 교육 프로그램 제공"
        paragraph1="스타트업 운영에 필요한 교육 프로그램을 제공합니다."
        paragraph2="BOOSTAR는 스타트업 및 액셀러레이팅에 관련된 모든 분야의 전문가
        네트워크를 확보하고 있습니다."
      />
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
