import React from "react";

export default function DayTag({ date }) {
  const deadline = new Date(date).getTime();
  const today = new Date().getTime();
  const gap = deadline - today;
  const dDay = Math.ceil(gap / (1000 * 60 * 60 * 24));

  return (
    <>
      {date === " 2023-12-31" && (
        <div style={{ borderRadius: "3px", background: "#c5f59e" }}>
          <p className="py-1 fw-bolder text-center">상시</p>
        </div>
      )}
      {dDay === 0 && (
        <div style={{ borderRadius: "3px", background: "#fec230" }}>
          <p className="py-1 fw-bolder text-center">D-Day</p>
        </div>
      )}
      {dDay > 0 && dDay <= 7 && (
        <div style={{ borderRadius: "3px", background: "#fec230" }}>
          <p className="py-1 fw-bolder text-center">D-{dDay}</p>
        </div>
      )}
      {dDay > 7 && date !== " 2023-12-31" && (
        <div style={{ borderRadius: "3px", background: "#fea8a8" }}>
          <p className="py-1 fw-bolder text-center">D-{dDay}</p>
        </div>
      )}
      {dDay < 0 && (
        <div style={{ borderRadius: "3px", background: "#c7e1fa" }}>
          <p className="py-1 fw-bolder text-center">마감</p>
        </div>
      )}
    </>
  );
}
