import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import orderApi from "../api/order";
import { userInfoState } from "../store/userState";

export default function OrderList() {
  const [orderInfo, setOrderInfo] = useState(null);

  const accessToken = localStorage.getItem("accessToken");
  const user = useRecoilValue(userInfoState(accessToken));

  const navigate = useNavigate();

  useEffect(() => {
    const getOrderList = async () => {
      const response = await orderApi.getOrderList(user.email);

      if (response.result === "success") {
        setOrderInfo(response.data.orderList);
      }
    };

    getOrderList();
  }, []);

  const OrderStatus = (status) => {
    const [statusContent, setStatusContent] = useState("");
    const [statusColor, setStatusColor] = useState("");

    useEffect(() => {
      switch (status.status) {
        case "DONE":
          setStatusContent("결제완료");
          setStatusColor("#000000");
          break;
        case "CANCELING":
          setStatusContent("결제취소중");
          setStatusColor("#fbb60d");
          break;
        case "CANCELED":
          setStatusContent("결제취소");
          setStatusColor("#ff3d0d");
          break;
      }
    }, [status.status]);

    return (
      <span
        className="px-lg-2 py-lg-1 px-2"
        style={{
          borderRadius: "5px",
          color: `${statusColor}`,
          background: "#fef4e5",
        }}
      >
        {statusContent}
      </span>
    );
  };

  return (
    <main>
      <section className="position-relative">
        <div className="container position-relative">
          <div className="position-relative pt-9 pb-9 pb-lg-11">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column w-lg-50 w-100">
                <nav className="nav nav-pills">
                  <a href="/my_page" className="nav-link">
                    <i className="bx bx-user-circle me-2"></i>내 프로필
                  </a>
                  <a href="/order/list" className="nav-link active">
                    <i className="bx bx-credit-card me-2"></i>결제 내역
                  </a>
                  <a href="/my_class/list" className="nav-link">
                    <i className="bx bx-rocket me-2" />
                    강의 신청 내역
                  </a>
                  <a href="/review/list" className="nav-link">
                    <i className="bx bx-comment-detail me-2" />
                    내가 쓴 리뷰
                  </a>
                </nav>
                <div className="d-flex align-items-center pt-1 pb-3">
                  <span className="flex-grow-1 border-bottom pt-1" />
                </div>
                <div className="h-100">
                  {orderInfo?.map((info, index) => {
                    const orderDate = new Date(info?.approved_at);

                    return (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between py-lg-3 py-3 px-lg-3 px-2 my-lg-3 my-3"
                          style={{
                            border: "1px solid #e8e8e8",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          key={info?.order_number}
                          onClick={() =>
                            navigate(`/order/detail/${info?.order_number}`)
                          }
                        >
                          <div className="my-lg-2 my-2">
                            <OrderStatus status={info?.status} />
                            <span className="mx-lg-3 mx-3 small text-muted">
                              {orderDate?.toLocaleDateString().slice(0, -1)}
                            </span>
                            <div className="d-flex align-items-center pt-1">
                              <span className="flex-grow-1 border-bottom pt-1" />
                            </div>
                          </div>
                          <div>
                            <div className="my-lg-1 my-1">
                              주문번호: {info?.order_number}
                            </div>
                            <div className="my-lg-1 my-1">
                              {info?.service_type} - {info?.service_name}
                            </div>
                          </div>
                          <div className="d-flex justify-content-end pt-lg-3">
                            <div
                              className="me-lg-2 me-2"
                              style={{ color: "#387bfe" }}
                            >
                              상세보기 &gt;&gt;
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
