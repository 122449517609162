import React from "react";
import { useParams } from "react-router-dom";

import TossPayments from "../components/tossPayments";

export default function PrivatePay() {
  const { price, pageCode, productCode } = useParams();

  return (
    <main className="position-relative">
      <div className="container py-lg-6 px-lg-11">
        <div className="d-flex flex-center">
          <div className="col-lg-5">
            <TossPayments
              primeCost=""
              price={price}
              discount="0"
              type="부스타 > 서비스"
              title="개인 결제"
              productCode={productCode}
              pageCode={pageCode}
              pageTitle="개인 결제 서비스"
              url={`pay/${price}/${pageCode}/${productCode}`}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
