import React from "react";

import ExpertIntro from "../components/Service/Intro2";
import More from "../components/More";

import introImage from "../assets/img/image/tax_accountant_intro.png";
import step1Image from "../assets/img/image/tax_accountant_step1.png";
import step2Image from "../assets/img/image/tax_accountant_step2.png";

export default function TaxAccountant() {
  return (
    <main>
      <ExpertIntro
        image={introImage}
        title1="스타트업을 위한 세무 파트너"
        title2="BOOSTAR에서 확인하세요"
        subTitle="지속적인 성장을 위한 빌드 업, BOOSTAR의 세무 전문가와 함께하세요."
        url="/contact"
      />
      <section className="position-relative" style={{ background: "#f6f6f6" }}>
        <div className="container py-8">
          <div className="row">
            <div className="my-2 my-lg-6 px-3">
              <h1 className="lh-base mb-3 mb-lg-5 fs-3">
                스타트업 전문 세무사 찾고 계신가요?
              </h1>
              <h5 className="mb-3 mb-lg-3">
                번거로운 세무회계 업무, 세무 파트너에게 맡기시고 사업 성장에
                집중하세요.
              </h5>
              <h5 className="mb-3 mb-lg-3">
                스타트업 전문 세무사를 통해 기본적인 세무 업무부터 자금 조달을
                위한 준비까지.
                <br />
                BOOSTAR에서 스타트업 전문 세무사와 함께 확실하게 해결할 수
                있습니다.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center text-white">
              <div
                className="px-lg-3 px-3 py-lg-2 py-2 w-lg-90"
                style={{ background: "#42445c" }}
              >
                <p className="lh-base fs-4 mb-2">Step 1</p>
                <h1 className="lh-base fs-3 mb-2 mb-lg-3">
                  세무 대행이 꼭 필요한 이유
                </h1>
                <h6 className="lh-base fw-bold">비용 절감</h6>
                <h6 className="lh-base mb-3 mb-lg-4">
                  당장 매출이 발생하지 않거나 적다고 해도 세무 업무는 필수!
                  <br />
                  사업을 하면서 발생한 손실을 기록해두고 추후에 공제가
                  가능합니다.
                </h6>
                <h6 className="lh-base fw-bold">자금 조달을 위한 준비</h6>
                <h6 className="lh-base mb-3 mb-lg-4">
                  투자나 대출을 받을 때 재무제표를 반드시 요청 받습니다.
                  <br />
                  세법 및 회계상 지식이 부족하면 정확한 재무제표 작성이
                  어렵습니다.
                </h6>
                <h6 className="lh-base fw-bold">기장(장부 작성) 대리</h6>
                <h6 className="lh-base mb-2 mb-lg-4">
                  법인사업자라면 복식부기로 의무적으로 장부를 작성하고 신고해야
                  합니다.
                  <br />
                  복식부기 또한 전문성을 필요로 해서 직접 작성이 매우
                  번거롭습니다.
                </h6>
              </div>
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={step1Image} className="img-fluid w-lg-90" />
            </div>
          </div>
        </div>
      </section>
      <section
        className="position-relative overflow-hidden"
        style={{ background: "#f6f6f6" }}
      >
        <div className="container py-8 py-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <img src={step2Image} className="img-fluid w-lg-90" />
            </div>
            <div className="col-lg-6 my-2 my-lg-6 px-3 flex-center">
              <div
                className="px-lg-3 px-3 py-lg-7 py-2 w-lg-90"
                style={{ background: "#fec72f" }}
              >
                <p className="lh-base fs-4 mb-2">Step 1</p>
                <h1 className="lh-base fs-3 mb-2 mb-lg-6">
                  스타트업 전문
                  <br /> 세무사를 만나보세요
                </h1>
                <h6 className="lh-base mb-3 mb-lg-4">
                  스타트업에 대한 이해를 기반으로 세무 서비스를 제공합니다.
                </h6>
                <h6 className="lh-base mb-3 mb-lg-4">
                  같은 분야의 세무 서비스라도, 일반 기업과 스타트업을 대상으로
                  하는 <br />
                  세무 서비스는 다릅니다.
                </h6>
                <h6 className="lh-base mb-2 mb-lg-4">
                  BOOSTAR에서 스타트업 전문 세무사를 빠르게 연결해드립니다.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <More
        content="BOOSTAR와 함께할 준비되셨나요?"
        subContent="혼자 고민하지 마세요! BOOSTAR의 전문가가 여러분을 기다리고 있습니다."
        buttonTitle="시작하기"
        url="/contact"
      />
    </main>
  );
}
