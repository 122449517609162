import React from "react";

export default function TimeLine() {
  return (
    <section>
      <div className="container d-flex flex-center flex-column">
        <div className="mx-lg-5 w-lg-90">
          <div className="fw-bolder mb-lg-4 mt-6" style={{ color: "#2196F3" }}>
            TimeLine
          </div>
          <div className="">
            <div className="mb-lg-4 my-5">
              <span style={{ color: "#F44336" }}>▶︎</span>
              <span className="fw-bolder"> INTERNATIONAL TRACK</span>
            </div>
            <div className="row mb-lg-5 mb-3">
              <div
                className="col-lg-2 col-3"
                // style={{ borderRadius: "5px 0 0 5px", background: "#ffffff" }}
              >
                <img
                  src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/timeline_3%EC%9B%94.png"
                  className="img-fluid"
                />
              </div>
              <div
                className="col-lg-9 col-9 d-flex flex-column justify-content-center px-lg-3 py-2"
                style={{
                  border: "2px solid #4CAF50",
                  borderRadius: "0 5px 5px 0",
                }}
              >
                <p className="fw-bolder mb-0">참가신청 전세계 동시 오픈</p>
                <p className="mb-0 small">
                  • 해외 파트너와 협력하여 참가 신청서 모집
                </p>
                <p className="mb-0 small fw-bolder">
                  • 23.04.14 오후 6시 부스타 내 참가 신청 마감
                </p>
              </div>
            </div>
            <div className="row my-lg-5 mb-3">
              <div
                className="col-lg-2 col-3"
                // style={{ background: "#8DC3EE", borderRadius: "5px 0 0 5px" }}
              >
                <img
                  src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/timeline_6%EC%9B%94.png"
                  className="img-fluid"
                />
              </div>
              <div
                className="col-lg-9 col-9 d-flex flex-column justify-content-center px-lg-3 py-2"
                style={{
                  border: "2px solid #4CAF50",
                  borderRadius: "0 5px 5px 0",
                }}
              >
                <p className="fw-bolder mb-0">Top 50 선정</p>
                <p className="mb-0 small">• 현지 전문가와 인터뷰</p>
                <p className="mb-0 small">• 서류심사</p>
                <p className="mb-0 small fw-bolder">• TOP 50 발표</p>
              </div>
            </div>
            <div className="row my-lg-5 mb-3">
              <div
                className="col-lg-2 col-3"
                // style={{ background: "#56ABEE", borderRadius: "5px 0 0 5px" }}
              >
                <img
                  src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/timeline_7%EC%9B%94.png"
                  className="img-fluid"
                />
              </div>
              <div
                className="col-lg-9 col-9 d-flex flex-column justify-content-center px-lg-3 py-2"
                style={{
                  border: "2px solid #4CAF50",
                  borderRadius: "0 5px 5px 0",
                }}
              >
                <p className="fw-bolder mb-0">Top 50 활동</p>
                <p className="mb-0 small">
                  • 베트남 및 동남아시아 시장 진출 전략 준비
                </p>
                <p className="mb-0 small">
                  • 현지 시장 인사이트를 학습하고 현지 파트너와 연결하기 위해
                  워크샵 참여
                </p>
              </div>
            </div>
            <div className="row my-lg-5">
              <div
                className="col-lg-2 col-3"
                // style={{ background: "#2196F3", borderRadius: "5px 0 0 5px" }}
              >
                <img
                  src="https://groundup-s3.s3.ap-northeast-2.amazonaws.com/boostar/image/timeline_8%EC%9B%94.png"
                  className="img-fluid"
                />
              </div>
              <div
                className="col-lg-9 col-9 d-flex flex-column justify-content-center px-lg-3 py-2"
                style={{
                  border: "2px solid #4CAF50",
                  borderRadius: "0 5px 5px 0",
                }}
              >
                <p className="fw-bolder mb-0">
                  베트남 Startup Day: International Track의 마지막 라운드(08.25
                  ~ 08.26)
                </p>
                <p className="mb-0 small">
                  • 베트남의 대형 기업 CEO, 벤처 캐피탈 및 투자자로 구성된
                  심사위원회 앞에서 상위 5개 팀이 경쟁하는 피칭 대결
                </p>
                <p className="mb-0 small">
                  • 베트남 호치민시에서 개최되는 행사에서 상위 50개 참가자의
                  2일간의 전시회
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
