import React from "react";
import { Modal, CloseButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import setCookieExpires from "../util/setCookieExpires";
import getCookie from "../util/getCookie";

const ModalBodyContainer = styled.div`
  .button {
    cursor: pointer;
  }
`;

export default function EventModal(props) {
  const { onHide, show, setShow } = props;

  const popupImage =
    "https://cdn-s3.boostar.co.kr/boostar/class/54b96314-dd47-4064-9c71-240791667cab.jpg";

  const cookie = getCookie("boostar:popup");

  const handlesOneDayCloseButtonClick = () => {
    setCookieExpires(1);
    setShow(false);
  };

  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      {/* <Modal.Header
        className="justify-content-end py-lg-1"
        style={{ background: "#E1E1E1", border: 0 }}
      >
        <CloseButton onClick={onHide} className="fs-4">
          <span className="input-icon">
            <i className={`bx bx-x`} />
          </span>
        </CloseButton>
      </Modal.Header> */}
      <Modal.Body
        className="my-lg-0 mx-lg-0 py-lg-0 px-lg-0 py-0 px-0"
        style={{ background: "#ffffff", borderRadius: "15px" }}
        onClick={() => navigate("/class/230525")}
      >
        <ModalBodyContainer className="container my-lg-0 mx-lg-0 py-lg-0 px-lg-0 px-0">
          <div className="d-flex flex-column align-items-center">
            <img
              src={popupImage}
              className="img-fluid w-lg-100 w-100 button"
              style={{ borderRadius: "6px 6px 0 0" }}
            />
          </div>
        </ModalBodyContainer>
      </Modal.Body>
      <Modal.Footer
        className="py-lg-3 py-2"
        style={{ background: "#ffffff", padding: 0, border: 0 }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-around">
            <div
              type="button"
              className="col-lg-6 col-6 text-center"
              style={{
                borderRight: "1px solid black",
                fontWeight: "31rem",
              }}
              onClick={handlesOneDayCloseButtonClick}
            >
              하루 동안 보지 않기
            </div>
            <div
              type="button"
              className="col-lg-6 col-6 text-center"
              style={{
                fontWeight: "31rem",
              }}
              onClick={onHide}
            >
              닫기
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
